/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const SWITCH_ROLE = "SWITCH_ROLE";

/* USER */
export const UPDATE_USER = "UPDATE_USER";
export const USER_LOADING = "USER_LOADING";
export const GET_USER_DOCUMENTS = "GET_USER_DOCUMENTS";

/* BREAD CRUMBS */
export const PUSH_CRUMBS = "PUSH_CRUMBS";
export const PUSH_NEW_CRUMBS = "PUSH_NEW_CRUMBS";
export const BACK_TO_CRUMBS = "BACK_TO_CRUMBS";
export const POP_CRUMBS = "POP_CRUMBS";

/* PHOTO LOADER */
export const SET_TIME_ID = "SET_TIME_ID";
export const SET_STATUS = "SET_STATUS";
