import React from "react";
import { Container } from "reactstrap";
import RightIcon from "mdi-react/ChevronRightIcon";

const CreateProjectBreadCrumbs = ({
  breadCrumbs,
  currentIndex,
  setPage,
  setBreadCrumbs, projectName,
}) => {
  return (
    <Container>
        <div>
            {!!projectName &&  <div className={"projectNameContainer"}>Project: {projectName}</div>}
            <div className="breadCrumbsContainer">
                {breadCrumbs.length
                    ? breadCrumbs.map((item, index) => {
                        return (
                            <div
                                className="breadCrumb"
                                style={{
                                    color: index + 1 === currentIndex - 1 ? "#007DBC" : "black",
                                    fontWeight:
                                        index + 1 === currentIndex - 1 ? "bold" : "normal",
                                }}
                            >
                  <span
                      className="breadCrumbName"
                      onClick={() => {
                          setPage(index + 1);
                          setBreadCrumbs(
                              breadCrumbs.filter(
                                  (item, itemIndex) => itemIndex < index
                              )
                          );
                      }}
                  >
                    {item.name}
                  </span>{" "}
                                {currentIndex - 1 !== index + 1 ? (
                                    <RightIcon className="page-subhead subhead" />
                                ) : null}{" "}
                                <span>&nbsp;</span>
                            </div>
                        );
                    })
                    : null}
            </div>
        </div>

    </Container>
  );
};

export default CreateProjectBreadCrumbs;
