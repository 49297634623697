import React, { useState } from "react";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { CreateIcon } from "../../../../constants/icons";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";
import ColorFieldMaterial from "../../../../shared/components/material_field/ColorFieldMaterial";

const requiredColor = (value) =>
  value ? undefined : "You must select a color";
const required = (value) => (value ? undefined : "You must enter a value");
const requiredNumber = (value) => {
  if (value) {
    if (value < 0) {
      return "Value must be a positive number";
    }
    return undefined;
  } else {
    return "You must enter a value";
  }
};

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength5 = maxLength(5);

var values = ["", 0, 0, "#"];

let AddTypeForm = ({ handleSubmit, reset }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const toggle = () => setModalIsOpened(!modalIsOpened);

  return (
    <div>
      <Button className="button" onClick={toggle}>
        <div className="addButton">
          <div className="mr-2">
            <CreateIcon />
          </div>
          Add new type
        </div>
      </Button>

      <Modal
        isOpen={modalIsOpened}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary locum-preview-modal createBlockModal`}
      >
        <ModalHeader className="modal-header">
          <div>
            <h3>Add new type</h3>
          </div>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => {
              toggle();
              reset();
            }}
          />
        </ModalHeader>

        <ModalBody className="createBlockModalBody">
          <form
            id={"add_type_form"}
            onSubmit={(event) => {
              handleSubmit(event);
              event.stopPropagation();

              if (
                values[0].length <= 5 &&
                parseInt(values[1]) >= 0 &&
                parseInt(values[2]) >= 0 &&
                values[3] !== "#"
              ) {
                values = ["", 0, 0, "#"];
                toggle();
                reset();
              }
            }}
          >
            <Row md={12} className="mt-2">
              <Field
                name="reference"
                component={TextFieldMaterial}
                placeholder="Enter Type Reference/Number/Name"
                type={"text"}
                validate={[required, maxLength5]}
                onChange={(value) => {
                  values[0] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfBedrooms"
                component={TextFieldMaterial}
                placeholder="Enter Number of Bedrooms"
                type={"number"}
                validate={requiredNumber}
                onChange={(value) => {
                  values[1] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfBathrooms"
                component={TextFieldMaterial}
                placeholder="Enter Number of Bathrooms"
                type={"number"}
                validate={requiredNumber}
                onChange={(value) => {
                  values[2] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <span className="sp16">Select color</span>

              <Field
                name="colorCode"
                component={ColorFieldMaterial}
                type={"color"}
                validate={requiredColor}
                onChange={(value) => {
                  values[3] = value.target.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4 justify-content-end">
              <Row md={12} className="justify-content-end">
                <Col md={5}>
                  <Button
                    className="cancelButton"
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={5}>
                  <Button
                    className="button"
                    type={"submit"}
                    form={"add_type_form"}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "add_type_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(AddTypeForm);
