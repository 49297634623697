import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm, initialize } from "redux-form";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";
import ColorFieldMaterial from "../../../../shared/components/material_field/ColorFieldMaterial";
import store from "../../../../redux/store";

const required = (value) => (value ? undefined : "You must enter a value");
const requiredNumber = (value) => {
  if (value) {
    if (value < 0) {
      return "Value must be a positive number";
    }
    return undefined;
  } else {
    return "You must enter a value";
  }
};

const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength5 = maxLength(5);

var values = ["_", 1, 1];

let EditTypeForm = ({
  handleSubmit,
  reference,
  noOfBedrooms,
  noOfBathrooms,
  colorCode,
}) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const toggle = () => setModalIsOpened(!modalIsOpened);

  function initializeEditTypeForm() {
    store.dispatch(
      initialize("edit_type_form", {
        reference,
        noOfBedrooms,
        noOfBathrooms,
        colorCode,
      })
    );
  }

  useEffect(() => {
    if (modalIsOpened) {
      initializeEditTypeForm();
    }
  }, [modalIsOpened]);

  return (
    <div className="centered">
      <Button
        className="editButton"
        onClick={() => {
          toggle();
        }}
      >
        EDIT TYPE
      </Button>

      <Modal
        isOpen={modalIsOpened}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary locum-preview-modal createBlockModal`}
      >
        <ModalHeader className="modal-header">
          <div>
            <h3>Edit type</h3>
          </div>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => {
              toggle();
            }}
          />
        </ModalHeader>

        <ModalBody className="createBlockModalBody">
          <form
            id={"edit_type_form"}
            onSubmit={(event) => {
              handleSubmit(event);
              event.stopPropagation();

              if (
                values[0].length <= 5 &&
                parseInt(values[1]) >= 0 &&
                parseInt(values[2]) >= 0
              ) {
                values = ["_", 1, 1];
                toggle();
              }
            }}
          >
            <Row md={12} className="mt-2">
              <Field
                name="reference"
                component={TextFieldMaterial}
                placeholder="Enter Type Reference/Number/Name"
                type={"text"}
                validate={[required, maxLength5]}
                onChange={(value) => {
                  values[0] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfBedrooms"
                component={TextFieldMaterial}
                placeholder="Enter Number of Bedrooms"
                type={"number"}
                validate={requiredNumber}
                onChange={(value) => {
                  values[1] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfBathrooms"
                component={TextFieldMaterial}
                placeholder="Enter Number of Bathrooms"
                type={"number"}
                validate={requiredNumber}
                onChange={(value) => {
                  values[2] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <span className="sp16">Edit color</span>

              <Field
                name="colorCode"
                component={ColorFieldMaterial}
                type={"color"}
              />
            </Row>

            <Row md={12} className="mt-4 justify-content-end">
              <Row md={12} className="justify-content-end">
                <Col md={5}>
                  <Button
                    className="cancelButton"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={5}>
                  <Button
                    className="button"
                    type={"submit"}
                    form={"edit_type_form"}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "edit_type_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditTypeForm);
