import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "reactstrap";
import { reduxForm, FieldArray } from "redux-form";
import Select, { components } from "react-select";

var selectedType = {};

function sumOfFlatsWithType(block) {
  return block.levels
    .map((level) =>
      level.flats.length
        ? level.flats
            .map((flat) => (flat.hasOwnProperty("type") ? 1 : 0))
            .reduce((prev, next) => prev + next)
        : 0
    )
    .reduce((prev, next) => prev + next);
}

const renderOption = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className={"typeOption"}>
        {data.reference}
        <div
          className={"typeColor"}
          style={{ backgroundColor: data.colorCode }}
        ></div>
      </div>
    </components.Option>
  );
};

const renderSingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div className={"typeOption"}>
        {data.reference}
        <div
          className={"typeColor"}
          style={{ backgroundColor: data.colorCode }}
        ></div>
      </div>
    </components.SingleValue>
  );
};

const renderTypes = ({ fields }) => {
  useEffect(() => {
    selectedType = fields.get(0);
  }, []);

  return (
    <Select
      options={fields.getAll()}
      components={{ Option: renderOption, SingleValue: renderSingleValue }}
      className={"typeSelect"}
      isSearchable={false}
      placeholder={"Select type"}
      defaultValue={fields.getAll()[0]}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option}
      onChange={(val) => (selectedType = val)}
      styles={{ valueContainer: (base) => ({ ...base, height: "50px" }) }}
    />
  );
};

const renderBlocks = ({ fields, saveProject }) => {
  // Triggering rerender
  const [trigger, setTrigger] = useState(false);
  const [everyFlatHasType, setEveryFlatHasType] = useState(false);

  const noOfFlatsWithType = () => {
    return fields
      .getAll()
      .map((block) =>
        block.levels
          .map((level) =>
            level.flats.length
              ? level.flats
                  .map((flat) => (flat.hasOwnProperty("type") ? 1 : 0))
                  .reduce((prev, next) => prev + next)
              : 0
          )
          .reduce((prev, next) => prev + next)
      )
      .reduce((prev, next) => prev + next);
  };

  const checkNoOfFlats = () => {
    const noOfFlats = fields
      .getAll()
      .map((block) =>
        block.levels
          .map((level) => parseInt(level.flats.length))
          .reduce((prev, next) => prev + next)
      )
      .reduce((prev, next) => prev + next);

    if (noOfFlats === noOfFlatsWithType()) {
      setEveryFlatHasType(true);
    } else {
      setEveryFlatHasType(false);
    }
  };

  useEffect(() => {
    checkNoOfFlats();
  }, []);

  useEffect(() => {
    checkNoOfFlats();
  }, [noOfFlatsWithType()]);

  const clearFlats = (index) => {
    var helperBlock = fields.get(index);

    for (let i = 0; i < helperBlock.levels.length; i++) {
      for (let j = 0; j < helperBlock.levels[i].flats.length; j++) {
        delete helperBlock.levels[i].flats[j].type;
      }
    }

    fields.splice(index, 1, helperBlock);
    setTrigger(!trigger);
  };

  const editFlat = (blockIndex, levelIndex, flatIndex) => {
    const helperBlock = fields.get(blockIndex);

    helperBlock.levels[levelIndex].flats[flatIndex].type = selectedType;

    fields.splice(blockIndex, 1, helperBlock);
    setTrigger(!trigger);
  };

  return (
    <>
      <Row md={12} className="justify-content-between">
        <Col md={4}>
          <h2>Define types</h2>
        </Col>

        <div className="buttonContainer">
          <Col md={6}>
            <Button className="button" onClick={saveProject}>
              Save
            </Button>
          </Col>

          <Col md={6} className={"justify-content-end d-flex"}>
            <Button
              type={"submit"}
              className="text-nowrap button"
              disabled={!everyFlatHasType}
            >
              Next
            </Button>
          </Col>
        </div>
      </Row>

      <div>
        <FieldArray name={"types"} component={renderTypes} />

        <Row md={12} className={"mt-3"}>
          <Col md={12}>
            {fields &&
              fields.length &&
              fields.getAll().map((block, blockIndex) => (
                <div key={blockIndex}>
                  <div className="d-flex flex-row align-items-center">
                    <div className="blockContainer">
                      <div className="levelLetterContainer">{block.id}</div>

                      <div>Block </div>

                      <div className="flatLimit">
                        ({sumOfFlatsWithType(block)}/{block.quantity})
                      </div>
                    </div>

                    <Button
                      className="button"
                      style={{
                        marginBottom: 0,
                        paddding: 0,
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        clearFlats(blockIndex);
                      }}
                    >
                      Clear all
                    </Button>
                  </div>
                  <Col className="align-items-center">
                    {block.levels.map((level, levelIndex) => (
                      <Row md={12} className="flex-nowrap" key={levelIndex}>
                        <div className="levelsContainer">
                          <div className="levelContainer flatLevel">
                            <div className="levelLetterContainer">
                              {level.level}
                            </div>

                            <div>Level {level.level}</div>
                          </div>
                        </div>
                        <Row className=" flatRowContainer flex-nowrap">
                          {level.flats.map((flat, flatIndex) => (
                            <div
                              className={"flatContainer"}
                              style={{
                                backgroundColor: flat.hasOwnProperty("type")
                                  ? flat.type.colorCode
                                  : "white",
                                cursor: "pointer",
                              }}
                              key={flatIndex}
                              onClick={() => {
                                editFlat(blockIndex, levelIndex, flatIndex);
                              }}
                            >
                              {flat.flatId}
                            </div>
                          ))}
                        </Row>
                      </Row>
                    ))}
                  </Col>
                </div>
              ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

let CreateProjectStepSix = (props) => {
  const { handleSubmit, saveProject } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container className="m-0">
        <FieldArray
          name="blocks"
          component={renderBlocks}
          rerenderOnEveryChange={true}
          props={{
            saveProject,
          }}
        />
      </Container>
    </form>
  );
};

CreateProjectStepSix = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepSix);

export default CreateProjectStepSix;
