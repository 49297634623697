import React from 'react';
import { Link } from 'react-router-dom';
import TopbarProfile from './TopbarProfile';
import { connect } from "react-redux";
import TopbarSidebarButton from './TopbarSidebarButton'

class TopbarWithNavigation extends React.Component {
    render() {
        const { toggleSidebar } = this.props;
        return (
            <div className="topbar topbar--navigation">
                <div className="topbar__wrapper">
                    <div className="topbar__left">
                        <TopbarSidebarButton toggleSidebar={toggleSidebar}/>
                        <Link className="topbar__logo" to="/" />
                    </div>
                    <div className="topbar__right">
                        <TopbarProfile />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(
    mapStateToProps
)(TopbarWithNavigation);
