import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PROJECT_STATUS } from '../../../../constants/enamerations';
import { CollapseIcon, CreateIcon, ExpandIcon } from '../../../../constants/icons';
import ContractProjectsTable from './ContractProjectsTable';
import { getContractsWithProjectsStatuses } from '../../../../redux/services/contracts';
import {PREVIEW_PROJECT} from "../../../../constants/routes";

const ProjectTypes = ({ contract }) => {
  const history = useHistory();
  const [contractWithProjectsStatuses, setContractWithProjectStatuses] = useState();
  const [activeCollapse, setActiveCollpse] = useState({
    [PROJECT_STATUS.DRAFT.value]: {
      active: false,
    },
    [PROJECT_STATUS.ACTIVE.value]: {
      active: false,
    },
    [PROJECT_STATUS.COMPLETED.value]: {
      active: false,
    },
  });

  const toggleCollapse = (status) => {
    setActiveCollpse({
      ...activeCollapse,
      [PROJECT_STATUS[status].value]: {
        active: !activeCollapse[PROJECT_STATUS[status].value]
          .active,
      },
    })
  }

  useEffect(() => {
    getContractsWithProjectsStatuses(contract.id)
    .then(res => setContractWithProjectStatuses(res))
  },[])

  return (
  <Row className='mt-4'>
  <Col md={12} xl={12} className="d-flex justify-content-between">
    <h1 className="page-title">Projects</h1>
    <Button
      className="btn_with_img"
      color="primary"
      onClick={() => {
        history.push({
          pathname:`/projects/createdProjects/`,
          state:{contractId:contract.id}
        });
      }}
    >
      <CreateIcon /> Create project
    </Button>
  </Col>

  <Col md={12} xl={12}>
    <Card>
      <CardBody>
        <Row className="pl-4 pr-4">
          <div
            className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
            onClick={() => toggleCollapse('DRAFT')}
          >
            <h5 className="page-title m-0">DRAFT PROJECTS</h5>
            {activeCollapse[PROJECT_STATUS.DRAFT.value].active ? (
              <CollapseIcon />
            ) : (
              <ExpandIcon />
            )}
          </div>
        </Row>
        {activeCollapse[PROJECT_STATUS.DRAFT.value].active && (
          <Row className="pl-3 pr-3">
            <ContractProjectsTable
                status={PROJECT_STATUS.DRAFT.value}
                href={PREVIEW_PROJECT}
                contract={contractWithProjectsStatuses}
            />
          </Row>
        )}
      </CardBody>
    </Card>
  </Col>

  <Col md={12} xl={12} className="mt-4">
    <Card>
      <CardBody>
        <Row className="pl-4 pr-4">
          <div
            className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
            onClick={() => toggleCollapse('ACTIVE')}
          >
            <h5 className="page-title m-0">ACTIVE PROJECTS</h5>
            {activeCollapse[PROJECT_STATUS.ACTIVE.value].active ? (
              <CollapseIcon />
            ) : (
              <ExpandIcon />
            )}
          </div>
        </Row>
        {activeCollapse[PROJECT_STATUS.ACTIVE.value].active && (
          <Row className="pl-3 pr-3">
            <ContractProjectsTable
                status={PROJECT_STATUS.ACTIVE.value}
                href={PREVIEW_PROJECT}
                contract={contractWithProjectsStatuses}
            />
          </Row>
        )}
      </CardBody>
    </Card>
  </Col>

  <Col md={12} xl={12} className="mt-4">
    <Card>
      <CardBody>
        <Row className="pl-4 pr-4">
          <div
            className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
            onClick={() => toggleCollapse('COMPLETED')}
          >
            <h5 className="page-title m-0">PROJECTS HISTORY</h5>
            {activeCollapse[PROJECT_STATUS.COMPLETED.value].active ? (
              <CollapseIcon />
            ) : (
              <ExpandIcon />
            )}
          </div>
        </Row>
        {activeCollapse[PROJECT_STATUS.COMPLETED.value].active && (
          <Row className="pl-3 pr-3">
            <ContractProjectsTable
                status={PROJECT_STATUS.COMPLETED.value}
                href={PREVIEW_PROJECT}
                contract={contractWithProjectsStatuses}
            />
          </Row>
        )}
      </CardBody>
    </Card>
  </Col>
</Row>
  )
}

export default ProjectTypes
