import React, {useEffect, useState} from "react"
import {Link, useParams, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {Container, Card, CardBody, Row, Col} from 'reactstrap';
import RequestsTable from "./Tables/RequestsTable"
import RequestsFilterForm from "./Forms/RequestsFilterForm"
import {getRequests, getQARequests, getAdditionalWorkRequests} from "../../redux/services/requests"
import Loading from "../../shared/components/Loader"
import {pushCrumbs} from "../../redux/actions/bread_crumbs"
import {PROJECT_STRUCTURE, REQUEST_TYPE} from "../../constants/enamerations"
import {CONTRACTS, PREVIEW_CONTRACT, PREVIEW_PROJECT} from "../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";
import {getProjectsById} from "../../redux/services/projects";
const index = ({match}) => {
    const params = useParams()
    const [requests, setRequests] = useState(null)
    const [project, setProject] = useState(null)
    const [page, setPage] = useState(0)
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState({
        structure: {
            target_ids: [+match.params.id],
            target_type: PROJECT_STRUCTURE.PROJECT
        },
        job_type: "",
        unit_type: ""
    });
    const getProject = () => {
        getProjectsById(+params.id)
            .then(res => {
                setProject(res)
            })
    }

    useEffect(() => {
        getProject();
    }, [])

    useEffect(() => {
        request();
    }, [filter, page]);

    const request = () => {
        setLoad(true);
        filter && filter.data === REQUEST_TYPE.JOB_REQUEST.value ?
            getRequests(match.params.id, filter, page)
                .then(res => {
                    setRequests(res);
                    setLoad(false)
                })
             : filter && filter.data === REQUEST_TYPE.QA_REQUEST.value ?
            getQARequests(match.params.id, filter, page)
                .then(res => {
                    setRequests(res);
                    setLoad(false)
                }) :
            getAdditionalWorkRequests(match.params.id, filter, page)
                .then(res => {
                    setRequests(res);
                    setLoad(false)
                })

    }

    const currentProjectName = project && project.data && project.data.name ? project.data.name : ''
    const contract = project && project.data && project.data.projectGroup ? project.data.projectGroup : null
    return (
        <Container>
            <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
                <Link to={{pathname: CONTRACTS}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        Contracts
                    </h3>
                </Link>
                {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!contract.name ? contract.name : `Current Contract`}
                    </h3>
                </Link>}
                {project && project.data && project.data.id && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',project.data.id ).replace(':status', 'ACTIVE')}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!currentProjectName ? currentProjectName : 'Current Project'}
                    </h3>
                </Link> }

                <Link to={{pathname: ''}} >
                    <h3 className={"page-subhead subhead bread_crumbs-active"}>
                        Requests
                    </h3>
                </Link>
            </Breadcrumbs>
            {/* Page title */}
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Requests</h5>
                    </Col>
                </Col>
            </Row>

            {/* Requests table */}
            <Row>
                <Col md={12} xl={12}>
                    <Card>
                        <CardBody>
                            <RequestsFilterForm filter={filter}
                                                project={project}
                                                setFilter={setFilter}
                                                data={REQUEST_TYPE.ADDITIONAL_WORK_REQUEST.value}
                                                requests={requests} />
                            {load
                                ? <Loading/>
                                : requests && requests.items && requests.items.length !== 0
                                    ? <RequestsTable
                                        requests={requests}
                                        page={page}
                                        setPage={setPage}
                                    />

                                    : <div className="d-flex justify-content-center"><p>Not Found</p></div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

const mapDispatchToProps = {
    pushCrumbs
};

function mapStateToProps() {
    return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
