import moment from "moment";
import {timeFormat} from "./validationRules";

export const getRate = (item) => {
  const differenceInDate =
    (moment(item.date_to).unix() - moment(item.date_from).unix()) / (3600 * 24);
  const startMin =
    moment(item.start_at, timeFormat)
      .toDate()
      .getHours() *
      60 +
    moment(item.start_at, timeFormat)
      .toDate()
      .getMinutes();
  const endMin =
    moment(item.end_at, timeFormat)
      .toDate()
      .getHours() *
      60 +
    moment(item.end_at, timeFormat)
      .toDate()
      .getMinutes();
  let remainder = (endMin - startMin) % 15;
  const realTime = (endMin - startMin - remainder) / 60;
  const creatorHourlyRate = item.creator.hourly_rate;
  return (differenceInDate > 0
    ? realTime * differenceInDate * creatorHourlyRate
    : realTime * creatorHourlyRate
  ).toFixed(2);
};


export const calculateDayWorkHourCost = ({startDate, endDate, startTime, endTime, dayworkHour, userHourRate,budget, cis, vat })  => {
  let startDateValue = '1970-01-01T'
  let endDateValue = '1970-01-01T'
  if(!!startDate && !!endDate && startDate.toString().trim() !== endDate.toString().trim() ){
    startDateValue = `${startDate}T`
    endDateValue = `${endDate}T`
  }
  const start = new Date(`${startDateValue}${startTime}`);
  const end = new Date(`${endDateValue}${endTime}`);

  // Calculate the time difference in milliseconds
  const timeDiff = end.getTime() - start.getTime();

  // Convert milliseconds to hours
  const totalHours = timeDiff / (1000 * 60 * 60);

  //Get the Rate
  const hourlyRateValue = dayworkHour ? dayworkHour : userHourRate ? userHourRate : 0

  //Calculate all Hour Cost
  const hoursCost = totalHours * hourlyRateValue

  // Round the total hours to two decimal places
  const totalCost = !!budget ? Number(budget) : hoursCost
  const roundedCost = totalCost.toFixed(2)
  const cisDeduction =  cis ? Number(cis) === 0 ? 0 : (totalCost * Number(cis) / 100).toFixed(2) : 0
  const vatRate = vat ? Number(vat) === 0 ? 0 : (totalCost * Number(vat) / 100).toFixed(2) : 0
  return {cost: parseFloat(roundedCost), cis: parseFloat(cisDeduction),vat: parseFloat(vatRate)}
}
