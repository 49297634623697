import React from 'react'
import {Link} from 'react-router-dom'
import { pushCrumbs} from '../../../redux/actions/bread_crumbs';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { labelVariationOrder } from '../../../helpers/variation_order';
import {labelsForTypes} from '../../../constants/variation_orders';
import {PREVIEW_VO} from "../../../constants/routes";

export const VoRow = ({vo, index, totalCost}) => {

  if(!vo) return null;

  const voLabel = labelVariationOrder(vo.id, vo.project.name, vo.project.projectGroup.name);

  return (
    <tr key={index}>
      <td className="vo-row-td">
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {voLabel}
          </Link>
      </td>
      <td className={'vo-row-td-description'}>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
               <span className={'operativeStepJobDescriptionText'} data-text={vo.description}>
                   {vo.description}
               </span>
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {vo.client_reference}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {labelsForTypes[vo.type]}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {vo.amount ? `₤${vo.amount}` : `-`}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {vo.revised_amount ? `₤${vo.revised_amount}` : `-`}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              ₤{totalCost}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              { vo.revised_amount ? `₤${vo.revised_amount - vo.amount}` : `-`}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {vo.dayWorks && vo.dayWorks.length}
          </Link>
      </td>
      <td>
          <Link to={{pathname: PREVIEW_VO.replace(':id', vo.id)}} className={'table-link'} style={{color: 'black'}}>
              {vo.additionalWorks && vo.additionalWorks.length}
          </Link>
      </td>
    </tr>
  )
}
const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps(
) {
  return{}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoRow));
