import React from 'react';
import LoginCard from '../../shared/components/login/LoginCard';

const LogIn = () => (
    <div className="account account--not-photo">
        <LoginCard />
    </div>
);

export default LogIn;
