import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Button, Card, CardBody} from 'reactstrap';
import {getVariationOrderById} from '../../../redux/services/variation_orders'
import Loading from '../../../shared/components/Loading';
import { labelVariationOrder } from '../../../helpers/variation_order';
import NamePicture from '../../../shared/components/NamePicture';
import BudgetElement from '../../../shared/components/BudgetElement';
import {calculateDayWorkHourCost} from "../../../config/func";
import {
  BriefCaseQuestionMarkIcon,
  CalendarIcon,
  DeleteIcon,
  EditMoneyIcon,
  MoneyIcon,
  PoundIcon,
  QuestionMarkIcon,
  SettingsIcon, SortOrderIcon,
} from '../../../constants/icons';
import { ADDITIONAL_WORK_REQUEST_STATUS, DAY_WORK_STATUS, JOB_STATUS, USER_ROLE } from '../../../constants/enamerations';
import  BudgetVariations from '../../Jobs/JobPreview/BudgetVariations';
import AcceptedDayWorks from '../../../shared/components/AcceptedDayWorks';
import InstructionStatusDropdown from './InstructionStatusDropdown';
import { labelsForInstructionsStatus } from '../../../constants/variation_orders';
import { deleteVariationOrder } from '../../../redux/services/variation_orders';
import {Link, useHistory} from 'react-router-dom';
import DayworkModal from '../DayworkModal';
import AdditionalWorkModal from '../AdditionalWorkModal';
import ReactToPrint from 'react-to-print';
import VariationOrderPrint from './Prints/VariationOrderPrint';
import EvidencePrint from './Prints/EvidencePrint';
import EditableBudgetElement from '../../../shared/components/EditableBudgetElement';
import { totalDayworksAmount, totalAdditionalWorksAmount } from '../../../helpers/variation_order';
import EditVoModal from './EditVoModal';
import Select from 'react-select'
import {instructionsStatusDropDown, valuesForStatuses} from '../../../constants/variation_orders'
import UnassignRequestModal from './UnassignRequestModal';
import {formatPrice} from "../../../helpers/function";
import {COMMERCIAL, CONTRACT_VARIATION_ORDERS} from "../../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";
import Moment from 'react-moment';

const PreviewVariationOrder = ({match}) => {
  const history = useHistory();
  const variationOrderId = match.params.id;
  const [load, setLoad] = useState(false);
  const [variationOrder, setVariationOrder] = useState();

  const evidenceRef = useRef();
  const variationOrderRef = useRef();

  const [selectedDaywork, setSelectedDaywork] = useState();
  const [dayworkModal, setDayWorkModal] = useState(false);

  const [selectedAdditionalWork, setSelectedAdditionalWork] = useState();
  const [additionalWorkModal, setAdditionalWorkModal] = useState(false);

  const [voEditModal, setVoEditModal] = useState(false);

  const [availableLevelFilters, setAvailableLevelFilters] = useState([])
  const [availableBlockFilters, setAvailableBlockFilters] = useState([])
  const [availableUnitIdsFilters, setAvailableUnitIdsFilters] = useState([])

  const [sortOrder, setSortOrder] = useState('asc');

  const [unassignVariationOrderModal, setUnassignVariationOrderModal] = useState(false);
  const [unassginedItem, setUnassignedItem] = useState({});

  const [filters, setFilters] = useState({
    instruction_status: '',
    req_type:'',
    level:'',
    block:'',
    unit_id:'',
  });

  const requestsTypes = [{value:'', label:'All'},{value:'daywork', label:'Daywork request'}, {value: 'budget_req', label:'Budget variation'}]

  const fetchVariationOrder = () => {
    getVariationOrderById(variationOrderId)
        .then(res => {
          setVariationOrder(res)

          const levelFilters = [
            {value:'', label:'All'},
          ];

          const blockFilters = [
            {value:'', label:'All'}
          ];

          const unitIdsFilters = [
            {value:'', label:'All'}
          ]

          res.dayWorks.forEach(daywork => {
            if(daywork.levels && daywork.levels.id && !levelFilters.find(lf => lf.value === daywork.levels.id)){
              levelFilters.push({
                value: daywork.levels.id,
                label: daywork.levels.name,
              })
            }
            if(daywork.levels && daywork.levels.block && !blockFilters.find(lf => lf.value === daywork.levels.block.id)){
              blockFilters.push({
                value: daywork.levels.block.id,
                label: daywork.levels.block.name,
              })
            }
          })

          res.additionalWorks.forEach(additionalWork => {
            if(!levelFilters.find(lf => lf.value === additionalWork.jobs.level.id)){
              levelFilters.push({
                value: additionalWork.jobs.level.id,
                label: additionalWork.jobs.level.name,
              })
            }
            if(!blockFilters.find(lf => lf.value === additionalWork.jobs.level.block.id)){
              blockFilters.push({
                value: additionalWork.jobs.level.block.id,
                label: additionalWork.jobs.level.block.name,
              })
            }
            if(!unitIdsFilters.find(lf => lf.value === additionalWork.jobs.unit_type)){
              unitIdsFilters.push({
                value: additionalWork.jobs.unit_type,
                label: additionalWork.jobs.unit_type,
              })
            }
          })

          setAvailableLevelFilters(levelFilters);
          setAvailableBlockFilters(blockFilters);
          setAvailableUnitIdsFilters(unitIdsFilters);
        })
  }

  useEffect(()=>{
    setLoad(true);
    fetchVariationOrder()
    setLoad(false);
  },[filters])

  if(load || !variationOrder) return <Loading/>

  const toggleVoEditModal = () => {
    setVoEditModal(current => !current);
  }

  const handleDeletion = (voId, projectId) => {
    deleteVariationOrder(voId)
        .then(res => {
          history.replace(CONTRACT_VARIATION_ORDERS.replace(':id', projectId))
        })
  }

  const toggleDayworkModal = (item) => {
    setSelectedDaywork(item);
    setDayWorkModal(current => !current);
  }

  const toggleAdditionalWorkModal = (item) => {
    setSelectedAdditionalWork(item);
    setAdditionalWorkModal(current => !current);
  }

  const toggleUnassignVariationOrderModal = () => {
    setUnassignVariationOrderModal(current => !current);
  }

  const openUnassignModal = (itemId, itemType) => {
    setUnassignedItem({itemId, itemType});
    setUnassignVariationOrderModal(true);
  }

  const clickedSortHeader = (column) => {
    if (column === 'status') {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }

      variationOrder.dayWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.status.localeCompare(b.status);
        } else {
          return b.status.localeCompare(a.status);
        }
      });

      variationOrder.additionalWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.status.localeCompare(b.status);
        } else {
          return b.status.localeCompare(a.status);
        }
      });
    }

    if (column === 'user') {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }

      variationOrder.dayWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.creator.first_name.localeCompare(b.creator.first_name);
        } else {
          return b.creator.first_name.localeCompare(a.creator.first_name);
        }
      });

      variationOrder.additionalWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.jobs.employee.first_name.localeCompare(b.jobs.employee.first_name);
        } else {
          return b.jobs.employee.first_name.localeCompare(a.jobs.employee.first_name);
        }
      });
    }

    if (column === 'date') {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }

      variationOrder.dayWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.date_from.localeCompare(b.date_from);
        } else {
          return b.date_from.localeCompare(a.date_from);
        }
      });

      /*variationOrder.additionalWorks.sort((a, b) => {
        if (sortOrder === 'asc') {
          return a.date_from.localeCompare(b.date_from);
        } else {
          return b.date_from.localeCompare(a.date_from);
        }
      });*/
    }

    if (column === 'amount') {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }

      variationOrder.dayWorks.sort((a, b) => {
        let aPrice = calculateDayWorkHourCost({
          startDate: a.date_from,
          endDate: a.date_to,
          startTime: a.start_at,
          endTime: a.end_at,
          dayworkHour: a.hourly_rate,
          userHourRate: a.creator && a.creator.hourly_rate ? a.creator.hourly_rate : undefined,
          budget: a.budget,}).cost;
        let bPrice = calculateDayWorkHourCost({
          startDate: b.date_from,
          endDate: b.date_to,
          startTime: b.start_at,
          endTime: b.end_at,
          dayworkHour: b.hourly_rate,
          userHourRate: b.creator && b.creator.hourly_rate ? b.creator.hourly_rate : undefined,
          budget: b.budget,}).cost
        if (sortOrder === 'asc') {
          return aPrice - bPrice;
        } else {
          return bPrice - aPrice;
        }
      });
      variationOrder.additionalWorks.sort((a, b) => {
        let aPrice = a.budget;
        let bPrice = b.budget;
        if (sortOrder === 'asc') {
          return aPrice - bPrice;
        } else {
          return bPrice - aPrice;
        }
      });
    }
  }

  const voLabel = labelVariationOrder(variationOrder.id, variationOrder.project.name, variationOrder.project.projectGroup.name);
  const originalBudget = variationOrder.amount;
  const dayworksBudget = totalDayworksAmount(variationOrder.dayWorks);
  const additionalBudget = totalAdditionalWorksAmount(variationOrder.additionalWorks);
  const revisedBudget = variationOrder.revised_amount ? variationOrder.revised_amount : 0;

  const EvidencePhotosPrint = React.forwardRef((props, ref) => (
      <div ref={ref}>
        <EvidencePrint
            variationOrder={variationOrder}
        />
      </div>
  ));

  const VaritionOrderPrintForward = React.forwardRef((props, ref) => (
      <div ref={ref}>
        <VariationOrderPrint
            voLabel={voLabel}
            variationOrder={variationOrder}
        />
      </div>
  ))

  return (
      <Container>
        <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
          <Link to={{pathname: COMMERCIAL}} >
            <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
              Commercial
            </h3>
          </Link>
          {variationOrder && variationOrder.project  && variationOrder.project.id && <Link to={{pathname: CONTRACT_VARIATION_ORDERS.replace(':id', variationOrder.project.id)}} >
            <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
              {variationOrder.project.name ? variationOrder.project.name : 'Current Project'}
            </h3>
          </Link>}
          <Link to={{pathname: ''}} >
            <h3 className={"page-subhead subhead bread_crumbs-active"}>
              {voLabel}
            </h3>
          </Link>
        </Breadcrumbs>

        <Row className='d-flex justify-content-between'>
          <Col md={5} xl={5} className="d-flex align-items-center mb-4">
            <Col md={1.5} xl={1.5} className="p-0">
              <h5 className="page-title m-0">{voLabel}</h5>
            </Col>
            <Col
                md={1.5}
                xl={1.5}
                className="p-0 ml-3 hover"
                onClick={() => toggleVoEditModal()}
            >
              <SettingsIcon/>
            </Col>
          </Col>
          <Col lg={5} className='d-flex justify-content-end'>
            <ReactToPrint
                trigger={() => <Button color='primary'>
                  Print Variation Order
                </Button>}
                content={() => variationOrderRef.current}
            />
            <ReactToPrint
                trigger={() =>
                    <Button color='primary'>
                      Print Evidence
                    </Button>
                }
                content={() => evidenceRef.current}
            />

            {
                !variationOrder.dayWorks.length && !variationOrder.additionalWorks.length
                &&  <Button
                    color='danger'
                    onClick={()=>handleDeletion(variationOrder.id, variationOrder.project.id)}
                >
                  Delete Variation Order
                </Button>
            }
          </Col>
        </Row>
        <Row className='d-flex justify-content-evenly'>
          <Col lg={4}>
            <Card>
              <CardBody>
                <Row className='p-2 px-4' style={{minHeight:'100px'}}>
                  <h5><b>Description:</b> {variationOrder.description}</h5>
                </Row>
                <hr/>
                <Row className='p-2 px-4'>
                  <h5><b>Client reference:</b> {variationOrder.client_reference}</h5>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card>
              <CardBody>
                {
                  variationOrder.project.qsUser
                      ? <>
                        <Row className='p-2 px-4'>
                          <h5><b>QUANTITY SURVEYOR</b> </h5>
                        </Row>
                        <Row className='p-2 px-4'>
                          <Col lg={4} xl={4} md={12} sm={12}>
                            <div className="quality-surveyor-image">
                              <NamePicture
                                  first_name={variationOrder.project.qsUser.first_name}
                                  surname={variationOrder.project.qsUser.surname}
                              />
                            </div>
                          </Col>
                          <Col lg={8} xl={8} md={12} sm={12}>
                            <Row className='p-2 px-4'>
                              <div>
                                <h4><b>{variationOrder.project.qsUser.first_name} {variationOrder.project.qsUser.surname}</b></h4>
                                <h4>{USER_ROLE[variationOrder.project.qsUser.role].label}</h4>
                              </div>
                              <div className={'mt-3'}>
                                <h5>{variationOrder.project.qsUser.email}</h5>
                                <h5>{variationOrder.project.qsUser.phone}</h5>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </>
                      : <h4> No quality surveyor set. </h4>
                }
              </CardBody>
            </Card>
          </Col>
          <Col lg={5}>
            <Card >
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <BudgetElement
                        title={'Original Budget'}
                        budget={originalBudget}
                        icon={<MoneyIcon/>}
                    />
                    <EditableBudgetElement
                        title={'Revised Budget'}
                        budget={revisedBudget}
                        voId={variationOrder.id}
                        icon={<EditMoneyIcon/>}
                    />
                  </Col>
                  <Col lg={6}>
                    <BudgetElement
                        title={'Daywork Requests'}
                        budget={dayworksBudget}
                        icon={JOB_STATUS.ACTIVE.icon}
                    />
                    <BudgetElement
                        title={'Additional Work Requests'}
                        budget={additionalBudget}
                        icon={<QuestionMarkIcon/>}
                    />
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <BudgetElement
                        title={'Difference'}
                        budget={originalBudget - revisedBudget}
                        icon={<PoundIcon/>}
                        tooltipInfo={'(Original budget - revised budget)'}
                    />
                  </Col>
                  <Col>
                    <BudgetElement
                        title={'Total Requests'}
                        budget={dayworksBudget + additionalBudget}
                        icon={<BriefCaseQuestionMarkIcon/>}
                    />
                  </Col>2
                  <Col>
                    <BudgetElement
                        title={'Difference'}
                        budget={revisedBudget - (dayworksBudget + additionalBudget)}
                        icon={<PoundIcon/>}
                        tooltipInfo={'(Total budget - revised budget)'}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <Card>
              <CardBody>
                <Row className="d-flex justify-content-between">
                  <Col xl={2} className={'ml-2'}>
                    <Select
                        options={[{value:'', label:'All'},...instructionsStatusDropDown]}
                        defaultValue={{value:'', label:'All instruction statuses'}}
                        placeholder='Instruction status'
                        onChange={(e) => setFilters(curr => {return {...curr, instruction_status: e.value}})}
                        styles={{
                          input:(base, state) => ({
                            ...base,
                            overflow:'visible',
                          }),
                        }}
                    />
                  </Col>
                  <Col xl={2} className={'ml-2'}>
                    <Select
                        options={requestsTypes}
                        defaultValue={{value:'', label:'All requests types'}}
                        placeholder='Request type'
                        onChange={(e) => setFilters(curr => {return {...curr, req_type: e.value}})}
                        styles={{
                          input:(base, state) => ({
                            ...base,
                            overflow:'visible',
                          }),
                        }}
                    />
                  </Col>
                  <Col xl={2} className={'ml-2'}>
                    <Select
                        options={availableLevelFilters}
                        defaultValue={{value:'', label:'All levels'}}
                        placeholder='Level'
                        onChange={(e) => setFilters(curr => {return {...curr, level: e.value}})}
                        styles={{
                          input:(base, state) => ({
                            ...base,
                            overflow:'visible',
                          }),
                        }}
                    />
                  </Col>
                  <Col xl={2} className={'ml-2'}>
                    <Select
                        options={availableBlockFilters}
                        defaultValue={{value:'', label:'All blocks'}}
                        onChange={(e) => setFilters(curr => {return {...curr, block: e.value}})}
                        placeholder='Block'
                        styles={{
                          input:(base, state) => ({
                            ...base,
                            overflow:'visible',
                          }),
                        }}
                    />
                  </Col>
                  <Col xl={2} className={'ml-2'}>
                    <Select
                        options={availableUnitIdsFilters}
                        defaultValue={{value:'', label:'All unit ids'}}
                        onChange={(e) => setFilters(curr => {return {...curr, unit_id: e.value}})}
                        placeholder='Unit ID'
                        styles={{
                          input:(base, state) => ({
                            ...base,
                            overflow:'visible',
                          }),
                        }}
                    />
                  </Col>
                </Row>
                <table
                    responsive
                    hover
                    className="table react-table table--bordered pl-0"
                >
                  <thead>
                  <tr>
                    <th>INSTRUCTIONS STATUS</th>
                    <th>REQUEST TYPE</th>
                    <th onClick={() => clickedSortHeader('status')}>STATUS <SortOrderIcon size={20} /></th>
                    <th onClick={() => clickedSortHeader('user')}>USER <SortOrderIcon size={20} /></th>
                    <th onClick={() => clickedSortHeader('date')}>DATE <SortOrderIcon size={20} /></th>
                    <th onClick={() => clickedSortHeader('amount')}>AMOUNT <SortOrderIcon size={20} /></th>
                    <th>LEVEL</th>
                    <th>BLOCK</th>
                    <th>UNIT ID</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                      !!variationOrder.dayWorks.length &&
                      variationOrder.dayWorks
                          .map((daywork, index) =>
                              daywork.level
                                  ? (
                                      (valuesForStatuses[daywork.voCategory] === filters.instruction_status || filters.instruction_status === '')
                                      && (filters.req_type === 'daywork' || filters.req_type === '')
                                      && (filters.level === daywork.levels.id || filters.level === '')
                                      && (filters.block === daywork.levels.block.id || filters.block === '')
                                      && filters.unit_id === ''
                                      && daywork.status !== DAY_WORK_STATUS.REJECTED.value
                                  )
                                  && <tr key={index}  className={'hover unassign-row'}>
                                    <td>
                                      <InstructionStatusDropdown
                                          itemId={daywork.id}
                                          type='daywork'
                                          itemStatus={
                                            daywork.voCategory
                                                ? {
                                                  label: labelsForInstructionsStatus[valuesForStatuses[daywork.voCategory]],
                                                  value: valuesForStatuses[daywork.voCategory]
                                                }
                                                : null
                                          }
                                      />
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>DayWork Request</td>
                                    <td>
                                      {daywork.status && DAY_WORK_STATUS[daywork.status].icon} &nbsp;{" "}
                                      {daywork.status && DAY_WORK_STATUS[daywork.status].label}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>
                                      {daywork.creator && `${daywork.creator.first_name && daywork.creator.first_name} ${daywork.creator.surname && daywork.creator.surname}`}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>{formatPrice(calculateDayWorkHourCost({
                                      startDate: daywork.date_from,
                                      endDate: daywork.date_to,
                                      startTime: daywork.start_at,
                                      endTime: daywork.end_at,
                                      dayworkHour: daywork.hourly_rate,
                                      userHourRate:  daywork.creator && daywork.creator.hourly_rate ? daywork.creator.hourly_rate : undefined,
                                      budget: daywork.budget,
                                    }).cost)}</td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>
                                      { daywork.levels && daywork.levels.name}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>{daywork.levels && daywork.levels.block && daywork.levels.block.name}</td>
                                    <td> - </td>
                                    <td  className='unassign-column'>
                                      <div className={'unassign-button-container'}>
                                        <button className={'unassign-button'} onClick={()=> openUnassignModal(daywork.id, 'daywork')}>
                                          <DeleteIcon/>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                  : (
                                      (valuesForStatuses[daywork.voCategory] === filters.instruction_status || filters.instruction_status === '')
                                      && (filters.req_type === 'daywork' || filters.req_type === '')
                                      && filters.unit_id === ''
                                      && daywork.status !== DAY_WORK_STATUS.REJECTED.value
                                  )
                                  && <tr key={index} className={'hover unassign-row'}>
                                    <td>
                                      <InstructionStatusDropdown
                                          itemId={daywork.id}
                                          type='daywork'
                                          itemStatus={
                                            daywork.voCategory
                                                ? {
                                                  label: labelsForInstructionsStatus[valuesForStatuses[daywork.voCategory]],
                                                  value: valuesForStatuses[daywork.voCategory]
                                                }
                                                : null
                                          }
                                      />
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>DayWork Request</td>
                                    <td>
                                      {daywork.status && DAY_WORK_STATUS[daywork.status].icon} &nbsp;{" "}
                                      {daywork.status && DAY_WORK_STATUS[daywork.status].label}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>
                                      {daywork.creator && `${daywork.creator.first_name && daywork.creator.first_name} ${daywork.creator.surname && daywork.creator.surname}`}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>
                                      <CalendarIcon /> &nbsp;{" "}
                                      <Moment format="LL">{daywork.date_from}</Moment> -{" "}
                                      <Moment format="LL">{daywork.date_to}</Moment>
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>{formatPrice(calculateDayWorkHourCost({
                                      startDate: daywork.date_from,
                                      endDate: daywork.date_to,
                                      startTime: daywork.start_at,
                                      endTime: daywork.end_at,
                                      dayworkHour: daywork.hourly_rate,
                                      userHourRate: daywork.creator && daywork.creator.hourly_rate ? daywork.creator.hourly_rate : undefined,
                                      budget: daywork.budget,}).cost)}</td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>
                                      {'-'}
                                    </td>
                                    <td onClick={() => toggleDayworkModal(daywork)}>{'-'}</td>
                                    <td> - </td>
                                    <td className='unassign-column'>
                                      <div className={'unassign-button-container'}>
                                        <button className={'unassign-button'} onClick={()=> openUnassignModal(daywork.id, 'daywork')}>
                                          <DeleteIcon/>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                          )
                  }
                  {
                      !!variationOrder.additionalWorks.length
                      && variationOrder.additionalWorks.map((additionalWork, index) =>
                              (
                                  (valuesForStatuses[additionalWork.voCategory] === filters.instruction_status || filters.instruction_status === '')
                                  && (filters.req_type === 'budget_req' || filters.req_type === '')
                                  && (filters.level === additionalWork.jobs.level.id || filters.level === '')
                                  && (filters.block === additionalWork.jobs.level.block.id || filters.block === '')
                                  && (filters.unit_id === additionalWork.jobs.unit_type || filters.unit_id === '')
                                  && additionalWork.status !== ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value
                              ) && <tr key={index} className={'hover unassign-row'}>
                                <td>
                                  <InstructionStatusDropdown
                                      itemId={additionalWork.id}
                                      type='additionalWork'
                                      itemStatus={
                                        additionalWork.voCategory
                                            ? {
                                              label: labelsForInstructionsStatus[valuesForStatuses[additionalWork.voCategory]],
                                              value: valuesForStatuses[additionalWork.voCategory]
                                            }
                                            : null
                                      }
                                  />
                                </td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>Budget Variation</td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {additionalWork.status && ADDITIONAL_WORK_REQUEST_STATUS[additionalWork.status].icon} &nbsp;{" "}
                                  {additionalWork.status && ADDITIONAL_WORK_REQUEST_STATUS[additionalWork.status].label}
                                </td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {`${additionalWork.jobs.employee.first_name} ${additionalWork.jobs.employee.surname}`}
                                </td>
                                <td></td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {additionalWork.budget}
                                </td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {additionalWork.jobs.level.name}
                                </td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {additionalWork.jobs.level.block.name}
                                </td>
                                <td onClick={() => toggleAdditionalWorkModal(additionalWork)}>
                                  {additionalWork.jobs.unit_type}
                                </td>
                                <td className='unassign-column'>
                                  <div className={'unassign-button-container'}>
                                    <button className={'unassign-button'} onClick={()=> openUnassignModal(additionalWork.id, 'budget variation')}>
                                      <DeleteIcon/>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                      )
                  }
                  {(!variationOrder.dayWorks.length && !variationOrder.additionalWorks.length)
                      && <tr><td>No requests found in this variation order.</td></tr>}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Col>
          <Row>
            <Col xl={6} lg={6}>
              <Row className={'mt-4 mr-2'}>
                <Card>
                  <CardBody>
                    <div className={'mb-3'}>
                      <h5><b>BUDGET REQUESTS</b></h5>
                    </div>
                    <BudgetVariations
                        data={variationOrder.additionalWorks}
                        typeCard="Reason"
                        projectId={variationOrder.project.id}
                    />
                  </CardBody>
                </Card>
              </Row>
            </Col>
            <Col xl={6} lg={6}>
              <Row className={'mt-4 ml-2'}>
                <Card>
                  <CardBody>
                    <div className={'mb-3'}>
                      <h5><b>DAYWORK REQUESTS</b></h5>
                    </div>
                    <AcceptedDayWorks
                        data={variationOrder.dayWorks}
                        typeCard="Reason"
                        projectId={variationOrder.project.id}
                    />
                  </CardBody>
                </Card>
              </Row>
            </Col>
          </Row>
        </Col>
        <EditVoModal
            isOpen={voEditModal}
            toggleModal={toggleVoEditModal}
            variationOrder={variationOrder}
            voLabel={voLabel}
            initialValues={{
              description: variationOrder.description,
              client_reference: variationOrder.client_reference,
            }}
        />
        <DayworkModal
            modal={dayworkModal}
            item={selectedDaywork}
            setModal={toggleDayworkModal}
            voLabel={voLabel}
            load={load}
            setLoad={setLoad}
            refetch={fetchVariationOrder}
        />
        <AdditionalWorkModal
            isOpen={additionalWorkModal}
            item={selectedAdditionalWork}
            toggleModal={toggleAdditionalWorkModal}
            voLabel={voLabel}
        />
        <UnassignRequestModal
            modal={unassignVariationOrderModal}
            toggleModal={toggleUnassignVariationOrderModal}
            selectedItem={unassginedItem}
            refetch={fetchVariationOrder}
        />
        <div style={{display:'none'}}>
          <EvidencePhotosPrint ref={evidenceRef}/>
          <VaritionOrderPrintForward ref={variationOrderRef}/>
        </div>
      </Container>
  )
}

export default PreviewVariationOrder
