import React, {useState, useEffect} from "react"
import {Col} from 'reactstrap';
import {Field, reduxForm, change} from 'redux-form';
import SelectFieldNoValidate from '../../../shared/components/SelectFieldNoValidate';
import {PROJECT_STRUCTURE} from '../../../constants/enamerations'
import {WORK_TYPE_OPTIONS} from "../../../constants/options"

const HistoryJobsFilterForm = ({jobs, projects, f, dispatch, filter, setFilter, status}) => {
    const [projectID, setProjectID] = useState(null);
    const [blockID, setBlockID] = useState(null);
    const [levelID, setLevelID] = useState(null);
    const [projectOptions, setProjectOptions] = useState([])
    const [blockOptions, setBlockOptions] = useState([])
    const [levelOptions, setLevelOptions] = useState([])
    const [plotOptions, setPlotOptions] = useState([])

    useEffect(() => {
        projects && projects.items && getProjects()
    }, [projects])

    useEffect(() => {
        jobs && jobs.items && jobs.items.length > 0 && getBloks()
    }, [jobs])

    const getProjects = () => {
        const tempProjectOptions = [{value: null, label: 'All'}]
        projects.items.map(item => tempProjectOptions.push({value: item.id, label: item.name}))
        setProjectOptions(tempProjectOptions)
    }

    const chooseProject = (id) => {
        setProjectID(id)
        id ?
            setFilter({...filter, structure: {target_ids: [id], target_type: PROJECT_STRUCTURE.PROJECT}}) :
            setFilter({
                job_status: status && status, job_type: []
            })
        dispatch(change('filter_form_jobs', 'block', ''))
        dispatch(change('filter_form_jobs', 'level', ''))
        dispatch(change('filter_form_jobs', 'plot', ''))
    }

    const getBloks = () => {
        const tempBlockOptions = [{value: null, label: 'All'}]
        jobs.items.map(item => tempBlockOptions.push({value: item.block.id, label: item.block.name}))
        setBlockOptions(Array.from(new Set(tempBlockOptions.map(JSON.stringify))).map(JSON.parse))
    }

    const chooseBlock = (id) => {
        setBlockID(id)
        getLevels(id)
        id ?
            setFilter({...filter, structure: {target_ids: [id], target_type: PROJECT_STRUCTURE.BLOCK}}) :
            setFilter({...filter, structure: {target_ids: [projectID], target_type: PROJECT_STRUCTURE.PROJECT}})
        dispatch(change('filter_form_jobs', 'level', ''))
        dispatch(change('filter_form_jobs', 'plot', ''))
    }

    const getLevels = () => {
        const tempLevelOptions = [{value: null, label: 'All'}]
        jobs.items.map(item => tempLevelOptions.push({value: item.level.id, label: item.level.name}))
        setLevelOptions(Array.from(new Set(tempLevelOptions.map(JSON.stringify))).map(JSON.parse));
    }

    const chooseLevel = (id) => {
        setLevelID(id)
        getPlots()
        id ?
            setFilter({...filter, structure: {target_ids: [id], target_type: PROJECT_STRUCTURE.LEVEL}}) :
            setFilter({...filter, structure: {target_ids: [blockID], target_type: PROJECT_STRUCTURE.BLOCK}});
        dispatch(change('filter_form_jobs', 'plot', ''))
    }

    const getPlots = () => {
        const tempPlotOptions = [{value: null, label: 'All'}]
        jobs.items.map(item => tempPlotOptions.push({value: item.plot.id, label: item.plot.name}))
        setPlotOptions(Array.from(new Set(tempPlotOptions.map(JSON.stringify))).map(JSON.parse))
    }

    const choosePlot = (id) => {
        id ?
            setFilter({...filter, structure: {target_ids: [id], target_type: PROJECT_STRUCTURE.PLOT}}) :
            setFilter({...filter, structure: {target_ids: [levelID], target_type: PROJECT_STRUCTURE.LEVEL}})
    }

    return (
        <form>
            <Col md={12} xl={12} className="d-flex justify-content-between p-0">
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="job_type"
                                component={SelectFieldNoValidate}
                                placeholder="Trade"
                                options={WORK_TYPE_OPTIONS}
                                onChange={data => setFilter({...filter, job_type: data ? [data] : []})}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="project"
                                component={SelectFieldNoValidate}
                                placeholder="Project"
                                options={projectOptions}
                                onChange={data => chooseProject(data)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="block"
                                component={SelectFieldNoValidate}
                                placeholder="Block"
                                options={blockOptions && blockOptions.length !== 0 ? blockOptions : []}
                                disabled={f && f.filter_form_jobs && f.filter_form_jobs.values && f.filter_form_jobs.values.project ? false : true}
                                onChange={data => chooseBlock(data)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="level"
                                component={SelectFieldNoValidate}
                                placeholder="Level"
                                options={levelOptions && levelOptions.length !== 0 ? levelOptions : []}
                                disabled={f && f.filter_form_jobs && f.filter_form_jobs.values && f.filter_form_jobs.values.block ? false : true}
                                onChange={data => chooseLevel(data)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="plot"
                                component={SelectFieldNoValidate}
                                placeholder="Plot"
                                options={plotOptions && plotOptions.length !== 0 ? plotOptions : []}
                                disabled={f && f.filter_form_jobs && f.filter_form_jobs.values && f.filter_form_jobs.values.level ? false : true}
                                onChange={data => choosePlot(data)}
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </form>
    )
}

export default reduxForm({
    form: 'filter_form_jobs',
})(HistoryJobsFilterForm);
