import React, { useState, useEffect } from "react"
import { Row, Col } from 'reactstrap';
import MultiLinearProgressBar from "../../../../shared/components/progress/MultiLinearProgressBar"
import Loading from "../../../../shared/components/Loader"
import { getQAStatusAnalytics } from "../../../../redux/services/projects"
import { JOB_STATUS } from "../../../../constants/enamerations"
import {JOBS} from "../../../../constants/routes";

const QAStatus = ({ item }) => {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setLoad(true)
        item && getQAStatusAnalytics(item.id)
            .then(res => {
                setLoad(false)
                setData(res.data)
            })
    }, [item])

    return (
        load
            ? <Loading />
            : <>
                <div className="wrapper_page-title">
                    <h5 className="page-title m-0">QA STATUS</h5>
                </div>
                <Row className="mt-3 d-flex justify-content-between">
                    <Col md={6} xl={6} className="border-right hover">
                        <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ASSIGNED_TO_PM.value)}>
                            <div className="lg_wrapper_page-title">
                                <p className="page-title m-0 text-yellow">{data && data.counter_of_under_verification_jobs}</p>
                            </div>
                            <div className="wrapper_page-title">
                                <span className="form__form-group-label m-0">AWAITING APPROVAL</span>
                            </div>
                        </a>

                    </Col>
                    <Col md={5} xl={5}>
                        <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.DECLINED.value)}>
                            <div className="lg_wrapper_page-title hover">
                                <p className="page-title m-0 text-red">{data && data.counter_of_declined_jobs}</p>
                            </div>
                            <div className="wrapper_page-title">
                                <span className="form__form-group-label m-0">ACTIVE REJECTIONS</span>
                            </div>
                        </a>

                    </Col>
                </Row>
                <div className="wrapper_page-title mt-3">
                    <p className="form__form-group-label m-0">Fixed rejections rate</p>
                </div>
                <Row className="mt-1 mb-2">
                    <Col md={12} xl={12}>
                        <MultiLinearProgressBar one='yellow' two='red' oneValue={data && +data.counter_of_under_verification_jobs} twoValue={data && +data.counter_of_declined_jobs} />
                    </Col>
                </Row>
            </>
    )
}

export default QAStatus
