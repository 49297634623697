import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate";
import { PROJECT_STRUCTURE } from "../../../constants/enamerations";
import {
  WORK_TYPE_OPTIONS,
  JOB_STATUS_OPTIONS,
} from "../../../constants/options";

const JobsFilterForm = ({
  project,
  f,
  setFilter,
  filter,
  dispatch,
  job_status,
  uniqueValue,
}) => {
  const [blockID, setBlockID] = useState(null);
  const [levelID, setLevelID] = useState(null);
  const [blockOptions, setBlockOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [plotOptions, setPlotOptions] = useState([]);
  useEffect(() => {
    dispatch(change("filter_form_jobs", "job_status", job_status));
  }, []);

  useEffect(() => {
    project && project.data && getBloks();
  }, [project]);

  const getBloks = () => {
    const tempBlockOptions = [{ value: null, label: "All" }];
    project.data.blocks.forEach((item) =>
      tempBlockOptions.push({ value: item.id, label: item.name })
    );
    setBlockOptions(tempBlockOptions);
  };

  const chooseBlock = (id) => {
    setBlockID(id);
    getLevels(id);
    id
      ? setFilter({
          ...filter,
          structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.BLOCK },
        })
      : setFilter({
          ...filter,
          structure: {
            target_ids: [project.data.id],
            target_type: PROJECT_STRUCTURE.PROJECT,
          },
        });
    dispatch(change("filter_form_jobs", "level", ""));
    dispatch(change("filter_form_jobs", "plot", ""));
  };

  const getLevels = (id) => {
    const tempLevelOptions = [{ value: null, label: "All" }];
    project.data.blocks.forEach(
      (item) =>
        item.id === id &&
        item.levels.map((item) =>
          tempLevelOptions.push({ value: item.id, label: item.name })
        )
    );
    setLevelOptions(tempLevelOptions);
  };

  const chooseLevel = (id) => {
    setLevelID(id);
    getPlots(id);
    id
      ? setFilter({
          ...filter,
          structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.LEVEL },
        })
      : setFilter({
          ...filter,
          structure: {
            target_ids: [blockID],
            target_type: PROJECT_STRUCTURE.BLOCK,
          },
        });

    dispatch(change("filter_form_jobs", "plot", ""));
  };

  const getPlots = (id) => {
    const tempPlotOptions = [{ value: null, label: "All" }];
    project.data.blocks.forEach(
      (block) =>
        block.id === blockID &&
        block.levels.map(
          (level) =>
            level.id === id &&
            level.plots.map((item) =>
              tempPlotOptions.push({ value: item.id, label: item.name })
            )
        )
    );
    setPlotOptions(tempPlotOptions);
  };

  const choosePlot = (id) => {
    id
      ? setFilter({
          ...filter,
          structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.PLOT },
        })
      : setFilter({
          ...filter,
          structure: {
            target_ids: [levelID],
            target_type: PROJECT_STRUCTURE.LEVEL,
          },
        });
  };

  const chooseUnitType = (data) => {
    setFilter({ ...filter, unit_type: data });
  };

  return (
    <form>
      <Col md={12} xl={12} className="d-flex justify-content-between p-0">
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="job_type"
                component={SelectFieldNoValidate}
                placeholder="Trade"
                options={WORK_TYPE_OPTIONS}
                onChange={(data) => setFilter({ ...filter, job_type: data })}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="job_status"
                component={SelectFieldNoValidate}
                placeholder="Status"
                options={JOB_STATUS_OPTIONS}
                onChange={(data) => setFilter({ ...filter, job_status: data })}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="unit_type"
                component={SelectFieldNoValidate}
                placeholder="Unit type"
                options={uniqueValue}
                // onChange={data => setFilter({ ...filter, unit_type: data })}
                onChange={(data) => chooseUnitType(data)}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="block"
                component={SelectFieldNoValidate}
                placeholder="Block"
                options={
                  blockOptions && blockOptions.length !== 0 ? blockOptions : []
                }
                onChange={(data) => chooseBlock(data)}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="level"
                component={SelectFieldNoValidate}
                placeholder="Level"
                options={
                  levelOptions && levelOptions.length !== 0 ? levelOptions : []
                }
                disabled={
                  f &&
                  f.filter_form_jobs &&
                  f.filter_form_jobs.values &&
                  f.filter_form_jobs.values.block
                    ? false
                    : true
                }
                onChange={(data) => chooseLevel(data)}
              />
            </div>
          </div>
        </div>
        <div className="form__form-group w-50 mr-3">
          <div className="form__form-group-field">
            <div className="create_user_select_wrapper w-100">
              <Field
                name="plot"
                component={SelectFieldNoValidate}
                placeholder="Plot"
                options={
                  plotOptions && plotOptions.length !== 0 ? plotOptions : []
                }
                disabled={
                  f &&
                  f.filter_form_jobs &&
                  f.filter_form_jobs.values &&
                  f.filter_form_jobs.values.level
                    ? false
                    : true
                }
                onChange={(data) => choosePlot(data)}
              />
            </div>
          </div>
        </div>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "filter_form_jobs",
})(JobsFilterForm);
