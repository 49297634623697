export const getAllTransformedOperativeSteps = (allOperativeSteps) => {
    let tempOperativeSteps = []
    allOperativeSteps.forEach((operativeStep, index) => {
        tempOperativeSteps.push({...operativeStep, originalIndex: index})
    })
    return tempOperativeSteps
}

export const allOperativeStepsAreValid = (operativeSteps, checkedIndexes) => {
    let operativeStepsAreValid = true
    checkedIndexes.forEach((checkedIndex) => {
        const operativeStep = operativeSteps.find((opStep) => opStep.originalIndex === checkedIndex)
        if(!!operativeStep){
            operativeStepsAreValid = operativeStepsAreValid && !!operativeStep.description
        }
    })
    return operativeStepsAreValid
}

export const isOperativeStepChecked = (checkedIndexes, operativeStepIndex) => {
    return checkedIndexes.includes(operativeStepIndex)
}

export const getAllUncheckedOperativeSteps = (allOperativeSteps, checkedIndexes) => {
    let uncheckedSteps = []
    allOperativeSteps.forEach((operativeStep,index) => {
        if(!checkedIndexes.includes(index)){
            uncheckedSteps.push(operativeStep)
        }
    })
    return uncheckedSteps
}

export const getALlUpdatesOperativeSteps = (allOperativeSteps, checkedIndexes) => {
    let uncheckedSteps = []
    allOperativeSteps.forEach((operativeStep,index) => {
        if(!checkedIndexes.includes(index)){
            uncheckedSteps.push(operativeStep)
        }
    })
    return uncheckedSteps
}

