import React from 'react'
import { totalDayworksAmount, totalAdditionalWorksAmount } from '../../../../helpers/variation_order'
import {calculateDayWorkHourCost} from '../../../../config/func'
import { labelsForInstructionsStatus } from '../../../../constants/variation_orders'
import { ADDITIONAL_WORK_REQUEST_STATUS, DAY_WORK_STATUS } from '../../../../constants/enamerations'
import {formatPrice} from "../../../../helpers/function";

const VariationOrderPrint = ({variationOrder, voLabel}) => {
  return (
    <div className={'vw-100'}>
      <div className="print_wrapper w-100">
        <div className="print_header">
          <div className="logo_header">
            <img
              className="pdfLogoimage"
              src={require("../../../../shared/img/logo/pdfLogo.png")}
              alt={"logo"}
            />
          </div>
          <div className="contact_header">
            <p>020 3909 8880</p>
            <p>info@londonmep.co.uk</p>
          </div>
        </div>

        <div className="body_wrapper">
          <div className="project_build_wrapper vo-print-top-info align-items-center">
            <div className="project_info">
              <h1>{voLabel}</h1>
              <div className="d-flex align-items-center">
                <h3>Client reference: {variationOrder.client_reference}</h3>
              </div>
            </div>
            <div className="build_info amounts-info">
              <div className="pr-5 w-80 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <h4 className="dim">Amount</h4>
                  <span><h4>£{variationOrder.amount}</h4></span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <h4 className="dim">Revised Amount</h4>
                  <span><h4>£{variationOrder.revised_amount || 0}</h4></span>
                </div>
              </div>
              <div className="pl-5 w-80 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <h4 className="dim">Dayworks Amount</h4>
                  <span><h4>{formatPrice(totalDayworksAmount(variationOrder.dayWorks))}</h4></span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <h4 className="dim">Additional Works Amount</h4>
                  <span><h4>{formatPrice(totalAdditionalWorksAmount(variationOrder.additionalWorks))}</h4></span>
                </div>
              </div>
            </div>
          </div>

          <div className="inspection_wrapper">
            <h3>QUANTITY SURVEYOR</h3>

            <div className="inspection_information">
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <h4 className="dim">Name: </h4>
                  <span>
                    <h4>John doe</h4>
                  </span>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <h4 className="dim">Email</h4>
                  <span>
                    <h4>Johndoe@gmail.com</h4>
                  </span>
                </div>
              </div>
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <h4 className="dim">Phone</h4>
                  <span>
                    <h4>020 3909 8880</h4>
                  </span>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>


      <h3 className="text-uppercase mb-0 mt-3" style={{ padding: `0 30px` }}>
        DESCRIPTION:
      </h3>
      <div className="w-100 px-4 pt-2 pb-5">
        <h4 className='ml-2'>{variationOrder.description}</h4>
      </div>

      <h3 className="text-uppercase mb-0 mt-3" style={{ padding: `0 30px` }}>
        DAYWORK REQUESTS
      </h3>

      <div className="w-100 px-4 py-5">
        <table className="w-100 ml-2">
          <thead>
            <tr>
              <th><h4>ID</h4></th>
              <th><h4>INSTRUCTIONS STATUS </h4></th>
              <th><h4>REQUEST TYPE </h4></th>
              <th><h4>STATUS </h4></th>
              <th><h4>USER </h4></th>
              <th><h4>AMOUNT </h4></th>
              <th><h4>LEVEL </h4></th>
              <th><h4>BLOCK </h4></th>
              <th><h4>UNIT ID </h4></th>
            </tr>
          </thead>
          <tbody className="w-100">
            {
            variationOrder.dayWorks.map(dayWork => (
              <tr key={dayWork.id + dayWork.created_at + 'daywork'}>
                <td>{dayWork.id}</td>
                <td>{labelsForInstructionsStatus[dayWork.voCategory]}</td>
                <td>{'Daywork Request'}</td>
                <td>{dayWork.status && DAY_WORK_STATUS[dayWork.status].label}</td>
                <td>{`${dayWork.creator && dayWork.creator.first_name} ${dayWork.creator && dayWork.creator.surname}`}</td>
                <td>{formatPrice(calculateDayWorkHourCost({
                  startDate: dayWork.date_from,
                  endDate: dayWork.date_to,
                  startTime: dayWork.start_at,
                  endTime: dayWork.end_at,
                  dayworkHour: dayWork.hourly_rate,
                  userHourRate:  dayWork.creator && dayWork.creator.hourly_rate ? dayWork.creator.hourly_rate : undefined,
                  budget: dayWork.budget}).cost)}</td>
                <td>{dayWork.levels && dayWork.levels.name}</td>
                <td>{dayWork.levels && dayWork.levels.block && dayWork.levels.block.name}</td>
                <td> - </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


        <h3 className="text-uppercase mb-0 mt-3" style={{ padding: `0 30px` }}>
          BUDGET VARIATION REQUESTS
        </h3>

        <div className="w-100 px-4 py-5">
        <table className="w-100 ml-2">
          <thead>
            <tr>
              <th><h4>ID</h4></th>
              <th><h4>INSTRUCTIONS STATUS </h4></th>
              <th><h4>REQUEST TYPE </h4></th>
              <th><h4>STATUS </h4></th>
              <th><h4>USER </h4></th>
              <th><h4>AMOUNT </h4></th>
              <th><h4>LEVEL </h4></th>
              <th><h4>BLOCK </h4></th>
              <th><h4>UNIT ID </h4></th>
            </tr>
          </thead>
          <tbody className="w-100">
            {
            variationOrder.additionalWorks.map(additionalWork => (
              <tr key={additionalWork.id + additionalWork.created_at + 'budget_req'}>
                <td>{additionalWork.id}</td>
                <td>
                {labelsForInstructionsStatus[additionalWork.voCategory]}
                </td>
                <td>Budget Variation</td>
                <td>
                  {additionalWork.status && ADDITIONAL_WORK_REQUEST_STATUS[additionalWork.status].label}
                </td>
                <td>
                  {`${additionalWork.jobs.employee.first_name} ${additionalWork.jobs.employee.surname}`}
                </td>
                <td>
                  {additionalWork.budget}
                </td>
                <td>
                  {additionalWork.jobs.level.name}
                </td>
                <td>
                  {additionalWork.jobs.level.block.name}
                </td>
                <td>
                  {additionalWork.jobs.unit_type}
                </td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default VariationOrderPrint
