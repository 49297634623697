import axios from "axios";
import { BASE_API } from "../../config/api";

export const getAllVariationOrdersAsync = async () =>
  await axios
    .get(`${BASE_API}variation-order`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const updateVariationOrderAsync = async (id,data) =>
await axios 
    .patch(`${BASE_API}variation-order/${id}`, data)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const deleteVariationOrderAsync = async (id) =>
await axios 
    .delete(`${BASE_API}variation-order/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
    

export const getVariationOrderByIdAsync = async (id) =>
await axios
  .get(`${BASE_API}variation-order/${id}`)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const getVariationOrdersByProjectIdAsync = async (projectId) =>
await axios
  .get(`${BASE_API}variation-order?project_id=${projectId}`)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const createVariationOrderAsync = async (data) => 
await axios
  .post(`${BASE_API}variation-order`, data)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const addVosToAdditionalWorkAsync = async (data) => 
await axios
 .patch(`${BASE_API}variation-order/add-vo-to-additional-works/`, data)
 .then((response) => response && response.data)
 .catch((error) => error.message);

export const addVoCategoryToAdditionalWorkAsync = async (id, data) =>
await axios
  .patch(`${BASE_API}variation-order/${id}/additional-works-vo-category`, data)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const removeAdditionalWorkVOAsync = async(id) =>
await axios 
  .patch(`${BASE_API}admin/additional-work-request/${id}/remove-variation-order`)
  .then((response) => response && response.data)
  .catch((error) => error.message);