import React, { PureComponent } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Alert, Button } from 'reactstrap';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import {patternEmail} from "../../../config/validationRules";

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Email field shouldn’t be empty';
    } else if (!patternEmail.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'Password field shouldn’t be empty';
    }
    return errors;
};

const renderField = ({
    input, placeholder, type, meta: { touched, error },
}) => (
    <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
        <input {...input} placeholder={placeholder} type={type} />
        {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

class LogInForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    showPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    };
    render() {
        const { handleSubmit, errorMessage } = this.props;
        const { showPassword, disSubmit } = this.state;
        return (
            <Form className="form login-form" onSubmit={handleSubmit}>
                <Alert
                    color="danger"
                    isOpen={!!errorMessage}
                >
                    {errorMessage}
                </Alert>
                <div className="form__form-group">
                    <span className="form__form-group-label">Username</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                        </div>
                        <Field
                            name="email"
                            component={renderField}
                            type="email"
                            placeholder="Email"
                        />
                    </div>
                </div>
                <div className="form__form-group m-0">
                    <span className="form__form-group-label">Password</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <KeyVariantIcon />
                        </div>
                        <Field
                            name="password"
                            component={renderField}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                        />
                    </div>
                </div>
                {/*<div className="account__reset_password">*/}
                {/*    <p>RESET PASSWORD</p>*/}
                {/*</div>*/}
                <div className="form__form-group mt-4">
                    <div className="form__form-group-field">
                        <Field
                            name="remember"
                            component={renderCheckBoxField}
                            label="Remember me"
                            defaultChecked
                        />
                    </div>
                </div>
                <div className="form__form-group m-0">
                    <Button className="account__btn m-0" submit="true" color="primary" size="sm" disabled={disSubmit}>LOG IN</Button>
                </div>
            </Form>
        );
    }
}

export default reduxForm({
    form: 'log_in_form',
    validate
})(LogInForm);
