import axios from "axios";
import { BASE_API } from "../../config/api";

//TODO: review and discuss
// export const loginwithEmailPassword = (credentials) => {
//     return async (dispatch) => {
//         await axios.post(`${BASE_API}auth/admin/signin`, credentials)
//             .then((response) => {
//                 if (response && response.status !== 200) {
//                      dispatch({type: 'LOGIN_BTN_DISABLED', payload: true})
//                         return response.data
//                 } else {
//                     dispatch({type: 'LOGIN_BTN_ACTIVE', payload: false})
//                 }
//             }).catch((err) => {
//                 dispatch({type: LOGIN_FAILED})
//                 return err.response.data
//             })
//     }
// }


export const loginWithEmailPassword = async ({ email, password }) =>
    await axios.post(`${BASE_API}auth/admin/signin`, {
        email, password
    })
        .then(response => response && response.data)
        .catch(error => error && error.response && error.response.data);

export const checkAdminPassword = async ({ email, password }) =>
await axios.post(`${BASE_API}auth/admin/check-admin-password`, {
    email, password
})
    .then(response => response && response.data)
    .catch(error => error && error.response && error.response.data);


export const authenticationWithToken = async () =>
    await axios.get(`${BASE_API}auth`)
        .then(response => response && response.data)
        .catch(error => error.message);
