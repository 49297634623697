import React from 'react'
import DayworkLogRow from "./DayworkLogRow";
import {Table} from "reactstrap";

const DayworkHistoryTable = ({dayworksLogs, onInvoicePressed}) => {
    return <Table
        responsive
        className="table table--bordered pl-0">
        <thead>
        <tr>
            <th>ACTION</th>
            <th>USER</th>
            <th>DATE</th>
        </tr>
        </thead>
        <tbody>
        {dayworksLogs.sort(function(a, b){return (a.created_at > b.created_at) ? 1 : -1}).map((dayworkLog) => <DayworkLogRow dayworkLog={dayworkLog} onInvoicePressed={onInvoicePressed}/>)}
        </tbody>

    </Table>
}

export default DayworkHistoryTable
