import React from "react";
import { TextField } from "@material-ui/core";

const MiniTextField = ({
  input: { value, onChange, onBlur },
  label,
  placeholder,
  type,
  meta: { touched, error },
}) => {
  const handleChange = (value) => {
    onChange(value);
  };

  const getValue = (value) => {
    return value;
  };

  const handleOnBlur = (value) => {
    onBlur(value);
  };

  return (
    <>
      <TextField
        placeholder={placeholder}
        label={label}
        value={getValue(value)}
        onChange={handleChange}
        type={type ? type : "text"}
        className={"miniTextField"}
        inputProps={{ maxLength: 5 }}
        style={{
          width: value.length * 9 + "px",
          minWidth: "10px",
          maxWidth: "100px",
        }}
        onBlur={handleOnBlur}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </>
  );
};

export default MiniTextField;
