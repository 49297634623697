import React from "react";
import { Table } from "reactstrap";
import { WORK_TYPE, JOB_STATUS } from "../../../constants/enamerations";
import { pageSize } from "../../../config/pagination";
import Pagination from "@material-ui/lab/Pagination";

const UninvoicedJobsTable = ({
                       jobs,
                       previewJob,
                       setPage,
                       page,
                   }) => {

    return (
        <form>
            <Table responsive hover className="table react-table table--bordered">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>TRADE</th>
                    <th>NAME</th>
                    <th>STATUS</th>
                    <th>QTY</th>
                    <th>BLOCK</th>
                    <th>LEVEL</th>
                    <th>UNIT TYPE</th>
                    <th>UNIT ID</th>
                </tr>
                </thead>
                <tbody>
                {jobs &&
                    jobs.items &&
                    jobs.items.map((item, index) => (
                        <tr key={index}>
                            <td onClick={() => previewJob(item)}>{item.id && item.id}</td>
                            <td onClick={() => previewJob(item)}>
                                {item.job_type && WORK_TYPE[item.job_type].icon} &nbsp;{" "}
                                {item.job_type && WORK_TYPE[item.job_type].label}
                            </td>
                            <td onClick={() => previewJob(item)}>
                                {item.description && item.description}
                            </td>
                            <td onClick={() => previewJob(item)}>
                                {item.status && JOB_STATUS[item.status].icon} &nbsp;{" "}
                                {item.status && JOB_STATUS[item.status].label}
                            </td>
                            <td onClick={() => previewJob(item)}>{item.qty && item.qty}</td>
                            <td onClick={() => previewJob(item)}>
                                {item.block && item.block.name}
                            </td>
                            <td onClick={() => previewJob(item)}>
                                {item.level && item.level.name}
                            </td>
                            <td onClick={() => previewJob(item)}>
                                {item.unit_type && item.unit_type}
                            </td>
                            <td onClick={() => previewJob(item)}>
                                {item.plot && item.plot.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {jobs && Math.ceil(jobs.total / pageSize) >= 2 && (
                <div className="box-pagination">
                    <Pagination
                        count={jobs && jobs.total ? Math.ceil(jobs.total / pageSize) : 0}
                        page={page + 1}
                        onChange={(event, page) => setPage(page - 1)}
                    />
                </div>
            )}
        </form>
    );
};

export default UninvoicedJobsTable;
