import React, {useCallback, useEffect, useRef, useState} from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import ProjectRow from "../../../Projects/Tables/ProjectRow";
import SearchIcon from "mdi-react/SearchIcon";


const PROJECT_AREAS = {
  ACTIVE: "activeProjects",
  COMPLETED: "completedProjects",
  DRAFT : 'draftProjects',
}

const ContractProjectsTable = ({ href, status, contract }) => {
  const contractProjects = contract && contract[0] &&
  !!contract[0][PROJECT_AREAS[status]] ? contract[0][PROJECT_AREAS[status]] : []
  //States
  const [projects, setProjects] = useState(contractProjects)
  const [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    if(!!searchFilter){
      const filteredProjects =  contractProjects.filter((project) => project.name.toLowerCase()
          .includes(searchFilter.toLowerCase()) )
      setProjects(filteredProjects)

    }else {
      setProjects(contractProjects)
    }
  }, [searchFilter, contractProjects]);

  const isProjectGroupVisible = useRef({});

  const expandProject = (projectId) => {
    return isProjectGroupVisible.current[projectId] = true;
  }

  const _renderTableHeader = useCallback(() => {
    return <thead>
    <tr>
      <th>NAME</th>
      <th>BUDGET</th>
      <th>PAID</th>
      <th>DATE</th>
      <th>TERM</th>
    </tr>
    </thead>
  },[])

  const _renderSearchSection = useCallback(() => {
    return  <div className="r-flex flex-row justify-content-between w-100">
      <div className="mt-3">
        <div className="form__form-group-field">
          <div className="search_input">
            <SearchIcon />
            <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search_input"
                value={searchFilter}
                onChange={(data) => {
                  setSearchFilter(data.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
            />
          </div>
        </div>
      </div>
    </div>
  },[searchFilter, setSearchFilter])

  const _renderTableBody = () => {
    return <tbody>
    {projects.length === 0 ? <tr><td>No projects in this category!</td></tr> : projects.sort(function(a, b){return (a.name > b.name) ? 1 : -1}).map((item, index) => {
      return(
          <ProjectRow
              key={index}
              item={item}
              index={index}
              href={href}
              status={status}
              expandProject={expandProject}
              isProjectGroupVisibleRef={isProjectGroupVisible}
          />
      )
    })}
    </tbody>
  }
  const _renderTable = () => {
    return <Table
        responsive
        hover
        className="mt-3 table react-table table--bordered pl-0"
    >
      {_renderTableHeader()}
      {_renderTableBody()}

    </Table>
  }
  return (
      <>
        {_renderSearchSection()}
        {_renderTable()}
      </>

  );
};

export default withRouter(ContractProjectsTable);
