import React from 'react';
import { Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import SelectFieldNoValidate from '../../../shared/components/SelectFieldNoValidate';
import { EMPLOYMENT_TYPE_OPTIONS, USER_ROLE_OPTIONS } from '../../../constants/options';
import SearchIcon from 'mdi-react/SearchIcon';

const FilterForm = ({ filter, setFilter, setPage }) => {
    const Search = (data) => {
        setFilter({ ...filter, text: data })
        setPage(0)
    }

    const JobStatus = (data) => {
        setFilter({ ...filter, job_status: data ? data : '' })
        setPage(0)
    }

    const SystemRole = (data) => {
        setFilter({ ...filter, systemRole: data ? data : '' })
        setPage(0)
    }

    return (
        <form>
            <Col md={12} xl={12} className="p-0 d-flex justify-content-start">
                <div className="form__form-group mr-3">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input type="text" name="search" placeholder="Search..." className="search_input" value={filter.text} onChange={data => Search(data.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="form__form-group mr-3">
                    <div className="form__form-group-field">
                        <Field
                            name="job_status"
                            component={SelectFieldNoValidate}
                            placeholder="Status"
                            options={EMPLOYMENT_TYPE_OPTIONS}
                            onChange={data => JobStatus(data)}
                        />
                    </div>
                </div>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <Field
                            name="system_кole"
                            component={SelectFieldNoValidate}
                            placeholder="Role"
                            options={USER_ROLE_OPTIONS}
                            onChange={data => SystemRole(data)}
                        />
                    </div>
                </div>
            </Col>
        </form>
    );
}

export default reduxForm({
    form: 'filter_form',
})(FilterForm);
