import React from "react";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import classNames from "classnames";
import moment from "moment";

class CheckBoxField extends React.Component {

  render() {
    const {
      disabled,
      className,
      name,
      onChange,
      label,
      color,
      checked,
    } = this.props;
    const CheckboxClass = classNames({
      "checkbox-btn": true,
      disabled,
    });
    const data = moment().format("x");
    return (
        <label
            className={`${CheckboxClass} ${
                className ? ` checkbox-btn--${className}` : ""
            }`}
            htmlFor={`${name}_${data}`}
        >
          <input
              className="checkbox-btn__checkbox"
              type="checkbox"
              id={`${name}_${data}`}
              name={name}
              onChange={onChange}
              checked={checked}
              disabled={disabled}
          />
          <span
              className="checkbox-btn__checkbox-custom"
              style={color ? { background: color, borderColor: color } : {}}
          >
          <CheckIcon />
        </span>
          {className === "button" ? (
              <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span>
          ) : (
              ""
          )}
          <span className="checkbox-btn__label">{label}</span>
        </label>
    );
  }
}

const renderCheckBoxField = (props) => {
  const {
    input,
    label,
    defaultChecked,
    disabled,
    className,
    color,
    defaultValue,
    checked,
  } = props;

  return (
      <CheckBoxField
          {...input}
          label={label}
          defaultChecked={defaultChecked}
          disabled={disabled}
          className={className}
          color={color}
          defaultValue={defaultValue}
          checked={checked}
      />
  );
};

export default renderCheckBoxField;

