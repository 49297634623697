import {
  getInvoiceByUserIdAsync,
  updateInvoiceAsync,
  getInvoiceByProjectIdAsync,
  updateInvoicesByIdsAsync,
  getInvoiceByIdAsync,
  invoiceToCsvAsync,
  getAllInvoicesAsync,
} from "../api/invoice";

export const getInvoiceByUserId = async (id, filter, pagination) =>
  await getInvoiceByUserIdAsync(id, filter, pagination);

export const getInvoiceByProjectId = async (id, filter, pagination) =>
  await getInvoiceByProjectIdAsync(id, filter, pagination);

export const getAllInvoices = async (filter, pagination) =>
  await getAllInvoicesAsync(filter, pagination);

export const updateInvoice = async (id, isPaid) =>
  await updateInvoiceAsync(id, isPaid);

export const updateInvoicesByIds = async (status, ids) =>
  await updateInvoicesByIdsAsync(status, ids);

export const getInvoiceById = async (id) => await getInvoiceByIdAsync(id);

export const invoiceToCsv = async (data) => await invoiceToCsvAsync(data);
