import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { STEP_STATUS } from "../../../constants/enamerations";
import ImagesCard from "./ImagesCard";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import Moment from "react-moment";
import {UNKNOWN_REQUESTS} from "../../../constants/routes";
import {Link} from "react-router-dom";

const BudgetVariations = ({ data, typeCard, projectId }) => {
  const [moreImageID, setMoreImageID] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    data.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };

  if(!data.length) {
    return <div className={'d-flex flex-row align-items-center justify-content-space-center'}>
      <p>No additional work request were found.</p>
      <Link to={{pathname: UNKNOWN_REQUESTS.replace(':id', projectId)}}>
        <Button size="sm" color="primary" className={`ml-2 mt-3`}>
          Add
        </Button>
      </Link>

    </div>
  }

  return (
    <>
      {data &&
        data.map((item, index) =>
          item.status === "COMPLETED" ? (
            item.assets.length === 0 ? (
              <div key={index}>
                <Row className="d-flex justify-content-between">
                  <Col md={12} xl={12}>
                    <div
                      style={{
                        borderLeft: `4px solid ${
                          STEP_STATUS[item.status].color
                        }`,
                      }}
                      className="question_job_wrapper"
                    >
                      <div className="wrapper_page-title">
                        <h5 className="page-title m-0 mb-2">{typeCard}</h5>
                      </div>
                      <p>{item.description ? item.description : ""}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xl={12}>
                    <div className="hr_wrapper mb-4 mt-4">
                      {data.length - 1 !== index && <hr />}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div key={index}>
                <Row className="d-flex justify-content-between">
                  <Col md={5} xl={6}>
                    <div
                      style={{
                        borderLeft: `4px solid ${item.status &&
                          STEP_STATUS[item.status].color}`,
                      }}
                      className="question_job_wrapper"
                    >
                      <div className="wrapper_page-title">
                        <h5 className="page-title m-0 mb-2">{typeCard}</h5>
                      </div>
                      <div
                        style={{ width: `100%` }}
                        className="description_question_wrapper"
                      >
                        <p>{item.description ? item.description : ""}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xl={6}>
                    <ImagesCard
                      item={item}
                      moreImageID={moreImageID}
                      toggleMoreImage={toggleMoreImage}
                      getPhotosByStep={getPhotosByStep}
                      cardClass="no-description"
                    />
                    <div className="row flex-column mt-2">
                      <div className="mb-1 budget-print-details">
                        <span className="dim">Amount</span>
                        <span className="budget-value">₤{item.budget}</span>
                      </div>
                      <div className="budget-print-details">
                        <span className="dim">Date</span>
                        <span className="budget-value">
                          <Moment format="YYYY-MM-DD">
                            {item && item.created_at}
                          </Moment>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row key={index}>
                  <Col md={12} xl={12}>
                    <div className="hr_wrapper mb-4 mt-4">
                      {data.length - 1 !== index && <hr />}
                    </div>
                  </Col>
                </Row>
              </div>
            )
          ) : (
            ""
          )
        )}
      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={viewerIsOpen}
      />
    </>
  );
};

export default BudgetVariations;
