import React from "react"
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const DateFieldMaterial = ({ input: { value, onChange }, label, placeholder, meta: { touched, error } }) => {
    return (
        <>
            <InputMask  mask="99-99-9999" maskChar=""  placeholder='DD-MM-YYYY' alwaysShowMask={false} value={value} onChange={onChange}>
                {(inputProps) => <TextField {...inputProps} label={label} disableUnderline />}
            </InputMask>
            {touched && error && <span className="form__form-group-error">{error}</span>}
        </>
    )
};
export default DateFieldMaterial;