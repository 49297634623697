import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Table } from 'reactstrap';
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import { pageSize } from "../../../config/pagination"
import { EMPLOYMENT_TYPE } from "../../../constants/enamerations"
import NamePicture from "../../../shared/components/NamePicture"
import Pagination from '@material-ui/lab/Pagination';
import {PREVIEW_USER} from "../../../constants/routes";


const UsersTable = ({ choosedUsers, setChoosedUsers, users, page, setPage }) => {
    const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

    const onChecked = (id, event) => id && event && event.target && event.target.checked ? setChoosedUsers({ ...choosedUsers, [id]: event.target.checked }) : delete choosedUsers[id]

    const onCheckedAll = (event) => {
        if (event && event.target.checked) {
            const temp = {}
            users.items.map(item => temp[item.id] = true)
            setChoosedUsers(temp);
        } else if (event && !event.target.checked) {
            setChoosedUsers({})
        }
    }

    return (
        <form>
            <Table responsive hover className="table react-table table--bordered">
                <thead>
                    <tr>
                        <th><Field
                            name="checkbox_main"
                            component={renderCheckBoxField}
                            onChange={(event) => onCheckedAll(event)}
                        /></th>
                        <th>ID</th>
                        <th>Photo</th>
                        <th>First name</th>
                        <th>Surname</th>
                        <th>Username</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.items && users.items.map((user, index) => {
                        const fullUrl = PREVIEW_USER.replace(':id', user.id)
                        return (<tr key={index}>
                            <td><Field
                                name={`User ${user.id}`}
                                component={renderCheckBoxField}
                                checked={choosedUsers[user.id]}
                                onChange={(event) => onChecked(user.id, event)}
                            /></td>
                            <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{user.id}</a></td>
                            <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                                {user.avatar_url
                                    ? <img className="topbar__avatar-img"
                                           src={user.avatar_url ? `${user.avatar_url}?${new Date().getTime()}` : Ava}
                                           alt="avatar" />
                                    : <div className="topbar__avatar-img">
                                        <NamePicture first_name={user.first_name} surname={user.surname} />
                                    </div>}
                            </a>
                            </td>
                            <td><a href={fullUrl}  className={'table-link'} style={{color: 'black'}}>{user && user.first_name}</a></td>
                            <td><a href={fullUrl}  className={'table-link'} style={{color: 'black'}}>{user && user.surname}</a></td>
                            <td><a href={fullUrl}  className={'table-link'} style={{color: 'black'}}>{user && user.email}</a></td>
                            <td><a href={fullUrl}  className={'table-link'} style={{color: 'black'}}>{user && user.active_jobs_status && EMPLOYMENT_TYPE[user.active_jobs_status].icon} &nbsp; {user && user.active_jobs_status && EMPLOYMENT_TYPE[user.active_jobs_status].label}</a></td>
                        </tr>)
                    }
                    )}
                </tbody>
            </Table>
            {users &&  Math.ceil(users.total / pageSize) >= 2 &&
            <div className="box-pagination">
                <Pagination count={users && users.total ? Math.ceil(users.total / pageSize)   : 0}  page={page + 1} onChange={(event, page) =>setPage(page - 1)} />
            </div>
            }

        </form >
    );
}

export default reduxForm({
    form: 'select_users_form',
})(UsersTable);

