import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from 'reactstrap'
import { TextField } from "@material-ui/core";
import { createVariationOrder } from '../../../redux/services/variation_orders';
import Select from "react-select";
import Loading from '../../../shared/components/Loading';

const CreateVoModal = ({
  modalVisibility,
  toggleModal,
  project,
}) => {
  const voTypes = [
  {
    label:'Client instruction',
    value:'CLIENT_INSTRUCTION'
  },
  {
    label:'Contra charge',
    value:'CONTRA_CHARGER'
  },
  {
    label:`LMEP Cost`,
    value:'LMEP_FAULT'
  }
]
  const formRef = useRef({
    client_reference:'',
    type:voTypes[0].value,
    amount:'',
    description:'',
    serial:' ',
    number:0,
  });

  const [validate, setValidate] = useState(true);
  const [formErrors, setFormErrors] = useState(
    {
      client_reference:'',
      amount:'',
      description:'',
    }
  )
  
  if(!project) return <Loading/>;

  const displayErrors = () => {
    if(!formRef.current.client_reference) {
      setValidate(false);
      return setFormErrors({
        amount:'',
        description:'',
        client_reference:'Client reference is required'
      })
    }
    if(!formRef.current.amount) {
      setValidate(false);
      return setFormErrors({
        description:'',
        client_reference:'',
        amount:'Amount is required'
      })
    }

    if(!formRef.current.description) {
      setValidate(false);
      return setFormErrors({
        client_reference:'',
        amount:'',
        description:'Description is required'
      })
    }

    setValidate(true);
    return setFormErrors({
      client_reference:'',
      amount:'',
      description:'',
    })
  }

  const handleVoCreation = (event) => {
    displayErrors();
    // event.preventDefault();

    if(!validate) {
      event.preventDefault();
      return;
    }

    createVariationOrder({
      ...formRef.current,
      project_id:project.id,
    }).then((res) => {
      toggleModal();
      console.log(res)
    })
  }

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog-centered`}
    >
    <div className={'modal__header d-flex justify-content-center text-center'}>
      <p className={'page-title'}>
        Create variation order 
      </p>
    </div>
    <div className={'modal__body mt-5'} >
      <Form className={'mt-3'} onSubmit={handleVoCreation}>
        <TextField
          label="Client reference"
          fullWidth
          margin='normal'
          name="client_reference"
          onChange={(e) => formRef.current.client_reference = e.target.value}
        />
        <p className='text-red'>{formErrors.client_reference}</p>
        <Select
          placeholder={"Variation order type"}
          onChange={(e) => formRef.current.type = e.value}
          options={voTypes}
          classNamePrefix="react-select"
          className="react-select w-100 mt-3"
          defaultValue={voTypes[0]}
        />
        <TextField
          label="Amount"
          type={'number'}
          fullWidth
          margin='normal'
          name="amount"
          onChange={(e) => formRef.current.amount = e.target.value}
        />
        {/* <TextField
          label="Serial"
          type={'string'}
          fullWidth
          margin='normal'
          name="serial"
          onChange={(e) => formRef.current.serial = e.target.value}
        /> */}
        <p className='text-red'>{formErrors.amount}</p>
        <TextField
          aria-label="minimum height"
          placeholder="Description"
          name="description"
          className={'mui-text-area'}
          InputProps={{ disableUnderline: true }}
          onChange={((e) => formRef.current.description = e.target.value)}
          style={{
            width: '100%',
            border:0,
            backgroundColor:'#F4F4F4',
            borderRadius:5,
            marginTop:10,
            paddingInline:10,
            paddingTop:10,
            paddingBottom:20,
          }}
        />
        <p className='text-red'>{formErrors.description}</p>
        <Button 
        type="submit"
        color = "primary"
        className="mt-3"
        >
          Create
        </Button>
      </Form>
    </div>
    </Modal>
  )
}

export default CreateVoModal;
