import axios from "axios";
import { BASE_API } from "../../config/api";

export const getAllContractsAsync = async () =>
  await axios
    .get(`${BASE_API}project-group/contracts`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getContractByIdAsync = async (contractId) =>
  await axios
  .get(`${BASE_API}project-group/contract/${contractId}`)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const getContractsWithProjectsStatusesAsync = async (groupId) =>
await axios
  .get(`${BASE_API}project-group/${groupId}/contract-with-projects-and-statuses`)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const createContractAsync = async (projectGroupName) =>
  await axios
    .post(`${BASE_API}project-group`, {
      projectGroupName,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const editContractAsync = async (contractId, newContractName) =>{
return  await axios
    .patch(`${BASE_API}project-group/${contractId}`, {
      projectGroupName: newContractName
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractDayWorkRequestsCounterAsync =  async (groupId) => {
  await axios
    .get(`${BASE_API}group/${groupId}/day-work-requests-counter`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractAdditionalWorkRequestsCounterAsync =  async (groupId) => {
  await axios
    .get(`${BASE_API}group/${groupId}/group-additional-work-requests-counter`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractQaRequestsCounterAsync =  async (groupId) => {
  await axios
    .get(`${BASE_API}group/${groupId}/group-qa-requests-counter`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractJobRequestsCounterAsync =  async (groupId) => {
  await axios
    .get(`${BASE_API}group/${groupId}/group-job-requests-counter`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractBudgetAnalyticsAsync =  async (groupId) => {
  return await axios
    .get(`${BASE_API}stat/group/${groupId}/budget`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractActiveJobsAsync =  async (groupId) => {
  return await axios
    .get(`${BASE_API}stat/group/${groupId}/active-jobs-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
};

export const getContractJobCompletionByTypesAsync =  async (groupId) => {
  return await axios
    .get(`${BASE_API}stat/group/${groupId}/job-types-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractJobUnderVerificationAsync =  async (groupId) => {
  return await axios
    .get(`${BASE_API}stat/group/${groupId}/under-verification-jobs-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

export const getContractInvoicesAsync =  async (groupId) => {
  return await axios
    .get(`${BASE_API}stat/group/${groupId}/invoice`)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}

