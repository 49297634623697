import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, Table } from "reactstrap";
import {
  reduxForm,
  FieldArray,
  Field,
  arrayPush,
  arraySplice,
} from "redux-form";
import Pagination from "@material-ui/lab/Pagination";
import store from "../../../redux/store";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import SelectField from "../../../shared/components/SelectField";
import SelectFieldBlue from "./components/SelectFieldBlue";
import EditBudgetForm from "./components/EditBudgetForm";
import { DeleteIconDark } from "../../../constants/icons";

const pageSize = 10;

const renderFlats = ({ fields, jobs }) => {
  const [page, setPage] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [choosedFlats, setChoosedFlats] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [levelFilter, setLevelFilter] = useState("");
  const [blockFilter, setBlockFilter] = useState("");
  const [unitTypeFilter, setUnitTypeFilter] = useState("");
  const [unitIdFilter, setUnitIdFilter] = useState("");
  const [levelValues, setLevelValues] = useState([]);
  const [blockValues, setBlockValues] = useState([]);
  const [unitTypeValues, setUnitTypeValues] = useState([]);
  const [unitIdValues, setUnitIdValues] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [budgetVariationValues, setBudgetVariationValues] = useState([]);
  const [selectedBudgetVariation, setSelectedBudgetVariation] = useState({});
  const [plotsLength, setPlotsLength] = useState(0);

  useEffect(() => {
    const blocks = fields.getAll();
    const distinctLevels = new Set();
    const distincUnitIds = new Set();
    const distinctBlocks = [];
    const distinctTypes = store
      .getState()
      .form.create_project_form_wizard.values.types.map((type) => {
        return { value: type.reference, label: type.reference };
      });

    for (let i = 0; i < blocks.length; i++) {
      distinctBlocks.push({ value: blocks[i].id, label: blocks[i].id });

      for (let j = 0; j < blocks[i].levels.length; j++) {
        distinctLevels.add(parseInt(blocks[i].levels[j].level));

        for (let k = 0; k < blocks[i].levels[j].flats.length; k++) {
          distincUnitIds.add(blocks[i].levels[j].flats[k].flatId);
        }
      }
    }

    var levelValueHelper = [];
    var unitIdHelper = [];

    distinctLevels.forEach((level) => {
      levelValueHelper.push({ value: level, label: `Level ${level}` });
    });

    distincUnitIds.forEach((unitId) => {
      unitIdHelper.push({ value: unitId, label: unitId });
    });

    levelValueHelper.sort((a, b) => a.value - b.value);
    unitIdHelper.sort((a, b) => a.value > b.value);

    setLevelValues([{ value: "", label: "All" }].concat(levelValueHelper));
    setUnitIdValues([{ value: "", label: "All" }].concat(unitIdHelper));
    setBlockValues([{ value: "", label: "All" }].concat(distinctBlocks));
    setUnitTypeValues([{ value: "", label: "All" }].concat(distinctTypes));
  }, []);

  useEffect(() => {}, [trigger]);

  useEffect(() => {
    setPage(0);

    const fieldsLength =
      fields &&
      fields.length > 0 &&
      fields
        .getAll()
        .filter((block) => {
          if (blockFilter) {
            return block.id === blockFilter;
          }

          return true;
        })
        .flatMap((block, blockIndex) =>
          block.levels.flatMap((level, levelIndex) =>
            level.flats
              .filter((flat) => {
                if (
                  level.level.toString().includes(levelFilter.toString()) &&
                  flat.type.reference.includes(unitTypeFilter) &&
                  flat.flatId.includes(unitIdFilter)
                ) {
                  return true;
                }
                return false;
              })
              .map((flat, flatIndex) => flat)
          )
        ).length;

    setPlotsLength(fieldsLength);
  }, [levelFilter, blockFilter, unitTypeFilter, unitIdFilter]);

  function onChecked(index, event) {
    if (event && event.target) {
      if (event.target.checked) {
        setChoosedFlats({
          ...choosedFlats,
          [index]: event.target.checked,
        });
      } else {
        setChoosedFlats((prevData) => {
          const newData = { ...prevData };
          delete newData[index];
          return newData;
        });
      }
    }
  }

  const onCheckedAll = (event) => {
    const temp = {};

    if (event && event.target) {
      fields
        .getAll()
        .filter((block) => {
          if (blockFilter) {
            return block.id === blockFilter;
          }

          return true;
        })
        .flatMap((block, blockIndex) =>
          block.levels.flatMap((level, levelIndex) =>
            level.flats
              .filter((flat) => {
                if (
                  level.level.toString().includes(levelFilter.toString()) &&
                  flat.type.reference.includes(unitTypeFilter) &&
                  flat.flatId.includes(unitIdFilter)
                ) {
                  return true;
                }
                return false;
              })
              .map((flat) => blockIndex + "@" + levelIndex + "@" + flat.flatId)
          )
        )
        .slice(page * 10, page * 10 + pageSize)
        .forEach(
          (value) => (temp[value] = event.target.checked ? true : undefined)
        );

      const choosedFlatsHelper = choosedFlats;

      Object.keys(temp).forEach((value) => {
        if (event && event.target) {
          if (event.target.checked) {
            if (choosedFlats.hasOwnProperty(value)) {
            } else {
              choosedFlatsHelper[value] = true;
            }
          } else {
            delete choosedFlats[value];
          }
        }
      });

      setChoosedFlats(choosedFlatsHelper);
      setIsAllChecked(event.target.checked ? true : undefined);
    }
  };

  function allocateJob() {
    Object.keys(choosedFlats).forEach((value) => {
      const blockIndex = parseInt(value.split("@")[0]);
      const levelIndex = parseInt(value.split("@")[1]);
      const flatId = value.split("@")[2];
      var helperVar = fields.get(blockIndex);

      const flatIndex = helperVar.levels[levelIndex].flats.findIndex(
        (flat) => flat.flatId === flatId
      );

      var helperJob = Object.assign({}, selectedJob);
      delete helperJob.costTypes;
      helperJob.costType =
        selectedJob.costTypes[
          selectedJob.costTypes.findIndex(
            (costType) =>
              costType.costTypeName === selectedBudgetVariation.costTypeName
          )
        ];

      helperJob.quantity = 1;

      if (
        helperVar.levels[levelIndex].flats[flatIndex].hasOwnProperty("jobs")
      ) {
        if (
          !helperVar.levels[levelIndex].flats[flatIndex].jobs.some(
            (job) => JSON.stringify(job) === JSON.stringify(helperJob)
          )
        ) {
          helperVar.levels[levelIndex].flats[flatIndex].jobs.push(helperJob);

          fields.splice(blockIndex, 1, helperVar);
          store.dispatch(
            arrayPush("create_project_form_wizard", "allocatedJobs", {
              job: helperJob,
              level: helperVar.levels[levelIndex].level,
              block: helperVar.id,
              unitType:
                helperVar.levels[levelIndex].flats[flatIndex].type.reference,
              unitId: helperVar.levels[levelIndex].flats[flatIndex].flatId,
              quantity: 1,
              price: helperJob.costType.budget,
            })
          );
        }
      } else {
        helperVar.levels[levelIndex].flats[flatIndex].jobs = [helperJob];

        fields.splice(blockIndex, 1, helperVar);
        store.dispatch(
          arrayPush("create_project_form_wizard", "allocatedJobs", {
            job: helperJob,
            level: helperVar.levels[levelIndex].level,
            block: helperVar.id,
            unitType:
              helperVar.levels[levelIndex].flats[flatIndex].type.reference,
            unitId: helperVar.levels[levelIndex].flats[flatIndex].flatId,
            quantity: 1,
            price: helperJob.costType.budget,
          })
        );
      }

      setTrigger(!trigger);
    });
  }

  return (
    <>
      <Container className="allocateBudgetContainer ">
        <Row md={9}>
          <div className="ml-3">
            <Field
              name={`level`}
              component={SelectField}
              required={true}
              options={levelValues}
              onChange={(value) => setLevelFilter(value.value)}
              placeholder={"Level"}
            />
          </div>

          <div className="ml-3">
            <Field
              name={`block`}
              component={SelectField}
              required={true}
              options={blockValues}
              onChange={(value) => setBlockFilter(value.value)}
              placeholder={"Block"}
            />
          </div>

          <div className="ml-3">
            <Field
              name={`unitType`}
              component={SelectField}
              required={true}
              options={unitTypeValues}
              onChange={(value) => setUnitTypeFilter(value.value)}
              placeholder={"Unit type"}
            />
          </div>

          <div className="ml-3">
            <Field
              name={`unitId`}
              component={SelectField}
              required={true}
              options={unitIdValues}
              onChange={(value) => setUnitIdFilter(value.value)}
              placeholder={"Unit ID"}
            />
          </div>

          <div className="ml-3">
            <Field
              name={`jobToAssgin`}
              component={SelectFieldBlue}
              required={true}
              options={jobs}
              onChange={(value) => {
                setSelectedJob(value.value);
                setBudgetVariationValues(
                  value.value.costTypes.map((costType) => {
                    return { value: costType, label: costType.costTypeName };
                  })
                );
              }}
              placeholder={"Select job to assign"}
              isSearchable={true}
            />
          </div>

          <div className="ml-3">
            <Field
              name={`budgetVariation`}
              component={SelectFieldBlue}
              required={true}
              options={budgetVariationValues}
              onChange={(value) => setSelectedBudgetVariation(value.value)}
              placeholder={"Budget variation"}
            />
          </div>
        </Row>
        <Row md={9} className={'mt-2'}>
          <Col md={6}>
            <Button
                className="text-nowrap button"
                disabled={Object.keys(choosedFlats).length === 0}
                onClick={() => setChoosedFlats({})}
            >
              Reset Selected Plots
            </Button>
          </Col>
        </Row>
        <Row md={9}>
          <div className="jobTableContainer">
            {Object.keys(choosedFlats).length ? (
              <Row md={12} className="justify-content-between">
                <Col md={4}>
                  <span className="text-nowrap font-weight-bold">
                    Selected jobs: {Object.keys(choosedFlats).length}
                  </span>
                </Col>
              </Row>
            ) : null}

            <Table
              hover
              responsive
              className="table react-table table--bordered"
            >
              <thead>
                <tr className={"d-flex"}>
                  <th
                    className={
                      "th30 d-flex justify-content-center align-items-center"
                    }
                  >
                    <div>
                      <Field
                        name={`allCheckboxes`}
                        component={renderCheckBoxField}
                        onChange={(event) => onCheckedAll(event)}
                        checked={
                          isAllChecked && Object.keys(choosedFlats).length
                            ? true
                            : undefined
                        }
                      />
                    </div>
                  </th>
                  <th className={"th60"}>LEVEL</th>
                  <th className={"th60"}>BLOCK</th>
                  <th className={"th180"}>UNIT TYPE</th>
                  <th className={"th180"}>UNIT ID</th>
                  <th className={"th180"}>NO. OF ASSIGNED JOBS</th>
                  <th className={"th180"}>TOTAL COST</th>
                </tr>
              </thead>

              <tbody>
                {fields &&
                  fields.length > 0 &&
                  fields
                    .getAll()
                    .filter((block) => {
                      if (blockFilter) {
                        return block.id === blockFilter;
                      }

                      return true;
                    })
                    .flatMap((block, blockIndex) =>
                      block.levels.flatMap((level, levelIndex) =>
                        level.flats
                          .filter((flat) => {
                            if (
                              level.level
                                .toString()
                                .includes(levelFilter.toString()) &&
                              flat.type.reference.includes(unitTypeFilter) &&
                              flat.flatId.includes(unitIdFilter)
                            ) {
                              return true;
                            }
                            return false;
                          })
                          .map((flat) => {
                            return (
                              <tr
                                key={
                                  blockIndex +
                                  "" +
                                  levelIndex +
                                  "" +
                                  flat.flatId
                                }
                                className={
                                  "d-flex justify-content-center align-items-center"
                                }
                              >
                                <td className={"operativeStepsCheckBox"}>
                                  <div>
                                    <Field
                                      name={`Step${blockIndex} ${levelIndex} ${flat.flatId}}`}
                                      component={renderCheckBoxField}
                                      onChange={(event) => {
                                        onChecked(
                                          blockIndex +
                                            "@" +
                                            levelIndex +
                                            "@" +
                                            flat.flatId,
                                          event
                                        );
                                      }}
                                      checked={
                                        choosedFlats[
                                          blockIndex +
                                            "@" +
                                            levelIndex +
                                            "@" +
                                            flat.flatId
                                        ]
                                      }
                                    />
                                  </div>
                                </td>
                                <td className={"td60"}>{level.level}</td>
                                <td className={"td60"}>{block.id}</td>
                                <td className={"td180"}>
                                  {flat.type.reference}
                                </td>
                                <td className={"td180"}>{flat.flatId}</td>
                                <td className={"td180"}>
                                  {flat.jobs ? flat.jobs.length : 0}
                                </td>
                                <td className={"td180"}>
                                  £
                                  {flat.jobs
                                    ? flat.jobs
                                        .map(
                                          (job) =>
                                            job.quantity *
                                            Number(job.costType.budget)
                                        )
                                        .reduce((prev, next) => prev + next)
                                    : 0}
                                </td>
                              </tr>
                            );
                          })
                      )
                    )
                    .slice(page * 10, page * 10 + pageSize)}
              </tbody>
            </Table>

            {fields && Math.ceil(plotsLength / pageSize) >= 2 && (
              <div className="box-pagination">
                <Pagination
                  count={
                    fields && plotsLength
                      ? Math.ceil(plotsLength / pageSize)
                      : 0
                  }
                  page={page + 1}
                  onChange={(event, page) => {
                    setPage(page - 1);
                  }}
                />
              </div>
            )}
          </div>
        </Row>

        <Row md={9} className={"justify-content-end"}>
          <div className="mr-3 d-flex">
            <div className="allocateButton">
              <Button
                className="button"
                onClick={() => allocateJob()}
                disabled={
                  !(
                    Object.keys(selectedJob).length &&
                    Object.keys(selectedBudgetVariation).length &&
                    Object.keys(choosedFlats).length
                  )
                }
              >
                Allocate
              </Button>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

const renderAllocatedJobs = ({ fields, setCanFinish }) => {
  const [page, setPage] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    setCanFinish(fields.length);
    // console.log("Use effect triggered");
    // console.log("fields from use effect:");
    // console.log(fields.getAll());
  }, [fields.length]);

  function getBlocks() {
    return store.getState().form.create_project_form_wizard.values.blocks;
  }

  function editQuantity(data, index) {
    var helperVar = fields.get(index);

    helperVar.quantity = data.budget;

    fields.splice(index, 1, helperVar);

    const blocks = getBlocks();

    const blockIndex = blocks.findIndex(
      (value) => value.id === helperVar.block
    );
    const block = blocks[blockIndex];

    const levelIndex = block.levels.findIndex(
      (value) => value.level.toString() === helperVar.level.toString()
    );
    const level = block.levels[levelIndex];

    const flatIndex = level.flats.findIndex(
      (value) =>
        value.flatId === helperVar.unitId &&
        value.type.reference === helperVar.unitType
    );
    const flat = level.flats[flatIndex];

    const jobIndex = flat.jobs.findIndex(
      (value) => value.id === helperVar.job.id
    );

    blocks[blockIndex].levels[levelIndex].flats[flatIndex].jobs[
      jobIndex
    ].quantity = Number(helperVar.quantity);

    store.dispatch(
      arraySplice("create_project_form_wizard", "blocks", blockIndex, 1, block)
    );
  }
  const deleteAllocatedJob = (job) => {
    fields.getAll().map((allocatedJob) => {
      if (JSON.stringify(allocatedJob) === JSON.stringify(job)) {
        // Delete from `allocatedJobs`
        store.dispatch(
          arraySplice(
            "create_project_form_wizard",
            "allocatedJobs",
            fields.getAll().indexOf(allocatedJob),
            1
          )
        );
      }
      return fields;
    });

    // Delete from `blocks`
    const blocks = getBlocks();
    const modifiedBlock = blocks.find((block) => block.id === job.block);
    const blockIndex = blocks.findIndex(
      (block) => block.id === modifiedBlock.id
    );

    modifiedBlock.levels.forEach((level) => {
      if (level.level === job.level) {
        level.flats.forEach((flat) => {
          if (flat.flatId === job.unitId) {
            const jobIndex = flat.jobs.findIndex(
              (jobValue) => jobValue.id === job.id
            );
            flat.jobs.splice(jobIndex, 1);

            // If the jobs array is empty, delete the jobs property, to prevent rendering bug
            if (!flat.jobs.length) {
              delete flat.jobs;
            }
          }
        });
      }
    });

    store.dispatch(
      arraySplice(
        "create_project_form_wizard",
        "blocks",
        blockIndex,
        1,
        modifiedBlock
      )
    );

    // console.log("delete triggered");
    // console.log("Fields from function:")
    // console.log(fields.getAll());
  };

  return (
    fields &&
    fields.length > 0 && (
      <>
        <Container className="allocateBudgetContainer mt-4">
          <Row md={12} className="justify-content-between">
            <Col md={4}>
              <span className="text-nowrap font-weight-bold">
                Allocated jobs
              </span>
            </Col>
          </Row>

          <Row md={9}>
            <div className="jobTableContainer">
              <Table
                responsive
                hover
                className="table react-table table--bordered"
              >
                <thead>
                  <tr className={"d-flex"}>
                    <th className={"th60"}>LEVEL</th>
                    <th className={"th60"}>BLOCK</th>
                    <th className={"th180"}>UNIT TYPE</th>
                    <th className={"th180"}>UNIT ID</th>
                    <th className={"th180"}>QUANTITY</th>
                    <th className={"th180"}>TOTAL COST</th>
                    <th className={"th180"}>JOB DESCRIPTION</th>
                    <th className={"th180"}>BUDGET VARIATION</th>
                  </tr>
                </thead>

                <tbody>
                  {fields &&
                    fields.length > 0 &&
                    fields
                      .getAll()
                      .map((job, jobIndex) => {
                        return (
                          <tr
                            key={jobIndex}
                            className={
                              "d-flex justify-content-center align-items-center"
                            }
                          >
                            <td className={"td60"}>{job.level}</td>
                            <td className={"td60"}>{job.block}</td>
                            <td className={"td180"}>{job.unitType}</td>
                            <td className={"td180"}>{job.unitId}</td>
                            <td className={"td180"}>
                              {isEditing && currentIndex === jobIndex ? (
                                <EditBudgetForm
                                  budget={job.quantity}
                                  onSubmit={(data) => {
                                    editQuantity(data, jobIndex);
                                    setIsEditing(false);
                                  }}
                                />
                              ) : (
                                <span
                                  onClick={() => {
                                    setIsEditing(true);
                                    setCurrentIndex(jobIndex);
                                  }}
                                >
                                  {job.quantity}
                                </span>
                              )}
                            </td>
                            <td className={"td180"}>
                              £{job.price * job.quantity}
                            </td>

                            <td className={"td180"}>
                              <div
                                className={
                                  "operativeStepJobStepDescriptionContainer"
                                }
                              >
                                <span
                                  className={
                                    job.job.description.length > 20
                                      ? "operativeStepJobDescriptionText"
                                      : null
                                  }
                                  data-text={job.job.description}
                                  style={{ lineHeight: 1.5 }}
                                >
                                  {job.job.description}
                                </span>
                              </div>
                            </td>

                            <td className={"td180"}>
                              <div
                                className={
                                  "operativeStepJobStepDescriptionContainer"
                                }
                              >
                                <span
                                  className={
                                    job.job.costType.costTypeName.length > 20
                                      ? "operativeStepJobDescriptionText"
                                      : null
                                  }
                                  data-text={job.job.costType.costTypeName}
                                  style={{ lineHeight: 1.5 }}
                                >
                                  {job.job.costType.costTypeName}
                                </span>
                              </div>
                            </td>
                            <td onClick={() => deleteAllocatedJob(job)}>
                              <DeleteIconDark />
                            </td>
                          </tr>
                        );
                      })
                      .slice(page * 10, page * 10 + pageSize)}
                </tbody>
              </Table>

              {fields && Math.ceil(fields.length / pageSize) >= 2 && (
                <div className="box-pagination">
                  <Pagination
                    count={
                      fields && fields.length
                        ? Math.ceil(fields.length / pageSize)
                        : 0
                    }
                    page={page + 1}
                    onChange={(event, page) => {
                      setPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Container>
      </>
    )
  );
};

function jobsToSelectValues() {
  const selectedJobs = store.getState().form.create_project_form_wizard.values
    .jobs;
  return selectedJobs.map((job) => {
    return { value: job, label: job.description };
  });
}

let CreateProjectStepNine = (props) => {
  const { handleSubmit, saveProject } = props;
  const [canFinish, setCanFinish] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container className="m-0">
        <Row md={12} className="justify-content-between text-nowrap">
          <Col md={4}>
            <h2>Allocate jobs to plots</h2>
          </Col>

          <div className="buttonContainer">
            <Col md={6}>
              <Button className="button" onClick={saveProject}>
                Save
              </Button>
            </Col>

            <Col md={6} className={"justify-content-end d-flex"}>
              <Button
                type={"submit"}
                className="text-nowrap button"
                disabled={!canFinish}
              >
                Finish
              </Button>
            </Col>
          </div>
        </Row>

        <FieldArray
          name="blocks"
          component={renderFlats}
          rerenderOnEveryChange={true}
          props={{
            jobs: jobsToSelectValues(),
          }}
        />

        <FieldArray
          name="allocatedJobs"
          component={renderAllocatedJobs}
          rerenderOnEveryChange={true}
          props={{
            setCanFinish,
          }}
        />
      </Container>
    </form>
  );
};

CreateProjectStepNine = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepNine);

export default CreateProjectStepNine;
