import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { Col, Card, CardBody, Button, Row } from "reactstrap";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial";
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate";
import { getJobTemplates } from "../../../redux/services/templates";
import { addJobToProject } from "../../../redux/services/jobs";
import { getUniqueValuesByFilter } from "../../../redux/services/projects";
import store from "../../../redux/store";

const index = ({ handleSubmit, toggle, project }) => {
  const [levels, setLevels] = useState([]);
  const [plots, setPlots] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);

  useEffect(() => {
    getValues().catch((e) => {
      alert(e);
    });
  }, []);

  const getValues = async () => {
    await getJobTemplates().then((response) => {
      setJobs(response.jobTemplates);
    });

    await getUniqueValuesByFilter(project.id, "unit_type").then((response) => {
      setUnitTypes(response);
    });
  };

  function setLevelsForDropDown(blockId) {
    const blockIndex = project.blocks.findIndex(
      (block) => block.id === blockId
    );

    setLevels(project.blocks[blockIndex].levels);
  }

  function setPlotsForDropDown(levelId) {
    const levelIndex = levels.findIndex((level) => level.id === levelId);
    setPlots(levels[levelIndex].plots);
  }

  async function createJob(data) {
    const jobData = {
      projectId: project.id,
      deadline: project.deadline,
      project_reference: project.project_ref,
      ...data,
    };
    await addJobToProject(jobData).then((response) => {
      if (response.data) {
        toggle("job");
      } else {
        alert("A problem occured while adding the job.");
      }
    });
  }

  return (
    <form onSubmit={handleSubmit((data) => createJob(data))}>
      <Col md={12} lg={12} className="p-0 mt-5">
        <Card className="p-0">
          <CardBody className="p-0 pt-4">
            <Col md={12} className="mt-2">
              <Row md={12}>
                <span className="sp16">Select job</span>
              </Row>

              <Row md={12}>
                <div className="create_user_select_wrapper w-100">
                  <Field
                    name="job"
                    component={SelectFieldNoValidate}
                    placeholder="Select job"
                    options={jobs.map((value) => ({
                      label: value.description,
                      value: value,
                    }))}
                  />
                </div>
              </Row>
            </Col>

            <Row md={12} className="mt-3 ml-1">
              <Col md={5}>
                <Row>
                  <span className="sp16">Select block</span>
                </Row>

                <Row>
                  <Field
                    name="block"
                    component={SelectFieldNoValidate}
                    placeholder="Select block"
                    options={project.blocks.map((value) => ({
                      label: value.name,
                      value: value,
                    }))}
                    onChange={(event, newValue) => {
                      setLevelsForDropDown(newValue.id);
                    }}
                  />
                </Row>
              </Col>

              <Col md={5} className="ml-3">
                <Row>
                  <span className="sp16">Select level</span>
                </Row>

                <Row>
                  <Field
                    name="level"
                    component={SelectFieldNoValidate}
                    placeholder="Select level"
                    options={levels.map((value) => ({
                      label: value.name,
                      value: value,
                    }))}
                    onChange={(event, newValue) => {
                      setPlotsForDropDown(newValue.id);
                    }}
                  />
                </Row>
              </Col>
            </Row>

            <Row md={12} className="mt-3 ml-1">
              <Col md={5}>
                <Row>
                  <span className="sp16">Select plot</span>
                </Row>

                <Row>
                  <Field
                    name="plot"
                    component={SelectFieldNoValidate}
                    placeholder="Select plot"
                    options={plots.map((value) => ({
                      label: value.name,
                      value: value,
                    }))}
                  />
                </Row>
              </Col>

              <Col md={5} className="ml-3">
                <Row>
                  <span className="sp16">Select unit type</span>
                </Row>

                <Row>
                  <Field
                    name="unit_type"
                    component={SelectFieldNoValidate}
                    placeholder="Select unit type"
                    options={unitTypes}
                  />
                </Row>
              </Col>
            </Row>

            <Row md={12} className="mt-3 ml-1">
              <Col md={5}>
                <Row>
                  <span className="sp16">Quantity</span>
                </Row>

                <Row>
                  <Field
                    name="qty"
                    component={TextFieldMaterial}
                    placeholder="Quantity"
                    label="Quantity"
                    type={"number"}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        onKeyDown: (event) => {
                          // Prevent minus sign from being entered
                          if (event.keyCode === 189 || event.keyCode === 109) {
                            event.preventDefault();
                          }

                        }
                      }
                    }}
                  />
                </Row>
              </Col>

              <Col md={5} className="ml-3">
                <Row>
                  <span className="sp16">Rate</span>
                </Row>
                <Row>
                  <Field
                    name="rate"
                    component={TextFieldMaterial}
                    placeholder="Rate"
                    label="Rate"
                    type={'number'}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">￡</InputAdornment>,
                      inputProps: {
                        min: 0,
                        step: 0.01,
                        onKeyDown: (event) => {

                          //Get Form
                          const myForm = store.getState().form.job_create_form

                          // Get the pressed key
                          const keyPressed = event.key;

                          // Prevent the default behavior if the pressed key is not a number, a dot, or a backspace
                          if (isNaN(keyPressed) && keyPressed !== '.' && keyPressed !== 'Backspace') {
                            event.preventDefault();


                          }

                          // Allow only one dot in the input value
                          if (keyPressed === '.' && myForm && myForm.values && myForm.values.rate && myForm.values.rate.includes('.')) {
                            event.preventDefault();
                          }

                          // Allow only non-negative numbers
                          if (keyPressed === '-'  || keyPressed === 'e') {
                            event.preventDefault();
                          }

                          // Allow only up to 2 decimal places
                          if (myForm && myForm.values && myForm.values.rate && myForm.values.rate.includes('.') && keyPressed !== 'Backspace') {
                            const decimalPlaces = myForm.values.rate.split('.')[1].length;
                            if (decimalPlaces >= 2) {
                              event.preventDefault();
                            }
                          }
                        }
                      }
                    }}
                  />
                </Row>
              </Col>
            </Row>

            <div className="form__form-group mt-3">
              <div className="form__form-group-field d-flex justify-content-end">
                <div className="d-flex">
                  <Button
                    className="mb-0"
                    type="button"
                    onClick={() => toggle()}
                  >
                    Cancel
                  </Button>
                  <Button className="mb-0" color="primary" type="submit">
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "job_create_form",
})(index);
