import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button, Modal, Card, CardBody, Input } from "reactstrap";
import { STEP_STATUS } from "../../../constants/enamerations";
import ImagesCard from "./ImagesCard";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import { DeleteIconDark, EditIcon } from "../../../constants/icons";

const StepCard = ({ data, typeCard, isPageEditable, addToEditedField, EditedFields }) => {
  const [moreImageID, setMoreImageID] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [deletionModal, setDeletionModal] = useState(false);
  const [editableFieldStyling, setEditableFieldStyling] = useState({})

  const elementRefs = useRef([]);
  elementRefs.current = [];

  const [description, setDescription] = useState('');
  const [photoMandatory, setPhotoMandatory] = useState(false);

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    data.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };

  const toggleModal = () => setModal(!modal);
  const toggleDeletionModal = () => setDeletionModal(!deletionModal);


  // addToRefs function creates a ref for each step/question that exists in the
  // initial "data" object that is sent through the props
  // this is necessary so we can refer to certain steps/questions
  const addToRefs = (element) => {
    element && !elementRefs.current.includes(element) && elementRefs.current.push(element)
  }

  // as the user clicks on the Edit icon, this function shifts the focus
  // on the coresponding step/question
  const focusOnField = (item) => {
    elementRefs.current.map( paragraph =>
    parseInt(paragraph.id) === parseInt(item.id) && paragraph.focus())
  }

  // this function checks if the step or question paragraph is diffrent from the
  // initial data that was parsed from "data" object.
  const checkIfChanged = element =>
    elementRefs.current.map(paragraph => {
    if(parseInt(paragraph.id) === parseInt(element.id) && (paragraph.innerHTML !== element.text))
    {
      const itemAndNewDescription = {...element, newDescription:paragraph.innerHTML}
      addToEditedField(itemAndNewDescription, typeCard, "edited")
    }
    return true;
})

  useEffect(()=>{
    if(isPageEditable) setEditableFieldStyling({
      border:"2px dashed black",
      borderRadius:"0.5rem",
      padding:"0.5rem"
    })
    else setEditableFieldStyling({})
  },[isPageEditable])

  // this function takes the step or the question and sends it back to the main page
  // first, it verifies if it's a step or a question (because steps have photoMandatory and questions not)
  // the step or the question will be pushed into the "fields" object from PreviewJob
  const sendToEditedField = (description, photoMandatory, typeCard, action) => {
    if(typeCard==="STEP") {
      const sendedObject = {description, photoMandatory}
      addToEditedField(sendedObject, typeCard, action)
    } else {
      addToEditedField(description, typeCard, action)
    }
  }

  const checkIfItemIsDeleted = (item) => {
    var isDeleted = false;
    EditedFields[typeCard].deleted.forEach(field => {
      if(field.item.id === item.id) isDeleted = true;
    })
    return isDeleted
  }
  return (
    <>
      {data && data.length &&
        data.filter(item => !checkIfItemIsDeleted(item)).map((item, index) =>
            <div key={index}>
              <Row className="d-flex justify-content-between">
                <Col md={5} xl={5}>
                  <div style={{borderLeft: `4px solid ${STEP_STATUS[item.status].color}`,}}
                       className="question_job_wrapper">
                    <div className="wrapper_page-title">
                      <h5 className="page-title m-0 mb-2">
                        {typeCard} {index + 1}
                      </h5>
                    </div>
                    <p ref={addToRefs}
                       contentEditable={isPageEditable}
                       id={item.id}
                       onBlur={()=>checkIfChanged(item)}
                       style={editableFieldStyling}>
                      {item.text ? item.text : ""}
                    </p>
                    {item.status !== STEP_STATUS.UNDEFINED.label && (
                        <div className="mt-3 d-flex align-items-center">
                          {STEP_STATUS[item.status].icon}
                          {STEP_STATUS[item.status].label}
                        </div>
                    )}
                  </div>
                </Col>
                <Col md={5} xl={5}>
                  {item.assets.length === 0 ? ((item.operative_comment && item.operative_comment) ||
                          (item.pm_comment && item.pm_comment) ||
                          (item.description && item.description) ? <Row><Card>
                    <CardBody className="p-0">
                      <div className="description_question_wrapper">
                        <p>
                          {(item.operative_comment && item.operative_comment) ||
                              (item.pm_comment && item.pm_comment) ||
                              (item.description && item.description)}
                        </p>
                      </div>
                    </CardBody>
                  </Card></Row> : null)  : <ImagesCard
                      item={item}
                      moreImageID={moreImageID}
                      toggleMoreImage={toggleMoreImage}
                      getPhotosByStep={getPhotosByStep}
                  />}
                </Col>
                {isPageEditable && <Col md={2} xl={2} className="d-flex justify-content-between flex-column align-items-center">
                  <Row onClick={()=>focusOnField(item)} className="mb-2">
                    <EditIcon/>
                  </Row>
                  <Row onClick={()=>{
                    var sentItemToDeletion = {
                      element:item,
                      typeCard:typeCard,
                      action:"deleted"
                    };
                    localStorage.setItem('deletedItem', JSON.stringify(sentItemToDeletion))
                    toggleDeletionModal()
                  }}>
                    <DeleteIconDark/>
                  </Row>
                </Col>}
              </Row>
              <Row>
                <Col md={12} xl={12}>
                  <div className="hr_wrapper mb-4 mt-4">
                    {data.length - 1 !== index && <hr />}
                  </div>
                </Col>
              </Row>
            </div>
        )}

        {isPageEditable && EditedFields[typeCard].added.map((element,index)=>{
         return <div key={index}>
              <Row className="d-flex justify-content-between">
                <Col md={12} xl={12}>
                  <div
                    style={{
                      borderLeft: `4px solid #95A4B1`,
                    }}
                    className="question_job_wrapper"
                  >
                    <div className="wrapper_page-title">
                      <h5 className="page-title m-0 mb-2">
                        {typeCard} {data.length + index + 1}
                      </h5>
                    </div>
                    <Row md={12} xl={12} className="d-flex justify-content-between">
                    <Col md={10} xl={10}>
                    <p ref={addToRefs} contentEditable={isPageEditable} onBlur={()=>checkIfChanged(element)}>{
                      typeCard==="STEP" ? element.item.description : element.item
                    }</p>
                    </Col>
                    {isPageEditable &&
                    <Col md={2} xl={2} className="d-flex justify-content-between flex-column align-items-center">
                    <Row onClick={()=>focusOnField(element)} className="mb-2">
                    <EditIcon/>
                    </Row>
                    <Row onClick={()=>{
                      var item = {
                        element:element,
                        typeCard:typeCard,
                        action:"deleted"
                      };
                      localStorage.setItem('deletedItem', JSON.stringify(item))
                    }}>
                    <DeleteIconDark/>
                    </Row>
                    </Col>}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12}>
                  <div className="hr_wrapper mb-4 mt-4">
                    {data.length - 1 !== index && <hr />}
                  </div>
                </Col>
              </Row>
            </div>
        })}

        {isPageEditable && <div>
        <Button
        color="primary"
        onClick={toggleModal}
        >
          ADD {typeCard}
        </Button>
    <Modal className={`modal-dialog--primary locum-preview-modal`}
          isOpen={modal}
          toggle={toggleModal}
          modalClassName={`ltr-support`}>
            <div className="modal__header">
              <h3 className="page-title">ADD {typeCard}</h3>
              <button className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={toggleModal} />
            </div>

          <div className="modal__body">
            <Col md={12} lg={12} className="p-0 mt-5">
              <Card className="p-0">
                <CardBody className="p-0 pt-4">
                    <Row>
                    <Col md={3} xl={3}><h5 className="page-title mt-2"> {typeCard} DESCRIPTION: </h5></Col>
                    <Col md={9} xl={9}>
                    <Input type="text" name="description" placeholder="Add description here"
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                    />
                    </Col>
                    </Row>

                    {typeCard==="STEP" &&
                    <Row>
                    <Col md={3} xl={3}><h5 className="page-title mt-2">IS PHOTO MANDATORY?</h5></Col>
                    <Col md={2} xl={2}><Input type="checkbox" name="photo-mandatory" onChange={e => setPhotoMandatory(e.target.checked)}/></Col>
                    </Row>}

                    <Row className="form__form-group-field d-flex justify-content-end">
                      <div className="d-flex">
                        <Button className="mb-0"
                        color="secondary"
                        onClick={() => toggleModal()} >
                          Cancel
                        </Button>

                        <Button className="mb-0"
                        color="primary"
                        onClick={()=> {
                          sendToEditedField(description, photoMandatory, typeCard, "added")
                          toggleModal();
                          }}>
                          OK
                        </Button>
                      </div>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Modal>

        <Modal className={`modal-dialog--primary locum-preview-modal`}
          isOpen={deletionModal}
          toggle={toggleDeletionModal}
          modalClassName={`ltr-support`}>
            <div className="modal__header">
              <h3 className="page-title">DELETE {typeCard}</h3>
              <button className="lnr lnr-cross modal__close-btn"
                type="button"
                onClick={toggleDeletionModal} />
            </div>

          <div className="modal__body">
            <Col md={12} lg={12} className="p-0 mt-5">
              <Card className="p-0">
                <CardBody className="p-0 pt-4">
                <Row className="form__form-group-field d-flex justify-content-between">
                  <Col md={6} xl={6}><h5 className="page-title m-2">ARE YOU SURE YOU WANT TO DELETE THIS {typeCard}?</h5></Col>
                      <Col md={5} xl={5} className="d-flex">
                        <Button className="mb-0"
                        color="secondary"
                        onClick={() => toggleDeletionModal()} >
                          Cancel
                        </Button>

                        <Button className="mb-0"
                        color="danger"
                        onClick={()=> {
                          let sentItem = JSON.parse(localStorage.getItem('deletedItem'))
                          addToEditedField(sentItem.element, sentItem.typeCard, sentItem.action)
                          localStorage.removeItem('deletedItem')
                          toggleDeletionModal();
                          }}>
                          Delete
                        </Button>
                      </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </div>
        </Modal>
        </div>}

      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={viewerIsOpen}
      />
    </>
  );
};

export default StepCard;
