import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldArray, initialize, reduxForm} from "redux-form";
import {Button, Card, CardBody, Container, Table} from "reactstrap";
import {CreateIcon} from "../../constants/icons";
import {JOB_AREA_OPTIONS, SAGE_REF_OPTIONS, WORK_TYPE_OPTIONS,} from "../../constants/options";
import {
  getJobTemplates,
  getOperativeTemplates,
  getQATemplates,
  saveJobTemplates,
} from "../../redux/services/templates";
import Loading from "../../shared/components/Loader";
import store from "../../redux/store";
import renderCheckBoxField from "../../shared/components/form/CheckBox";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from "mdi-react/SearchIcon";
import {
  checkedJobTemplatesAreValid,
  getAllUpdatedJobs,
  getOrganizedJobs,
  isJobChecked
} from "../../helpers/create-project";
import {FormControl, FormHelperText, MenuItem, Select, TextField} from "@material-ui/core";

const PAGE_SIZE = 10;

const renderJobTemplates = ({
  fields,
  operativeTemplates,
  qaTemplates,
}) => {

  //Job Templates
  const [filteredJobTemplates, setFilteredJobTemplates] = useState(fields && fields.getAll() ? fields.getAll() : [])
  const [jobTemplatesCurrentPage, setJobTemplatesCurrentPage] = useState(0);
  const [jobTemplatesPageCounts, setJobTemplatesPageCounts] = useState(0);
  const [jobTemplatesSearchFilter, setJobTemplatesSearchFilter] = useState("");
  const [checkedJobTemplates, setCheckedJobTemplates] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  //Changing Filters
  useEffect(() => {
    if(!isNewTemplate){
      setJobTemplatesCurrentPage(0)
      setCheckedJobTemplates([])
    }
    const searchedJobs = filteredJobTemplates.filter((job) => {
      return job.description
          .toLowerCase()
          .includes(jobTemplatesSearchFilter.toLowerCase())
    })
    const tempJobs = !!jobTemplatesSearchFilter  ? searchedJobs : fields.getAll()
    setFilteredJobTemplates(tempJobs)
  }, [fields, jobTemplatesSearchFilter ])

  useEffect(() => {
    setJobTemplatesPageCounts(Math.ceil(filteredJobTemplates.length / PAGE_SIZE))
  },[filteredJobTemplates])


  async function saveJobTemplates() {
    const allJobs = fields && fields.getAll() ? fields.getAll() : []
    const {checked} = getOrganizedJobs(allJobs, checkedJobTemplates)
    const updatedJobs = getAllUpdatedJobs(allJobs, checked)
    fields.removeAll()
    updatedJobs.forEach((updatedJob) => fields.push(updatedJob))
    setIsNewTemplate(false);
    setJobTemplatesSearchFilter("");
    setCheckedJobTemplates([])
    await saveValues();
  }

  async function deleteTemplates() {
    const {notChecked} = getOrganizedJobs(filteredJobTemplates, checkedJobTemplates)
    fields.removeAll()
    notChecked.forEach((notChecked) => fields.push(notChecked))
    setCheckedJobTemplates([])
    await saveValues();
  }

  const addJobTemplate = () => {
    setIsNewTemplate(true);
    setJobTemplatesSearchFilter("");
    const tempId = Date.now().toString(36) +
            Math.random()
                .toString(36)
                .substring(2)
    const tempJob = {description: '', id: tempId}
    fields.push(tempJob);
    setCheckedJobTemplates([tempId])
    const newJobPageCount = Math.ceil((fields.getAll().length + 1) / PAGE_SIZE)
    setJobTemplatesCurrentPage(newJobPageCount -1 )
    setIsEditing(true);
  };

  const onJobTemplateCheckboxPressed = useCallback((jobId,event) => {
    if(jobId && event && event && event.target && event.target.checked){
      const newArray = [...checkedJobTemplates, jobId];
      setCheckedJobTemplates(newArray);
    } else {
      const newArray = checkedJobTemplates.filter((item) => item !== jobId);
      setCheckedJobTemplates(newArray);
    }
  },[checkedJobTemplates])

  const _renderSearchSection = useCallback(() => {
    return  <div className="d-flex flex-row justify-content-between w-100">
      <h3>Job templates</h3>
      <div className="mb-3">
        <div className="form__form-group-field">
          <div className="search_input">
            <SearchIcon />
            <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search_input"
                value={jobTemplatesSearchFilter}
                onChange={(data) => {
                  setJobTemplatesSearchFilter(data.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
            />
          </div>
        </div>
      </div>
    </div>
  },[jobTemplatesSearchFilter, setJobTemplatesSearchFilter])

  const _renderTableHeader = useCallback(() => {
    return <thead>
    <tr className={"d-flex"}>
      <th className={"th30"}></th>
      <th className={"th280"}>Description</th>
      <th className={"th200"}>Area</th>
      <th className={"th200"}>Trade</th>
      <th className={"th200"}>Sage Ref</th>
      <th className={"th200"}>Operative Steps</th>
      <th className={"th200"}>QA Steps</th>
    </tr>
    </thead>
  },[])

  const _renderActionButtons = useCallback(() => {

    if(checkedJobTemplates.length > 0){
      const {checked} = getOrganizedJobs(filteredJobTemplates, checkedJobTemplates)
      return   <div className={"actionButtonContainer"}>
        <div className={"operativeStepActionButton"}>
          <Button
              className={"editButton"}
              onClick={() => {
                if(isEditing){
                  saveJobTemplates();
                }
                setIsEditing(!isEditing)
              }}
              disabled={isEditing ? !checkedJobTemplatesAreValid(checked) : false}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
        </div>
        <div className={"operativeStepActionButton"}>
          <Button
              className={"deleteButton"}
              onClick={() => {
                deleteTemplates();
              }}
              disabled={isEditing}
          >
            Delete
          </Button>
        </div>
      </div>
    } else {
      return null
    }
  },[isEditing, checkedJobTemplates,saveJobTemplates,isNewTemplate,deleteTemplates,filteredJobTemplates,getOrganizedJobs])

  const _onChangeDescription = useCallback((jobId, description) => {
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      job.description = description
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }

  },[filteredJobTemplates, setFilteredJobTemplates])

  const _onAreaChange = useCallback((jobId,event) => {
    const newValue = event.target.value
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      const foundArea = JOB_AREA_OPTIONS.find((area) => area.value === newValue)
      job.area = {
        value: newValue,
        label: foundArea ? foundArea.label : newValue
      }
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }
  },[filteredJobTemplates, setFilteredJobTemplates])

  const _onTradeChange = useCallback((jobId,event) => {
    const newValue = event.target.value
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      const foundTrade = WORK_TYPE_OPTIONS.find((area) => area.value === newValue)
      job.trade = {
        value: newValue,
        label: foundTrade ? foundTrade.label : newValue
      }
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }
  },[filteredJobTemplates, setFilteredJobTemplates])

  const _onSageRefChange = useCallback((jobId,event) => {
    const newValue = event.target.value
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      const foundSage = SAGE_REF_OPTIONS.find((area) => area.value === newValue)
      job.sageRef = {
        value: newValue,
        label: foundSage ? foundSage.label : newValue
      }
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }
  },[filteredJobTemplates, setFilteredJobTemplates])

  const _onOperativeStepChange = useCallback((jobId,event) => {
    const newValue = event.target.value
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      const foundOperativeTemplate = operativeTemplates.find((item) => item.value.description.toString().toUpperCase() === newValue)
      job.operativeSteps = {
        label:newValue,
        value: {
          description: newValue,
          steps: foundOperativeTemplate && foundOperativeTemplate.value && foundOperativeTemplate.value.steps ? foundOperativeTemplate.value.steps : []
        }
      }
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }
  },[filteredJobTemplates, setFilteredJobTemplates])

  const _onQaStepChange = useCallback((jobId, event) => {
    const newValue = event.target.value
    const jobIndex = filteredJobTemplates.findIndex((job) => job.id === jobId)
    if(jobIndex !== -1){
      const job = filteredJobTemplates[jobIndex]
      const foundQaTemplate = qaTemplates.find((item) => item.value.description.toString().toUpperCase() === newValue)
      job.qaSteps = {
        label:newValue,
        value: {
          description: newValue,
          steps: foundQaTemplate && foundQaTemplate.value && foundQaTemplate.value.steps ? foundQaTemplate.value.steps : []
        }
      }
      let tempJobs = filteredJobTemplates
      tempJobs[jobIndex] = job
      setFilteredJobTemplates([...tempJobs])
    }
  },[filteredJobTemplates, setFilteredJobTemplates])

  const _renderTable = useCallback(() => {
    return <div className={'table--bordered'}>
      {_renderActionButtons()}
    <Table responsive hover className="table react-table ">
      {_renderTableHeader()}
      <tbody>
      {filteredJobTemplates && filteredJobTemplates.length > 0 &&
          filteredJobTemplates
              .filter((element) => {
                if (isEditing) {
                  return true;
                } else {
                  return element.description.toLowerCase().includes(jobTemplatesSearchFilter.toLowerCase());
                }
              })
              .slice(jobTemplatesCurrentPage * PAGE_SIZE, jobTemplatesCurrentPage * PAGE_SIZE + PAGE_SIZE)
              .map((job) => {
                return (
                    <tr
                        key={`job-templates-${job.id}`}
                        className={"d-flex justify-content-start align-items-center"}
                    >
                      <td className={"operativeStepsCheckBox "}>
                        <div>
                          <Field
                              name={`checkmark-template-${job.id}`}
                              component={renderCheckBoxField}
                              onChange={(event) => onJobTemplateCheckboxPressed(job.id, event)}
                              checked={isJobChecked(checkedJobTemplates, job.id)}
                              disabled={isEditing}
                          />
                        </div>
                      </td>

                      <td className={"td280"}>
                        {isEditing && isJobChecked(checkedJobTemplates, job.id) ? (
                            <TextField
                                placeholder={"Enter job description"}
                                value={job.description}
                                type={'text'}
                                required={true}
                                error={!job.description}
                                helperText={!job.description ? "You must enter a value!" : undefined}
                                onChange={(event) => _onChangeDescription(job.id,event.target.value)}/>
                        ) : (
                            <div className={"operativeStepJobStepDescriptionContainer"}>
                              <span className={job.description.length > 20 ? "operativeStepJobDescriptionText" : null}
                                    data-text={job.description}>
                                {job.description}
                              </span>
                            </div>
                        )}
                      </td>
                      <td className={"td200"}>
                        {isEditing &&  isJobChecked(checkedJobTemplates, job.id) ? (
                            <FormControl error={job.area && !job.area.label}>
                              <Select
                                  className={'react-select p-2'}
                                  placeholder={"Select job area"}
                                  value={job.area && !!job.area.value ? job.area.value : '' }
                                  required={true}
                                  onChange={(event) => _onAreaChange(job.id, event)}
                              >
                                {JOB_AREA_OPTIONS.map((option) => {
                                  return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                })}
                              </Select>
                              {job.area && !job.area.label ? <FormHelperText>You must enter a value!</FormHelperText> : undefined}

                            </FormControl>
                        ) : (
                            job.area && job.area.label
                        )}
                      </td>
                      <td className={"td200"}>
                        {isEditing &&  isJobChecked(checkedJobTemplates, job.id) ? (
                            <FormControl error={job.trade && !job.trade.value}>
                              <Select
                                  className={'react-select p-2'}
                                  placeholder={"Select trade"}
                                  value={job.trade && !!job.trade.value ? job.trade.value : '' }
                                  required={true}
                                  onChange={(event) => _onTradeChange(job.id, event)}
                              >
                                {WORK_TYPE_OPTIONS.slice(1,WORK_TYPE_OPTIONS.length).map((option) => {
                                  return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                })}
                              </Select>
                              {job.trade && !job.trade.value ? <FormHelperText>You must enter a value!</FormHelperText> : undefined}

                            </FormControl>
                        ) : (
                            job.trade && job.trade.label
                        )}
                      </td>
                      <td className={"td200"}>
                        {isEditing &&  isJobChecked(checkedJobTemplates, job.id) ? (
                            <FormControl error={job.sageRef && !job.sageRef.value}>
                              <Select
                                  className={'react-select p-2'}
                                  placeholder={"Select sage reference"}
                                  value={job.sageRef && !!job.sageRef.value ? job.sageRef.value.toString().toUpperCase() : '' }
                                  required={true}
                                  onChange={(event) => _onSageRefChange(job.id, event)}
                              >
                                {SAGE_REF_OPTIONS.map((option) => {
                                  return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                })}
                              </Select>
                              {job.sageRef && !job.sageRef.value ? <FormHelperText>You must enter a value!</FormHelperText> : undefined}

                            </FormControl>
                        ) : (
                            job.sageRef && job.sageRef.label
                        )}
                      </td>
                      <td className={"td200"}>
                        {isEditing &&  isJobChecked(checkedJobTemplates, job.id) ? (
                            <FormControl error={job.operativeSteps && !job.operativeSteps.value.description}>
                              <Select
                                  className={'react-select p-2'}
                                  placeholder={"Select operative step"}
                                  value={job.operativeSteps && !!job.operativeSteps.value ? job.operativeSteps.value.description.toString().toUpperCase() : '' }
                                  required={true}
                                  onChange={(event) => _onOperativeStepChange(job.id, event)}
                              >
                                {operativeTemplates.map((option, index) => {
                                  return <MenuItem
                                      key={`operativeOption-${option.value.description}-${index}`}
                                      value={option.value.description.toString().toUpperCase()}> {option.label}</MenuItem>
                                })}
                              </Select>
                              {job.operativeSteps && !job.operativeSteps.value.description ? <FormHelperText>You must enter a value!</FormHelperText> : undefined}
                            </FormControl>
                        ) : (
                            job.operativeSteps && (
                                <div className={"operativeStepJobStepDescriptionContainer"} style={{width: '100%'}}>
                                  <span className={job.operativeSteps.label.length > 20 ? "operativeStepJobDescriptionText" : null} data-text={job.operativeSteps.label}>
                                    {job.operativeSteps && job.operativeSteps.label}
                                  </span>
                                </div>
                            )
                        )}
                      </td>
                      <td className={"td200"}>
                        {isEditing &&  isJobChecked(checkedJobTemplates, job.id) ? (
                                <FormControl error={job.qaSteps && !job.qaSteps.value.description}>
                                  <Select
                                      className={'react-select p-2'}
                                      placeholder={"Select QA step"}
                                      value={job.qaSteps && !!job.qaSteps.value &&  job.qaSteps.value.description ? job.qaSteps.value.description.toString().toUpperCase() : ''}
                                      required={true}
                                      onChange={(event) => _onQaStepChange(job.id, event)}
                                  >
                                    {qaTemplates.map((option, index) => {
                                      return <MenuItem
                                          key={`qaOption-${option.value.description}-${index}`}
                                          value={option.value.description.toString().toUpperCase()}> {option.label}</MenuItem>
                                    })}
                                  </Select>
                                  {job.qaSteps && !job.qaSteps.value.description ? <FormHelperText>You must enter a value!</FormHelperText> : undefined}
                                </FormControl>
                        ) : (
                            job.qaSteps && (
                                <div className={"operativeStepJobStepDescriptionContainer"} style={{width: '100%'}}>
                                  <span className={job.qaSteps.label.length > 20 ? "operativeStepJobDescriptionText" : null} data-text={job.qaSteps.label}>
                                    {job.qaSteps && job.qaSteps.label}
                                  </span>
                                </div>
                            )
                        )}
                      </td>
                    </tr>
                );
              })}
      </tbody>
    </Table>
    </div>
  },[_renderTableHeader,_renderActionButtons,filteredJobTemplates,jobTemplatesCurrentPage,isEditing,jobTemplatesSearchFilter,onJobTemplateCheckboxPressed,isJobChecked ])

  const _renderJobTemplatesPagination = useCallback(() => {
    if(filteredJobTemplates && jobTemplatesPageCounts >= 2){
      return <div className="box-pagination">
        <Pagination
            count={jobTemplatesPageCounts}
            page={jobTemplatesCurrentPage + 1}
            onChange={(event, page) => setJobTemplatesCurrentPage(page-1)}
        />
      </div>
    } else {
      return null
    }
  },[jobTemplatesPageCounts,jobTemplatesCurrentPage,filteredJobTemplates ])

  return (
    <>
      {_renderSearchSection()}
      {_renderTable()}
      {_renderJobTemplatesPagination()}
      <Button
        className="btn_with_img mt-5"
        color="primary"
        onClick={addJobTemplate}
        disabled={isEditing}
      >
        <CreateIcon /> Create job template
      </Button>
    </>
  );
};

async function saveValues() {
  const formValue = store.getState().form.job_templates.values;
  await saveJobTemplates({ jobTemplates: formValue.jobTemplates });
}

const JobTemplates = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [operativeTemplates, setOperativeTemplates] = useState(null);
  const [qaTemplates, setQATemplates] = useState(null);

  useEffect(() => {
    getValues()
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getValues = async () => {
    await getOperativeTemplates().then((response) => {
      const values = [];

      for (let i = 0; i < response.operativeSteps.length; i++) {
        values.push({
          label: response.operativeSteps[i].description,
          value: response.operativeSteps[i],
        });
      }

      setOperativeTemplates(values);
    });

    await getQATemplates().then((response) => {
      const values = [];

      for (let i = 0; i < response.qaSteps.length; i++) {
        values.push({
          label: response.qaSteps[i].description,
          value: response.qaSteps[i],
        });
      }

      setQATemplates(values);
    });

    await getJobTemplates().then((response) => {
      store.dispatch(initialize("job_templates", response));
    });
  };

  return isLoading ? (
    <Loading />
  ) : (
    <form>
      <Container>
        <Card>
          <CardBody style={{ minWidth: "min-content" }}>
            <FieldArray
              name="jobTemplates"
              component={renderJobTemplates}
              rerenderOnEveryChange={true}
              props={{
                operativeTemplates,
                qaTemplates,
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: "job_templates",
  enableReinitialize: true,
})(JobTemplates);
