import {
    SET_TIME_ID,
    SET_STATUS
} from "../actions";

export const setTimeOutID = (id) => ({
    type: SET_TIME_ID,
    payload: id,
});

export const setStatus = (status) => ({
    type: SET_STATUS,
    payload: status,
});