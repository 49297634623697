import React, { useEffect, useState } from "react";
import { Row, Col, Table, Container, Button } from "reactstrap";
import { getAllSavedProjects } from "../../../redux/services/projects";
import Loading from "../../../shared/components/Loading";
import { deleteSavedProject } from "../../../redux/services/projects";

const CreatedProjects = ({ history, location }) => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { contractId } = location.state;

  useEffect(() => {
    getAllProjects();
  }, []);

  async function getAllProjects() {
    await getAllSavedProjects()
      .then((response) => {
        const filteredResponse = response.filter(project => 
          project && project.formvalues && project.formvalues.project_group_id && project.formvalues.project_group_id === contractId
        );
        setProjects(filteredResponse);
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

  }

  async function deleteProject(id) {
    setIsLoading(true);

    await deleteSavedProject(id)
      .catch((error) => alert(error.message))
      .finally(async () => {
        await getAllProjects();
      });
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Row md={12} className="justify-content-between">
        <Col md={4}>
          <h2 className="text-nowrap">Create projects</h2>
        </Col>

        <Col md={6} className={"justify-content-end d-flex"}>
          <Button
            type={"submit"}
            className="text-nowrap button"
            onClick={() => {
              history.push({
                pathname: "/projects/create",
                state: {
                  projectId: null,
                },
              });
            }}
          >
            Create new
          </Button>
        </Col>
      </Row>

      { projects.length ? (
        <Container md={12} className="mt-3  innerContainerSecond">
          <Table
            responsive
            hover
            className="table react-table table--bordered "
          >
            <thead>
              <tr className="d-flex">
                <th className="th220">Name of project</th>

                <th className="th180"></th>
              </tr>
            </thead>
            <tbody>
              {
                projects
                .map((project, index) => 
                (
                <tr
                  key={index}
                  className={"d-flex justify-content-start align-items-center"}
                >
                  <td
                    className="td220"
                    onClick={() => {
                      history.push({
                        pathname: "/projects/create",
                        state: {
                          projectId: project.id,
                          createdProjectId: project.createdprojectid,
                        },
                      });
                    }}
                  >
                    {project.formvalues.projectName}
                  </td>

                  <td className="td180">
                    <div className="centered">
                      <Button
                        className="deleteButton"
                        onClick={() => {
                          deleteProject(project.id);
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      ) : (
        <Container md={12} className="mt-3  innerContainerSecond">
          <div className="d-flex justify-content-center align-items-center">
            <span style={{ fontSize: "16px", fontWeight: "500" }}>
              No projects available
            </span>
          </div>
        </Container>
      )}
    </Container>
  );
};

export default CreatedProjects;
