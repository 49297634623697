import React from "react"
import { Col, Card, CardBody, Button } from 'reactstrap';
import { PROJECT_ACTION } from "../../../constants/enamerations"

const ChangeModal = ({ changeModalBody, changeModal, startProject, deleteProject, stopProject, project }) => {
    return (
        <Col md={12} lg={12} className="p-0 mt-5">
            <Card className="p-0">
                <CardBody className="p-0 pt-4">
                    <div>
                        <div className="wrapper_page-title mt-4">
                            <p className="form__form-group-label m-0">{changeModalBody === PROJECT_ACTION.START.value && `Start this project?`}{changeModalBody === PROJECT_ACTION.DELETE.value && `Delete this project?`}{changeModalBody === PROJECT_ACTION.STOP.value && `Stop this project?`}</p>
                        </div>
                    </div>
                    <div className="form__form-group m-0">
                        <div className="form__form-group-field d-flex justify-content-end">
                            <div className="d-flex">
                                <Button className="mb-0" color="secondary" onClick={() => changeModal()}>Cancel</Button>
                                {changeModalBody === PROJECT_ACTION.START.value && <Button className="mb-0" color="primary" onClick={() => startProject(project.id)}>OK</Button>}
                                {changeModalBody === PROJECT_ACTION.STOP.value && <Button className="mb-0" color="warning" onClick={() => stopProject(project.id)}>OK</Button>}
                                {changeModalBody === PROJECT_ACTION.DELETE.value && <Button className="mb-0" color="danger" onClick={() => deleteProject(project.id)}>OK</Button>}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default ChangeModal