export const getDayworkUserInfo = (daywork) => {
    let info = '' 
    if(daywork.action === 'DW_INVOICED'){
        info = `${!!daywork.creator ?daywork.creator : ''}`.trim()
    }else {
        if(daywork.pm ){
            info = `${!!daywork.pm.first_name ? daywork.pm.first_name : ''} ${!!daywork.pm.surname ? daywork.pm.surname : ''}`.trim()
        }
        if(daywork.qa ){
            info = `${!!daywork.qa.first_name ? daywork.qa.first_name : ''} ${!!daywork.qa.surname ? daywork.qa.surname : ''}`.trim()
        }
        if(daywork.admin ){
            info = `${!!daywork.admin.first_name ? daywork.admin.first_name : ''} ${!!daywork.admin.surname ? daywork.admin.surname : ''}`.trim()
        }
        if(daywork.operative ){
            info = `${!!daywork.operative.first_name ? daywork.operative.first_name : ''} ${!!daywork.operative.surname ? daywork.operative.surname : ''}`.trim()
        }
    }

    return info
}
