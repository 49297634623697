import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Scrollbar from 'react-smooth-scrollbar';
import SidebarContent from './SidebarContent';

const Sidebar = ({ sidebar }) => {
  return (
    <div className={sidebar.collapse ? 'sidebar sidebar--collapse' : 'sidebar'}>
      <button className="sidebar__back" type="button" />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <SidebarContent sidebar={sidebar} />
        </div>
      </Scrollbar>
    </div >
  );
}

function mapStateToProps({ sidebar }) {
  return {
    sidebar,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
