import React, { useRef } from 'react'
import { Button, Modal } from 'reactstrap'
import { reduxForm, Field  } from 'redux-form'
import TextFieldMaterial from '../../../../shared/components/material_field/TextFieldMaterial'
import { updateVariationOrder } from '../../../../redux/services/variation_orders'

const EditVoModal = ({
  isOpen,
  toggleModal,
  variationOrder,
  voLabel,
  initialValues,
}) => {
  const descriptionRef = useRef();
  const clientReferenceRef = useRef();

  const editVariationOrder = (e) => {
    Promise.all([
      updateVariationOrder(variationOrder.id, {
        description: descriptionRef.current,
        client_reference: clientReferenceRef.current,
      }),
    ])
    
  }

  return (
    <Modal
    isOpen={isOpen}
    toggle={toggleModal}
    modalClassName={`ltr-support`}
    className={`modal-dialog--primary additional_work-modal`}
    >
      <div className="modal__header text-left d-flex flex-column justify-content-center">
        <h4 className="m-0">Edit variation order</h4>
        <p className='m-0 text-white'>Variation Order: {voLabel || '-'}</p>
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
      </div>
      <div className="modal__body text-left mt-5 pt-5">
        <form onSubmit={(e) => editVariationOrder(e)}>
        <div className='mb-3'>
          <Field
            name="description"
            component={TextFieldMaterial}
            placeholder="Description"
            label="Description"
            onChange={(e) => descriptionRef.current = (e.target.value)}
          />
        </div>
        <div className='mb-3'>
          <Field
            name="client_reference"
            component={TextFieldMaterial}
            placeholder="Client reference"
            label="Client reference"
            onChange={(e) => clientReferenceRef.current = (e.target.value)}
          />
        </div>
        <div className={'d-flex flex-row justify-content-end w-full'}>
        <Button 
        type="submit"
        color="primary"
        >
          Save
        </Button>
        </div>
        </form>
      </div>
    </Modal>
  )
}

export default reduxForm({
  form: "edit_vo_form",
  enableReinitialize: true,
})(EditVoModal);
