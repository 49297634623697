import React, { useState, useEffect } from "react"
import { Row, Col } from 'reactstrap';
import LinearProgressBar from "../../../../shared/components/progress/LinearProgressBar"
import Loading from "../../../../shared/components/Loader"
import { getBudgetAnalytics } from "../../../../redux/services/projects"

const BudgetStatus = ({ id }) => {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setLoad(true)
        getBudgetAnalytics(id)
            .then(res => {
                setLoad(false)
                setData(res.data)
            })
    }, [])
    
    return (
        load
            ? <Loading />
            : <>
                <div className="wrapper_page-title">
                    <h5 className="page-title m-0">BUDGET</h5>
                </div>
                <Row className="mt-3 d-flex justify-content-between">
                    <Col md={6} xl={6} className="border-right">
                        <div className="lg_wrapper_page-title">
                            <p className="page-title m-0 text-purple">₤{data && data.budget}</p>
                        </div>
                        <div className="wrapper_page-title">
                            <span className="form__form-group-label m-0">ALLOCATED BUDGET</span>
                        </div>
                    </Col>
                    <Col md={5} xl={5}>
                        <div className="lg_wrapper_page-title">
                            <p className="page-title m-0 text-purple">₤{data && data.spent_budget}</p>
                        </div>
                        <div className="wrapper_page-title">
                            <span className="form__form-group-label m-0">SPENT BUDGET</span>
                        </div>
                    </Col>
                </Row>
                <div className="wrapper_page-title mt-3">
                    <p className="form__form-group-label m-0">Budget spend rate</p>
                </div>
                <Row className="mt-1 mb-2">
                    <Col md={12} xl={12}>
                        <LinearProgressBar color='purple' value={data && Math.round((data.spent_budget * 100) / data.budget)} />
                    </Col>
                </Row>
            </>
    )
}

export default BudgetStatus