import React, {useCallback, useState} from "react";
import { Field, reduxForm } from "redux-form";
import { Table } from "reactstrap";
import { WORK_TYPE, JOB_STATUS } from "../../../constants/enamerations";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import { pageSize } from "../../../config/pagination";
import Pagination from "@material-ui/lab/Pagination";
import InvoiceModal from "../../Invoices/invoiceModal";
import {getInvoiceById} from "../../../redux/services/invoice";
import {formatPrice} from "../../../helpers/function";
import {SortOrderIcon} from "../../../constants/icons";
import {PREVIEW_JOB} from "../../../constants/routes";

const JobsTable = ({
  jobs,
  setPage,
  page,
  setChoosedJobs,
  choosedJobs,
  filter, setFilter,
}) => {
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [loadInvoiceModal, setLoadInvoiceModal] = useState(false);
  const toggleInvoiceModal = () => {
    setInvoiceModalOpen(!invoiceModalOpen);
  };
  const getInvoiceId = (id) => {
    setLoadInvoiceModal(true);
    getInvoiceById(id).then((res) => {
      setInvoiceItem(res);
      setLoadInvoiceModal(false);
    });
  };
  const onChecked = (id, event) => {
    if(id && event && event.target){
      const isChecked = event.target.checked
      const updatedJobs = { ...choosedJobs };
      if(isChecked){
        updatedJobs[id] = isChecked;
      }else {
        delete updatedJobs[id];
      }
      setChoosedJobs(updatedJobs);
    }
  }


  const onCheckedAll = (event) => {
    if (event && event.target.checked) {
      const temp = {};
      jobs.items.forEach((item) => {
        if (item.status !== JOB_STATUS.COMPLETED.value) {
          return (temp[item.id] = true);
        }
      });
      setChoosedJobs(temp);
    } else if (event && !event.target.checked) {
      setChoosedJobs({});
    }
  };

  const onIdHeaderClicked = useCallback(() => {
    if(filter && filter.dateOrder === 'ASC') {
      setFilter({
        ...filter,
        orderBy: 'job.id',
        dateOrder: 'DESC',
      });
    } else {
      setFilter({
        ...filter,
        orderBy: 'job.id',
        dateOrder: 'ASC',
      });
    }
  },[setFilter, filter])

  const onNameHeaderClicked = useCallback(() => {
    if(filter && filter.dateOrder === 'ASC') {
      setFilter({
        ...filter,
        orderBy: 'job.description',
        dateOrder: 'DESC',
      });
    } else {
      setFilter({
        ...filter,
        orderBy: 'job.description',
        dateOrder: 'ASC',
      });
    }
  },[setFilter, filter])

  const onBlockHeaderClicked = useCallback(() => {
    if(filter && filter.dateOrder === 'ASC') {
      setFilter({
        ...filter,
        orderBy: 'block.name',
        dateOrder: 'DESC',
      });
    } else {
      setFilter({
        ...filter,
        orderBy: 'block.name',
        dateOrder: 'ASC',
      });
    }
  },[setFilter, filter])

  const onLevelHeaderClicked = useCallback(() => {
    if(filter && filter.dateOrder === 'ASC') {
      setFilter({
        ...filter,
        orderBy: 'level.name',
        dateOrder: 'DESC',
      });
    } else {
      setFilter({
        ...filter,
        orderBy: 'level.name',
        dateOrder: 'ASC',
      });
    }
  },[setFilter, filter])

  const onUnitIdHeaderClicked = useCallback(() => {
    if(filter && filter.dateOrder === 'ASC') {
      setFilter({
        ...filter,
        orderBy: 'plot.name',
        dateOrder: 'DESC',
      });
    } else {
      setFilter({
        ...filter,
        orderBy: 'plot.name',
        dateOrder: 'ASC',
      });
    }
  },[setFilter, filter])

  return (
    <form>
      <Table responsive hover className="table react-table table--bordered">
        <thead>
          <tr style={{alignItems: 'flex-end'}}>
            <th>
              <Field
                name="checkbox_main"
                component={renderCheckBoxField}
                onChange={(event) => onCheckedAll(event)}
                disabled={
                  filter && filter.job_status === JOB_STATUS.COMPLETED.value
                }
              />
            </th>
            <th onClick={() => onIdHeaderClicked()}>ID  <SortOrderIcon size={20} /></th>
            <th>TRADE</th>
            <th  onClick={() => onNameHeaderClicked()}>NAME  <SortOrderIcon size={20} /></th>
            <th>STATUS</th>
            <th>QTY</th>
            <th  onClick={() => onBlockHeaderClicked()}>BLOCK  <SortOrderIcon size={20} /></th>
            <th  onClick={() => onLevelHeaderClicked()}>LEVEL  <SortOrderIcon size={20} /></th>
            <th>UNIT TYPE</th>
            <th  onClick={() => onUnitIdHeaderClicked()}>UNIT ID  <SortOrderIcon size={20} /></th>
            <th>RATE</th>
            <th>INVOICE</th>
          </tr>
        </thead>
        <tbody>
          {jobs &&
            jobs.items &&
            jobs.items.map((item, index) => {
                  const fullUrl = PREVIEW_JOB.replace(':project_id', item.project_id).replace(':id', item.id)
              return <tr key={index}>
                <td>
                  <Field
                      name={`Job ${item.id}`}
                      component={renderCheckBoxField}
                      checked={choosedJobs[item.id]}
                      onChange={(event) => onChecked(item.id, event)}
                      disabled={item.status === JOB_STATUS.COMPLETED.value}
                  />
                </td>
                <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.id && item.id}</a></td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.job_type && WORK_TYPE[item.job_type].icon} &nbsp;{" "}
                    {item.job_type && WORK_TYPE[item.job_type].label}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.description && item.description}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.status && JOB_STATUS[item.status].icon} &nbsp;{" "}
                    {item.status && JOB_STATUS[item.status].label}
                  </a>
                </td>
                <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.qty && item.qty}</a></td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.block && item.block.name}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.level && item.level.name}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.unit_type && item.unit_type}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.plot && item.plot.name}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.rate && formatPrice(item.rate)}
                  </a>
                </td>
                <td>
                  {item.InvoiceItem && (<div onClick={() => {
                    toggleInvoiceModal();
                    getInvoiceId(item.InvoiceItem.invoice.id);
                  }} style={{color: '#70bbfd'}}>#{item.InvoiceItem.invoice.id}</div>)
                  }
                </td>
              </tr>
            }

            )}
        </tbody>
      </Table>
      {invoiceModalOpen && (
          <InvoiceModal
              invoiceModal={invoiceModalOpen}
              toggleInvoiceModal={toggleInvoiceModal}
              invoiceItem={invoiceItem}
              loadInvoiceModal={loadInvoiceModal}
          />
      )}
      {jobs && Math.ceil(jobs.total / pageSize) >= 2 && (
        <div className="box-pagination">
          <Pagination
            count={jobs && jobs.total ? Math.ceil(jobs.total / pageSize) : 0}
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
    </form>
  );
};

export default reduxForm({
  form: "select_jobs_form",
})(JobsTable);
