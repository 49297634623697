import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

class DatePickerField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.value ? props.value : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { onChange } = this.props;
    this.setState({
      startDate: date,
    });
    onChange(date);
  }

  render() {
    const { startDate } = this.state;
    const { placeholder, minDate, disabled, value, name, maxDate } = this.props;
    return (
      <div className="date-picker">
        <DatePicker
          name={name}
          id={name}
          className="form__form-group-datepicker"
          selected={value ? moment(value).toDate() : startDate}
          onChange={this.handleChange}
          dropDownMode="select"
          showYearDropdown
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          locale="en-gb"
          disabled={disabled}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input, placeholder, minDate, disabled, maxDate, meta } = props;
  return (
    <>
      <DatePickerField
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </>
  );
};

export default renderDatePickerField;
