import React, {useCallback, useEffect, useState} from "react";
import { Col, Row, Container, Button, Table } from "reactstrap";
import { reduxForm, FieldArray, Field } from "redux-form";
import SearchIcon from "mdi-react/SearchIcon";
import SelectField from "../../../shared/components/SelectField";
import {
  JOB_AREA_OPTIONS,
  WORK_TYPE_OPTIONS,
} from "../../../constants/options";
import { WORK_TYPE } from "../../../constants/enamerations";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import Pagination from "@material-ui/lab/Pagination";
import { getJobTemplates } from "../../../redux/services/templates";
import Loading from "../../../shared/components/Loader";
import store from "../../../redux/store";
import {getAllAvailableJobs, getOrganizedJobs, isJobChecked} from "../../../helpers/create-project";

const PAGE_SIZE = 10;

const renderJobs = ({ fields, availableJobs, selectedJobs, updateAvailableJobs, updateSelectedJobs }) => {
  //Available Jobs
  const [filteredAvailableJobs, setFilteredAvailableJobs] = useState(availableJobs ? availableJobs : [])
  const [availableJobsCurrentPage, setAvailableJobsCurrentPage] = useState(0);
  const [availableJobsPageCounts, setAvailableJobsPageCounts] = useState(0);
  const [availableJobsSearchFilter, setAvailableJobsSearchFilter] = useState("");
  const [availableJobsAreaFilter, setAvailableJobsAreaFilter] = useState("");
  const [availableJobsTradeFilter, setAvailableJobsTradeFilter] = useState("");
  const [checkedAvailableJobs, setCheckedAvailableJobs] = useState([]);

  //Available Jobs Logic For Filtering
  useEffect(() => {
    setAvailableJobsCurrentPage(0)
    setCheckedAvailableJobs([])
    const searchedJobs = filteredAvailableJobs.filter((job) => {
      let filterQuery = job.description
          .toLowerCase()
          .includes(availableJobsSearchFilter.toLowerCase())
      if(!!availableJobsAreaFilter){
        filterQuery = filterQuery && job.area.value.toLowerCase().includes(availableJobsAreaFilter.toLowerCase())
      }
      if(!!availableJobsTradeFilter){
        filterQuery = filterQuery && job.trade.value.toLowerCase().includes(availableJobsTradeFilter.toLowerCase())
      }

      return filterQuery
    })
    const tempJobs = !!availableJobsSearchFilter || !!availableJobsAreaFilter || !! availableJobsTradeFilter ? searchedJobs : availableJobs
    setFilteredAvailableJobs(tempJobs)
  }, [availableJobs, availableJobsSearchFilter,availableJobsAreaFilter,availableJobsTradeFilter ])
  useEffect(() => {
    setAvailableJobsPageCounts(Math.ceil(filteredAvailableJobs.length / PAGE_SIZE))
  },[filteredAvailableJobs])

  //Selected Jobs
  const [filteredSelectedJobs, setFilteredSelectedJobs] = useState(selectedJobs ? selectedJobs : [])
  const [selectedJobsCurrentPage, setSelectedJobsCurrentPage] = useState(0);
  const [selectedJobsPageCounts, setSelectedJobsPageCounts] = useState(0);
  const [selectedJobsSearchFilter, setSelectedJobsSearchFilter] = useState("");
  const [selectedJobsAreaFilter, setSelectedJobsAreaFilter] = useState("");
  const [selectedJobsTradeFilter, setSelectedJobsTradeFilter] = useState("");
  const [checkedSelectedJobs, setCheckedSelectedJobs] = useState([]);

  //Selected Jobs Logic For Filtering
  useEffect(() => {
    setSelectedJobsCurrentPage(0)
    setCheckedSelectedJobs([])
    const searchedJobs = filteredSelectedJobs.filter((job) => {
      let filterQuery = job.description
          .toLowerCase()
          .includes(selectedJobsSearchFilter.toLowerCase())
      if(!!selectedJobsAreaFilter){
        filterQuery = filterQuery && job.area.value.toLowerCase().includes(selectedJobsAreaFilter.toLowerCase())
      }
      if(!!selectedJobsTradeFilter){
        filterQuery = filterQuery && job.trade.value.toLowerCase().includes(selectedJobsTradeFilter.toLowerCase())
      }

      return filterQuery
    })
    const tempJobs = !!selectedJobsSearchFilter || !!selectedJobsAreaFilter || !! selectedJobsTradeFilter ? searchedJobs : selectedJobs
    setFilteredSelectedJobs(tempJobs)
  }, [selectedJobs, selectedJobsSearchFilter,selectedJobsAreaFilter,selectedJobsTradeFilter ])
  useEffect(() => {
    setSelectedJobsPageCounts(Math.ceil(filteredSelectedJobs.length / PAGE_SIZE))
  },[filteredAvailableJobs])



  const onAvailableJobCheckboxPressed = useCallback((jobId,event) => {
    if(jobId && event && event && event.target && event.target.checked){
      const newArray = [...checkedAvailableJobs, jobId];
      setCheckedAvailableJobs(newArray);
    } else {
      const newArray = checkedAvailableJobs.filter((item) => item !== jobId);
      setCheckedAvailableJobs(newArray);

    }
  },[checkedAvailableJobs])

  const onSelectedJobCheckboxPressed = useCallback((jobId,event) => {
    if(jobId && event && event && event.target && event.target.checked){
      const newArray = [...checkedSelectedJobs, jobId];
      setCheckedSelectedJobs(newArray);
    } else {
      const newArray = checkedSelectedJobs.filter((item) => item !== jobId);
      setCheckedSelectedJobs(newArray);

    }
  },[checkedSelectedJobs])

  const addJobToSelected = useCallback(() =>{
    const {checked, notChecked} = getOrganizedJobs(filteredAvailableJobs, checkedAvailableJobs)
    const {notChecked: notCheckedAll} = getOrganizedJobs(availableJobs, checkedAvailableJobs)
    setFilteredAvailableJobs(notChecked)
    setFilteredSelectedJobs([...filteredSelectedJobs, ...checked])
    updateAvailableJobs(notCheckedAll)
    updateSelectedJobs([...selectedJobs, ...checked])
    checked.forEach((checkedJob) => fields.push(checkedJob))
    setCheckedAvailableJobs([])
    setCheckedSelectedJobs([])
  },[filteredSelectedJobs,checkedAvailableJobs, setFilteredAvailableJobs, setFilteredSelectedJobs,setCheckedAvailableJobs,updateAvailableJobs,updateSelectedJobs,getOrganizedJobs ] )
  const removeJobFromSelected = useCallback(() =>{
    const {checked, notChecked} = getOrganizedJobs(filteredSelectedJobs, checkedSelectedJobs)
    const {notChecked: notCheckedAll} = getOrganizedJobs(selectedJobs, checkedSelectedJobs)
    setFilteredSelectedJobs(notChecked)
    setFilteredAvailableJobs([...filteredAvailableJobs, ...checked])
    updateSelectedJobs(notCheckedAll)
    updateAvailableJobs([...availableJobs, ...checked])
    fields.removeAll()
    notChecked.forEach((notChecked) => fields.push(notChecked))
    setCheckedAvailableJobs([])
    setCheckedSelectedJobs([])
  },[filteredAvailableJobs,checkedSelectedJobs, setFilteredAvailableJobs, setCheckedSelectedJobs,setCheckedSelectedJobs,updateSelectedJobs,updateAvailableJobs,getOrganizedJobs ] )

  const _renderAvailableJobsTopSection = useCallback(() => {
    return <Row md={12} className="justify-content-between">
      <Col md={4}>
            <span className="text-nowrap font-weight-bold">
              AVAILABLE JOBS TO ADD
            </span>
      </Col>
    </Row>
  },[])
  const _renderAddJobToSelectedButton = useCallback(() => {
    if(checkedAvailableJobs.length > 0){
      return  <div className="ml-3">
        <Button className="text-nowrap button"
                onClick={() =>  addJobToSelected() }>
          Add selected jobs
        </Button>
      </div>
    } else {
      return null
    }
  },[checkedAvailableJobs])
  const _renderAvailableJobsFilterSection = useCallback(() => {
    return <>
      <div className="w-25 ml-3">
        <div className="form__form-group-field">
          <div className="search_input">
            <SearchIcon />
            <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search_input"
                onChange={(data) => setAvailableJobsSearchFilter(data.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
            />
          </div>
        </div>
      </div>
      <div className="ml-3">
        <Field
            name={`area`}
            component={SelectField}
            required={true}
            options={[{ value: "", label: "All" }].concat(JOB_AREA_OPTIONS)}
            placeholder={"Area"}
            onChange={(value) => setAvailableJobsAreaFilter(value.value)}
        />
      </div>
      <div className="ml-3">
        <Field
            name={`trade`}
            component={SelectField}
            required={true}
            options={WORK_TYPE_OPTIONS}
            placeholder={"Trade"}
            onChange={(value) => setAvailableJobsTradeFilter(value.value)}
        />
      </div>
      {_renderAddJobToSelectedButton()}
    </>
  },[setAvailableJobsSearchFilter, setAvailableJobsAreaFilter, setAvailableJobsTradeFilter, _renderAddJobToSelectedButton])
  const _renderAvailableJobsTableHeader = useCallback(() => {
    return <thead>
    <tr className={"d-flex"}>
      <th className={"th30"}></th>
      <th className={"th60"}>TRADE</th>
      <th className={"th220"}>NAME</th>
      <th className={"th220"}>AREA</th>
      <th className={"th220"}>OPERATIVE STEPS </th>
      <th className={"th220"}>QA VERIFICATION STEPS</th>
      <th className={"th220"}>SAGE REF</th>
    </tr>
    </thead>
  },[])
  const _renderAvailableJobsBodyRow = useCallback(() => {
    return <tbody>
    {filteredAvailableJobs &&
        filteredAvailableJobs.length > 0 &&
        filteredAvailableJobs.slice(availableJobsCurrentPage * PAGE_SIZE, availableJobsCurrentPage * PAGE_SIZE + PAGE_SIZE)
            .map((job) => {
              return (
                  <tr
                      key={`available-job-${job.id}`}
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                  >
                    <td className={"operativeStepsCheckBox"}>
                      <div>
                        <Field
                            name={`checkmark-available-${job.id}`}
                            component={renderCheckBoxField}
                            onChange={(event) => onAvailableJobCheckboxPressed(job.id, event)}
                            checked={isJobChecked(checkedAvailableJobs, job.id)}
                        />
                      </div>
                    </td>

                    <td className={"td60"}>
                      <div>{WORK_TYPE[job.trade.value].bigIcon}</div>
                    </td>
                    <td className={"td220"}>
                      <div className={"operativeStepJobStepDescriptionContainer"}>
                        <span className={ job.description.length > 20 ? "operativeStepJobDescriptionText" : null}
                              data-text={job.description}>
                                {job.description}
                        </span>
                      </div>
                    </td>
                    <td className={"td220"}>{job.area.label}</td>
                    <td className={"td220"}>
                      <div className={"operativeStepJobStepDescriptionContainer"}>
                        <span className={job.operativeSteps.label.length > 20 ? "operativeStepJobDescriptionText" : null}
                              data-text={job.operativeSteps.label}>
                                {job.operativeSteps.label}
                        </span>
                      </div>
                    </td>
                    <td className={"td220"}>
                      <div className={"operativeStepJobStepDescriptionContainer"}>
                        <span className={job.qaSteps.label.length > 20 ? "operativeStepJobDescriptionText" : null}
                              data-text={job.qaSteps.label}>
                                {job.qaSteps.label}
                        </span>
                      </div>
                    </td>

                    <td className={"td220"}>{job.sageRef.label}</td>
                  </tr>
              );
            })}
    </tbody>
  },[filteredAvailableJobs,availableJobsCurrentPage,checkedAvailableJobs,onAvailableJobCheckboxPressed])
  const _renderAvailableJobsPagination = useCallback(() => {
    if(filteredAvailableJobs && availableJobsPageCounts >= 2){
      return <div className="box-pagination">
        <Pagination
            count={availableJobsPageCounts}
            page={availableJobsCurrentPage + 1}
            onChange={(event, page) => setAvailableJobsCurrentPage(page-1)}
        />
      </div>
    }else {
      return null
    }
  },[availableJobsPageCounts,availableJobsCurrentPage, filteredAvailableJobs])


  const _renderSelectedJobsTopSection = useCallback(() => {
    return <Row md={12} className="justify-content-between">
      <Col md={4}>
            <span className="text-nowrap font-weight-bold">
              SELECTED JOBS FOR THIS PROJECT
            </span>
      </Col>
    </Row>
  },[])
  const _renderRemoveJobFromSelectedButton = useCallback(() => {
    if(checkedSelectedJobs.length > 0){
      return  <div className="ml-3">
        <Button className="text-nowrap button"
                onClick={() =>  removeJobFromSelected() }>
          Remove selected jobs
        </Button>
      </div>
    } else {
      return null
    }
  },[checkedSelectedJobs])
  const _renderSelectedJobsFilterSection = useCallback(() => {
    return <>
      <div className="w-25 ml-3">
        <div className="form__form-group-field">
          <div className="search_input">
            <SearchIcon />
            <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search_input"
                onChange={(data) =>
                    setSelectedJobsSearchFilter(data.target.value)
                }
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
            />
          </div>
        </div>
      </div>
      <div className="ml-3">
        <Field
            name={`selectedarea`}
            component={SelectField}
            required={true}
            options={[{ value: "", label: "All" }].concat(JOB_AREA_OPTIONS)}
            placeholder={"Area"}
            onChange={(value) => setSelectedJobsAreaFilter(value.value)}
        />
      </div>
      <div className="ml-3">
        <Field
            name={`selectedtrade`}
            component={SelectField}
            required={true}
            options={WORK_TYPE_OPTIONS}
            placeholder={"Trade"}
            onChange={(value) => setSelectedJobsTradeFilter(value.value)}
        />
      </div>
      {_renderRemoveJobFromSelectedButton()}
    </>
  },[setSelectedJobsSearchFilter,setSelectedJobsAreaFilter,setSelectedJobsTradeFilter,_renderRemoveJobFromSelectedButton ])
  const _renderSelectedJobsTableHeader = useCallback(() => {
    return <thead>
    <tr className={"d-flex"}>
      <th className={"th30"}></th>
      <th className={"th60"}>TRADE</th>
      <th className={"th220"}>NAME</th>
      <th className={"th220"}>AREA</th>
      <th className={"th220"}>OPERATIVE STEPS </th>
      <th className={"th220"}>QA VERIFICATION STEPS</th>
      <th className={"th220"}>SAGE REF</th>
    </tr>
    </thead>
  },[])
  const _renderSelectedJobsBodyRow = useCallback(() => {
    return <tbody>
    {filteredSelectedJobs &&
        filteredSelectedJobs.length > 0 &&
        filteredSelectedJobs.slice(selectedJobsCurrentPage * 10, selectedJobsCurrentPage * 10 + PAGE_SIZE)
            .map((selectedJob) => {
              return (
                  <tr
                      key={`selected-job-${selectedJob.id}`}
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                  >
                    <td className={"operativeStepsCheckBox"}>
                      <div>
                        <Field
                            name={`checkmark-selected-${selectedJob.id}`}
                            component={renderCheckBoxField}
                            onChange={(event) => onSelectedJobCheckboxPressed(selectedJob.id, event)}
                            checked={isJobChecked(checkedSelectedJobs, selectedJob.id)}
                        />
                      </div>
                    </td>

                    <td className={"td60"}>
                      <div>
                        {WORK_TYPE[selectedJob.trade.value].bigIcon}
                      </div>
                    </td>

                    <td className={"td220"}>
                      <div
                          className={
                            "operativeStepJobStepDescriptionContainer"
                          }
                      >
                              <span
                                  className={
                                    selectedJob.description.length > 20
                                        ? "operativeStepJobDescriptionText"
                                        : null
                                  }
                                  data-text={selectedJob.description}
                              >
                                {selectedJob.description}
                              </span>
                      </div>
                    </td>

                    <td className={"td220"}>{selectedJob.area.label}</td>

                    <td className={"td220"}>
                      <div
                          className={
                            "operativeStepJobStepDescriptionContainer"
                          }
                      >
                              <span
                                  className={
                                    selectedJob.operativeSteps.label.length > 20
                                        ? "operativeStepJobDescriptionText"
                                        : null
                                  }
                                  data-text={selectedJob.operativeSteps.label}
                              >
                                {selectedJob.operativeSteps.label}
                              </span>
                      </div>
                    </td>

                    <td className={"td220"}>
                      <div
                          className={
                            "operativeStepJobStepDescriptionContainer"
                          }
                      >
                              <span
                                  className={
                                    selectedJob.qaSteps.label.length > 20
                                        ? "operativeStepJobDescriptionText"
                                        : null
                                  }
                                  data-text={selectedJob.qaSteps.label}
                              >
                                {selectedJob.qaSteps.label}
                              </span>
                      </div>
                    </td>

                    <td className={"td220"}>
                      {selectedJob.sageRef.label}
                    </td>
                  </tr>
              );
            })}
    </tbody>
  },[filteredSelectedJobs,selectedJobsCurrentPage, checkedSelectedJobs, onSelectedJobCheckboxPressed ])
  const _renderSelectedJobsPagination = useCallback(() => {
    if(filteredSelectedJobs && selectedJobsPageCounts >= 2){
      return <div className="box-pagination">
        <Pagination
            count={selectedJobsPageCounts}
            page={selectedJobsCurrentPage + 1}
            onChange={(event, page) => setSelectedJobsCurrentPage(page-1)}
        />
      </div>
    }else {
      return null
    }
  },[selectedJobsPageCounts,selectedJobsCurrentPage, filteredSelectedJobs])

  return (
      <>
        <Container className="innerContainerSecondBottom">
          {_renderAvailableJobsTopSection()}
          <Row md={9} className="mt-4">
            {_renderAvailableJobsFilterSection()}
            <div className="jobTableContainer">
              <Table
                  responsive
                  hover
                  className="table react-table table--bordered"
              >
                {_renderAvailableJobsTableHeader()}
                {_renderAvailableJobsBodyRow()}
              </Table>
              {_renderAvailableJobsPagination()}
            </div>
          </Row>
        </Container>

        <Container className="innerContainerSecondBottom  mt-3">
          {_renderSelectedJobsTopSection()}
          <Row md={9} className="mt-4">
            {_renderSelectedJobsFilterSection()}
            <div className="jobTableContainer">
              <Table
                  responsive
                  hover
                  className="table react-table table--bordered"
              >
                {_renderSelectedJobsTableHeader()}
                {_renderSelectedJobsBodyRow()}
              </Table>
              {_renderSelectedJobsPagination()}
            </div>
          </Row>
        </Container>
      </>
  );
};

const getJobs = async () => {
  var jobs = [];

  await getJobTemplates().then((response) => {
    jobs = response.jobTemplates;
  });

  const selectedJobs = store.getState().form.create_project_form_wizard.values
      .jobs;


  jobs = getAllAvailableJobs(jobs, selectedJobs ? selectedJobs : [])
  return {availableJobs: jobs, selectedJobs: selectedJobs ? selectedJobs :[]};
};


let CreateProjectStepSeven = (props) => {
  const { handleSubmit, saveProject } = props;
  const [availableJobs, updateAvailableJobs] = useState([]);
  const [selectedJobs, updateSelectedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    window.scrollTo(0, 0);

    getJobs()
        .then((response) => {
          updateAvailableJobs(response.availableJobs)
          updateSelectedJobs(response.selectedJobs)
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, []);


  return isLoading ? (
      <Loading />
  ) : (
      <form onSubmit={handleSubmit}>
        <Container className="m-0" >
          <Row md={12} className="justify-content-between text-nowrap">
            <Col md={4}>
              <h2>Select job types for this project</h2>
            </Col>

            <div className="buttonContainer">
              <Col md={6}>
                <Button className="button" onClick={saveProject}>
                  Save
                </Button>
              </Col>

              <Col md={6} className={"justify-content-end d-flex"}>
                <Button
                    type={"submit"}
                    className="text-nowrap button"
                    disabled={selectedJobs && selectedJobs.length === 0}
                >
                  Next
                </Button>
              </Col>
            </div>
          </Row>

          <FieldArray
              name="jobs"
              component={renderJobs}
              rerenderOnEveryChange={true}
              props={{
                availableJobs,
                selectedJobs,
                updateAvailableJobs,
                updateSelectedJobs,
              }}
          />
        </Container>
      </form>
  );
};

CreateProjectStepSeven = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepSeven);

export default CreateProjectStepSeven;
