import React from "react"
import { Col, Card, CardBody, Button } from "reactstrap";
import DropZone from "../../../shared/components/form/DropZone"
import Loading from "../../../shared/components/Loader"

const ProjectCreateStepOneForm = ({ toggle, getUploadFile, uploadStatus, loadUploadFile, nextStep }) => {
    return (
        <Col md={12} lg={12} className="p-0 mt-5">
            <Card className="p-0">
                <CardBody className="p-0 pt-4">
                    <div className="wrapper_page-title mb-3">
                        <h5 className="page-title m-0">UPLOAD A SPREADSHEET</h5>
                    </div>
                    {loadUploadFile
                        ? <Loading />
                        : (uploadStatus 
                            ? <div className="mb-3">
                                <div className="wrapper_page-title mt-3">
                                    <p className="form__form-group-label m-0">The spreadsheet has been uploaded. <br />  The draft project will be created after push the button Finish</p>
                                </div>
                            </div>
                            : <DropZone getUploadFile={getUploadFile} accept=".csv" />
                        )
                    }
                    <div className="form__form-group m-0">
                        <div className="form__form-group-field d-flex justify-content-end">
                            <div className="d-flex">
                                <Button className="mb-0" type="button" onClick={() => toggle()}>Cancel</Button>
                                <Button className="mb-0" color="primary" type="button" onClick={() => nextStep()}>Next</Button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default ProjectCreateStepOneForm