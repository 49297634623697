import React from "react";
import Select from "react-select";

const SelectFieldNoValidate = ({
  input: { value, onChange },
  placeholder,
  disabled,
  options,
  meta: { touched, error },
  defaultValue,
}) => {
  const handleChange = (option) => {
    onChange(option && option.value ? option.value : null);
  };
  const getOption = (value) => {
    return value ? options.filter((type) => type.value === value)[0] : null;
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        value={getOption(value)}
        onChange={handleChange}
        options={options}
        isDisabled={disabled ? disabled : false}
        classNamePrefix="react-select"
        className="react-select"
        defaultValue={defaultValue}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </>
  );
};

export default SelectFieldNoValidate;
