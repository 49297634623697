import React from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import ContractRow from "./ContractRow";


const ContractsTable = ({ contracts }) => {
  const tableHeads = ['NAME', 'TOTAL PROJECTS', 'TOTAL BUDGET', 'DEADLINE'];

  if(!contracts || !contracts.items || !contracts.items.length)
    return (
      <div className="w-100 d-flex justify-content-center">
        <p>No contract found</p>
      </div>
    )

  return (
  <Table
    responsive
    hover
    className="mt-3 table react-table table--bordered pl-0"
  >
    <thead>
      <tr>
        {tableHeads.map((head,index) => <th key={index}>{head}</th>)}
      </tr>
    </thead>
    <tbody>
      {contracts.items.map((contract, index) =>
        <ContractRow
          contract={contract}
          index={index}
          key={contract.name+index}
        />
      )}
    </tbody>
  </Table>
  );
};

export default withRouter(ContractsTable);
