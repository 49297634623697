import {
  getOperativeTemplatesAsync,
  saveOperativeTemplatesAsync,
  getQATemplatesAsync,
  saveQATemplatesAsync,
  getJobTemplatesAsync,
  saveJobTemplatesAsync,
} from "../api/templates";

export const getOperativeTemplates = async () =>
  await getOperativeTemplatesAsync();

export const saveOperativeTemplates = async (formValue) =>
  await saveOperativeTemplatesAsync(formValue);

export const getQATemplates = async () => await getQATemplatesAsync();

export const saveQATemplates = async (formValue) =>
  await saveQATemplatesAsync(formValue);

export const getJobTemplates = async () => await getJobTemplatesAsync();

export const saveJobTemplates = async (formValue) =>
  await saveJobTemplatesAsync(formValue);
