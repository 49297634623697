import React from 'react';

const JobImageGallery = ({ groupedImages }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', pageBreakBefore: 'always' }}>
            {groupedImages.map((pageImages, pageIndex) => (
                <div key={pageIndex} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {pageImages.map((image, index) => (
                        <div key={index} style={{ width: '50%', maxHeight: '790px', padding: '10px' }}>
                            <img
                                src={image}
                                alt={`${index + 1}`}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    ))}
                    {pageIndex !== groupedImages.length - 1 && (
                        <hr style={{ margin: '20px 0' }} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default JobImageGallery;
