import React, { useState } from "react";
import { Col, Card, CardBody, Button, Alert } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate";
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial";
import PhoneFieldMaterial from "../../../shared/components/material_field/PhoneFieldMaterial";
import CheckBoxField from "../../../shared/components/form/CheckBox";
import {
  BUSINESS_TYPE_OPTIONS,
  CIS_STATUS_OPTIONS,
  USER_ROLE_OPTIONS,
} from "../../../constants/options";

const UserCreate = ({ handleSubmit, addUser, errorMessage, toggleModal }) => {
  const [vatIsRegistered, setVatIsRegistered] = useState(false);

  return (
    <form onSubmit={handleSubmit(addUser)}>
      <div className="alert_wrapper_cerate_user">
        <Alert color="danger" isOpen={!!errorMessage} className="mb-5">
          {errorMessage}
        </Alert>
      </div>
      <Col md={12} lg={12} className="p-0 mt-5">
        <Card className="p-0">
          <CardBody className={!!errorMessage ? "p-0 pt-5" : "p-0"}>
            <div
              className={
                !!errorMessage ? "form__form-group mt-3" : "form__form-group"
              }
            >
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="sage_reference"
                    component={TextFieldMaterial}
                    placeholder="Sage Reference"
                    label="Sage Reference"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group mb-4">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="first_name"
                    component={TextFieldMaterial}
                    placeholder="John"
                    label="First name"
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="surname"
                    component={TextFieldMaterial}
                    placeholder="Johnson"
                    label="Surname"
                  />
                </div>
              </div>
            </div>
            <h4 className="page-title">ADDRESS</h4>
            <div className="form__form-group">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="street"
                    component={TextFieldMaterial}
                    placeholder="Street of you address"
                    label="Street"
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="house_number"
                    component={TextFieldMaterial}
                    placeholder="Number of your home"
                    label="Number"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group mb-4">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="city"
                    component={TextFieldMaterial}
                    placeholder="Your city"
                    label="City"
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="post_code"
                    component={TextFieldMaterial}
                    placeholder="Post code of you address"
                    label="Post code"
                  />
                </div>
              </div>
            </div>
            <h4 className="page-title">CONTACT</h4>
            <div className="form__form-group mb-4">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="phone"
                    component={PhoneFieldMaterial}
                    placeholder="xxx xxxx xxxx"
                    label="Phone number"
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="email"
                    component={TextFieldMaterial}
                    placeholder="example@gmail.com"
                    label="Email"
                  />
                </div>
              </div>
            </div>
            <h4 className="page-title">COMPANY</h4>
            <div className="form__form-group">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_select_wrapper">
                  <Field
                    name="sub_contractor_type"
                    component={SelectFieldNoValidate}
                    placeholder="Sub-Contractor Type"
                    options={BUSINESS_TYPE_OPTIONS}
                  />
                </div>
                <div className="create_user_select_wrapper">
                  <Field
                    name="role"
                    component={SelectFieldNoValidate}
                    placeholder="Role"
                    options={USER_ROLE_OPTIONS.slice(
                      1,
                      USER_ROLE_OPTIONS.length
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_field_wrapper">
                  <Field
                    name="company_name"
                    component={TextFieldMaterial}
                    placeholder="Company name"
                    label="Company name"
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="utr"
                    component={TextFieldMaterial}
                    placeholder="Utr"
                    label="Utr"
                  />
                </div>
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field d-flex justify-content-between">
                <div className="create_user_select_wrapper">
                  <Field
                    name="cis_status"
                    component={SelectFieldNoValidate}
                    placeholder="CIS Status"
                    options={CIS_STATUS_OPTIONS}
                  />
                </div>
                <div className="create_user_field_wrapper">
                  <Field
                    name="hourly_rate"
                    component={TextFieldMaterial}
                    placeholder="Hourly Rate"
                    label="Hourly Rate"
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="form__form-group">
              <div className="form__form-group-field d-flex  justify-content-between">
                <div className="create_user_checkbox_wrapper">
                  <span>VAT registered?</span>
                  <Field
                    name="is_vat_registered"
                    component={CheckBoxField}
                    onChange={(value) => {
                      if (value) {
                        setVatIsRegistered(value.currentTarget.checked);
                      }
                    }}
                  />
                </div>

                <div className="create_user_field_wrapper">
                  {vatIsRegistered ? (
                    <Field
                      name="vat_number"
                      component={TextFieldMaterial}
                      placeholder="VAT Number"
                      label="VAT Number"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form__form-group m-0">
              <div className="form__form-group-field d-flex justify-content-end">
                <div className="d-flex">
                  <Button
                    className="mb-0"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    Cancel
                  </Button>
                  <Button className="mb-0" color="primary" type="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "user_create_form",
})(UserCreate);
