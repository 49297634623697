import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from './MainWrapper.jsx';
import AuthRoutes from './Auth';
import { ROOT, LOGIN, NOT_FOUND } from "../constants/routes"
import NotFound404 from '../containers/DefaultPage/404/index';
import LogIn from '../containers/LogIn';
import PrivacyPolicy from "../../src/shared/privacy-policy.pdf";

const AuthRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            auth ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{
                            pathname: { LOGIN },
                            state: { from: props.location }
                        }}
                    />
                )
        }
    />
);

const Router = ({ user }) => {
    return (
        <MainWrapper>
            <Route path="/privacy.pdf">
                <iframe src={PrivacyPolicy} title="Privacy Policy" style={{width: '100%', height: '100vh'}}/>
            </Route>
            <Switch>
                {!user && <Switch>
                    <Route exact path={NOT_FOUND} component={NotFound404}/>
                    <Route path={LOGIN} component={LogIn}/>
                </Switch>}
                <AuthRoute path={ROOT} auth={user} component={AuthRoutes}/>
            </Switch>
        </MainWrapper>
    )
}

export default Router;
