import {
  getAllVariationOrdersAsync,
  getVariationOrdersByProjectIdAsync,
  createVariationOrderAsync,
  addVosToAdditionalWorkAsync,
  getVariationOrderByIdAsync,
  updateVariationOrderAsync,
  addVoCategoryToAdditionalWorkAsync,
  deleteVariationOrderAsync,
  removeAdditionalWorkVOAsync,
} from "../api/variation_orders";

export const getAllVariationOrders = async () => await getAllVariationOrdersAsync();

export const getVariationOrdersByProjectId = async (projectId) => await getVariationOrdersByProjectIdAsync(projectId);

export const createVariationOrder = async (data) => await createVariationOrderAsync(data);

export const addVoToAdditionalWork = async (data) => await addVosToAdditionalWorkAsync(data);

export const getVariationOrderById = async (id) => await getVariationOrderByIdAsync(id);

export const updateVariationOrder = async (id, data) => await updateVariationOrderAsync(id, data);

export const addVoCategoryToAdditionalWork = async (id, data) => await addVoCategoryToAdditionalWorkAsync(id, data);

export const deleteVariationOrder = async (id) => await deleteVariationOrderAsync(id);

export const removeAdditionalWorkVO = async (id) => await removeAdditionalWorkVOAsync(id);