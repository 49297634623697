import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import Moment from "react-moment";
import Loading from "../../../../shared/components/Loader";
import { pushCrumbs } from "../../../../redux/actions/bread_crumbs";
import { CalendarIcon, CustomCreateIcon } from "../../../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import { labelVariationOrder } from "../../../../helpers/variation_order";
import {ADDITIONAL_WORK_REQUEST_STATUS} from "../../../../constants/enamerations";
import {PREVIEW_JOB} from "../../../../constants/routes";

const AdditionalWorks = ({
  projectId,
  projectName,
  contractName,
  variationOrder,
  addAdditionalWorkToVo,
  additionalWorksAddedToVo,
                             additionalWorksLoading,
                             additionalWorksPage,
                             additionalWorks,
                             setAdditionalWorksPage,
    pageSize,


 }) => {


  const chooseFunction = (item) => {
    if(variationOrder){
      addAdditionalWorkToVo(item.id);
    }
  }

  if(!additionalWorks || !additionalWorks.items || !additionalWorks.items.length){
    return <p>No budget request found.</p>
  }

  return (
    <>
    <Col xl={8} lg={16}>
    <Row className='d-flex justify-content-center mr-1'>
    <Card>
    <CardBody style={{minHeight:'525px'}} className={'d-flex justify-content-center'}>
    {
      additionalWorksLoading
      ? <Loading/>
      : <Table
        responsive
        hover
        className="mt-1 table react-table table--bordered pl-0"
      >
        <thead>
          <b>BUDGET VARIATIONS</b>
          <tr>
            <th>STATUS</th>
            <th>OPERATIVE</th>
            <th>DATE REQUESTED</th>
            <th>JOB</th>
            <th>AMOUNT</th>
            <th>VO#</th>
          </tr>
        </thead>
        <tbody>
          {additionalWorks &&
          additionalWorks.items &&
          additionalWorks.items.map((item) => {
              const fullUrl = PREVIEW_JOB.replace(':project_id', projectId).replace(':id', item.jobs.id)
              return   <tr key={item.id} >
                  <td>
                      {item.status && ADDITIONAL_WORK_REQUEST_STATUS[item.status].icon} &nbsp;{" "}
                      {item.status && ADDITIONAL_WORK_REQUEST_STATUS[item.status].label}
                  </td>
                  <td>{
                      item.jobs && item.jobs.employee
                          ? item.jobs.employee.first_name + " " + item.jobs.employee.surname
                          : 'No employee found'
                  }</td>
                  <td>
                      <CalendarIcon /> &nbsp;{" "}
                      <Moment format="YYYY-MM-DD">
                          {item.created_at}
                      </Moment>
                  </td>
                  <td>
                      <a href={fullUrl}>
                          {item.jobs && item.jobs.id}
                      </a>
                  </td>
                  <td>₤{item.budget}</td>
                  <td>
                      {
                          (additionalWorksAddedToVo && additionalWorksAddedToVo[item.id] && variationOrder)
                              ? <div className="d-flex align-items-center">
                                  <b className="text-green ml-1">{labelVariationOrder(additionalWorksAddedToVo[item.id],projectName, contractName)}</b>
                              </div>
                              : variationOrder
                                  ? <div className="d-flex align-items-center" onClick={() => chooseFunction(item)}>
                                      <CustomCreateIcon
                                          size={20}
                                      />
                                      <b className="text-blue ml-1">Add to VO</b>
                                  </div>
                                  : <div className="d-flex align-items-center">
                                      <CustomCreateIcon
                                          size={20}
                                          color="#888888"
                                      />
                                      <b className="text-gray ml-1">Add to VO</b>
                                  </div>
                      }
                  </td>
              </tr>
              }
          )
          }
        </tbody>
        </Table>
    }
    </CardBody>
    </Card>
    <Col lg={5} mb={3}>
    {additionalWorks && Math.ceil(additionalWorks.total / pageSize) >= 2 && (
      <div className="box-pagination">
        <Pagination
          count={
            additionalWorks && additionalWorks.total ? Math.ceil(additionalWorks.total / pageSize) : 0
          }
          page={additionalWorksPage + 1}
          onChange={(event, page) => setAdditionalWorksPage(page - 1)}
        />
      </div>
    )}
    </Col>
    </Row>
    </Col>
    </>
  );
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalWorks)
);
