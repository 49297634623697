import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "reactstrap";
import { INVOICES_STATUS } from "../../../constants/enamerations";
import moment from "moment";
import Loading from "../../../shared/components/Loader";
import {
  getInvoiceByUserId,
  updateInvoice,
  getInvoiceByProjectId,
  updateInvoicesByIds,
  getInvoiceById,
  invoiceToCsv,
  getAllInvoices,
} from "../../../redux/services/invoice";
import { withRouter } from "react-router-dom";
import InvoiceFilterForm from "../invoiceFilterForm";
import { getProjectsByFilter } from "../../../redux/services/projects";
import { unixTime } from "../../../config/validationRules";
import { Field, reduxForm } from "redux-form";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import Pagination from "@material-ui/lab/Pagination";
import InvoiceModal from "../invoiceModal";
import { SortOrderIcon } from "../../../constants/icons";

const InvoicesTable = ({ type, match }) => {
  const paramId = match.params.id
  const [projects, setProjects] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(0);
  const [choosedInvoices, setChoosedInvoices] = useState({});
  const [modal, setModal] = useState(false);
  //Invoice modal
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [loadInvoiceModal, setLoadInvoiceModal] = useState(false);
  const [order, setOrder] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const toggleInvoiceModal = () => {
    setInvoiceModalOpen(!invoiceModalOpen);
  };
  const getInvoiceId = (id) => {
    setLoadInvoiceModal(true);
    getInvoiceById(id).then((res) => {
      setInvoiceItem(res);
      setLoadInvoiceModal(false);
    });
  };

  const [filter, setFilter] = useState({
    status: "",
    date: {
      from: null,
      to: null,
    },
  });

  const [projectId, setProjectId] = useState(
    type === 'USER' ? null : paramId
  );

  const toggleModal = () => setModal(!modal);

  const getInvoice = () => {
    filter.project_ids && filter.project_ids === "all"
      ? getAllInvoices(filter, { page, pageSize }).then((res) => {
          setInvoice(res);
          setLoad(false);
        })
      : type === 'USER'
      ? getInvoiceByUserId(match.params.id, filter, { page, pageSize }).then(
          (res) => {
            setInvoice(res);
            setLoad(false);
          }
        )
      : getInvoiceByProjectId(projectId, filter, { page, pageSize }).then(
          (res) => {
            setInvoice(res);
            setLoad(false);
          }
        );
  };

  useEffect(() => {
    setLoad(true);
    getInvoice();
  }, [page, filter, pageSize]);

  useEffect(() => {
    getProjectsByFilter().then((res) => {
      setProjects(res);
    });
  }, []);

  const paidInvoice = (id) => {
    setLoad(true);
    updateInvoice(id, true).then(() => {
      getInvoice();
    });
  };

  const sumAmount = (item) => {
    let sum = 0;

    if (item.invoice[0].drc) {
      item.invoice.forEach((invoice) => {
        sum += Number(invoice.net_amount - invoice.cis_deduction);
      });

      return sum;
    }

    item.invoice.forEach((invoice) => {
      sum += Number(invoice.amount);
    });

    return sum;
  };

  const onChecked = (id, event) => {
    if (event && event.target) {
      if (event.target.checked) {
        setChoosedInvoices({ ...choosedInvoices, [id]: true });
      } else {
        let temp = { ...choosedInvoices };
        delete temp[id];
        setChoosedInvoices(temp);
      }
    }
  };

  const onCheckedAll = (event) => {
    if (event && event.target.checked) {
      const temp = {};
      invoice.items.map((item) => (temp[item.id] = true));
      setChoosedInvoices(temp);
    } else if (event && !event.target.checked) {
      setChoosedInvoices({});
    }
  };

  const paidInvoices = () => {
    setLoad(true);
    updateInvoicesByIds(
      INVOICES_STATUS.PAID.value,
      Object.keys(choosedInvoices).map((item) => +item)
    ).then(() => {
      getInvoice();
      setChoosedInvoices({});
      setModal(false);
    });
  };

  const getInvoicesToCsv = () => {
    setLoad(true);
    invoiceToCsv(Object.keys(choosedInvoices).map((item) => +item)).then(() => {
      getInvoice();
      setChoosedInvoices({});
      setModal(false);
    });
  };

  return (
    <>
      <div className="card__title d-flex justify-content-between m-0">
        <InvoiceFilterForm
          setPage={setPage}
          type={type}
          setFilter={setFilter}
          projects={projects}
          filter={filter}
          setPageSize={setPageSize}
          setProjectId={setProjectId}
        />
      </div>
      {Object.keys(choosedInvoices).length > 0 && (
        <div className="w-50 d-flex justify-content-start">
          <div className="plan_wrapper">
            <span
              className="bread_crumbs-active"
              onClick={() => setModal(!modal)}
            >
              MARK AS PAID INVOICES
            </span>
          </div>
          <div className="plan_wrapper ml-3">
            <span className="bread_crumbs-active" onClick={getInvoicesToCsv}>
              EXPORT INVOICE DATA
            </span>
          </div>
        </div>
      )}
      {load ? (
        <Loading />
      ) : invoice && invoice.items && invoice.items.length !== 0 ? (
        <Table hover className="table react-table table--bordered mt-1">
          <thead>
            <tr>
              <th>
                <Field
                  name="checkbox_main"
                  component={renderCheckBoxField}
                  onChange={(event) => onCheckedAll(event)}
                />
              </th>
              <th>INVOICE</th>
              <th>NAME</th>
              <th>AMOUNT</th>
              <th style={{ paddingBottom: 8, cursor: "pointer" }}>
                <div
                  className="d-flex flex-row align-items-center"
                  onClick={() => {
                    let invoiceHelper = invoice;
                    setLoad(true);

                    invoiceHelper.items.sort((first, second) => {
                      if (first.updated_at > second.updated_at) {
                        return order ? -1 : 1;
                      }

                      if (first.updated_at < second.updated_at) {
                        return order ? 1 : -1;
                      }

                      return 0;
                    });

                    setOrder(!order);
                    setInvoice(invoiceHelper);
                    setLoad(false);
                  }}
                >
                  DATE
                  <SortOrderIcon />
                </div>
              </th>
              <th>DUE BY</th>
              <th>STATUS</th>
              <th>ACTION</th>
              <th>EXPORT</th>
            </tr>
          </thead>
          <tbody>
            {invoice &&
              invoice.items &&
              invoice.items.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    toggleInvoiceModal();
                    getInvoiceId(item.id);
                  }}
                >
                  <td>
                    <Field
                      name={`Invoice ${item.id}`}
                      component={renderCheckBoxField}
                      checked={choosedInvoices[item.id]}
                      onChange={(event) => onChecked(item.id, event)}
                    />
                  </td>
                  <td>Invoice #{item && item.id}</td>
                  <td>{item.user.first_name + " " + item.user.surname}</td>
                  <td>₤ &nbsp; {sumAmount(item).toFixed(2)}</td>
                  <td>
                    {item && moment.unix(item.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {item &&
                      moment
                        .unix(item.created_at + unixTime)
                        .format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {item && item.status && INVOICES_STATUS[item.status].icon}{" "}
                    &nbsp;{" "}
                    {item && item.status && INVOICES_STATUS[item.status].label}
                  </td>
                  <td>
                    {item && item.status === INVOICES_STATUS.UNPAID.value && (
                      <span
                        className="bread_crumbs-active"
                        onClick={(event) => {
                          paidInvoice(item.id);
                          event.stopPropagation();
                        }}
                      >
                        MARK AS PAID
                      </span>
                    )}
                  </td>
                  <td>
                    <span
                      className="bread_crumbs-active"
                      onClick={(event) => {
                        invoiceToCsv([item.id]);
                        event.stopPropagation();
                      }}
                    >
                      EXPORT INVOICE DATA
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <p>Not found</p>
        </div>
      )}
      {invoice && Math.ceil(invoice.total / pageSize) >= 2 && (
        <div className="box-pagination">
          <Pagination
            count={
              invoice && invoice.total ? Math.ceil(invoice.total / pageSize) : 0
            }
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Mark as Paid</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModal}
            />
          </div>
          <div className="modal__body modal_body">
            <p className="form__form-group-label m-0">
              Are you sure you want to mark them as paid?
            </p>
            <div className="mt-3">
              <Button className="mb-0" color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button className="mb-0" color="primary" onClick={paidInvoices}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {invoiceModalOpen && (
        <InvoiceModal
          invoiceModal={invoiceModalOpen}
          toggleInvoiceModal={toggleInvoiceModal}
          invoiceItem={invoiceItem}
          loadInvoiceModal={loadInvoiceModal}
        />
      )}
    </>
  );
};

export default withRouter(
  reduxForm({
    form: "select_invoice_form",
  })(InvoicesTable)
);
