import React, { useState, useEffect } from "react"
import { reduxForm, Field } from 'redux-form';
import { Col, Card, CardBody, Button } from "reactstrap";
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial"
import HourlyFieldMaterial from "../../../shared/components/material_field/HourlyFieldMaterial"
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate";

const ProjectEdit = ({ handleSubmit, toggle, editProject, f, contracts }) => {
    const [isWasEdited, setIsWasEdited] = useState(true);

    useEffect(() => {
        f &&
        f.project_edit_form &&
        f.project_edit_form.initial &&
        f.project_edit_form.values && 
        JSON.stringify(f.project_edit_form.initial) !== JSON.stringify(f.project_edit_form.values) 
         ? setIsWasEdited(false) 
         : setIsWasEdited(true)
    
        if(f)
        console.log(f)
    }, [f])

    return (
        <form onSubmit={handleSubmit(data => editProject(data))}>
            <Col md={12} lg={12} className="p-0 mt-5">
                <Card className="p-0">
                    <CardBody className="p-0 pt-4">
                        <div className="form__form-group">
                            <div className="form__form-group-field d-flex justify-content-between">
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="name"
                                        component={TextFieldMaterial}
                                        placeholder="Name"
                                        label="Name"
                                    />
                                </div>
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="budget"
                                        component={HourlyFieldMaterial}
                                        placeholder="Budget"
                                        label="Budget"
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <h4 className="page-title">LOCATION</h4>
                        <div className="form__form-group">
                            <div className="form__form-group-field d-flex justify-content-between">
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="street"
                                        component={TextFieldMaterial}
                                        placeholder="Street"
                                        label="Street"
                                    />
                                </div>
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="house_number"
                                        component={TextFieldMaterial}
                                        placeholder="Number"
                                        label="Number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group">
                            <div className="form__form-group-field d-flex justify-content-between">
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="city"
                                        component={TextFieldMaterial}
                                        placeholder="City"
                                        label="City"
                                    />
                                </div>
                                <div className="create_user_field_wrapper">
                                    <Field
                                        name="post_code"
                                        component={TextFieldMaterial}
                                        placeholder="Post code"
                                        label="Post code"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group mb-4">
                            <div className="form__form-group-field d-flex justify-content-between">
                                <div className="create_project_field_wrapper">
                                    <Field
                                        name="description"
                                        component={TextFieldMaterial}
                                        placeholder="Description"
                                        label="Description"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group">
                            <div className="form__form-group-field d-flex justify-content-between">
                                <div className="create_user_field_wrapper d-flex flex-column align-items-start">
                                <div className="mb-1">Contract</div>
                                    <Field
                                        name={"project_group_id"}
                                        component={SelectFieldNoValidate}
                                        placeholder={"Contract"}
                                        options={contracts}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__form-group m-0">
                            <div className="form__form-group-field d-flex justify-content-end">
                                <div className="d-flex">
                                    <Button className="mb-0" color="secondary" onClick={() => toggle()}>Cancel</Button>
                                    <Button className="mb-0" color="primary" type="submit" disabled={isWasEdited}>Save</Button>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </form>
    )
}

export default reduxForm({
    form: 'project_edit_form',
    enableReinitialize: true
})(ProjectEdit);