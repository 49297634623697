import {Field, formValueSelector, reduxForm} from "redux-form";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";
import React from "react";
import {connect} from "react-redux";
import {updateBlock} from "../../../../redux/services/projects";

const minValueEditBlock = (value) => {
    return value && value <= values[0] ? `Value must be greater than ${values[0]}` : undefined;
}

const requiredFieldEditBlock = (value) => (value ? undefined : "You must enter a value");
var values = [];

let EditExistingBlockForm = ({modalEditBlock, toggleEditModal, id, noOfLevels, initialBlocks, blockId}) => {

    const handleSubmit = (event) => {
        updateBlock({
            blockName: id,
            levels: noOfLevels,
            blockId
        });
    }
    values.push(initialBlocks);

    return (<div className="centered">
        <Modal
            isOpen={modalEditBlock}
            toggle={toggleEditModal}
            modalClassName={`ltr-support`}
            className={`modal-dialog--primary locum-preview-modal createBlockModal`}
        >
            <ModalHeader className="modal-header">
                <div>
                    <h3>Edit block</h3>
                </div>
                <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={() => {
                        toggleEditModal();
                    }}
                />
            </ModalHeader>
            <ModalBody className="createBlockModalBody">
                <form
                    id={"edit_existing_block_form"}
                    onSubmit={(event) => {
                        handleSubmit(event);
                        event.preventDefault();

                        if (
                            id.length &&
                            noOfLevels >= initialBlocks
                        ) {
                            toggleEditModal();
                        }
                    }}
                >

                    <Row md={12} className="mt-2">
                        <Field
                            name="id"
                            component={TextFieldMaterial}
                            placeholder="Enter Block Letter/Number/ID"
                            type={"text"}
                            validate={[requiredFieldEditBlock]}
                        />
                    </Row>

                    <Row md={12} className="mt-4">
                        <Field
                            name="noOfLevels"
                            component={TextFieldMaterial}
                            placeholder="Enter Number of Levels"
                            type={"number"}
                            validate={[requiredFieldEditBlock, minValueEditBlock]}
                        />
                    </Row>

                    <Row md={12} className="mt-4">
                        <Field name="initialBlocks"
                               component={"input"}
                               type={"hidden"}/>
                        <Field name="blockId"
                               component={"input"}
                               type={"hidden"}/>
                    </Row>

                    <Row md={12} className="mt-4 justify-content-end">
                        <Row md={12} className="justify-content-end">
                            <Col md={5}>
                                <Button
                                    className="cancelButton"
                                    onClick={() => {
                                        toggleEditModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>

                            <Col md={5}>
                                <Button
                                    className="button"
                                    type={"submit"}
                                    form={"edit_existing_block_form"}
                                >
                                    Confirm
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    </div>)
}

EditExistingBlockForm =  reduxForm({
    form: "edit_existing_block_form",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    touchOnChange: true,
})(EditExistingBlockForm);

const selector = formValueSelector('edit_existing_block_form');
EditExistingBlockForm = connect(state => {
    return selector(state, 'id', 'noOfLevels', 'initialBlocks', 'blockId');
})(EditExistingBlockForm)

export default  EditExistingBlockForm;