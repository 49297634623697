import React from "react"
import ProgressBar from 'react-customizable-progressbar'

const CircularProgressBar = ({ color, value }) => {
    return (
        <ProgressBar
            progress={value}
            radius={100}
            strokeWidth={18}
            strokeColor={color}
            strokeLinecap="square"
            trackStrokeWidth={18}
            counterClockwise
        >
            <div className="indicator">
                <div>{value}%</div>
            </div>
        </ProgressBar>
    )
}

export default CircularProgressBar