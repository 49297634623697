import React, {useCallback, useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {
    Col,
    Row,
    Container,
    Table,
    Card,
    CardBody, Modal,
} from 'reactstrap';
import {getDayWorkRequests} from "../../../redux/services/requests";
import Loader from "../../../shared/components/Loader";
import Pagination from "@material-ui/lab/Pagination";
import {DAY_WORK_STATUS} from "../../../constants/enamerations";
import {BuildIcon, CalendarIcon, SortOrderIcon} from "../../../constants/icons";
import Moment from "react-moment";
import {calculateDayWorkHourCost} from "../../../config/func";
import {formatPrice} from "../../../helpers/function";
import SearchIcon from "mdi-react/SearchIcon";
import Select from "react-select";
import {DAY_WORK_STATUS_OPTIONS} from "../../../constants/options";
import ReactDatePicker from 'react-datepicker'
import {formatISO} from "date-fns";
import DayWorkForm from "../../Accounts/Forms/DayWorkForm";
import PhotoViewer from "../../../shared/components/PhotoViewer";


const DayWorks = () => {
    const [currentDayworks, setCurrentDayworks] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [sortFilter, setSortFilter] = useState()
    const [statusFilter, setStatusFilter] = useState(null);
    const [operativeNameFilter, setOperativeNameFilter] = useState('');
    const [projectNameFilter, setProjectNameFilter] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);

    //Modal
    const [showReviewButtons, setShowReviewButtons] = useState(null)
    const [selectedDaywork, setSelectedDaywork] = useState(null);
    const [showDayworkModal, setShowDayworkModal] = useState(false);
    const [moreImageID, setMoreImageID] = useState(null);
    const [photos, setPhotos] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    useEffect(() => {
        getDayWorkRequest(0);
    }, []);

    useEffect(() => {
        if(sortFilter){
            if(sortFilter === 'date_desc'){
                const tempFilters = {...filters, orderBy: 'day_work.date_from',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'date_asc'){
                const tempFilters = {...filters, orderBy: 'day_work.date_from',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'status_desc'){
                const tempFilters = {...filters, orderBy: 'day_work.status',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'status_asc'){
                const tempFilters = {...filters, orderBy: 'day_work.status',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'operative_desc'){
                const tempFilters = {...filters, orderBy: 'creator.first_name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'operative_asc'){
                const tempFilters = {...filters, orderBy: 'creator.first_name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_desc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'projectName_asc'){
                const tempFilters = {...filters, orderBy: 'project.name',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }else if(sortFilter === 'dateRequest_desc'){
                const tempFilters = {...filters, orderBy: 'day_work.created_at',dateOrder: 'DESC' }
                setFilters(tempFilters)
            }else{
                const tempFilters = {...filters, orderBy: 'day_work.created_at',dateOrder: 'ASC' }
                setFilters(tempFilters)
            }
        }
    }, [sortFilter])

    useEffect(() => {
        setCurrentPage(0)
        // Filter out properties with undefined values
        const filteredFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== undefined)
        );

        if (Object.keys(filteredFilters).length > 0) {
            getDayWorkRequest(0, filteredFilters);
        }
    }, [filters])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(!!operativeNameFilter){
                // Set the Filters
                const tempFilters = {...filters, creator_name: operativeNameFilter, }
                setFilters(tempFilters)
            }else {
                const tempFilters = {...filters, creator_name: undefined, }
                setFilters(tempFilters)
            }
        }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

        // Clear the debounce timer on component unmount or when textInput changes
        return () => clearTimeout(debounceTimer);
    }, [operativeNameFilter]);

    useEffect(() => {
        if(statusFilter && !!statusFilter.value){
            const tempFilters = {...filters, status: statusFilter.value, }
            setFilters(tempFilters)
        }else {
            const tempFilters = {...filters, status: undefined, }
            setFilters(tempFilters)
        }
    }, [statusFilter]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if(!!projectNameFilter){
                // Set the Filters
                const tempFilters = {...filters, project: projectNameFilter, }
                setFilters(tempFilters)
            }else {
                const tempFilters = {...filters, project: undefined, }
                setFilters(tempFilters)
            }
        }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

        // Clear the debounce timer on component unmount or when textInput changes
        return () => clearTimeout(debounceTimer);
    }, [projectNameFilter]);

    useEffect(() => {
        if(dateRange){
            const [startDate, endDate] = dateRange;
            if(!!startDate && !!endDate) {
                let formattedStartDate = formatISO(startDate, )
                let formattedEndDate = formatISO(endDate)
                const tempFilters = {...filters, date_from: formattedStartDate.split('T')[0], date_to: formattedEndDate.split('T')[0] }
                setFilters(tempFilters)
            }
        }
    }, [dateRange]);


    const getDayWorkRequest = (page, filters) => {
        setLoading(true)
        getDayWorkRequests(0, page ? page :0, filters ? filters : undefined).then((res) => {
            if(res && res.items){
                setCurrentDayworks(res.items);
                setTotalPage(Math.ceil(res.total / 10))
            }
        }).catch(e =>  console.log("Error: ", e))
        setLoading(false);
    };

    const statusHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'status_desc') {
            setSortFilter('status_asc')
        } else {
            setSortFilter('status_desc')
        }
    },[setSortFilter, sortFilter])

    const operativeHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'operative_desc') {
            setSortFilter('operative_asc')
        } else {
            setSortFilter('operative_desc')
        }
    },[setSortFilter, sortFilter])

    const dateHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'date_desc') {
            setSortFilter('date_asc')
        } else {
            setSortFilter('date_desc')
        }
    },[setSortFilter, sortFilter])

    const dateRequestHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'dateRequest_desc') {
            setSortFilter('dateRequest_asc')
        } else {
            setSortFilter('dateRequest_desc')
        }
    },[setSortFilter, sortFilter])

    const projectNameHeaderClicked = useCallback(() => {
        if(sortFilter && sortFilter === 'projectName_desc') {
            setSortFilter('projectName_asc')
        } else {
            setSortFilter('projectName_desc')
        }
    },[setSortFilter, sortFilter])


    const toggleDayworkModal = () => {
        setShowDayworkModal(!showDayworkModal);
    };

    const toggleMoreImage = (id) => setMoreImageID(id);

    const getPhotosByStep = (id, index) => {
        const photos = [];
        currentDayworks.map(
            (item) =>
                item.id === id &&
                item.assets.map((item) =>
                    photos.push({ src: item.public_url, width: 4, height: 3 })
                )
        );
        setPhotos(photos);
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
        setCurrentImage(0);
    };


    const changeStatusFilter = (filterOption) => {
        setStatusFilter(filterOption)
    }

    const changeOperativeNameFilter = (text) => {
        setOperativeNameFilter(text)
    }

    const changeProjectNameFilter = (text) => {
        setProjectNameFilter(text)
    }

    const onChangeDateRange = (dates) => {
        setDateRange(dates)
    };

    const onChangePage = useCallback((page) => {
        setCurrentPage(page)
        getDayWorkRequest(page,filters);
    },[setCurrentPage,getDayWorkRequest])

    const _renderTableFilters = useCallback(() => {
        const [startDate, endDate] = dateRange;
        const modifiedOptions = [...DAY_WORK_STATUS_OPTIONS, { value: "HOLD", label: "On hold" },];
        return <div>
            <h5 className="page-title m-0 mb-2 mb-md-2">Filters</h5>
            <Row md={12} className={'m-0 mb-md-2 align-items-end'}>
                <Col md={2} className="d-flex justify-content-between p-0">
                    <Select
                        placeholder={'Status'}
                        value={statusFilter}
                        onChange={changeStatusFilter}
                        options={modifiedOptions}
                        isDisabled={false}
                        classNamePrefix="react-select"
                        className="react-select"
                        defaultValue={''}
                    />
                </Col>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search Operative"
                                className="search_input"
                                value={operativeNameFilter}
                                onChange={(data) => {
                                    changeOperativeNameFilter(data.target.value);
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="form__form-group-field">
                        <div className="search_input">
                            <SearchIcon />
                            <input
                                type="text"
                                name="search"
                                placeholder="Search Project"
                                className="search_input"
                                value={projectNameFilter}
                                onChange={(data) => {
                                    changeProjectNameFilter(data.target.value);
                                }}
                                onKeyPress={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={3} className="d-flex justify-content-between">
                    <div className="table-select-date-input" >
                        <div className={"d-flex flex-row align-items-center"}>
                            <CalendarIcon />
                            <div className={'d-flex flex-column align-items-start pl-1'}>
                                <ReactDatePicker
                                    onChange={onChangeDateRange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Start Date - End Date"
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    },[dateRange,onChangeDateRange, statusFilter, setStatusFilter, operativeNameFilter, projectNameFilter, changeOperativeNameFilter, changeProjectNameFilter])
    const _renderTableHeader = useCallback(() => {
        return  <thead>
        <tr>
            <th onClick={() => statusHeaderClicked()}>STATUS <SortOrderIcon size={24} /></th>
            <th onClick={() => operativeHeaderClicked()}>OPERATIVE <SortOrderIcon size={24}/> </th>
            <th onClick={() => dateHeaderClicked()}>DATE <SortOrderIcon size={24} /></th>
            <th onClick={() => dateRequestHeaderClicked()}>DATE REQUESTED <SortOrderIcon size={24} /></th>
            <th onClick={() => projectNameHeaderClicked()}>PROJECT <SortOrderIcon size={24} /></th>
            <th>BLOCK</th>
            <th>LEVEL</th>
            <th>SAGE REF</th>
            <th>RATE</th></tr>
        </thead>
    },[statusHeaderClicked,operativeHeaderClicked, dateHeaderClicked, dateRequestHeaderClicked])
    const _renderTableBody = useCallback(() => {
        if(currentDayworks){
            return  <tbody>
            {currentDayworks.map((item, index) => {
                return <tr key={index} onClick={() => {
                    setSelectedDaywork(item)
                    setShowDayworkModal(true)
                    setShowReviewButtons(item.status === DAY_WORK_STATUS.PENDING.value || item.status === DAY_WORK_STATUS.APPROVED_BY_PM.value)
                }}>
                    <td>
                        {!item.on_hold && item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
                        {!item.on_hold && item.status && DAY_WORK_STATUS[item.status].label}
                        {item.on_hold && "On Hold"}
                    </td>
                    <td>
                        {item.creator && item.creator.first_name + ' ' + item.creator.surname}
                    </td>
                    <td>
                        <CalendarIcon /> &nbsp;{" "}
                        <Moment format="LL">{item.date_from}</Moment>
                    </td>
                    <td>
                        <CalendarIcon /> &nbsp;{" "}
                        <Moment format="LL">{item.created_at}</Moment>
                    </td>
                    <td><BuildIcon /> &nbsp; {item.project && item.project.name}</td>
                    <td>{item.levels && item.levels.block.name}</td>
                    <td>{item.levels && item.levels.name}</td>
                    <td>{item.category}</td>
                    <td>{formatPrice(calculateDayWorkHourCost({startDate: item.date_from,
                        endDate: item.date_to,
                        startTime: item.start_at,
                        endTime: item.end_at,
                        dayworkHour: item.hourly_rate,
                        userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                        budget: item.budget}).cost) }
                    </td>
                </tr>

            })}
            </tbody>
        }else {
            return  null
        }

    },[currentDayworks, toggleDayworkModal, calculateDayWorkHourCost,formatPrice ])

    const _renderNoContent = useCallback(() => {
        return <div className="w-100 d-flex justify-content-center">
            <p>No content</p>
        </div>
    },[])

    const _renderTable = useCallback(() => {
        return <Row>
            <Card>
                <CardBody>
                    {_renderTableFilters()}
                    {currentDayworks && currentDayworks.length > 0 ? <Table responsive
                                                                           hover
                                                                           className="mt-1 table react-table table--bordered pl-0">

                        {_renderTableHeader()}
                        {_renderTableBody()}
                    </Table> : _renderNoContent()}

                </CardBody>
            </Card>

        </Row>
    },[_renderTableFilters,_renderTableHeader,_renderTableBody, currentDayworks,_renderNoContent])

    const _renderPagination = useCallback(() => {
        if(totalPage >= 2){
            return <div className="box-pagination">
                <Pagination
                    count={totalPage}
                    page={currentPage + 1}
                    onChange={(event, page) => onChangePage(page - 1)}
                />
            </div>
        }else {return null}
    },[totalPage,currentPage,onChangePage ])

    const _renderContent = useCallback(() => {
        return <div>
            {_renderTable()}
            {_renderPagination()}
        </div>
    },[_renderTable, _renderPagination])

    return (
        <Container>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Dayworks Approval</h5>
                    </Col>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {loading ? <Loader/>: _renderContent()}
                </CardBody>
            </Card>
            <PhotoViewer
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                photos={photos}
                viewerIsOpen={viewerIsOpen}
            />
            {showDayworkModal && (
                <Modal
                    isOpen={showDayworkModal}
                    toggle={toggleDayworkModal}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--primary additional_work-modal`}
                >
                    <div className="modal__header">
                        <h3 className="page-title">DayWork request</h3>
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            type="button"
                            onClick={toggleDayworkModal}
                        />
                    </div>
                    <div className="modal__body text-left mt-5">
                        <DayWorkForm
                            getDayWorkRequests={() => {
                                setCurrentPage(0)
                                getDayWorkRequest(0,filters);
                            }}
                            item={selectedDaywork}
                            moreImageID={moreImageID}
                            toggleMoreImage={toggleMoreImage}
                            getPhotosByStep={getPhotosByStep}
                            setModal={setShowDayworkModal}
                            setLoadTable={setLoading}
                            showButtons={showReviewButtons}
                        />
                    </div>
                </Modal>
            )}
        </Container>
    )
}

export default withRouter(DayWorks);
