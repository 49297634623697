import React, { useEffect, useRef, useState} from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap'
import { checkAdminPassword, authenticationWithToken } from '../../../redux/api/auth'


function ShowPinModal({
  toggleModal,
  modalVisibility,
  user,
  auth
}) {
  const passwordInputRef = useRef('')
  const [showPin, setShowPin] = useState(null);
  const [validation, setValidation] = useState(false)
  const [admin, setAdmin] = useState();

  const checkPassword = () => {
    if(passwordInputRef.current && admin) 
    return checkAdminPassword({
      email:admin.email,
      password:passwordInputRef.current
    }).then(res => {
      setShowPin(res)
    })
  }

  const handleAdminPassword = (event) => {
    return passwordInputRef.current = event.target.value;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.data.pin);
  }

  useEffect(() => {
    authenticationWithToken().then(res => setAdmin(res))
  },[])

  useEffect(()=>{
    if(showPin === null) return;
    if(showPin) setValidation(false)
    else setValidation('Invalid password. Please try again.')
  },[showPin])

  useEffect(()=>{
    if(!modalVisibility)
    {
      setShowPin(null);
      setValidation(false)
      passwordInputRef.current = ""
    }
  },[modalVisibility])

  return (
  <Modal
    isOpen={modalVisibility}
    toggle={toggleModal}
    modalClassName={`ltr-support`}
    className={`modal-dialog--primary locum-preview-modal`}
  >
  <div className="modal__header">
    <h3 className="page-title">Show user's recovery pin</h3>
    <button
      className="lnr lnr-cross modal__close-btn"
      type="button"
      onClick={toggleModal}
    />
  </div>
  <div className="modal__body">
    <Col md={12} lg={12} className="p-0 mt-5">
    <Card className="p-0">
    <CardBody className="p-0 pt-4 d-flex flex-column align-items-center">
      {
        !showPin &&
        <Row className="wrapper_page-title mt-3 w-50">
        <h4 className="form__form-group-label m-0">
          In order to show user's pin, please type your admin password.
        </h4>
        </Row>
      }
      <Row className="m-3">
        {
          !showPin
          ? 
            <Form>
            <FormGroup>
            <Label for="exampleEmail">
              <h4 className="form__form-group-label m-0">
              Password:
              </h4>
            </Label>
            <Input 
              type="password" 
              name="password" 
              ref={passwordInputRef}
              onChange={(e) => handleAdminPassword(e)}
            />
            {
              !!validation && 
              <div className='m-2'>
                <h5 style={{color:'red'}}>{validation}</h5>
              </div>  
            }
            </FormGroup>
            </Form> 
          : <h4 className="form__form-group-label m-0">The pin is: {user.data.pin}</h4>
        }
      </Row>
      <Row className="form__form-group form__form-group-field d-flex justify-content-center">
        <div className="d-flex">
          <Button
            className="mb-0"
            color="secondary"
            onClick={toggleModal}
          >
            {showPin ? 'Close' : 'Cancel'}
          </Button>
          <Button
            className="mb-0"
            color="primary"
            onClick={
              showPin 
              ? copyToClipboard
              : checkPassword
            }
          >
            {showPin ? 'Copy code' : 'Continue'}
          </Button>
        </div>
      </Row>
    </CardBody>
    </Card>
    </Col>
  </div>
</Modal>
  )
}

export default ShowPinModal
