import {
    LOGIN_USER,
    LOGOUT_USER,
    AUTH_LOADING,
    AUTH_ERROR,
    SWITCH_ROLE,
    UPDATE_USER,
    GET_USER_DOCUMENTS,
} from "../actions";

const INIT_STATE = {
    user: null,
    error: '',
    loading: false,
    global_loading: true,
    documents: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
                global_loading: false,
            };
        case LOGOUT_USER:
            return {
                ...state,
                user: null,
                documents: null,
                loading: false,
                global_loading: false,
            };
        case AUTH_LOADING:
            return { ...state, loading: action.payload };
        case AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                global_loading: false,
            };
        case SWITCH_ROLE:
            return { ...state, role: action.payload };
        case UPDATE_USER:
            return { ...state, user: action.payload };
        case GET_USER_DOCUMENTS:
            return { ...state, documents: action.payload };
        default:
            return { ...state };
    }
}
