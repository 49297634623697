import {
  getRequestsAsync,
  getQARequestsAsync,
  getAdditionalWorkRequestsAsync,
  getAdditionalWorkRequestsCounterAsync,
  getQARequestsCounterAsync,
  getRequestsCounterAsync,
  getDayWorkRequestsAsync,
  getDayWorkCounterAsync,
  getProjectAdditionalWorkRequestPageableAsync,
  getProjectDayworksWithoutVOAsync,
  getUnknownRequestsCounterAsync,
  getUninvoicedAdditionalWorkRequestsAsync,
} from '../api/requests';

export const getRequests = async (id,
   filters, page) => await getRequestsAsync(id, filters, page);
export const getQARequests = async (id, filters, page) => await getQARequestsAsync(id, filters, page);
export const getAdditionalWorkRequests = async (id, filters, page) => await getAdditionalWorkRequestsAsync(id, filters, page);
export const getUninvoicedAdditionalWorkRequests = async (id, page) => await getUninvoicedAdditionalWorkRequestsAsync(id, page);
export const getRequestsCounter = async (id) => await getRequestsCounterAsync(id);
export const getQARequestsCounter = async (id) => await getQARequestsCounterAsync(id);
export const getAdditionalWorkRequestsCounter = async (id) => await getAdditionalWorkRequestsCounterAsync(id);
export const getDayWorkRequests = async (id, page, filters) => await getDayWorkRequestsAsync(id, page, filters);
export const getDayWorkCounter = async (id) => await getDayWorkCounterAsync(id);
export const getProjectAdditionalWorkRequestPageable = async (projectId, pagination) => await getProjectAdditionalWorkRequestPageableAsync(projectId, pagination);
export const getProjectDayworksWithoutVO = async (projectId, filters,pagination) => await getProjectDayworksWithoutVOAsync(projectId, filters,pagination);
export const getUnknownRequestsCounter = async (id) => await getUnknownRequestsCounterAsync(id);
