import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { getProjects } from '../../../redux/services/projects'
import { getReducedUsers } from '../../../redux/services/users'
import { debounce } from 'lodash'
import ReactDatePicker from 'react-datepicker'
import moment from "moment";
import {SAGE_REF_OPTIONS} from '../../../../src/constants/options'
import {exportJobCsv, exportBudgetVariationCsv} from '../../../redux/services/jobs'
import { exportDayworkToCsv } from '../../../redux/services/day_work'

const ExportCsvModal = ({
  toggleModal,
  isOpen,
}) => {
  const [availableProjects, setAvailableProjects] = useState([]);
  const [availableOperatives, setAvailableOperatives] = useState();

  const [operative, setOperative] = useState();
  const [project, setProject] = useState();
  const [costCode, setCostCode] = useState();
  const [dateInterval, setDateInterval] = useState();
  const [category, setCategory] = useState();

  const categories = [
    { label: 'Job', value: 'job'},
    { label: 'Daywork', value: 'daywork'},
    { label: 'Budget Variation', value: 'budget_variation'}
  ]

  const selectStyles = {
    input: (base, state) => ({
      ...base,
      overflow:'visible',
    }),
    control: (base, state) => ({
      ...base,
      marginTop: '1.25rem'
    }),
  }

  const fetchProjects = async () => {
    await getProjects(undefined,
      {
        page: 0,
        pageSize:10000,
      }
    ).then(res => 
      res && res.items && 
      setAvailableProjects(res.items.map(project => ({label: project.name, value: project.id})))
    )
  }

  const handleOperativeSearch = debounce((e) => {
    if(e){
      getReducedUsers(e).then(res=> 
        setAvailableOperatives(res.items && res.items.map(operative => ({label: `${operative.first_name} ${operative.surname}`, value: operative.id})))
      )
    }
  },500)

  const exportJobCSV = () => {
    exportJobCsv({
      project_id: project,
      employee_id: operative,
      cost_code: costCode,
      date_from: dateInterval && dateInterval.start,
      date_to: dateInterval && dateInterval.end
    })
  }

  const exportDayworkCSV = () => {
    exportDayworkToCsv({
      project_id: project,
      creator_id: operative,
      cost_code: costCode,
      date_from: dateInterval && dateInterval.start,
      date_to: dateInterval && dateInterval.end
    })
  }

  const exportBudgetVariationCSV = () => {
    exportBudgetVariationCsv({
      project_id: project,
      creator_id: operative,
      cost_code: costCode,
      date_from: dateInterval && dateInterval.start,
      date_to: dateInterval && dateInterval.end
    })
  }

  const handleExport = (category) => {
    if(category === 'job'){
      return exportJobCSV();
    }

    if(category === 'daywork'){
      return exportDayworkCSV();
    }

    if(category === 'budget_variation'){
      return exportBudgetVariationCSV();
    }
  }

  useEffect(()=>{
    fetchProjects(); 
  },[])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog-centered`}
    >
    <ModalHeader className={'modal__header d-flex flex-row align-items-center'}>
      <h1 className='modal-title'>Export CSV</h1>
    </ModalHeader>
    <ModalBody>
      <div className='modal__body mt-5 pt-2'>
        <Select
          options={categories}
          styles={selectStyles}
          placeholder={'Select select category'}
          onChange={(e) => setCategory(e.value)}
        />
        <Select
          options={availableProjects}
          styles={selectStyles}
          placeholder={'Select project'}
          onChange={(e) => setProject(e.value)}
        />
        <Select
          options={availableOperatives}
          styles={selectStyles}
          placeholder={'Select operative'}
          onChange={(e) => setOperative(e.value)}
          onInputChange={(e) => handleOperativeSearch(e)}
        />
        <Select
          options={SAGE_REF_OPTIONS}
          styles={selectStyles}
          placeholder={'Select cost code'}
          onChange={(e) => setCostCode(e.value)}
        />
        <div className={'d-flex justify-content-between mt-3'}>
          <div className={'d-flex flex-column align-items-start'}>
            <label className={'m-0'}>Start date:</label>
            <ReactDatePicker
              selected={dateInterval && dateInterval.from}
              onChange={(startDate) => setDateInterval({...dateInterval, from: moment(startDate).startOf('day').toDate()})}
              className={'py-2 rounded-sm border border-gray-300 m-0 mr-2'}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className={'d-flex flex-column align-items-start'}>
            <label className={'m-0 ml-2'}>End date:</label>
            <ReactDatePicker
              selected={dateInterval && dateInterval.to}
              onChange={(endDate) => setDateInterval({...dateInterval, to: moment(endDate).endOf('day').toDate()})}
              className={'py-2 rounded-sm border border-gray-300 ml-2'}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className={'mt-3 d-flex justify-content-end'}>
          <Button
            color='secondary'
            onClick={toggleModal}
          >  
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => handleExport(category)}
          >  
            Export
          </Button>
        </div>
      </div>
    </ModalBody>
    </Modal>
  )
}

export default ExportCsvModal