import React, { useEffect, useState } from "react";
import { Row, Table } from "reactstrap";
import { INVOICES_STATUS } from "../../constants/enamerations";
import {
  getInvoiceByUserId,
  updateInvoice,
} from "../../redux/services/invoice";
import moment from "moment";
import Loading from "../../shared/components/Loader";
import { defaultPage } from "../../config/pagination";
import { unixTime } from "../../config/validationRules";

const RecentInvoices = ({ userId }) => {
  const [invoice, setInvoice] = useState(null);
  const [load, setLoad] = useState(false);
  const pageSize = 3;
  useEffect(() => {
    setLoad(true);
    getInvoiceByUserId(userId, "", { page: defaultPage, pageSize }).then(
      (res) => {
        setInvoice(res);
        setLoad(false);
      }
    );
  }, []);

  const paidInvoice = (id) => {
    setLoad(true);
    updateInvoice(id, true).then(() => {
      getInvoiceByUserId(userId, "", { page: defaultPage, pageSize }).then(
        (res) => {
          setInvoice(res);
          setLoad(false);
        }
      );
    });
  };

  const sumAmount = (item) => {
    let sum = 0;

    if (item.invoice[0] && item.invoice[0].drc) {
      item.invoice.forEach((invoice) => {
        sum += Number(invoice.net_amount - invoice.cis_deduction);
      });

      return sum;
    }

    item.invoice.forEach((invoice) => {
      sum += Number(invoice.amount);
    });

    return sum;
  };

  return (
    <Row className="pl-2 pr-4">
      {load ? (
        <Loading />
      ) : invoice && invoice.items && invoice.items.length !== 0 ? (
        <Table responsive className="table react-table table--bordered">
          <thead>
            <tr>
              <th>INVOICE</th>
              <th>AMOUNT</th>
              <th>DATE</th>
              <th>DUE BY</th>
              <th>STATUS</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {invoice &&
              invoice.items &&
              invoice.items.map((item, index) => (
                <tr key={index}>
                  <td>Invoice #{item && item.id}</td>
                  <td>₤ &nbsp; {sumAmount(item).toFixed(2)}</td>
                  <td>
                    {item && moment.unix(item.created_at).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {item &&
                      moment
                        .unix(item.created_at + unixTime)
                        .format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {item && item.status && INVOICES_STATUS[item.status].icon}{" "}
                    &nbsp;{" "}
                    {item && item.status && INVOICES_STATUS[item.status].label}
                  </td>
                  <td>
                    {item && item.status === INVOICES_STATUS.UNPAID.value && (
                      <span
                        className="bread_crumbs-active"
                        onClick={() => paidInvoice(item.id)}
                      >
                        MARK AS PAID
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <p>Not found</p>
        </div>
      )}
    </Row>
  );
};

export default RecentInvoices;
