import React, {useState, useEffect, useCallback} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Modal, Row} from "reactstrap";
import Moment from "react-moment";
import Loading from "../../../shared/components/Loader";
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import { getDayWorkById } from "../../../redux/services/day_work";
import {
  BuildIcon,
  CalendarIcon,
  CreateCalendarIcon,
  PrintIcon,
  SortOrderIcon
} from "../../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import { DAY_WORK_STATUS } from "../../../constants/enamerations";
import {calculateDayWorkHourCost} from "../../../config/func";
import DayWorkForm from "../Forms/DayWorkForm";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import ReactToPrint from "react-to-print";
import DayWorkPrint from "../Print/DayWorkPrint";
import Select from "react-select";
import {DAY_WORK_STATUS_OPTIONS} from "../../../constants/options";
import {formatPrice} from "../../../helpers/function";

const PAGE_SIZE = 10;

const DayWorkTable = ({ match, getDayWorkRequests }) => {
  //NEW STATE
  const [loadingDaywork, setLoadingDaywork] = useState(false)
  const [filteredDayworks, setFilteredDayworks] = useState([])
  const [dayworksCurrentPage, setDayworksCurrentPage] = useState(0)
  const [dayworksTotalElements, setDayworksTotalElements] = useState(0)
  const [dayworksPageCounts, setDayworksPageCounts] = useState(0)
  const [filters, setFilters] = useState({status: "" })
  const [dateSortFilter, setDateSortFilter] = useState(null)
  const [selectedDaywork, setSelectedDaywork] = useState(null)
  const [showReviewButtons, setShowReviewButtons] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [currentImage, setCurrentImage] = useState(0);
  const [showImageCarousel, setShowImageCarousel] = useState(false);

  useEffect(() => {
    setLoadingDaywork(true);
    getDayWorkById(+match.params.id,filters, 0, PAGE_SIZE).then((res) => {
      setFilteredDayworks(res.items)
      setDayworksTotalElements(res.total)
      setLoadingDaywork(false)
    })
  }, [])

  useEffect(() => {
    setLoadingDaywork(true)
    getDayWorkById(+match.params.id,filters).then((res) => {
      setFilteredDayworks(res.items)
      setDayworksCurrentPage(0)
      setDayworksTotalElements(res.total)
      setLoadingDaywork(false)
    });
  }, [filters])

  useEffect(() => {
    setLoadingDaywork(true)
    getDayWorkById(+match.params.id,filters, dayworksCurrentPage).then((res) => {
      setFilteredDayworks(res.items)
      setDayworksTotalElements(res.total)
      setLoadingDaywork(false)
    });
  }, [dayworksCurrentPage])

  useEffect(() => {
    if(dateSortFilter){
      if(dateSortFilter === 'request_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.created_at',dateOrder: 'DESC' }
        setFilters(tempFilters)
      } else if(dateSortFilter === 'request_asc'){
        const tempFilters = {...filters, orderBy: 'dayWork.created_at',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }else if(dateSortFilter === 'date_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.date_from',dateOrder: 'DESC' }
        setFilters(tempFilters)
      }else{
        const tempFilters = {...filters, orderBy: 'dayWork.date_from',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }
    }
  }, [dateSortFilter])

  useEffect(() => {
    setDayworksPageCounts(Math.ceil(dayworksTotalElements / PAGE_SIZE))
  },[dayworksTotalElements])


  //OLD STATE
  const [moreImageID, setMoreImageID] = useState(null);
  const [photos, setPhotos] = useState(null);

  const componentRef = React.createRef();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    filteredDayworks.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setShowImageCarousel(true);
  };

  const closeLightbox = () => {
    setShowImageCarousel(false);
    setCurrentImage(0);
  };

  const RenderComponent = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <DayWorkPrint item={selectedDaywork} />
    </div>
  ));

  const handleChange = useCallback((filter) => {
    setFilters({...filters, status: filter.value});
  },[filters] )

  const dateHeaderClicked = useCallback(() => {
    if(dateSortFilter && dateSortFilter === 'date_desc') {
      setDateSortFilter('date_asc')
    } else {
      setDateSortFilter('date_desc')
    }
  },[setDateSortFilter, dateSortFilter])

  const dateRequestHeaderClicked = useCallback(() => {
    if(dateSortFilter && dateSortFilter === 'request_asc') {
      setDateSortFilter('request_desc')
    } else {
      setDateSortFilter('request_asc')
    }
  },[setDateSortFilter,dateSortFilter])

  const _renderLoading = useCallback(() => {
    return <Loading/>
  },[])

  const _renderFilterSection = useCallback(() => {
    return <>
      <Row md={9} className="mt-4">
        <div className="ml-3">
          <Select name={'status'}
                  placeholder={"Status"}
                  className="react-select"
                  classNamePrefix="react-select"
                  options={DAY_WORK_STATUS_OPTIONS}
                  onChange={handleChange} />
        </div>
      </Row>
    </>
  },[DAY_WORK_STATUS_OPTIONS,handleChange])

  const _renderTableHeader = useCallback(() => {
    return  <thead>
    <tr>
      <th>STATUS</th>
      <th onClick={() => dateHeaderClicked()}>DATE <SortOrderIcon size={20} /></th>
      <th onClick={() => dateRequestHeaderClicked()}>DATE REQUESTED <SortOrderIcon size={20} /></th>
      <th>PROJECT</th>
      <th>BLOCK</th>
      <th>LEVEL</th>
      <th>SAGE REF</th>
      <th>RATE</th>
    </tr>
    </thead>
},[dateHeaderClicked, dateRequestHeaderClicked])

  const _renderTableContent = useCallback(() => {
    return <Table responsive
                  hover
                  className="mt-1 table react-table table--bordered pl-0"
    >
      {_renderTableHeader()}
      <tbody>
      {filteredDayworks.map((item) => (
          <tr
              key={`daywork-table-${item.id}`}
              onClick={() => {
                setSelectedDaywork(item);
                setShowModal(true);
                setShowReviewButtons(item.status === DAY_WORK_STATUS.PENDING.value || item.status === DAY_WORK_STATUS.APPROVED_BY_PM.value)
              }}
          >
            <td>
              {item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
              {item.status && DAY_WORK_STATUS[item.status].label}
            </td>
            <td>
              <CalendarIcon /> &nbsp;{" "}
              <Moment format="LL">{item && item.date_from}</Moment> -
              <Moment format="LL">{item && item.date_to}</Moment>
            </td>
            <td>
              <CreateCalendarIcon /> &nbsp;{" "}
              <Moment format="LL">{item && item.created_at}</Moment> {" "}
            </td>
            <td>
              <BuildIcon /> &nbsp; {item.project && item.project.name}
            </td>
            <td>{item.levels && item.levels.block.name}</td>
            <td>{item.levels && item.levels.name}</td>
            <td>{item && item.category}</td>
            <td>{formatPrice(calculateDayWorkHourCost({
              startDate: item.date_from,
              endDate: item.date_to,
              startTime: item.start_at,
              endTime: item.end_at,
              dayworkHour: item.hourly_rate,
              userHourRate: item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
              budget: item.budget}).cost)}</td>
          </tr>
      ))}
      </tbody>
    </Table>
  },[filteredDayworks, _renderTableHeader])

  const _renderTablePagination = useCallback(() => {
    if(filteredDayworks && dayworksPageCounts >= 2 ){
      return  <div className="box-pagination">
        <Pagination
            count={dayworksPageCounts}
            page={dayworksCurrentPage + 1}
            onChange={(event, page) => setDayworksCurrentPage(page - 1)}
        />
      </div>
    } else {
      return  null
    }
  },[dayworksPageCounts,dayworksCurrentPage,filteredDayworks ])
  const _renderDayworkTable = useCallback(() => {
    if(filteredDayworks.length > 0) {
      return <>
        {_renderTableContent()}
        {_renderTablePagination()}
      </>
    } else {
      return <div className="w-100 d-flex justify-content-center">
                <p>Not found</p>
             </div>
    }

  },[_renderTableContent, _renderTablePagination,filteredDayworks])

  return (
    <>
      {_renderFilterSection()}
      {loadingDaywork ? _renderLoading() : _renderDayworkTable()}
      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={showImageCarousel}
      />
          <Modal
              isOpen={showModal}
              toggle={toggleModal}
              modalClassName={`ltr-support`}
              className={`modal-dialog--primary additional_work-modal`}
          >
            <div className="modal__header">
              <h3 className="page-title">DayWork request</h3>
              <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  onClick={toggleModal}
              />
            </div>
            <div className="modal__body text-left mt-5">
              <DayWorkForm
                  getDayWorkRequests={getDayWorkRequests}
                  item={selectedDaywork}
                  moreImageID={moreImageID}
                  toggleMoreImage={toggleMoreImage}
                  getPhotosByStep={getPhotosByStep}
                  setModal={setShowModal}
                  setLoadTable={setShowModal}
                  showButtons={showReviewButtons}
              />
              <div>
                <ReactToPrint
                    trigger={() => (
                        <a
                            className="print-btn d-inline-block mt-2 float-right"
                            role="button"
                        >
                          <PrintIcon /> <span>Print</span>
                        </a>
                    )}
                    content={() => componentRef.current}
                />
              </div>
            </div>
          </Modal>
      <div style={{ display: "none" }}>
        <RenderComponent ref={componentRef} item={selectedDaywork} />
      </div>
    </>
  );
};
const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DayWorkTable)
);
