import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm, initialize } from "redux-form";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";
import store from "../../../../redux/store";

const minValue = (min) => (value) =>
  value && value <= min ? `Value must be greater than ${min}` : undefined;

const minValueDefault = minValue(0);

const requiredField = (value) => (value ? undefined : "You must enter a value");
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength5 = maxLength(5);

var values = ["_", 1, 1];

let EditBlockForm = ({ handleSubmit, id, noOfLevels, quantity }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const toggle = () => setModalIsOpened(!modalIsOpened);

  function initializeEditBlockForm() {
    store.dispatch(
      initialize("edit_block_form", {
        id,
        noOfLevels,
        quantity,
      })
    );
  }

  useEffect(() => {
    if (modalIsOpened) {
      initializeEditBlockForm();
    }
  }, [modalIsOpened]);

  return (
    <div className="centered">
      <Button
        className="editButton"
        onClick={() => {
          toggle();
        }}
      >
        EDIT BLOCK
      </Button>

      <Modal
        isOpen={modalIsOpened}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary locum-preview-modal createBlockModal`}
      >
        <ModalHeader className="modal-header">
          <div>
            <h3>Edit block</h3>
          </div>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => {
              toggle();
            }}
          />
        </ModalHeader>

        <ModalBody className="createBlockModalBody">
          <form
            id={"edit_block_form"}
            onSubmit={(event) => {
              handleSubmit(event);
              event.stopPropagation();

              if (
                values[0].length &&
                parseInt(values[1]) > 0 &&
                values[1].length <= 5 &&
                parseInt(values[2]) > 0
              ) {
                values = ["_", 1, 1];
                toggle();
              }
            }}
          >
            <Row md={12} className="mt-2">
              <Field
                name="id"
                component={TextFieldMaterial}
                placeholder="Enter Block Letter/Number/ID"
                type={"text"}
                validate={[requiredField, maxLength5]}
                onChange={(value) => {
                  values[0] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfLevels"
                component={TextFieldMaterial}
                placeholder="Enter Number of Levels"
                type={"number"}
                validate={[requiredField, minValueDefault, maxLength5]}
                onChange={(value) => {
                  values[1] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="quantity"
                component={TextFieldMaterial}
                placeholder="Enter quantity of properties/plots/units"
                type={"number"}
                validate={[requiredField, minValueDefault]}
                onChange={(value) => {
                  values[2] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4 justify-content-end">
              <Row md={12} className="justify-content-end">
                <Col md={5}>
                  <Button
                    className="cancelButton"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={5}>
                  <Button
                    className="button"
                    type={"submit"}
                    form={"edit_block_form"}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "edit_block_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditBlockForm);
