import React, { useEffect, useState } from 'react'
import { Button, Card, Modal } from 'reactstrap'
import { Field, reduxForm } from 'redux-form';
import CheckBoxField from "../../../shared/components/form/CheckBox";
import TextFieldMaterial from '../../../shared/components/material_field/TextFieldMaterial';
import { resetJob } from '../../../redux/services/jobs';
import { useHistory } from "react-router-dom";

function ResetJobModal({
  isModalOpen,
  toggleModal,
  handleSubmit,
  job,
}) {
  let history = useHistory();
  const [unAssignOperative, setUnAssignOperative] = useState(false)
  const [unAssignPm, setUnAssignPm] = useState(false)
  const [unAssignQa, setUnAssignQa] = useState(false)
  const [validation, setValidation] = useState(false)

  const handleJobReset = (values) => {
    if(!unAssignOperative){
    values.valueToPayOldWorker = false;
    values.newRate = false;
    }
    if(!(unAssignOperative || unAssignPm || unAssignQa))
     return setValidation("You need to check at least one field in order to reset the job.")
    else {
    toggleModal()
    resetJob(job.id, values)
    .then(res => {
      if(res && res.data.id && job.project.id){
        history.push(`/projects/preview-projects/${job.project.id}/jobs/preview-jobs/${res.data.id}`)
        window.location.reload()
      }
    })
    }
  }

  useEffect(()=>{
    if(unAssignOperative || unAssignPm || unAssignQa) setValidation(false)
  },[unAssignPm, unAssignQa, unAssignOperative])

  if(!job) return <div>Loading...</div>

  return (
  <Modal
    isOpen={isModalOpen}
    toggle={toggleModal}
    className={`modal-dialog-centered`}
  >
    <div className=" modal__header">
      <h3 className="page-title">Reset job</h3>
      <button
        className="lnr lnr-cross modal__close-btn"
        type="button"
        color="primary"
      />
    </div>
    <Card className="pt-3 mt-5">
      <div className="m-0 mt-4 mb-4">
        <p>{`You are now resetting the job. Please choose the fields that you want to reset.`}</p>
      </div>
      <form onSubmit={handleSubmit(handleJobReset)}>
      <div className="form__form-group mb-4">
        <div className='d-flex flex-row' style={{gap:10}}>
        {
        !!job && !!job.employee_id &&
        <div className='d-flex flex-row align-items-center' style={{gap:20}}>
        <Field
        name="unassignOP"
        component={CheckBoxField}
        checked={unAssignOperative ? true : null}
        type={"checkbox"}
        onChange={(value) => {
          if (value) {
            setUnAssignOperative(value.currentTarget.checked);
          }
        }}
        />
        <span>Unassign operative</span>
        </div>
        }
        {
        !!job && !!job.pm_id &&
        <div className='d-flex flex-row mr-3 align-items-center' style={{gap:20}}>
        <Field
        name="unassignPM"
        component={CheckBoxField}
        checked={unAssignPm ? true : null}
        type={"checkbox"}
        onChange={(value) => {
          if (value) {
            setUnAssignPm(value.currentTarget.checked);
          }
        }}
        />
        <span>Unassign PM</span>
        </div>
        }
        {
        !!job && !!job.qa &&
        <div className='d-flex flex-row mr-3 align-items-center' style={{gap:20}}>
        <Field
        name="unassignQA"
        component={CheckBoxField}
        checked={unAssignQa ? true : null}
        type={"checkbox"}
        onChange={(value) => {
          if (value) {
            setUnAssignQa(value.currentTarget.checked);
          }
        }}
        />
        <span>Unassign QA</span>
        </div>
        }
        </div>
        {
        unAssignOperative &&
        <div className='mt-3'>
        <span>{`You are unassigning the operative. Please type the amount to be payed for ${job.employee.first_name} ${job.employee.surname}.`}</span>
        <div className='create_user_field_wrapper w-100 pt-3 pt-3'>
        <Field
          name="valueToPayOldWorker"
          component={TextFieldMaterial}
          placeholder="Amount"
          label={`Amount to be paid for ${job.employee.first_name} ${job.employee.surname}`}
          type="number"
        />
        </div>
        <div className='create_user_field_wrapper w-100 pt-3 pt-3'>
        <Field
          name="newRate"
          component={TextFieldMaterial}
          placeholder="Amount"
          label="New amount"
          type="number"
        />
        </div>
        </div>
        }  
      </div>
      {
        !!validation && 
        <div className='mb-3' style={{color:'red'}}>
          <span>{validation}</span>
        </div>
      }
      <div className="form__form-group-field d-flex justify-content-end"
        style={{gap:15}}
        > 
          <div className="d-flex">
          <Button 
          className="mb-0" 
          color="secondary"
          onClick={toggleModal}
          >
            Cancel
          </Button>
          </div>
          <div className="d-flex">
          <Button 
          className="mb-0"
          color="primary" 
          type="submit"
          >
            Reset
          </Button>
          </div>
      </div>
      </form>
    </Card>
  </Modal>
  )
}

export default reduxForm({
  form: "reset_job_form",
  enableReinitialize: true,
})(ResetJobModal);
