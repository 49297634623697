import React, { useState } from "react";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { CreateIcon } from "../../../../constants/icons";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";

const required = (value) => (value ? undefined : "You must enter a value");

let AddCostTypeForm = ({ handleSubmit, reset }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [value, setValue] = useState("");

  const toggle = () => setModalIsOpened(!modalIsOpened);

  return (
    <div>
      <Button className="button" onClick={toggle}>
        <div className="addButton">
          <div className="mr-2">
            <CreateIcon />
          </div>
          ADD COST TYPE
        </div>
      </Button>

      <Modal
        isOpen={modalIsOpened}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary locum-preview-modal createBlockModal`}
      >
        <ModalHeader className="modal-header">
          <div>
            <h3>Add cost type</h3>
          </div>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => {
              toggle();
              reset();
            }}
          />
        </ModalHeader>

        <ModalBody className="createBlockModalBody">
          <form
            id={"add_cost_type_form"}
            onSubmit={(event) => {
              handleSubmit(event);
              event.stopPropagation();
              if (value) {
                toggle();
                reset();
                setValue("");
              }
            }}
          >
            <Row md={12} className="mt-2">
              <Field
                name="costTypeName"
                component={TextFieldMaterial}
                placeholder="Enter cost type name"
                label={"Cost type"}
                type={"text"}
                validate={required}
                onChange={(value) => {
                  setValue(value.currentTarget.value);
                }}
              />
            </Row>

            <Row md={12} className="mt-4 justify-content-end">
              <Row md={12} className="justify-content-end">
                <Col md={5}>
                  <Button
                    className="cancelButton"
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={5}>
                  <Button
                    className="button"
                    type={"submit"}
                    form={"add_cost_type_form"}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "add_cost_type_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(AddCostTypeForm);
