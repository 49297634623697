export const NOT_FOUND = "/404";
export const ROOT = "/";
export const LOGIN = "/";

//Contract
export const CONTRACTS = "/contracts";
export const PREVIEW_CONTRACT = "/contracts/preview-contracts/:id";

// Users
export const USERS = "/accounts";
export const PREVIEW_USER = "/accounts/preview-account/:id";
export const INVOICES = "/accounts/preview-account/:id/invoices";
export const UNINVOICED = "/accounts/preview-account/:id/uninvoiced";


// Projects
export const PROJECTS = "/projects";
export const CREATE_PROJECT = "/projects/create";
export const CREATED_PROJECTS = "/projects/createdProjects";

export const REQUESTS = "/projects/preview-projects/:id/requests";
export const DAY_WORK = "/projects/preview-projects/:id/day-work";
export const PREVIEW_PROJECT = "/projects/preview-projects/:id/status/:status";
export const INVOICES_BY_PROJECT = "/projects/preview-project/:id/invoices";
export const PROJECT_STRUCTURE = "/projects/preview-projects/:id/structure";
export const JOBS = "/projects/preview-projects/:id/jobs/status/:status";
export const PREVIEW_JOB =
    "/projects/preview-projects/:project_id/jobs/preview-jobs/:id";



// Commercial
export const COMMERCIAL = "/commercial";
export const CONTRACT_VARIATION_ORDERS = "/commercial/preview-variation-orders/:id";
export const UNKNOWN_REQUESTS = "/commercial/preview-variation-orders/:id/unknown-requests";
export const PREVIEW_VO = '/commercial/variation-order/:id';

// Templates
export const VAT_TEMPLATES = "/vatTemplates";
export const JOB_TEMPLATES = "/jobTemplates";
export const QA_STEPS = "/qaSteps";
export const OPERATIVE_STEPS = "/operativeSteps";
export const GENERATE_INVOICES = "/generateInvoices";

// Approvals
export const APPROVAL_DAYWORKS = "/approval/dayworks";
export const APPROVAL_UPLIFTS = "/approval/uplifts";
export const APPROVAL_JOBS = "/approval/jobs";



