import React, { useRef, useState } from 'react'
import { Col, Container, Form, FormGroup, Modal } from 'reactstrap'
import { TextField } from "@material-ui/core";
import { Button } from 'reactstrap'
import { createContract } from '../../../../redux/services/contracts';

const CreateContractModal = ({
  modalVisibility,
  toggleModal,
  refetch,
}) => {
  const groupNameRef = useRef();
  const [error, setError] = useState();

  const handleGroupName = (event) => {
    return groupNameRef.current = event.target.value;
  }

  const createGroup = (e) => {
    e.preventDefault();

    if(!!!groupNameRef.current){
      return setError(true);
    }

    createContract(groupNameRef.current)
    setError(false);
    toggleModal();
    setTimeout(() => {
      refetch();
    },100)
  }

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog-centered`}
    >
      <Container className={"modal__header"}>
        <h3>Create new contract</h3>
      </Container>

      <Container className={"modal__body mt-5"}>
      <Col className={"mt-5"}>
        <Form onSubmit={createGroup}>
          <FormGroup className={"d-flex flex-column align-items-center"}>
            <div className="create_user_field_wrapper mb-3 w-100">
              <TextField
                placeholder={"Contract name"}
                label={"Contract name"}
                value={groupNameRef.current}
                onChange={handleGroupName}
                type={"text"}
              />
            </div>
            { !!error && 
            <p className='mt-1 mb-4 red-text'>
            You need to input a name for Contract
            </p>
            }
            <div className='d-flex justify-content-end w-100'>
            <Button onClick={toggleModal}>
              Cancel
            </Button>
            <Button 
            // onClick={createGroup}
            type="submit"
            color="primary"
            >
              Create
            </Button>
            </div>
          </FormGroup>
        </Form>
      </Col>
      </Container>
    </Modal>
  )
}

export default CreateContractModal