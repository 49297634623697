import {
    SET_TIME_ID,
    SET_STATUS
} from "../actions";


const INIT_STATE = {
    timeID: null,
    printReady: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TIME_ID:
            return { ...state, timeID: action.payload };
        case SET_STATUS:
            return { ...state, printReady: action.payload };

        default:
            return { ...state };
    }
}