export const isJobChecked = (jobsIds, jobId) => {
    return jobsIds.includes(jobId)
}

export const getAllAvailableJobs = (allJobs, selectedJobs) => {
    return allJobs.filter(
        (job) =>
            !selectedJobs.map((selectedJob) => selectedJob.id).includes(job.id)
    );
}


export const checkedJobTemplatesAreValid = (checkedJobTemplates) => {
    let jobsAreValid = true
    checkedJobTemplates.forEach((jobTemplate) => {
        jobsAreValid = jobsAreValid && !!jobTemplate.description
            && jobTemplate.area && !!jobTemplate.area.label && !!jobTemplate.area.value
            && jobTemplate.trade && !!jobTemplate.trade.label && !!jobTemplate.trade.value
            && jobTemplate.sageRef && !!jobTemplate.sageRef.label && !!jobTemplate.sageRef.value
            && jobTemplate.operativeSteps && !!jobTemplate.operativeSteps.label && jobTemplate.operativeSteps.value && jobTemplate.operativeSteps.value.description
            && jobTemplate.qaSteps && !!jobTemplate.qaSteps.label && jobTemplate.qaSteps.value && jobTemplate.qaSteps.value.description
    })
    return jobsAreValid
}


export const getAllUpdatedJobs = (allJobs, checkedJobs) => {
    let tempJobs = []
    allJobs.forEach((job) => {
        const foundIndex = checkedJobs.findIndex((checkedJob) => checkedJob.id === job.id)
        if(foundIndex === -1){
            tempJobs.push(job)
        }
    })
    checkedJobs.forEach((checkedJobs) => {
        tempJobs.push(checkedJobs)
    })

    return tempJobs
}


export const getOrganizedJobs = (jobs, checkedJobsId) => {
    let tempCheckedJobs = []
    let tempNotCheckedJobs = []
    jobs.forEach((availableJob) => {
        if(checkedJobsId.includes(availableJob.id)){
            tempCheckedJobs.push(availableJob)
        } else {
            tempNotCheckedJobs.push(availableJob)
        }
    })
    return {checked: tempCheckedJobs, notChecked: tempNotCheckedJobs}
}
