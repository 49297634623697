import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar'
import { changeSidebarVisibility } from '../../redux/actions/sidebar';

class Layout extends Component {

    toggleSidebar = () => {
        const { dispatch } = this.props;
        dispatch(changeSidebarVisibility());
    };

    render() {
        const layoutClass = classNames({
            layout: true,
            'layout--collapse': true,
        });
        return (
            <div className={layoutClass}>
                <TopbarWithNavigation toggleSidebar={this.toggleSidebar} />
                <Sidebar />
            </div>
        );
    }
}

export default withRouter(connect(state => ({
    sidebar: state.sidebar,
}))(Layout));
