import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../scss/app.scss';
import Routs from './Router.jsx';
import Loading from '../shared/components/Loading';
import { connect } from "react-redux";
import { checkAuthorization } from '../redux/services/auth';
import { withRouter } from "react-router-dom";

class App extends Component {
    componentDidMount() {
        const { history } = this.props;
        checkAuthorization(history).then(response => response);
    }

    render() {
        const { auth } = this.props;
        return (
            auth.global_loading
                ? <Loading loading={auth.loading} />
                : <Routs user={auth.user} />
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default withRouter(connect(
    mapStateToProps
)(App));
