import React from "react"
import { reduxForm } from 'redux-form';
import { Button } from "reactstrap";
import { Field } from 'redux-form';
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial"

const DayWorkRejectedForm = ({ handleSubmit, Rejected, setModal }) => {
    return (
        <form onSubmit={handleSubmit(data => Rejected(data))}>
            <div className="form__form-group mb-4 mt-4">
                <div className="form__form-group-field d-flex justify-content-between">
                    <div className="create_project_field_wrapper">
                        <Field
                            name="deny_comment"
                            component={TextFieldMaterial}
                            placeholder="Deny reason"
                            label="Deny reason"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
                <Button className="m-0 mr-3" color="secondary" onClick={() => setModal(false)}>Cancel</Button>
                <Button className="m-0" color="primary" type="submit">Confirm</Button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'day_work_rejected_form'
})(DayWorkRejectedForm);