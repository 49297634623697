import React, { Component } from "react";
import SidebarLink from "./SidebarLink";
import SidebarLinkTemplates from "./SidebarLinkTemplates";

class SidebarContent extends Component {
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { sidebar } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarLink
            title="Projects"
            icon="briefcase"
            route="/projects"
            sidebar={sidebar}
          /> */}
           <SidebarLink
            title="Contracts"
            icon="briefcase"
            route="/contracts"
            sidebar={sidebar}
          />
          <SidebarLink
            title="Accounts"
            icon="user"
            route="/accounts"
            sidebar={sidebar}
          />
          <SidebarLink
            title="Commercial"
            icon="user"
            route="/commercial"
            sidebar={sidebar}
          />
          <SidebarLinkTemplates
            title="Admin"
            icon="cog"
            sidebar={sidebar}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
