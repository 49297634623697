import React from 'react'
import { Col } from 'reactstrap'

function JobsCounter({user, typeOfJob}) {
  return (
    <Col
    md={3}
    xl={3}
    className="p-0 m-0 d-flex flex-column align-items-center"
    >
    <div className="lg_wrapper_page-title">
      <p className="page-title m-0">
        {user && user.data && user.data[`${typeOfJob}_jobs_counter`]}
      </p>
    </div>
    <span className="form__form-group-label m-0">{typeOfJob.toUpperCase()}</span>
  </Col>
  )
}

export default JobsCounter