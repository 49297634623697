import {
    loginWithEmailPassword,
    authenticationWithToken,
} from '../api/auth';
import axios from "axios";
import store from "../store";
import { loginUserAction, logoutUserAction, authLoadingAction, authErrorAction, switchRoleAction } from "../actions/auth";
import { setCookie, getCookie, removeCookie } from "../../helpers/session";
import { TOKEN_COOKIE_NAME, REMEMBER_ME } from "../../config/api.js";

export const loginUser = async ({ email, password, remember }, history) => {
    store.dispatch(authLoadingAction(true));
    const response = await loginWithEmailPassword({ email, password });
    if (response && response.token) {
        const { type, accessToken, expiresIn } = response.token;
        axios.defaults.headers.common['Authorization'] = accessToken && type ? `${type} ${accessToken}` : null;
        setCookie(TOKEN_COOKIE_NAME, `${type} ${accessToken}`, expiresIn);

        localStorage.setItem(TOKEN_COOKIE_NAME, accessToken)

        store.dispatch(loginUserAction(response.data))
        setCookie(REMEMBER_ME, !!remember)
        history.push('/');

    } else {
        store.dispatch(authErrorAction(response && response.error ? response.error.message : 'Login error!'));
    }
};

export const logoutUser = history => {
    store.dispatch(authLoadingAction(true));
    store.dispatch(logoutUserAction());
    removeCookie(TOKEN_COOKIE_NAME);
    removeCookie(REMEMBER_ME);

    localStorage.removeItem(TOKEN_COOKIE_NAME)

    history.push('/');
    window.location.reload();
};

export const checkAuthorization = async () => {
    store.dispatch(authLoadingAction(true));
    const token = getCookie(TOKEN_COOKIE_NAME);
    const remember = getCookie(REMEMBER_ME);
    if ((token && remember && remember !== "false") || localStorage.getItem(TOKEN_COOKIE_NAME)) {
        axios.defaults.headers.common['Authorization'] = token ? token : null;
        const user = await authenticationWithToken();
        if (user && user.id) {
            let role = await localStorage.getItem('role');
            role = +role;
            await store.dispatch(loginUserAction(user));
            (role <= user.role || user.super_admin) && await store.dispatch(switchRoleAction(role));
        } else {
            removeCookie(TOKEN_COOKIE_NAME);
            removeCookie(REMEMBER_ME);
            store.dispatch(logoutUserAction());
        }
    } else {
        removeCookie(TOKEN_COOKIE_NAME);
        removeCookie(REMEMBER_ME);
        store.dispatch(logoutUserAction());
    }
};

export const setError = ({ error }) => {
    store.dispatch(authErrorAction(error));
};
