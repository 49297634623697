import React from 'react'
import { Button, Card, CardBody, Col, Modal } from 'reactstrap'
import { deleteJob } from "../../../redux/services/jobs";


function DeleteJobModal({
  isModalOpen,
  toggleModal,
  match,
  job,
  history
}) {

  const jobDelete = () => {
    deleteJob(+match.params.id)
    .then(() => 
      history.push(`/projects/preview-projects/${job.project.id}/jobs`)
    );
  };

  return (
  <Modal
    isOpen={isModalOpen}
    toggle={toggleModal}
    modalClassName={`ltr-support`}
    className={`modal-dialog--primary locum-preview-modal`}
  >
    <div className="modal__header">
      <h3 className="page-title">Delete job</h3>
      <button
        className="lnr lnr-cross modal__close-btn"
        type="button"
        onClick={toggleModal}
      />
    </div>
    <div className="modal__header">
      <h3 className="page-title">Edit job</h3>
      <button
        className="lnr lnr-cross modal__close-btn"
        type="button"
        color="primary"
      />
    </div>
    <div className="modal__body">
      <Col md={12} lg={12} className="p-0 mt-5">
        <Card className="p-0">
          <CardBody className="p-0 pt-4">
            <div>
              <div className="wrapper_page-title mt-3">
                <p className="form__form-group-label m-0">
                  Delete this job?
                </p>
              </div>
            </div>
            <div className="form__form-group m-0">
              <div className="form__form-group-field d-flex justify-content-end">
                <div className="d-flex">
                  <Button
                    className="mb-0"
                    color="secondary"
                    onClick={() => toggleModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mb-0"
                    color="danger"
                    onClick={() => jobDelete()}
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </div>
  </Modal>
  )
}

export default DeleteJobModal