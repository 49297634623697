import React from "react";

const Tooltip = ({ text, children, className }) => {
  return (
    <div className={`reusable-tooltip ${className}`}>
      {children}
      <span className="reusable-tooltip-text">{text}</span>
    </div>
  );
};

export default Tooltip;