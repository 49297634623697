import axios from "axios";
import { BASE_API } from "../../config/api";
import {pageSize, defaultPage} from "../../config/pagination";
export const getRequestsAsync = async (id, filters, page) =>
    await axios.post(`${BASE_API}request/project/${id}/job-requests`, { filters: filters, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const getQARequestsAsync = async (id, filters, page) =>
    await axios.post(`${BASE_API}request/project/${id}/job-qa-requests`, { filters: filters, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const getAdditionalWorkRequestsAsync = async (id, filters, page) =>
    await axios.post(`${BASE_API}request/project/${id}/job-additional-work-requests`, { filters: filters, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const getUninvoicedAdditionalWorkRequestsAsync = async (id, page) =>
    await axios.post(`${BASE_API}job/uninvoiced/additional-requests`, { userId: id, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const getRequestsCounterAsync = async (id) =>
    await axios.get(`${BASE_API}request/project/${id}/job-requests-counter`)
        .then(response => response && response.data)
        .catch(error => error.message);

export const getQARequestsCounterAsync = async (id) =>
    await axios.get(`${BASE_API}request/project/${id}/job-qa-requests-counter`)
        .then(response => response && response.data)
        .catch(error => error.message);

export const getAdditionalWorkRequestsCounterAsync = async (id) =>
    await axios.get(`${BASE_API}request/project/${id}/job-additional-work-requests-counter`)
        .then(response => response && response.data)
        .catch(error => error.message);

export const getDayWorkRequestsAsync = async (id, page, filters) =>
    await axios.post(`${BASE_API}request/project/${id}/day-work-requests`,
        {
        pagination: { page: page ? page : defaultPage, pageSize: pageSize },
                filter: filters ? {...filters } : undefined
        })
        .then(response => response && response.data)
        .catch(error => error.message);

export const getDayWorkCounterAsync = async (id) =>
    await axios.get(`${BASE_API}request/project/${id}/day-work-requests-counter`)
        .then(response => response && response.data)
        .catch(error => error.message);

export const getProjectAdditionalWorkRequestPageableAsync = async (projectId, pagination) =>
    await axios.post(
        `${BASE_API}request/project/${projectId}/additional-work-request`,
        {pagination})
    .then(response => response && response.data)
    .catch(error => error.message);

export const getProjectDayworksWithoutVOAsync = async (projectId, filters, pagination) =>
await axios.post(
    `${BASE_API}request/project/${projectId}/day-work-requests-without-vo`,
    {filter:filters,pagination}
)
.then(response => response && response.data)
.catch(error => error.message);

export const getUnknownRequestsCounterAsync = async (projectId) =>
await axios.get(
    `${BASE_API}request/project/${projectId}/count-unknown-requests`
)
.then(response => response && response.data)
.catch(error => error.message);
