import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Modal, Card, CardBody } from "reactstrap";
import ProjectCreate from "./ProjectCreate";
import ProjectTable from "./Tables/ProjectTable";
import { pushNewCrumbs, pushCrumbs } from "../../redux/actions/bread_crumbs";
import { CollapseIcon, ExpandIcon, CreateIcon } from "../../constants/icons";
import { PROJECT_STATUS } from "../../constants/enamerations";
import {PREVIEW_PROJECT} from "../../constants/routes";

const Projects = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [activeCollapse, setActiveCollpse] = useState({
    [PROJECT_STATUS.DRAFT.value]: {
      active: false,
    },
    [PROJECT_STATUS.ACTIVE.value]: {
      active: false,
    },
    [PROJECT_STATUS.COMPLETED.value]: {
      active: false,
    },
  });

  const toggle = () => setModal(!modal);

  return (
    <Container className="m-0">
      <Row>
        <Col md={12} xl={12} className="d-flex justify-content-between">
          <h1 className="page-title">Projects</h1>
          <Button
            className="btn_with_img"
            color="primary"
            onClick={() => {
              history.push("/projects/createdProjects");
              // toggle();
            }}
          >
            <CreateIcon /> Create project
          </Button>
        </Col>

        <Col md={12} xl={12}>
          <Card>
            <CardBody>
              <Row className="pl-4 pr-4">
                <div
                  className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
                  onClick={() =>
                    setActiveCollpse({
                      ...activeCollapse,
                      [PROJECT_STATUS.DRAFT.value]: {
                        active: !activeCollapse[PROJECT_STATUS.DRAFT.value]
                          .active,
                      },
                    })
                  }
                >
                  <h5 className="page-title m-0">DRAFT PROJECTS</h5>
                  {activeCollapse[PROJECT_STATUS.DRAFT.value].active ? (
                    <CollapseIcon />
                  ) : (
                    <ExpandIcon />
                  )}
                </div>
              </Row>
              {activeCollapse[PROJECT_STATUS.DRAFT.value].active && (
                <Row className="pl-3 pr-3">
                  <ProjectTable
                      href={PREVIEW_PROJECT}
                      status={PROJECT_STATUS.DRAFT.value}
                  />
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-4">
          <Card>
            <CardBody>
              <Row className="pl-4 pr-4">
                <div
                  className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
                  onClick={() =>
                    setActiveCollpse({
                      ...activeCollapse,
                      [PROJECT_STATUS.ACTIVE.value]: {
                        active: !activeCollapse[PROJECT_STATUS.ACTIVE.value]
                          .active,
                      },
                    })
                  }
                >
                  <h5 className="page-title m-0">ACTIVE PROJECTS</h5>
                  {activeCollapse[PROJECT_STATUS.ACTIVE.value].active ? (
                    <CollapseIcon />
                  ) : (
                    <ExpandIcon />
                  )}
                </div>
              </Row>
              {activeCollapse[PROJECT_STATUS.ACTIVE.value].active && (
                <Row className="pl-3 pr-3">
                  <ProjectTable
                      href={PREVIEW_PROJECT}
                      status={PROJECT_STATUS.ACTIVE.value}
                  />
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-4">
          <Card>
            <CardBody>
              <Row className="pl-4 pr-4">
                <div
                  className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
                  onClick={() =>
                    setActiveCollpse({
                      ...activeCollapse,
                      [PROJECT_STATUS.COMPLETED.value]: {
                        active: !activeCollapse[PROJECT_STATUS.COMPLETED.value]
                          .active,
                      },
                    })
                  }
                >
                  <h5 className="page-title m-0">PROJECTS HISTORY</h5>
                  {activeCollapse[PROJECT_STATUS.COMPLETED.value].active ? (
                    <CollapseIcon />
                  ) : (
                    <ExpandIcon />
                  )}
                </div>
              </Row>
              {activeCollapse[PROJECT_STATUS.COMPLETED.value].active && (
                <Row className="pl-3 pr-3">
                  <ProjectTable
                      href={PREVIEW_PROJECT}
                      status={PROJECT_STATUS.COMPLETED.value}
                  />
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Create project</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggle}
            />
          </div>
          <div className="modal__body">
            <ProjectCreate toggle={toggle} />
          </div>
        </Modal>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  pushNewCrumbs,
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Projects)
);
