import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { JOB_STATUS } from '../../../constants/enamerations'
import { ActiveIcon, AssignedToUser, Completed, } from '../../../constants/icons'
import { 
  getContractActiveJobs,
  getContractJobUnderVerification,
  getContractInvoices,
  getContractBudgetAnalytics
 } from '../../../redux/services/contracts';
import Loading from "../../../shared/components/Loader";
import AnalyticsCard from './AnalyticsCard'
import WorkTypesPerContract from './WorkTypesPerContract';

export const ContractAnalytics = ({item, match, contract}) => {
  const [load, setLoad] = useState(true);
  const [contractActiveJobs, setContractActiveJobs] = useState();
  const [qaJobs, setQaJobs] = useState();
  const [invoices, setInvoices] = useState();
  const [budgetAnalytics, setBudgetAnalytics] = useState();

  const previewJobs = () => console.log("preview")

  const totalJobsOfType = (contract, type) => {
   return contract.projects.reduce((acc, project) => acc + parseInt(project[type]), 0)
  }

  const totalJobs = (contract) => {
    return contract.projects.reduce((acc, project) => acc + project.total_jobs , 0)
  }

  useEffect(() => {
    setLoad(true);

    getContractActiveJobs(+match.params.id)
    .then(res => { 
      setContractActiveJobs(res.data);
    })

    getContractJobUnderVerification(+match.params.id)
    .then(res => {
      setQaJobs(res.data)
    })

    getContractInvoices(+match.params.id)
    .then(res => {
      setInvoices(res.data)
    })

    getContractBudgetAnalytics(+match.params.id)
    .then(res => {
      setBudgetAnalytics(res.data)
    })

    setLoad(false);
  },[])

  if(load) return <Loading />

  return (
    <Card>
    <Row>
    <Col md={4} xl={4}>
        <Card>
          <CardBody>
            <div className="" onClick={() => previewJobs()}>
              <div className="wrapper_page-title">
                <h5 className="page-title m-0 d-flex align-items-center">
                  JOBS &nbsp;
                </h5>
              </div>
            </div>
            <Row className="mt-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center "
                onClick={() =>
                  previewJobs()
                }
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    <AssignedToUser />
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <div className="lg_wrapper_page-title">
                    <p className="page-title m-0">
                    {totalJobsOfType(contract, 'assigned_to_user')}
                    </p>
                  </div>
                  <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      ASSIGNED TO USER
                    </span>
                  </div>
                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start "
                onClick={() =>
                  previewJobs()
                }
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    {JOB_STATUS.ASSIGNED_TO_PM.icon}
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <div className="lg_wrapper_page-title">
                    <p className="page-title m-0">
                      {totalJobsOfType(contract, 'assigned_to_pm')}
                    </p>
                  </div>
                  <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      AWAITING APPROVAL
                    </span>
                  </div>
                </Col>
              </Col>
            </Row>
            <Row className="mt-4 mb-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center "
                onClick={() => previewJobs()}
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    <ActiveIcon />
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <div className="lg_wrapper_page-title">
                    <p className="page-title m-0">
                      {totalJobsOfType(contract, 'active')}
                    </p>
                  </div>
                  <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      ACTIVE JOBS
                    </span>
                  </div>
                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start "
                onClick={() => previewJobs()}
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    <Completed />
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <div className="lg_wrapper_page-title_dashboard">
                    <p className="page-title m-0">
                      {totalJobsOfType(contract, 'completed')}
                    </p>
                  </div>
                  <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      COMPLETED JOBS
                    </span>
                  </div>
                </Col>
              </Col>
            </Row>
            {/*new row*/}
            {/* <Row className="mt-4 mb-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center "
                onClick={() => previewJobs()}
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    <DraftIcon />
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                    <div>
                      <div className="lg_wrapper_page-title">
                        <p className="page-title m-0">
                        {'daywork req'}
                        </p>
                      </div>
                      <div className="wrapper_page-title">
                        <span className="form__form-group-label m-0">
                          DAYWORK REQUESTS
                        </span>
                      </div>
                    </div>
                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start "
                onClick={() => previewJobs()}
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <div className="dashboard_icon_wrapper">
                    <QuestionMarkIcon />
                  </div>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <div className="lg_wrapper_page-title">
                    <p className="page-title m-0">
                      {'requests'}
                    </p>
                  </div>
                  <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">REQUESTS</span>
                  </div>
                </Col>
              </Col>
            </Row> */}
            <div className="hr_wrapper">
              <hr />
            </div>

            <WorkTypesPerContract match={match} totalJobs={totalJobs(contract)}/>
          </CardBody>
        </Card>
    </Col>
    <Col md={4} xl={4} className="d-flex flex-column justify-content-between">
      <AnalyticsCard
        title="Work deployment"
        leftText="Assigned operatives"
        rightText="Idle operatives"
        leftCounter={(contractActiveJobs && contractActiveJobs.counter_of_active_users) || 0}
        rightCounter={(contractActiveJobs && contractActiveJobs.counter_of_inactive_users) || 0}
        progressTitle="Total operatives"
        completedCounter={(contractActiveJobs && contractActiveJobs.counter_of_active_users) || 0}
        totalCounter={(contractActiveJobs &&contractActiveJobs.counter_of_inactive_users) || 0}
        completedColor={"blue"}
        totalColor={"gray"}
      />
      <AnalyticsCard
        title="QA Status"
        leftText="Awaiting approval"
        rightText="Active rejections"
        leftCounter={(qaJobs && qaJobs.counter_of_under_verification_jobs) || 0}
        rightCounter={(qaJobs && qaJobs.counter_of_declined_jobs )|| 0}
        progressTitle="Total jobs in verification"
        completedCounter={(qaJobs && qaJobs.counter_of_under_verification_jobs )|| 0}
        totalCounter={(qaJobs && qaJobs.counter_of_declined_jobs )|| 0}
        completedColor={"yellow"}
        totalColor={"red"}
      />
    </Col>
    <Col md={4} xl={4} className="d-flex flex-column justify-content-between">
      <AnalyticsCard
        title={`Invoice status`}
        leftText="Paid invoices"
        rightText="Unpaid invoices"
        leftCounter={(invoices && invoices.invoices_paid) || 0}
        rightCounter={(invoices && invoices.invoices_unpaid) || 0}
        progressTitle="Total invoices"
        completedCounter={(invoices && invoices.invoices_paid) || 0}
        totalCounter={(invoices && invoices.invoices_unpaid) || 0}
        completedColor={"green"}
        totalColor={"red"}
      />
      <AnalyticsCard
        title='Budget'
        leftText="Allocated budget"
        rightText="Spent budget"
        leftCounter={(budgetAnalytics && budgetAnalytics.budget) || 0}
        rightCounter={(budgetAnalytics && budgetAnalytics.spent_budget) || 0}
        progressTitle="Remaining budget"
        completedCounter={(budgetAnalytics && budgetAnalytics.budget) || 0}
        totalCounter={(budgetAnalytics && budgetAnalytics.spent_budget) || 0}
        completedColor={"blue"}
        totalColor={"gray"}
      />
    </Col>
    </Row>
    
    </Card>
  )
}

export default ContractAnalytics
