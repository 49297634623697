import React from 'react'
import Moment from 'react-moment'
import {getDayworkUserInfo} from "../../../../helpers/dayworks";

const DayworkLogRow = ({dayworkLog, onInvoicePressed}) => {
    return <tr key={dayworkLog.id} >
        <td className="td-project">
            <div className="d-flex">
                {dayworkLog.action_description}
                {dayworkLog.action === 'DW_INVOICED' && dayworkLog.invoice && dayworkLog.invoice.id &&
                    <div className={"ml-2"} onClick={() => onInvoicePressed(dayworkLog.invoice.id)} style={{color: '#70bbfd'}}>
                        {`#${dayworkLog.invoice.id}`}
                    </div>
                }
            </div>
        </td>
        <td>
            {getDayworkUserInfo(dayworkLog)}
        </td>
        <td>
            <Moment format="DD-MM-YYYY HH:mm">
                {dayworkLog.created_at}
            </Moment>
        </td>
    </tr>
}

export default DayworkLogRow
