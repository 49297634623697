export const voItemStatuses = {
  RESOLVED:'RESOLVED',
  UNRESOLVED:'UNRESOLVED',
}

export const labelsForInstructionsStatus = {
  [null]:'Not agreed with client',
  [undefined]:'Not agreed with client',
  RESOLVED:'Agreed with client',
  UNRESOLVED:'Not agreed with client',
}

export const instructionsStatusDropDown = [
  {
    value:'UNRESOLVED',
    label:'Not agreed with client'
  },
  {
    value:'RESOLVED',
    label:'Agreed with client'
  },
]

export const valuesForStatuses = {
  UNRESOLVED:'UNRESOLVED',
  RESOLVED:'RESOLVED',
  [null]:'UNRESOLVED',
  [undefined]:'UNRESOLVED',
}

export const labelsForTypes = {
  'CONTRA_CHARGER':'Contra Charge',
  'CLIENT_INSTRUCTION':'Client instruction',
  'LMEP_FAULT':'LMEP Cost',
}