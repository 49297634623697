import React, { useEffect } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import store from "../../../../redux/store";
import MiniTextField from "./MiniTextField";
import { submit } from "redux-form";

const required = (value) => (value ? undefined : "You must enter a value");

let EditFlatForm = ({ handleSubmit, flatId }) => {
  function initializeEditFlatForm() {
    store.dispatch(
      initialize("edit_flat_form", {
        flatId: flatId,
      })
    );
  }

  useEffect(() => {
    initializeEditFlatForm();
  }, []);

  return (
    <div>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
          event.stopPropagation();
        }}
      >
        <Field
          name={`flatId`}
          component={MiniTextField}
          type={"text"}
          validate={required}
          onBlur={() => {
            store.dispatch(submit("edit_flat_form"));
          }}
        />

        <button type="submit" hidden></button>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "edit_flat_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditFlatForm);
