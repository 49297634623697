import React from "react";
import { TextField } from "@material-ui/core";

const TextFieldMaterial = ({
  input: { value, onChange },
  label,
  placeholder,
  type,
  meta: { touched, error },
  ...props
}) => {
  const handleChange = (value) => {
    onChange(value);
  };

  const getValue = (value) => {
    return value;
  };

  return (
    <>
      <TextField
        placeholder={placeholder}
        label={label}
        value={getValue(value)}
        onChange={handleChange}
        type={type ? type : "text"}
        {...props}
      />

      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </>
  );
};

export default TextFieldMaterial;
