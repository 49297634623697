import React from 'react'
import { Col } from 'reactstrap'
import { SettingsIcon } from '../../../constants/icons'

function TitleAndEditButton({user, setModal}) {
  return (
    <Col md={5} xl={5} className="d-flex align-items-center mb-4">
    <Col md={1.5} xl={1.5} className="p-0">
      <h5 className="page-title m-0">
        {user &&
          user.data &&
          user.data.first_name + " " + user.data.surname}
      </h5>
    </Col>
    <Col
      md={1.5}
      xl={1.5}
      className="p-0 ml-3"
      onClick={() => setModal(true)}
    >
      <div className="hover">
        <SettingsIcon />
      </div>
    </Col>
    </Col>
  )
}

export default TitleAndEditButton