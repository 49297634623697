import {
    PUSH_CRUMBS,
    PUSH_NEW_CRUMBS,
    BACK_TO_CRUMBS,
    POP_CRUMBS
} from "../actions";


const INIT_STATE = {
    bread_crumbs: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PUSH_CRUMBS:
            return { ...state, bread_crumbs: [...state.bread_crumbs, action.payload] };
        case PUSH_NEW_CRUMBS:
            return { ...state, bread_crumbs: [action.payload] };
        case BACK_TO_CRUMBS:
            return { ...state, bread_crumbs: [...action.payload] };
        case POP_CRUMBS:
            return { ...state, bread_crumbs: [...action.payload] };
        default:
            return { ...state };
    }
}