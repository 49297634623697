import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import App from './views/App.jsx';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import store from './redux/store';

render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
