import React from "react";
import { Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { pageSize } from "../../../config/pagination";
import { WORK_TYPE } from "../../../constants/enamerations";

const UnvoicedAdditionalWorkRequestsTable = ({ requests, page, setPage, previewJob }) => {

    return (
        <>
            <Table responsive hover className="table react-table table--bordered">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>TRADE</th>
                    <th>NAME</th>
                    <th>OPERATIVE</th>
                    <th>PROJECT MANAGER</th>
                    <th>BLOCK</th>
                    <th>LEVEL</th>
                    <th>UNIT TYPE</th>
                    <th>UNIT ID</th>
                </tr>
                </thead>
                <tbody>
                {requests &&
                    requests.items &&
                    requests.items.map((item, index) => (
                        <tr key={index} onClick={() => previewJob(item.jobs)}>
                            <td>{item && item.jobs.id}</td>
                            <td>{item && WORK_TYPE[item.jobs.job_type].bigIcon}</td>
                            <td>{item && item.jobs.description}</td>
                            <td>
                                {item.jobs &&
                                    item.jobs.employee &&
                                    item.jobs.employee.first_name + " " + item.jobs.employee.surname}
                            </td>
                            <td>
                                {item.jobs &&
                                    item.jobs.pm &&
                                    item.jobs.pm.first_name + " " + item.jobs.pm.surname}
                            </td>
                            <td>{item.jobs && item.jobs.block && item.jobs.block.name}</td>
                            <td>{item.jobs && item.jobs.level.name}</td>
                            <td>{item.jobs && item.jobs.unit_type}</td>
                            <td>{item.jobs && item.jobs.plot.name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {requests && Math.ceil(requests.total / pageSize) >= 2 && (
                <div className="box-pagination">
                    <Pagination
                        count={
                            requests && requests.total
                                ? Math.ceil(requests.total / pageSize)
                                : 0
                        }
                        page={page + 1}
                        onChange={(event, page) => setPage(page - 1)}
                    />
                </div>
            )}
        </>
    );
};

export default UnvoicedAdditionalWorkRequestsTable;
