import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

class MainWrapper extends React.Component {

  render() {
    const {children
    } = this.props;

    const wrapperClass = classNames({
      wrapper: true,
    });

    return (
      <div className="theme-light ltr-support" dir='ltr'>
        <div className={wrapperClass}>
          {children}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  theme: state.theme,
  rtl: state.rtl,
  customizer: state.customizer,
}))(MainWrapper));
