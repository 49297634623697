import React, { useState } from 'react'
import {instructionsStatusDropDown} from '../../../constants/variation_orders'
import Select from 'react-select'
import { addVoCategoryToDayWorks } from '../../../redux/services/day_work'
import { addVoCategoryToAdditionalWork } from '../../../redux/services/variation_orders'

const InstructionStatusDropdown = ({itemId, itemStatus, type}) => {
  const [value, setValue] = useState(itemStatus && itemStatus.value)

  const updateItemVoCategory = (value) => {
    setValue(value)

    if(type === 'daywork'){
      return addVoCategoryToDayWorks(itemId, {
        instructions_status: value
      })
    }

    if(type === 'additionalWork'){
      return addVoCategoryToAdditionalWork(itemId, {
        instructions_status: value
      })
    }
  }

  return (
    <Select
      options={instructionsStatusDropDown}
      defaultValue={
      itemStatus && itemStatus.value 
      ? itemStatus
      : instructionsStatusDropDown[0]}
      onChange={(selectedOption) => updateItemVoCategory(selectedOption.value)}
      styles={{
        control: (base, state) => (
          {
          ...base,
          width:'175px',
          height:'25px',
          padding:0,
          margin:0,
          fontSize:'12px',
          border: '1px solid rgba(0,0,0, 0.2)',
          ...(value === 'RESOLVED' 
          ? {backgroundColor:'rgba(15, 199, 88, 0.1)'} 
          : {backgroundColor:'rgba(255,	68,	68, 0.1)'})
          }
        ),
        option: (base, state) => ({
          ...base,
          width:'175px',
          overflow:'visible',
        }),
        input:(base, state) => ({
          ...base,
          overflow:'visible',
        }),
        menu: (base, state) => ({
          ...base,
          width:'175px',
        }),
        multiValue: (base, state) => ({
          ...base,
          width:'175px',
        }),
      }}
    />
  )
}

export default InstructionStatusDropdown