import React from 'react'
import { Button, Col } from 'reactstrap'
import { DeleteIcon } from '../../../constants/icons'

function DeleteAccountButton({toggleModalDelete}) {
  return (
    <Col md={1.5} xl={1.5} className="p-0">
    <Button
      className="btn_with_img"
      color="danger"
      onClick={toggleModalDelete}
    >
      <DeleteIcon /> Delete account
    </Button>
    </Col>
  )
}

export default DeleteAccountButton