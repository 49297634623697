import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import SelectFieldNoValidate from "../../shared/components/SelectFieldNoValidate";
import {
  INVOICE_STATUS_OPTIONS,
  TIME_PERIODS_OPTIONS,
  PAGE_SIZE_OPTIONS,
} from "../../constants/options";

const InvoiceFilterForm = ({
  filter,
  setFilter,
  setPage,
  setPageSize,
  projects,
  type,
  setProjectId,
}) => {
  const [projectOptions, setProjectOptions] = useState([]);

  const invoiceStatus = (data) => {
    setFilter({ ...filter, status: data ? data : "" });
    setPage(0);
  };
  const invoicePeriod = (data) => {
    setFilter({ ...filter, date: data ? data : "" });
    setPage(0);
  };

  const chooseProject = (id) => {
    setProjectId(id);
    if (id === "all") {
      setFilter({ ...filter, project_ids: "all" });
    } else {
      setFilter({ ...filter, project_ids: id ? [id] : null });
    }

    setPage(0);
  };

  const getProjects = () => {
    const tempProjectOptions = [];

    if (type !== 'USER') {
      tempProjectOptions.push({ value: "all", label: "All projects" });
    }

    projects.items.map((item) =>
      tempProjectOptions.push({ value: item.id, label: item.name })
    );
    setProjectOptions(tempProjectOptions);
  };

  useEffect(() => {
    projects && projects.items && getProjects();
  }, [projects]);

  return (
    <form>
      <Col md={12} xl={12} className="p-0 d-flex justify-content-start">
        <div className="form__form-group mr-3">
          <div className="form__form-group-field">
            <Field
              name="status"
              component={SelectFieldNoValidate}
              placeholder="Status"
              options={INVOICE_STATUS_OPTIONS}
              onChange={(data) => invoiceStatus(data)}
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="date"
              component={SelectFieldNoValidate}
              placeholder="Period"
              options={TIME_PERIODS_OPTIONS}
              onChange={(data) => invoicePeriod(data)}
            />
          </div>
        </div>

        <div className="form__form-group ml-3">
          <div className="form__form-group-field">
            <Field
              name="project"
              component={SelectFieldNoValidate}
              placeholder="Project"
              options={projectOptions}
              onChange={(data) => chooseProject(data)}
            />
          </div>
        </div>

        <div className="form__form-group ml-3">
          <div className="form__form-group-field">
            <Field
              name="pageSize"
              component={SelectFieldNoValidate}
              placeholder="Row limit"
              options={PAGE_SIZE_OPTIONS}
              onChange={(data) => setPageSize(data)}
            />
          </div>
        </div>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "invoice_filter_form",
})(InvoiceFilterForm);
