import React from 'react'
import { Button, Modal } from 'reactstrap'
import { unassignDayworkVO } from '../../../../redux/services/day_work'
import { removeAdditionalWorkVO} from '../../../../redux/services/variation_orders'

const UnassignRequestModal = ({
  modal,
  toggleModal,
  selectedItem,
  refetch,
}) => {
  const { itemId, itemType } = selectedItem

  const handleUnassign = () => {
    if(itemType === 'daywork'){
      unassignDayworkVO(itemId);
    } 

    if(itemType === 'budget variation'){
      removeAdditionalWorkVO(itemId);
    }
    toggleModal();
    refetch();
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog-centered`}
    >
      <div className="modal__header">
        <h3 className="page-title">Unassign {itemType}</h3>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleModal}
        />
      </div>
      <div className="modal__body mt-5 pt-5">
        <h4>
        Are you sure you want to unassign the {itemType} with id <b>{itemId}</b>?
        </h4>
        <div className={'d-flex flex-direction-row justify-content-around w-full mt-3'}>
          <Button
            color='secondary'
            type='button'
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            type='button'
            color='primary'
            onClick={handleUnassign}
          >
            Unassign
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UnassignRequestModal