import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import {
  ActiveIcon,
  Completed,
  AssignedToUser,
  QuestionMarkIcon,
  LinkIcon,
  RequestsIndicator,
  DraftIcon,
} from "../../../../constants/icons";
import { JOB_STATUS } from "../../../../constants/enamerations";
import WorkDeployment from "./WorkDeployment";
import QAStatus from "./QAStatus";
import InvoicesStatus from "./InvoicesStatus";
import BudgetStatus from "./BudgetStatus";
import WorkTypeStatus from "./WorkTypeStatus";
import {
  getRequestsCounter,
  getQARequestsCounter,
  getAdditionalWorkRequestsCounter,
  getDayWorkCounter,
} from "../../../../redux/services/requests";
import Loading from "../../../../shared/components/MiniLoader";
import {DAY_WORK, JOBS, REQUESTS} from "../../../../constants/routes";

const index = ({
  item,
  previewJobs,
  match,
}) => {
  const [counterJob, setCounterJob] = useState(0);
  const [counterQa, setCounterQa] = useState(0);
  const [counterAw, setCounterQw] = useState(0);
  const [dayWorkCounter, setDayWorkCounter] = useState(0);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getRequestsCounter(match.params.id).then((res) =>
      setCounterJob(+res.data.counter)
    );
    getQARequestsCounter(match.params.id).then((res) =>
      setCounterQa(+res.data.counter)
    );
    getAdditionalWorkRequestsCounter(match.params.id).then((res) =>
      setCounterQw(+res.data.counter)
    );
    getDayWorkCounter(match.params.id).then((res) => {
      setDayWorkCounter(res.data.counter ? res.data.counter: 0);
      setLoad(false);
    });
  }, []);

  return (
    <>
      <Col md={4} xl={4}>
        <Card>
          <CardBody>
            <a href={JOBS.replace(':id',item.id).replace(':status', 'ALL')}>
              <div className="wrapper_page-title">
                <h5 className="page-title m-0 d-flex align-items-center">
                  JOBS &nbsp; <LinkIcon />
                </h5>
              </div>
            </a>
            <Row className="mt-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ASSIGNED_TO_USER.value)}>
                    <div className="dashboard_icon_wrapper">
                      <AssignedToUser />
                    </div>
                  </a>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ASSIGNED_TO_USER.value)}>
                    <div className="lg_wrapper_page-title">
                      <p className="page-title m-0">
                        {item && item.assign_to_user_jobs_counter}
                      </p>
                    </div>
                    <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      ASSIGNED TO USER
                    </span>
                    </div>
                  </a>

                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ASSIGNED_TO_PM.value)}>
                    <div className="dashboard_icon_wrapper">
                      {JOB_STATUS.ASSIGNED_TO_PM.icon}
                    </div>
                  </a>

                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ASSIGNED_TO_PM.value)}>
                    <div className="lg_wrapper_page-title">
                      <p className="page-title m-0">
                        {item && item.assign_to_pm_jobs_counter}
                      </p>
                    </div>
                    <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      AWAITING APPROVAL
                    </span>
                    </div>
                  </a>

                </Col>
              </Col>
            </Row>
            <Row className="mt-4 mb-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ACTIVE.value)}>
                    <div className="dashboard_icon_wrapper">
                      <ActiveIcon />
                    </div>
                  </a>

                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.ACTIVE.value)}>
                    <div className="lg_wrapper_page-title">
                      <p className="page-title m-0">
                        {item && item.active_jobs_counter}
                      </p>
                    </div>
                    <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      ACTIVE JOBS
                    </span>
                    </div>
                  </a>
                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.COMPLETED.value)}>
                    <div className="dashboard_icon_wrapper">
                      <Completed />
                    </div>
                  </a>

                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={JOBS.replace(':id',item.id).replace(':status', JOB_STATUS.COMPLETED.value)}>
                    <div className="lg_wrapper_page-title_dashboard">
                      <p className="page-title m-0">
                        {item && item.completed_jobs_counter}
                      </p>
                    </div>
                    <div className="wrapper_page-title">
                    <span className="form__form-group-label m-0">
                      COMPLETED JOBS
                    </span>
                    </div>
                  </a>

                </Col>
              </Col>
            </Row>
            {/*new row*/}
            <Row className="mt-4 mb-4 d-flex justify-content-between">
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-center hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={DAY_WORK.replace(':id', item.id)}>
                    <div className="dashboard_icon_wrapper">
                      <DraftIcon />
                    </div>
                  </a>

                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={DAY_WORK.replace(':id', item.id)}>
                    {load ? (
                        <Loading />
                    ) : (
                        <div>
                          <div className="lg_wrapper_page-title">
                            <p className="page-title m-0">{dayWorkCounter}</p>
                          </div>
                          <div className="wrapper_page-title">
                        <span className="form__form-group-label m-0">
                          DAYWORK REQUESTS
                        </span>
                          </div>
                        </div>
                    )}
                  </a>

                </Col>
              </Col>
              <Col
                md={5}
                xl={5}
                className="p-0 d-flex justify-content-start hover"
              >
                <Col
                  md={5}
                  xl={5}
                  className="p-0 d-flex align-items-center justify-content-center"
                >
                  <a href={REQUESTS.replace(':id', item.id)}>
                    <div className="dashboard_icon_wrapper">
                      <QuestionMarkIcon />
                    </div>
                  </a>
                </Col>
                <Col
                  md={5}
                  xl={5}
                  className="p-0 m-0 d-flex flex-column align-items-start justify-content-center"
                >
                  <a href={REQUESTS.replace(':id', item.id)}>
                    <div className="lg_wrapper_page-title">
                      <p className="page-title m-0">
                        NEW{" "}
                        {counterAw + counterQa + counterJob > 0 && (
                            <RequestsIndicator />
                        )}{" "}
                      </p>
                    </div>
                    <div className="wrapper_page-title">
                      <span className="form__form-group-label m-0">REQUESTS</span>
                    </div>
                  </a>

                </Col>
              </Col>
            </Row>
            <div className="hr_wrapper">
              <hr />
            </div>

            <WorkTypeStatus id={item && item.id} />
          </CardBody>
        </Card>
      </Col>
      <Col md={4} xl={4} className="d-flex flex-column justify-content-between">
        <Card className="h-auto">
          <CardBody>
            <WorkDeployment id={item && item.id} />
          </CardBody>
        </Card>

        <Card className="h-auto mt-4">
          <CardBody>
            <QAStatus item={item} />
          </CardBody>
        </Card>
      </Col>
      <Col md={4} xl={4} className="d-flex flex-column justify-content-between">
        <Card className="h-auto">
          <CardBody className="hover">
            <InvoicesStatus id={item && item.id} />
          </CardBody>
        </Card>

        <Card className="h-auto mt-4">
          <CardBody>
            <BudgetStatus id={item && item.id} />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default withRouter(index);
