import React from 'react'
import Loading from "../../shared/components/Loader";
import moment from "moment";
import { billText, unixTime } from "../../config/validationRules";
import Moment from "react-moment";
import { CIS_STATUS } from "../../constants/enamerations";
import {calculateDayWorkHourCost} from "../../config/func";
import {formatPrice} from "../../helpers/function";

function InvoicePrintingContent({
  invoiceItem,
  loadInvoiceModal,
  getTotalJob,
  userIsVatRegistered,
  invoiceNetTotal,
  invoiceVatTotal,
  invoiceCisDeduction,
}) {

const areMoreThan6Items = invoiceItem => {
 let itemNumber = 0;
 if(invoiceItem.data.jobs && invoiceItem.data.jobs.length){
    itemNumber += invoiceItem.data.jobs.length;
 }
 if(invoiceItem.data.day_works && invoiceItem.data.day_works.length){
  itemNumber += invoiceItem.data.day_works.length;
 }

 return (itemNumber >= 6)
}

 return (
    <>
      <div className="modal_invoice print_wrapper vw-100">
          {loadInvoiceModal ? (
            <Loading />
          ) : (
            <div>
              <div className='print_invoice_header'>
                  <h3 className="print_invoice_title">Invoice # {invoiceItem.data.id}</h3>
              </div>
              <div className={
                areMoreThan6Items(invoiceItem)
                ? "print-invoice-long-content-body"
                : "print-invoice-short-content-body"
                }>
                <div className="modal_body__header d-flex justify-content-between p-4 rounded-sm">
                  <div className="modal-info d-flex flex-column ">
                    <h4 className="text-left name">
                      {invoiceItem.data.user.first_name} &nbsp;{" "}
                      {invoiceItem.data.user.surname}
                    </h4>
                    <div className="person-info d-flex  justify-content-between mt-2">
                      <div className="title d-flex flex-column justify-content-between text-left mr-2">
                        <span>Project name</span>
                        <span>UTR</span>
                        <span>VAT no</span>
                        <span>Address</span>
                      </div>
                      <div className="info d-flex flex-column text-left">
                        <span>{invoiceItem.data.project.name}</span>
                        <span>{invoiceItem.data.user.utr}</span>
                        <span>
                          {invoiceItem.data.user.vat_number ? (
                            invoiceItem.data.user.vat_number
                          ) : (
                            <p className="h8" />
                          )}
                        </span>
                        <span>
                          {invoiceItem.data.user.city} &nbsp;
                          {invoiceItem.data.user.street} &nbsp;
                          {invoiceItem.data.user.house_number}
                        </span>
                      </div>
                    </div>
                    <div className="date d-flex justify-content-between">
                      <div className="current text-left mr-2">
                        <h4 className="mb-2 date-title">DATE</h4>
                        <span>
                          {moment
                            .unix(invoiceItem.data.created_at)
                            .format("DD.MM.YYYY")}
                        </span>
                      </div>
                      <div className="till text-left">
                        <h4 className="mb-2 date-title">DATE DUE</h4>
                        <span>
                          {moment
                            .unix(invoiceItem.data.created_at + unixTime)
                            .format("DD.MM.YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="date-bill">
                    <div className="bill text-left mt-2">
                      <h4 className="mb-2 bill-title">BILL TO</h4>
                      <div className="d-flex flex-column">
                        <span>{billText.row1}</span>
                        <span className="row2">{billText.row2}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {invoiceItem.data.jobs && invoiceItem.data.jobs.length > 0 && (
                  <div>
                    <div className="job-description d-flex justify-content-between">
                      <span>Job description</span>
                      <span>Amount</span>
                    </div>
                    <div className="line" />
                  </div>
                )}

                {invoiceItem.data.jobs &&
                  invoiceItem.data.jobs.map((job, index) => (
                    <div className="jobs" key={index}>
                      <div className="jobs-body d-flex justify-content-between">
                        <p className="description">
                          {job.description} <span># {job.id}</span>
                        </p>
                        <b>₤ {getTotalJob(job).toFixed(2)}</b>
                      </div>

                      <div className=" d-flex justify-content-start box">
                        <div className="d-flex align-items-center">
                          <p className="m-0 text-nowrap">
                            <span style={{ color: "#999999" }}>Date:</span>{" "}
                            <Moment format={"DD.MM.YYYY"}>
                              {job.submitted_at}
                            </Moment>
                          </p>
                        </div>
                      </div>

                      <div className={"taxBody"}>
                        {userIsVatRegistered ? (
                          <div className="d-flex justify-content-between">
                            <p className="description">
                              VAT ({invoiceItem.data.project.vat.rate}%)
                            </p>
                            <b>
                              ₤
                              {invoiceItem.data.project.vat.rate &&
                                (
                                  (invoiceItem.data.project.vat.rate *
                                    getTotalJob(job)) /
                                  100
                                ).toFixed(2)}
                            </b>
                          </div>
                        ) : null}

                        <div className="d-flex justify-content-between">
                          <p className="description">
                            CIS Deduction (
                            {getTotalJob(job) *
                              CIS_STATUS[invoiceItem.data.user.cis_status]
                                .value ===
                            0
                              ? 0 + "%"
                              : (getTotalJob(job) *
                                  CIS_STATUS[invoiceItem.data.user.cis_status]
                                    .value *
                                  100) /
                                  getTotalJob(job).toFixed(2) +
                                "%"}
                            )
                          </p>
                          <b>
                            ₤
                            {(
                              getTotalJob(job) *
                              CIS_STATUS[invoiceItem.data.user.cis_status].value
                            ).toFixed(2)}
                          </b>
                        </div>
                      </div>

                      <div className="box mb-2 d-flex justify-content-between w-75">
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Level &nbsp;
                          </h3>
                          <p className="m-0">{job.level && job.level.name}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Block &nbsp;
                          </h3>
                          <p className="m-0">{job.block && job.block.name}</p>
                        </div>
                        {job.unit_type && (
                          <div className="d-flex align-items-center">
                            <h3 className="page-subhead subhead m-0">
                              Unit type &nbsp;
                            </h3>
                            <p className="m-0">{job.unit_type}</p>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Unit ID &nbsp;
                          </h3>
                          <p className="m-0">{job.plot && job.plot.name}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Cost code &nbsp;
                          </h3>
                          <p className="m-0">{job.sage_reference && job.sage_reference}</p>
                        </div>
                      </div>

                      {job.additional_work && job.additional_work.length > 0 && (
                        <div className="additional-work">
                          <div className="work d-flex justify-content-between">
                            <p>Additional works</p>
                          </div>
                          {job.additional_work &&
                            job.additional_work.map((work, index) => (
                              <div
                                className="work additional-work-body d-flex justify-content-between"
                                key={index}
                              >
                                <p className="description">
                                  {work.description} <span># {work.id}</span>
                                </p>
                                <span>
                                  ₤ {parseInt(work.budget).toFixed(2)}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}

                      <div className="line" />
                    </div>
                  ))}

                {invoiceItem.data.day_works &&
                  invoiceItem.data.day_works.length > 0 && (
                    <div className="job-description d-flex justify-content-between">
                      <span>Day Work description</span>
                      <span>Amount</span>
                    </div>
                  )}
                <div className="line" />
                {invoiceItem.data.day_works &&
                  invoiceItem.data.day_works.map((day_work, index) => {
                      const {cost, cis, vat} = calculateDayWorkHourCost({startDate: day_work.date_from,
                          endDate: day_work.date_to,
                          startTime: day_work.start_at,
                          endTime: day_work.end_at,
                          dayworkHour: day_work.hourly_rate,
                          userHourRate:  invoiceItem.data.user && invoiceItem.data.user.hourly_rate ? invoiceItem.data.user.hourly_rate : undefined,
                          budget: day_work.budget,
                          cis: invoiceItem.data.user && invoiceItem.data.user.cis_status ? CIS_STATUS[invoiceItem.data.user.cis_status].value * 100 : undefined,
                          vat:invoiceItem.data.project && invoiceItem.data.project.vat && invoiceItem.data.project.vat.rate ? invoiceItem.data.project.vat.rate : undefined
                      })

                      return   <div className="jobs" key={index}>
                          <div className="jobs-body d-flex justify-content-between">
                              <div>
                                  <p className="description">
                                      {day_work.description} <span># {day_work.id}</span>
                                  </p>
                              </div>

                              <b className="text-nowrap">
                                  {formatPrice(cost)}
                              </b>
                          </div>

                          <div className=" d-flex justify-content-start box">
                              <div className="d-flex align-items-center">
                                  <p className="m-0 text-nowrap">
                                      <span style={{ color: "#999999" }}>Date:</span>{" "}
                                      <Moment format={"DD.MM.YYYY"}>
                                          {day_work.date_from}
                                      </Moment>{" "}
                                      -{" "}
                                      <Moment format={"DD.MM.YYYY"}>
                                          {day_work.date_to}
                                      </Moment>
                                  </p>
                              </div>
                              <div className="d-flex align-items-center ml-2">
                                  <p className="m-0 text-nowrap">
                                      <span style={{ color: "#999999" }}>Time:</span>{" "}
                                      {day_work.start_at.slice(0, -3)} -{" "}
                                      {day_work.end_at.slice(0, -3)}
                                  </p>
                              </div>
                          </div>

                          <div className={"taxBody"}>
                              {userIsVatRegistered ? (
                                  <div className="d-flex justify-content-between">
                                      <p className="description">
                                          VAT ({invoiceItem.data.project.vat.rate}%)
                                      </p>
                                      <b>
                                          {formatPrice(vat)}
                                      </b>
                                  </div>
                              ) : null}

                              <div className="d-flex justify-content-between">
                                  <p className="description">
                                      CIS Deduction ({Number(CIS_STATUS[invoiceItem.data.user.cis_status].value) * 100}%)
                                  </p>
                                  <b>
                                      {formatPrice(cis)}
                                  </b>
                              </div>
                          </div>

                          <div className=" d-flex justify-content-between w-25 box">
                              <div className="d-flex align-items-center">
                                  <h3 className="page-subhead subhead m-0">
                                      Level &nbsp;
                                  </h3>
                                  <p className="m-0">
                                      {day_work.levels && day_work.levels.name}
                                  </p>
                              </div>
                              <div className="d-flex align-items-center">
                                  <h3 className="page-subhead subhead m-0">
                                      Block &nbsp;
                                  </h3>
                                  <p className="m-0">
                                      {day_work.levels &&
                                          day_work.levels.block &&
                                          day_work.levels.block.name}
                                  </p>
                              </div>
                          </div>
                          <div className="line" />
                      </div>
                  })}
                <div className="box-subtotal">
                  <div className="subtotal d-flex justify-content-between">
                    <p>NET TOTAL</p>
                      <span>{formatPrice(invoiceNetTotal)}</span>
                  </div>

                  {userIsVatRegistered ? (
                    <div className="vat d-flex justify-content-between">
                      <p>VAT TOTAL</p>
                        <span>{formatPrice(invoiceVatTotal)}</span>
                    </div>
                  ) : null}

                  <div className="vat d-flex justify-content-between">
                    <p>CIS DEDUCTION</p>
                      <span>{formatPrice(invoiceCisDeduction)}</span>
                  </div>

                  {invoiceItem.data.project.vat.drc ? (
                    <div className="vat d-flex justify-content-between">
                      <p>DRC applies - VAT to be paid by Customer</p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="modal__footer d-flex justify-content-between">
                <span>
                  TOTAL (
                  {invoiceItem.data.project.vat.drc || !userIsVatRegistered
                    ? "NET - CIS"
                    : "NET + VAT - CIS"}
                  )
                </span>
                <span>
                  ₤{" "}
                  {invoiceItem.data.project.vat.drc
                    ? (invoiceNetTotal - invoiceCisDeduction).toFixed(2)
                    : (
                        invoiceNetTotal +
                        invoiceVatTotal -
                        invoiceCisDeduction
                      ).toFixed(2)}
                </span>
              </div>
            </div>
          )}
        </div>

    </>
  )
}

export default InvoicePrintingContent
