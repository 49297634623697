import React, { useState, useEffect } from "react"
import CircularProgressBar from "../../../../shared/components/progress/CircularProgressBar"
import Loading from "../../../../shared/components/Loader"
import { getWorkTypeAnalytics } from "../../../../redux/services/projects"
import { WORK_TYPE } from "../../../../constants/enamerations"

const WorkTypeStatus = ({ id }) => {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setLoad(true)
        getWorkTypeAnalytics(id)
            .then(res => {
                setLoad(false)
                setData(res.items)
            })
    }, [])

    return (
        load
            ? <Loading />
            : <div className="dashboard_progress_bar_wrapper mt-4">
                {data && data.map((item, index) => (
                    <div key={index} className="progress_bar_group">
                        <CircularProgressBar color={WORK_TYPE[item.job_type].color} value={Math.round((item[item.job_type] * 100) / item.total)} />
                        <div className="wrapper_page-title mt-3">
                            <p className="form__form-group-label m-0">{WORK_TYPE[item.job_type].label}</p>
                        </div>
                    </div>
                ))}
            </div>
    )
}

export default WorkTypeStatus