import React, {useState, useEffect, useCallback, useMemo} from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CardBody, Col, Modal, Row, Table } from "reactstrap";
import Moment from "react-moment";
import Loading from "../../../../shared/components/Loader";
import { pushCrumbs } from "../../../../redux/actions/bread_crumbs";
import { getProjectDayworksWithoutVO } from "../../../../redux/services/requests";
import {CalendarIcon, CustomCreateIcon, SortOrderIcon} from "../../../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import { DAY_WORK_STATUS } from "../../../../constants/enamerations";
import PhotoViewer from "../../../../shared/components/PhotoViewer";
import DayWorkForm from "../../../Accounts/Forms/DayWorkForm";
import {calculateDayWorkHourCost} from "../../../../config/func";
import { labelVariationOrder } from "../../../../helpers/variation_order";
import {formatPrice} from "../../../../helpers/function";
import {debounce} from "lodash";
import SearchIcon from "mdi-react/SearchIcon";

const PAGE_SIZE = 10;

const DayWorkTableByProject = ({
  projectId,
  projectName,
  contractName,
  addDayWorkToVo,
  variationOrder,
  dayworksAddedToVo,
   }) => {

  //New State
  const [loadingDayworks, setLoadingDayworks] = useState(false);
  const [dayworks, setDayworks] = useState([]);
  const [dayworksCurrentPage, setDayworksCurrentPage] = useState(0);
  const [dayworksTotalElements, setDayworksTotalElements] = useState(0);
  const [dayworksPageCounts, setDayworksPageCounts] = useState(0);
  const [filters, setFilters] = useState({search: ''})
  const [sortFilter, setSortFilter] = useState(null)
  const [currentDayWork, setCurrentDayWork] = useState(null);
  const [showReviewButtons, setShowReviewButtons] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [moreImageID, setMoreImageID] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const getDayWorkRequest = (currentPage, filters) => {
    getProjectDayworksWithoutVO(projectId,filters, {
      page:!!currentPage ? currentPage : 0,
      pageSize:PAGE_SIZE,
    }).then((res) => {
      setDayworks(res.items)
      setDayworksTotalElements(res.total)
      setLoadingDayworks(false);
    });
  };

  useEffect(() => {
    if(Object.keys(dayworksAddedToVo).length === 0){
      setLoadingDayworks(true);
      getDayWorkRequest(0, {...filters, search: ''})
    }
  }, [dayworksAddedToVo])


  useEffect(() => {
    if(sortFilter){
      if(sortFilter === 'status_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.status',dateOrder: 'DESC' }
        setFilters(tempFilters)
      } else if(sortFilter === 'status_asc'){
        const tempFilters = {...filters, orderBy: 'dayWork.status',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'name_desc'){
        const tempFilters = {...filters, orderBy: 'creator.first_name',dateOrder: 'DESC' }
        setFilters(tempFilters)
      } else if(sortFilter === 'name_asc'){
        const tempFilters = {...filters, orderBy: 'creator.first_name',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'date_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.date_from',dateOrder: 'DESC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'date_asc'){
        const tempFilters = {...filters, orderBy: 'dayWork.date_from',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'request_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.created_at',dateOrder: 'DESC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'request_asc'){
        const tempFilters = {...filters, orderBy: 'dayWork.created_at',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }else if(sortFilter === 'sageRef_desc'){
        const tempFilters = {...filters, orderBy: 'dayWork.category',dateOrder: 'DESC' }
        setFilters(tempFilters)
      }else{
        const tempFilters = {...filters, orderBy: 'dayWork.category',dateOrder: 'ASC' }
        setFilters(tempFilters)
      }
    }
  }, [sortFilter])

  useEffect(() => {
    setLoadingDayworks(true)
    getDayWorkRequest(0, filters)
  }, [filters])

  useEffect(() => {
    setLoadingDayworks(true)
    getDayWorkRequest(dayworksCurrentPage,filters)
  }, [dayworksCurrentPage])

  useEffect(() => {
    setDayworksPageCounts(Math.ceil(dayworksTotalElements / PAGE_SIZE))
  },[dayworksTotalElements])

  const handleSearchChange = useCallback((searchValue) => {
    setFilters({...filters, search: searchValue})
  },[filters,setFilters] )


  const debouncedSearch = useMemo(() => {
    return debounce(handleSearchChange, 750);
  }, [handleSearchChange]);



  const onChangeSearchValue = (e) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    debouncedSearch(searchValue)
  }
  const statusHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'status_asc') {
      setSortFilter('status_desc')
    } else {
      setSortFilter('status_asc')
    }
  },[setSortFilter, sortFilter])

  const nameHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'name_asc') {
      setSortFilter('name_desc')
    } else {
      setSortFilter('name_asc')
    }
  },[setSortFilter, sortFilter])

  const dateHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'date_desc') {
      setSortFilter('date_asc')
    } else {
      setSortFilter('date_desc')
    }
  },[setSortFilter, sortFilter])

  const dateRequestHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'request_asc') {
      setSortFilter('request_desc')
    } else {
      setSortFilter('request_asc')
    }
  },[setSortFilter, sortFilter])

  const sageRefHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'sageRef_asc') {
      setSortFilter('sageRef_desc')
    } else {
      setSortFilter('sageRef_asc')
    }
  },[setSortFilter, sortFilter])

  const amountHeaderClicked = useCallback(() => {
    if(sortFilter && sortFilter === 'amount_asc') {
      setSortFilter('amount_desc')
    } else {
      setSortFilter('amount_asc')
    }
  },[setSortFilter, sortFilter])

  const toggleModal = (daywork) => {
    setCurrentDayWork(daywork);
    setShowReviewButtons(daywork.status === DAY_WORK_STATUS.PENDING.value || daywork.status === DAY_WORK_STATUS.APPROVED_BY_PM.value)
    setShowModal(!showModal);
  }

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    dayworks.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };



  const addToVariationOrder = (item) => {
    if(variationOrder) {
      return addDayWorkToVo(item.id);
    }
  }

  const openModal = (item) => {
    toggleModal(item)
  }

  const _renderLoading = useCallback(() => {
    return <Loading/>
  },[])


  const _renderTableHeader = useCallback(() => {
    return <thead>
    <tr>
      <th onClick={() => statusHeaderClicked()}>STATUS<SortOrderIcon size={24} /></th>
      <th onClick={() => nameHeaderClicked()}>OPERATIVE<SortOrderIcon size={24} /> </th>
      <th onClick={() => dateHeaderClicked()}>DATE<SortOrderIcon size={24} /></th>
      <th onClick={() => dateRequestHeaderClicked()}>DATE REQUESTED<SortOrderIcon size={24} /></th>
      <th onClick={() => sageRefHeaderClicked()}>SAGE REF<SortOrderIcon size={24} /></th>
      <th>AMOUNT</th>
      <th>VO#</th>
    </tr>
    </thead>
  },[statusHeaderClicked,dateHeaderClicked,dateRequestHeaderClicked,sageRefHeaderClicked,amountHeaderClicked])

  const _renderTableContent = useCallback(() => {
    return <Table responsive
                  hover
                  className="mt-1 table react-table table--bordered pl-0">
      {_renderTableHeader()}
      <tbody>
      {dayworks.map((item, index) =>
          (
              <tr key={index}>
                <td  onClick={() => openModal(item)}>
                  {item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
                  {item.status && DAY_WORK_STATUS[item.status].label}
                </td>
                <td  onClick={() => openModal(item)}>
                  {item.creator.first_name} {item.creator.surname}
                </td>
                <td  onClick={() => openModal(item)}>
                  <CalendarIcon /> &nbsp;{" "}
                  <Moment format="LL">{item && item.date_from}</Moment>
                </td>
                <td  onClick={() => openModal(item)}>
                  <CalendarIcon /> &nbsp;{" "}
                  <Moment format="LL">{item && item.created_at}</Moment>
                </td>
                <td  onClick={() => openModal(item)}> {item.category && item.category} </td>
                <td  onClick={() => openModal(item)}>{formatPrice(calculateDayWorkHourCost({startDate: item.date_from,
                  endDate: item.date_to,
                  startTime: item.start_at,
                  endTime: item.end_at,
                  dayworkHour: item.hourly_rate,
                  userHourRate: item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                  budget: item.budget}).cost)}</td>
                <td onClick={() => addToVariationOrder(item)}>
                  {
                    (dayworksAddedToVo && dayworksAddedToVo[item.id] && variationOrder)
                        ? <div className="d-flex align-items-center">
                          <b className="text-green ml-1">{labelVariationOrder(dayworksAddedToVo[item.id],projectName, contractName)} </b>
                        </div>
                        : variationOrder
                            ? <div
                                className="d-flex align-items-center"
                            >
                              <CustomCreateIcon
                                  size={20}
                                  fill="#007DBC"
                              />
                              <b className="text-blue ml-1">Add to VO</b>
                            </div>
                            : <div
                                className="d-flex align-items-center"
                            >
                              <CustomCreateIcon
                                  size={20}
                                  color="#888888"
                              />
                              <b className="text-gray ml-1">Add to VO</b>
                            </div>
                  }
                </td>
              </tr>
          ))}
      </tbody>
    </Table>
  },[_renderTableHeader,dayworks, openModal, addToVariationOrder])
  const _renderTablePagination = useCallback(() => {
    if(dayworks && dayworksPageCounts >= 2 ){
      return  <div className="box-pagination">
        <Pagination
            count={dayworksPageCounts}
            page={dayworksCurrentPage + 1}
            onChange={(event, page) => setDayworksCurrentPage(page - 1)}
        />
      </div>
    } else {
      return  null
    }
  },[dayworks,dayworksCurrentPage, dayworksPageCounts])

  const _renderDayworkTable = useCallback(() => {
    return  <Col>
      <b>DAYWORK HISTORY</b>
      <div className="form__form-group-field pt-2 pb-2">
        <div className="search_input">
          <SearchIcon />
          <input
              type="text"
              name="search"
              placeholder="Search..."
              className="search_input"
              value={searchValue}
              onChange={onChangeSearchValue}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
          />
        </div>
      </div>
      {dayworks.length > 0 ?  _renderTableContent() : <div className="w-100 d-flex justify-content-center">
        <p>Not found</p>
      </div>}
    </Col>

  },[dayworks, _renderTableContent,_renderTablePagination,searchValue,onChangeSearchValue ])
  return (
  <Col xl={8} lg={16} spacing={4} style={{paddingLeft: "0px", marginTop: "1.5rem"}}>
    <div>
      <Row className='d-flex justify-content-center ml-1'>
        <CardBody style={{minHeight:'525px'}} className={'d-flex justify-content-center'}>
          {loadingDayworks ? _renderLoading() : _renderDayworkTable()}
        </CardBody>
      </Row>
      {_renderTablePagination()}
    </div>

    <PhotoViewer
      closeLightbox={closeLightbox}
      currentImage={currentImage}
      photos={photos}
      viewerIsOpen={viewerIsOpen}
    />
    {showModal && <Modal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary additional_work-modal`}
    >
      <div className="modal__header">
        <h3 className="page-title">DayWork request</h3>
        <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={toggleModal}
        />
      </div>
      <div className="modal__body text-left mt-5">
        <DayWorkForm
            getDayWorkRequests={getDayWorkRequest}
            item={currentDayWork}
            moreImageID={moreImageID}
            toggleMoreImage={toggleMoreImage}
            getPhotosByStep={getPhotosByStep}
            setModal={setShowModal}
            setLoadTable={setLoadingDayworks}
            showButtons={showReviewButtons}
        />
      </div>
    </Modal>}

    </Col>
  );
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DayWorkTableByProject)
);
