import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import HourlyFieldMaterial from "../../../shared/components/material_field/HourlyFieldMaterial";
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial";
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate";
import { getVat } from "../../../redux/services/vat";
import { getProjectManagers } from "../../../redux/services/users";
import { getAllContracts } from "../../../redux/services/contracts";
import renderDatePickerField from "../../../shared/components/form/DatePicker";

const required = (value) => (value ? undefined : "You must enter a value");
const requiredSelect = (value) =>
  value ? undefined : "You must choose a value";

const CreateProjectStepOne = (props) => {
  const { handleSubmit, saveProject, defaultSelectValue } = props;
  const [vatValues, setVatValues] = useState([]);
  const [projectManagers, setProjectManagers] = useState([]);
  const [contracts, setContracts] = useState(['1', '2', '3'])

  async function getValues() {
    const values = await getVat();
    const pms = await getProjectManagers();

    await getAllContracts().then( res => {
      const contractsHelperArray = [];

      res.items.forEach(contract => contractsHelperArray.push({
        value: contract.id,
        label: contract.name
      }))

      setContracts(contractsHelperArray)
    })

    let vatHelperArray = [];
    let pmHelperArray = [{ value: "", label: "Empty" }];

    for (let i = 0; i < values.vatValues.length; i++) {
      vatHelperArray.push({
        value: {
          rate: values.vatValues[i].rate,
          sageCode: values.vatValues[i].sageCode,
          drc: values.vatValues[i].drc,
          name: values.vatValues[i].name,
        },
        label: values.vatValues[i].name,
      });
    }

    for (let i = 0; i < pms.length; i++) {
      pmHelperArray.push({
        value: pms[i].id,
        label: pms[i].first_name + " " + pms[i].surname,
      });
    }



    setVatValues(vatHelperArray);
    setProjectManagers(pmHelperArray);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    getValues();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row md={12} className="justify-content-between">
          <Col md={4}>
            <h2>Create project</h2>
          </Col>

          <div className="buttonContainer">
            <Col md={6}>
              <Button className="button" onClick={saveProject}>
                Save
              </Button>
            </Col>

            <Col md={6} className={"justify-content-end d-flex"}>
              <Button type={"submit"} className="text-nowrap button">
                Next
              </Button>
            </Col>
          </div>
        </Row>

        <Container md={12} className="mt-3  innerContainer">
          <Row md={12}>
            <Col md={4}>
              <Field
                name="projectName"
                placeholder={"Enter name of project"}
                component={TextFieldMaterial}
                type="text"
                label={"Project Name"}
                validate={required}
              />
            </Col>

            <Col md={4}>
              <Field
                name="projectReference"
                placeholder={"Enter project reference i.e.  001/ABC format"}
                component={TextFieldMaterial}
                label={"Project reference"}
                validate={required}
              />
            </Col>

            <Col md={4}>
              <Field
                name="budget"
                placeholder={"Enter budget"}
                component={HourlyFieldMaterial}
                label={"Budget"}
                validate={required}
              />
            </Col>
          </Row>

          <Row md={12} xl={12} className={"mt-4"}>
            <Col md={4}>
              <Field
                name="client"
                placeholder={"Enter client"}
                component={TextFieldMaterial}
                label={"Client"}
                validate={required}
              />
            </Col>

            <Col md={3}>
              <div className="mb-1">Deadline</div>
              <Field
                name={"deadline"}
                component={renderDatePickerField}
                validate={required}
                minDate={Date.now()}
              />
            </Col>
          </Row>

          <Row md={12} xl={12} className={"mt-4"}>
            <Col md={3}>
              <div className="mb-1">VAT</div>
              <Field
                name={"vat"}
                component={SelectFieldNoValidate}
                placeholder={"VAT"}
                options={vatValues}
                defaultValue={defaultSelectValue}
                validate={requiredSelect}
              />
            </Col>

            <Col md={3}>
              <div className="mb-1">Project manager</div>
              <Field
                name={"pm_id"}
                component={SelectFieldNoValidate}
                placeholder={"Project manager"}
                options={projectManagers}
              />
            </Col>

            <Col md={3}>
              <div className="mb-1">Quality assurance</div>
              <Field
                name={"qa_id"}
                component={SelectFieldNoValidate}
                placeholder={"Quality assurance"}
                options={projectManagers}
              />
            </Col>

            <Col md={3}>
              <div className="mb-1">Contract</div>
              <Field
                name={"project_group_id"}
                component={SelectFieldNoValidate}
                placeholder={"Contract"}
                options={contracts}
                validate={requiredSelect}
              />
            </Col>
          </Row>

          <Row md={12} xl={12} className={"mt-4"}>
            <Col md={12}>
              <h1 className="page-title">Location</h1>
            </Col>
          </Row>

          <Row md={12} xl={12}>
            <Col md={4}>
              <Field
                name="address_street"
                placeholder={"Street of your address"}
                component={TextFieldMaterial}
                type="text"
                label={"Street"}
                validate={required}
              />
            </Col>

            <Col md={4}>
              <Field
                name="address_number"
                placeholder={"Number of your home"}
                component={TextFieldMaterial}
                label={"Number"}
                validate={required}
              />
            </Col>

            <Col md={4}>
              <Field
                name="address_city"
                placeholder={"Your city"}
                component={TextFieldMaterial}
                label={"City"}
                validate={required}
              />
            </Col>
          </Row>

          <Row md={12} xl={12} className={"mt-4"}>
            <Col md={4}>
              <Field
                name="address_post_code"
                placeholder={"Post code of your address"}
                component={TextFieldMaterial}
                type="text"
                label={"Post code"}
                validate={required}
              />
            </Col>
          </Row>

          <Row md={12} xl={12} className={"mt-4"}>
            <Col md={12}>
              <h1 className="page-title">Description</h1>
            </Col>
          </Row>

          <Row md={12} xl={12}>
            <Col md={12}>
              <Field
                name="additional_information"
                placeholder={"Description of project"}
                component={TextFieldMaterial}
                type="text"
                label={"Additional information"}
                validate={required}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepOne);
