import React, {useEffect, useState} from "react"
import { Row, Col } from 'reactstrap';
import MultiLinearProgressBar from "../../../../shared/components/progress/MultiLinearProgressBar"
import Loading from "../../../../shared/components/Loader"
import {Link, withRouter} from "react-router-dom";
import {getInvoiceAnalytics} from "../../../../redux/services/projects";
import {pushCrumbs} from "../../../../redux/actions/bread_crumbs";
import {connect} from "react-redux";
import {INVOICES_BY_PROJECT} from "../../../../constants/routes";
const InvoicesStatus = ({ id }) => {

    const [load, setLoad] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setLoad(true);
        getInvoiceAnalytics(id)
            .then(res => {
                setLoad(false);
                setData(res.data)
            })
    }, []);
    return (
        load
            ? <Loading />
            : <>
                <div className="wrapper_page-title">
                    <h5 className="page-title m-0" >INVOICE STATUS</h5>
                </div>
                <Link to={{
                    pathname: INVOICES_BY_PROJECT.replace(':id', id),
                }}>
                    <Row className="mt-3 d-flex justify-content-between">
                        <Col md={6} xl={6} className="border-right">

                            <div className="lg_wrapper_page-title">
                                <p className="page-title m-0 text-green">{data && data.invoices_paid}</p>
                            </div>
                            <div className="wrapper_page-title">
                                <span className="form__form-group-label m-0">PAID INVOICES</span>
                            </div>
                        </Col>
                        <Col md={5} xl={5}>
                            <div className="lg_wrapper_page-title">
                                <p className="page-title m-0 text-red">{data && data.invoices_unpaid}</p>
                            </div>
                            <div className="wrapper_page-title">
                                <span className="form__form-group-label m-0">UNPAID INVOICES</span>
                            </div>
                        </Col>
                    </Row>
                </Link>

                <div className="wrapper_page-title mt-3">
                    <p className="form__form-group-label m-0">Total invoices</p>
                </div>
                <Row className="mt-1 mb-2">
                    <Col md={12} xl={12}>
                        <MultiLinearProgressBar one='green' two='red' oneValue={data && data.invoices_paid} twoValue={data && data.invoices_unpaid} />
                    </Col>
                </Row>
            </>
    )
}


const mapDispatchToProps = {
    pushCrumbs
};

function mapStateToProps({ form }) {
    return {
        form
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoicesStatus));
