import React, { useState } from 'react'
import { Col, Container, Form, FormGroup, Modal } from 'reactstrap'
import { TextField } from "@material-ui/core";
import { Button } from 'reactstrap'
import { editContract } from '../../../../redux/services/contracts';
import { useHistory } from 'react-router-dom';

const EditContractModal = ({
  modalVisibility,
  toggleModal,
  contract
}) => {
  const history = useHistory();
  const [groupName, setGroupName] = useState(contract.name.toString().trim());
  const [error, setError] = useState();

  const handleGroupName = (event) => {
    setGroupName(event.target.value)
  }

  const updateGroup = () => {
    if(groupName.toString().trim() === contract.name.toString().trim()){
      return setError("You need to input a different name in order to change the Contract name.")
    }

    if(!groupName){
      return setError("You need to input a name in order to edit the Contract name.");
    }

    setError('');
    toggleModal();
    editContract(contract.id, groupName.toString().trim()).then(()=>{
    history.push('/contracts')
    })
  }

  return (
    <Modal
      isOpen={modalVisibility}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog-centered`}
    >
      <Container className={"modal__header"}>
        <h3>Edit contract</h3>
      </Container>

      <Container className={"modal__body mt-5"}>
      <Col className={"mt-5"}>
        <Form>
          <FormGroup className={"d-flex flex-column align-items-center"}>
            <div className="create_user_field_wrapper mb-3 w-100">
              <TextField
                placeholder={"Contract name"}
                label={"Contract name"}
                value={groupName}
                onChange={handleGroupName}
                type={"text"}
              />
            </div>
            { !!error &&
            <p className='mt-1 mb-4 red-text'>
              {error}
            </p>
            }
            <div className='d-flex justify-content-end w-100'>
            <Button onClick={toggleModal}>
              Cancel
            </Button>
            <Button
            onClick={updateGroup}
            color="primary"
            >
              Update
            </Button>
            </div>
          </FormGroup>
        </Form>
      </Col>
      </Container>
    </Modal>
  )
}

export default EditContractModal
