import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, Modal, Button } from "reactstrap";
import Loading from "../../shared/components/Loader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserCreate from "./UserCreate";
import UsersTable from "./Tables/UsersTable";
import FilterForm from "./Forms/UserFilterForm";
import {getUsers, createUser, deleteUsers, usersToCsv} from "../../redux/services/users";
import { CreateIcon } from "../../constants/icons";
import { SubmissionError } from "redux-form";
import { USER_ROLE } from "../../constants/enamerations";
import {
  patternPhoneCreate,
  patternEmail,
} from "../../config/validationRules";

const Users = () => {
  const [load, setLoad] = useState(false);
  const [users, setUsers] = useState(null);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [modalDelete, setModalDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filter, setFilter] = useState({
    text: "",
    job_type: "",
    job_status: "",
    systemRole: "",
  });
  const [choosedUsers, setChoosedUsers] = useState({});

  useEffect(() => {
    getAllUsers();
  }, [page, filter]);

  const toggleModal = () => {
    setModal(!modal);
    setErrorMessage(null);
  };

  const handleExportUsers = () => {
    usersToCsv().then(() => {})
  }

  const toggleModalDelete = () => setModalDelete(!modalDelete);

  const getAllUsers = () => {
    setLoad(true);
    setModal(false);
    getUsers(page, filter).then((res) => {
      setUsers(res);
      setLoad(false);
    });
  };

  const addUser = (data) => {
    let error = {};
    let isError = false;
    if (!data.first_name || !data.first_name.trim()) {
      error.first_name = "First name field shouldn`t be empty";
      isError = true;
    }
    if (!data.surname || !data.surname.trim()) {
      error.surname = "Surname field shouldn`t be empty";
      isError = true;
    }
    if (!data.phone || !data.phone.trim()) {
      error.phone = "Phone field shouldn’t be empty";
      isError = true;
    } else if (!patternPhoneCreate.test(data.phone.trim().replace(/\s+/g,''))) {
      error.phone = "Phone number should be a format xxx xxxx xxxx";
      isError = true;
    }
    if (!data.email || !data.email.trim()) {
      error.email = "Email field shouldn’t be empty";
      isError = true;
    } else if (!patternEmail.test(data.email)) {
      error.email = "Invalid email address";
      isError = true;
    }
    if (!data.role || !data.role.trim()) {
      error.role = "Role field shouldn`t be empty";
      isError = true;
    } else if (
      data.role === USER_ROLE.USER.value ||
      data.role === USER_ROLE.PROJECT_MANAGER.value
    ) {
      if (!data.sage_reference || !data.sage_reference.trim()) {
        error.sage_reference = "Sage reference field shouldn`t be empty";
        isError = true;
      }
    }
    if (!data.sub_contractor_type || !data.sub_contractor_type.trim()) {
      error.sub_contractor_type =
        "Sub-Contractor Type field shouldn`t be empty";
      isError = true;
    }
    if (!data.cis_status || !data.cis_status.trim()) {
      error.cis_status = "CIS Status field shouldn`t be empty";
      isError = true;
    }
    if (!data.hourly_rate || !data.hourly_rate.trim()) {
      error.hourly_rate = "Hourly rate field shouldn`t be empty";
      isError = true;
    }
    if (!data.vat_number || !data.vat_number.trim()) {
      error.vat_number = "VAT number field shouldn’t be empty";
      isError = true;
    }
    if (!data.utr || !data.utr.trim()) {
      error.utr = "Utr rate field shouldn`t be empty";
      isError = true;
    }
    if (!data.is_vat_registered) {
      data.vat_number = "-";
    }

    if (isError) {
      throw new SubmissionError(error);
    } else {
      if (!isError) {
        delete data.is_vat_registered;

        const req = { ...data };
        req.phone = req.phone.replace(/\s/g, "");
        if (req.hourly_rate) {
          req.hourly_rate = req.hourly_rate.replace("£ ", "");
        }
        if (req.sage_reference) {
          req.sage_reference = req.sage_reference.toUpperCase();
        }
        if (req.email) {
          req.email = req.email.toLowerCase();
        }

        for (const [key, value] of Object.entries(req)) {
          req[key] = value.trim();
        }
        createUser(req)
          .then(() => {
            getAllUsers();
          })
          .catch((errors) => {
            if (errors && errors.response && errors.response.data) {
              setErrorMessage(errors.response.data.message);
            }
          });
      }
    }
  };

  const usersDelete = () => {
    deleteUsers(Object.keys(choosedUsers).map((item) => +item)).then(() => {
      getAllUsers();
      setModalDelete(false);
      setChoosedUsers({});
    });
  };

  return (
    <>
      <Container className="m-0">
        <Row>
          <Col md={12} xl={12} className="d-flex justify-content-between">
            <h1 className="page-title">Accounts</h1>
            <div className={'d-flex'}>
              <Button
                  className="btn_with_img"
                  color="primary"
                  onClick={toggleModal}
              >
                <CreateIcon /> Create account
              </Button>
              <Button
                  className="btn_with_img"
                  color="primary"
                  onClick={handleExportUsers}>
                Export Users
              </Button>
            </div>

          </Col>

          <Col md={12} xl={12} className="mb-5">
            <Card>
              <CardBody>
                <div className="card__title d-flex justify-content-between m-0">
                  <FilterForm
                    setPage={setPage}
                    setFilter={setFilter}
                    filter={filter}
                  />
                </div>
                {Object.keys(choosedUsers).length > 0 && (
                  <div className="w-50 d-flex justify-content-start">
                    <div className="plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={toggleModalDelete}
                      >
                        DELETE USERS
                      </span>
                    </div>
                  </div>
                )}
                {load ? (
                  <Loading />
                ) : users && users.items && users.items.length > 0 ? (
                  <UsersTable
                    choosedUsers={choosedUsers}
                    setChoosedUsers={setChoosedUsers}
                    setPage={setPage}
                    users={users}
                    page={page}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center">
                    <p>Not found</p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Create account</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModal}
            />
          </div>
          <div className="modal__body">
            <UserCreate
              toggleModal={toggleModal}
              addUser={addUser}
              errorMessage={errorMessage}
            />
          </div>
        </Modal>
      )}
      {modalDelete && (
        <Modal
          isOpen={modalDelete}
          toggle={toggleModalDelete}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Delete Users</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModalDelete}
            />
          </div>
          <div className="modal__body modal_body">
            <p className="form__form-group-label m-0">
              Are you sure you want to delete?
            </p>
            <div className="mt-3">
              <Button
                className="mb-0"
                color="secondary"
                onClick={toggleModalDelete}
              >
                Cancel
              </Button>
              <Button className="mb-0" color="primary" onClick={usersDelete}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

function mapStateToProps({ form }) {
  return {
    form,
  };
}

export default withRouter(connect(mapStateToProps)(Users));
