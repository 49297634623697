import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import {getAllContracts, getContractsWithProjectsStatuses} from "../../redux/services/contracts";
import Loading from "../../shared/components/Loading"
import { PROJECT_STATUS } from "../../constants/enamerations";
import { CollapseIcon, ExpandIcon } from "../../constants/icons";
import ContractsProjectTable  from "../Contracts/PreviewContract/ProjectsTable/ContractProjectsTable";
import { pushNewCrumbs, pushCrumbs } from "../../redux/actions/bread_crumbs";
import { connect } from "react-redux";
import ExportCsvModal from "./ExportCsvModal";
import {CONTRACT_VARIATION_ORDERS} from "../../constants/routes";

const Commercial = () => {
  const [contracts, setContracts] = useState(null);
  const [load, setLoad] = useState(false);
  const [csvModal, setCsvModal] = useState(false);

  const [activeCollapses, setActiveCollapses] = useState({});
  const [expandedContracts, setExpandedContracts] = useState({});
  const [isContractExpansionLoading, setIsContractExpansionLoading] = useState({});

  const toggleCollapse = (contractId) => {
    setActiveCollapses(current => {
      if(!current[contractId]) fetchProjectsFromExpanded(contractId);
      return {...current, [contractId]: !current[contractId]}
    })
  }

  const fetchProjectsFromExpanded = (contractId) => {
    setIsContractExpansionLoading(current => {
      return {...current, [contractId]: true}
    });

    getContractsWithProjectsStatuses(contractId).then(res => {
      setExpandedContracts(current => {
        return {...current, [contractId]: res}
      });

      setIsContractExpansionLoading(current => {
        return {...current, [contractId]: false}
      });
    });
  }

  const fetchContracts = () => {
    setLoad(true)
    getAllContracts().then(res =>{
      setContracts(res)

      const contractsState = {};
      const expandedContracts = {};

      res.items.forEach(contract => {
        contractsState[contract.id] = false;
        expandedContracts[contract.id] = [];
      })

      setActiveCollapses(contractsState);
      setExpandedContracts(expandedContracts);

      setLoad(false);
    });
  }

  useEffect(()=>{
    fetchContracts();
  },[])

  if(load) return <Loading/>

  if(!contracts || !contracts.items || !contracts.items.length)
    return (
      <div className="w-100 d-flex justify-content-center">
        <p>No contract found</p>
      </div>
    )

  const toggleCSVModal = () => setCsvModal(curr => !curr)

  return (
    <div>
    <Col>
    <Row className={'d-flex justify-content-between pl-2 pr-2'}>
      <h1 className={'page-title'}>Commercial</h1>
      <Button
        type="button"
        color="primary"
        onClick={toggleCSVModal}
      >
        Export CSV
      </Button>
    </Row>
    </Col>
    {
      contracts.items.map(contract =>
        <Col md={12} xl={12} spacing={2} className='mb-3 h-10 d-flex align-items-center' key={contract.id}>
        <Card>
          <CardBody>
            <Row className="pl-4 pr-4">
              <div
                className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
                onClick={() => toggleCollapse(contract.id)}
              >
                <h5 className="page-title m-0">{contract.name}</h5>
                {activeCollapses[contract.id] ? (
                  <CollapseIcon />
                ) : (
                  <ExpandIcon />
                )}
              </div>
            </Row>
            {
            activeCollapses[contract.id]
            && (
            isContractExpansionLoading[contract.id]
            ? <p className="pl-3">Loading...</p>
            : expandedContracts[contract.id] &&
            <Row className="pl-3 pr-3">
              <ContractsProjectTable
                  href={CONTRACT_VARIATION_ORDERS}
                status={PROJECT_STATUS.ACTIVE.value}
                contract={expandedContracts[contract.id]}

              />
            </Row>
            )
            }
          </CardBody>
        </Card>
        </Col>
      )
    }
    <ExportCsvModal
      isOpen={csvModal}
      toggleModal={toggleCSVModal}
    />
    </div>
  );
};
const mapDispatchToProps = {
  pushNewCrumbs,
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Commercial)
);

