import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Dropdown
} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import JobsFilterForm from "./Forms/JobsFilterForm";
import { deleteManyJobs, getJobsByProject } from "../../redux/services/jobs";
import {
  exportAllProjectJobs,
  getProjectsById,
  getUniqueValuesByFilter,
} from "../../redux/services/projects";
import { PROJECT_STRUCTURE, JOB_PLAN } from "../../constants/enamerations";
import { pageSize } from "../../config/pagination";
import Loading from "../../shared/components/Loader";
import { pushCrumbs } from "../../redux/actions/bread_crumbs";
import JobCreate from "./JobCreate";
import JobsTable from "./Tables/JobsTable";
import UploadPlanForm from "./Forms/UploadPlanForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  uploadFilePlan,
  uploadPhotoPlan,
  applyPhotoToJobs,
} from "../../redux/services/jobs";
import { CreateIcon } from "../../constants/icons";
import SearchIcon from "mdi-react/SearchIcon";
import {CONTRACTS, PREVIEW_CONTRACT, PREVIEW_PROJECT} from "../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";

const index = ({ match, form }) => {
  const id = Number(match.params.id)
  const status = match.params.status

  const [page, setPage] = useState(0);
  const [jobs, setJobs] = useState(null);
  const [project, setProject] = useState(null);
  const [filter, setFilter] = useState({
    search: '',
    structure: {
      target_ids: [id],
      target_type: PROJECT_STRUCTURE.PROJECT,
    },
    orderBy:"job.id",
    dateOrder:"DESC",
    job_status: !!status && status !== 'ALL' ? status : '',
    job_type: "",
    unit_type: "",
  });
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [typePlan, setTypePlan] = useState(null);
  const [choosedJobs, setChoosedJobs] = useState({});
  const [loadUploadFile, setLoadUploadFile] = useState(false);
  const [uploadState, setUploadState] = useState(false);
  const [uniqueValue, setUniqueValue] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);


  useEffect(() => {
    getJobs();
  }, [filter, page]);

  useEffect(() => {
    getProject();
  }, []);

  useEffect(() => {
    const unitTypeOptions = [{ value: null, label: "All" }];
    getUniqueValuesByFilter(id, "unit_type").then((res) => {
      unitTypeOptions.push(...res);
      setUniqueValue(unitTypeOptions);
    });
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      // Set the Filters
      setFilter({ ...filter, search: filterText})
      setPage(0)
    }, 800); // Debounce delay of 2 seconds (2000 milliseconds)

    // Clear the debounce timer on component unmount or when textInput changes
    return () => clearTimeout(debounceTimer);
  }, [filterText]);

  const toggleModal = (type) => {
    if (type === "job") {
      toast("Job added!");
    }

    setTypePlan(type);
    setUploadState(false);
    setModal(!modal);
  };

  const toggleModalDelete = () => setModalDelete(!modalDelete);

  const getJobs = () => {
    setLoad(true);
    getJobsByProject(
      {
        page: page && page,
        pageSize: pageSize && pageSize,
      },
      filter
    ).then((res) => {
      setJobs(res);
      setLoad(false);
    });
  };

  const getProject = () => {
    getProjectsById(id).then((res) => {
      setProject(res);
    });
  };


  const handleExportJobs = () => {
    const projectId = id
    if(!!projectId){
      exportAllProjectJobs(projectId, false).then(() => {})
    }
  }

  const handleExportJobsWithSteps = () => {
    const projectId = id
    if(!!projectId){
      exportAllProjectJobs(projectId, true).then(() => {})
    }
  }

  const getUploadFile = (file) => {
    setLoadUploadFile(true);
    const fileName = `${file.type.split("/")[0]}${Math.floor(
      Math.random() * 99999
    ) + 1}.${file.type.split("/")[1]}`;
    uploadFilePlan(typePlan, fileName).then((res) =>
      uploadPhotoPlan(res.data.signed_url, file).then(() =>
        applyPhotoToJobs(
          typePlan,
          fileName,
          Object.keys(choosedJobs).map((item) => +item)
        ).then((res) => {
          setChoosedJobs({});
          setLoadUploadFile(false);
          setUploadState(true);
        })
      )
    );
  };

  const jobDelete = () => {
    deleteManyJobs(Object.keys(choosedJobs)).then(() => {
      getJobs();
      setModalDelete(false);
      setChoosedJobs({});
    });
  };

  const constChangeFilterText = (text) => {
    setFilterText(text)

  }

  const contract = project && project.data ? project.data.projectGroup : null
  const projectId = project && project.data.id ? project.data.id : null
  const projectName = project && project.data.name ? project.data.name : null

  return (
    <>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
          <Link to={{pathname: CONTRACTS}} >
            <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
              Contracts
            </h3>
          </Link>
          {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
            <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
              {!!contract.name ? contract.name : `Current Contract`}
            </h3>
          </Link>}
          {!!projectId && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',projectId).replace(':status', 'ACTIVE')}} >
            <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
              {!!projectName ? projectName : 'Current Project'}
            </h3>
          </Link>}

          <Link to={{pathname: ''}} >
            <h3 className={"page-subhead subhead bread_crumbs-active"}>
              Jobs
            </h3>
          </Link>
        </Breadcrumbs>
        {/* Page title */}
        <Row className="pr-3 d-flex justify-content-between">
          <Col md={5} xl={5} className="d-flex align-items-center mb-4">
            <Col md={1.5} xl={1.5} className="p-0">
              <h5 className="page-title m-0">Jobs</h5>
            </Col>
          </Col>
          <div  className={'d-flex'}>
            <Button
                className="btn_with_img"
                color="primary"
                onClick={() => toggleModal(null)}
            >
              <CreateIcon /> Add job
            </Button>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret className={'btn_with_img'} color={'primary'}>Export jobs</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={handleExportJobs}>Without steps</DropdownItem>
                <DropdownItem onClick={handleExportJobsWithSteps}>With steps</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

        </Row>
        {/* Project table */}
        <Row>
          <Col md={12} xl={12}>
            <Card>
              <CardBody>
                <JobsFilterForm
                  job_status={!!status && status !== 'ALL' ? status : ''}
                  filter={filter}
                  setFilter={setFilter}
                  f={form}
                  project={project}
                  jobs={jobs}
                  getJobs={getJobs}
                  uniqueValue={uniqueValue}
                />
                <div className="form__form-group-field mb-2">
                  <div className="search_input">
                    <SearchIcon />
                    <input
                        type="text"
                        name="search"
                        placeholder="Search..."
                        className="search_input"
                        value={filterText}
                        onChange={(data) => {
                          constChangeFilterText(data.target.value);
                        }}
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                    />
                  </div>
                </div>
                {Object.keys(choosedJobs).length > 0 && (
                  <div className="w-50 d-flex justify-content-start">
                    <div className="plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={() => toggleModal(JOB_PLAN.FLAT.value)}
                      >
                        FLAT PLAN
                      </span>
                    </div>
                    <div className="ml-3 plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={() => toggleModal(JOB_PLAN.FLOOR.value)}
                      >
                        FLOOR PLAN
                      </span>
                    </div>
                    <div className="ml-3 plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={() => setModalDelete(true)}
                      >
                        DELETE JOBS
                      </span>
                    </div>
                  </div>
                )}
                {load ? (
                  <Loading />
                ) : jobs && jobs.items && jobs.items.length > 0 ? (
                  <JobsTable
                    choosedJobs={choosedJobs}
                    setChoosedJobs={setChoosedJobs}
                    jobs={jobs}
                    setPage={setPage}
                    page={page}
                    filter={filter}
                    setFilter={setFilter}
                  />
                ) : (
                  <div className="w-100 d-flex justify-content-center">
                    <p>Not found</p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">
              {typePlan
                ? `Assign ${typePlan} plan`
                : `Add job to ${project.data.name}`}
            </h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModal}
            />
          </div>
          <div className="modal__body">
            {typePlan ? (
              <UploadPlanForm
                toggle={toggleModal}
                uploadState={uploadState}
                loadUploadFile={loadUploadFile}
                getUploadFile={getUploadFile}
                typePlan={typePlan}
              />
            ) : (
              <JobCreate toggle={toggleModal} project={project.data} />
            )}
          </div>
        </Modal>
      )}
      {modalDelete && (
        <Modal
          isOpen={modalDelete}
          toggle={toggleModalDelete}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Delete Job</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModalDelete}
            />
          </div>
          <div className="modal__body modal_body">
            <p className="form__form-group-label m-0">
              Are you sure you want to delete?
            </p>
            <div className="mt-3">
              <Button
                className="mb-0"
                color="secondary"
                onClick={toggleModalDelete}
              >
                Cancel
              </Button>
              <Button className="mb-0" color="primary" onClick={jobDelete}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <ToastContainer
        position={"top-center"}
        autoClose={2000}
        pauseOnHover={false}
        hideProgressBar={true}
      />
    </>
  );
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps({ form }) {
  return {
    form,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
