export const getEnumerationLabel = (object, value) => {
    const values = Object.values(object).filter(type => type.value === value);
    return values && values[0] && values[0].label
};

export const getEnumerationObject = (object, value) => {
    return Object.values(object).filter(type => type.value === value)[0]
};

export const formatPrice = (price) => {
    const priceValue =  Number(price)
    return `₤ ${(priceValue).toFixed(2)}`
};

