import {calculateDayWorkHourCost} from '../config/func';

export const getAcronym = (projectName, contractName) => {
  const contractNameArray = contractName.trim().split(' ');
  const projectNameArray = projectName.trim().split(' ');
  let shortenName = '';

  contractNameArray.forEach(word => {
    if(!!word.toString().trim()){
      shortenName += word[0].toUpperCase();
    }
  })

  shortenName += '-'

  projectNameArray.forEach(word => {
    if(!!word.toString().trim()){
      shortenName += word[0].toUpperCase();
    }
  })

  return shortenName;
}

export const addZero = (number) => {
  var numberString = number.toString();
  var numberLength = numberString.length;
  var zeroString = '';

  if(numberLength >= 4) return numberString;

  for(var i = 0; i < 4 - numberLength; i++){
    zeroString += '0';
  }
  return zeroString + numberString;
}

export const labelVariationOrder = (voId, projectName, contractName)=>{
  return 'LMEP/VO/' + getAcronym(projectName, contractName)  + '/' + addZero(voId);
}

export const totalDayworksAmount = (dayworks) => {
  var total = 0;
  dayworks.forEach(daywork => {
    const totalAmount = calculateDayWorkHourCost({
      startDate: daywork.date_from,
      endDate: daywork.date_to,
      startTime: daywork.start_at,
      endTime: daywork.end_at,
      dayworkHour: daywork.hourly_rate,
      userHourRate:  daywork.creator && daywork.creator.hourly_rate ? daywork.creator.hourly_rate : undefined,
      budget: daywork.budget,}).cost
    total += totalAmount;
  })
  return total;
}

export const totalAdditionalWorksAmount = (additionalWorks) =>  {
  var total = 0;
  additionalWorks.forEach(additionalWork => {
    total += parseInt(additionalWork.budget);
  })
  return total;
}
