import React, { useEffect, useState } from "react";
import Loading from "../../../shared/components/Loader";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { Col, Row, Container, Card, CardBody} from "reactstrap";
import { getUserById } from "../../../redux/services/users";
import RecentInvoices from "../../Invoices/RecentInvoices";
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import AccountInfo from "./AccountInfo";
import {generateInvoicesById} from '../../../redux/api/invoice';
import DayWorkRequests from "./DayWorkRequests";
import { getDayWorkById } from "../../../redux/services/day_work";
import { typesOfJobsCounters, typesOfJobsSpan } from "./Iterators";
import { getActiveJobsByUser } from "../../../redux/services/jobs";
import DeleteAccountButton from "./DeleteAccountButton";
import TitleAndEditButton from "./TitleAndEditButton";
import JobsCounter from "./JobsCounter";
import RecentInvoicesTab from "./RecentInvoicesTab";
import WorkCategorySpan from "./WorkCategorySpan";
import DeleteUserModal from "./DeleteUserModal";
import EditAccountModal from "./EditAccountModal";
import ShowPinModal from "./ShowPinModal";
import {getInvoiceById} from "../../../redux/services/invoice";
import InvoiceModal from "../../Invoices/invoiceModal";
import {Breadcrumbs} from "@material-ui/core";
import {USERS} from "../../../constants/routes";

const UserPreview = ({ match, history, form }) => {
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState(null);
  const [hasActiveJobs, setHasActiveJobs] = useState(false);

  const [dayWork, setDayWork] = useState(null);
  const [generatedInvoicesLength, setGeneratedInvoicesLength] = useState();

  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [pinModalVisibility, setPinModalVisbility] = useState(false);

  const [activeCollapse, setActiveCollapse] = useState(0);

  const [errorMessage, setErrorMessage] = useState(null);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [loadInvoiceModal, setLoadInvoiceModal] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState(null);

  const toggleInvoiceModal = () => {
    setInvoiceModalOpen(!invoiceModalOpen);
  };

  const getInvoiceId = (id) => {
    setLoadInvoiceModal(true);
    getInvoiceById(id).then((res) => {
      setInvoiceItem(res);
      setLoadInvoiceModal(false);
    });
  };



  const filter = {
    status: "",
  };

  useEffect(() => {
    getUser();
    getDayWorkRequests();
    getActiveJobs();
  }, []);

  useEffect(()=>{
    if(generatedInvoicesLength === 0){
    alert("The user has no jobs completed since the last invoice generation.")
    setGeneratedInvoicesLength('')
    } else{
      getUser();
      getDayWorkRequests();
      getActiveJobs();
    }
  }, [generatedInvoicesLength])

  const toggleModal = () => {
    setModal(!modal);
    setErrorMessage(null);
  };

  const togglePinModal = () => setPinModalVisbility(current => !current)

  const toggleModalDelete = () => setModalDelete(!modalDelete);

  const getUser = () => {
    setLoad(true);
    getUserById(match.params.id).then((res) => {
      setUser(res);
      setLoad(false);
    });
  };

  const getDayWorkRequests = () => {
    getDayWorkById(match.params.id, filter, 0, 100).then((res) => {
      setDayWork(res);
    });
  };

  const getActiveJobs = () => {
    setLoad(true);
    getActiveJobsByUser(match.params.id).then((res) => {
      setHasActiveJobs(res);
      setLoad(false);
    });
  };

  if(load) return <Loading/>

  const userData = user && user.data && user.data.first_name ? `${user.data.first_name} ${user.data.surname}` : 'Current User'
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
        <Link to={{pathname: USERS}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            Accounts
          </h3>

        </Link>
        <Link to={{pathname: ''}} >
          <h3 className={"page-subhead subhead bread_crumbs-active"}>
            {userData}
          </h3>

        </Link>
      </Breadcrumbs>
      <Row className="pr-3 d-flex justify-content-between">
        <TitleAndEditButton user={user} setModal={setModal}/>
        <DeleteAccountButton toggleModalDelete={toggleModalDelete}/>
      </Row>
      <Row>
        <Col md={4} xl={4}>
          <Card className="h-auto">
          <CardBody className="pr-4">
            <Row className="pl-3 d-flex justify-content-center">
              <div className="d-flex w-100">
                <AccountInfo
                  data={user && user.data}
                  togglePinModal={togglePinModal}
                />
              </div>
            </Row>
              <div className="hr_wrapper"><hr/></div>
            <Row className="d-flex justify-content-between p-0 pr-4 pl-4">
                {typesOfJobsCounters.map( type => <JobsCounter key={type} user={user} typeOfJob={type}/>)}
            </Row>
          </CardBody>
          </Card>
        </Col>
        <Col md={8} xl={8}>
          <Card>
            <CardBody className="p-4 pr-2">
              <RecentInvoicesTab
              generateInvoicesById={generateInvoicesById}
              setGeneratedInvoicesLength={setGeneratedInvoicesLength}
              userId={match.params.id}
              />
              <RecentInvoices userId={+match.params.id} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ overflowX: "auto", flexWrap: "nowrap" }} className="mb-4 mt-4">
        <DayWorkRequests dayWork={dayWork} getDayWorkRequests={getDayWorkRequests} />
      </Row>
      {
        typesOfJobsSpan.map(jobSpan =>
        <WorkCategorySpan
          activeCollapse={activeCollapse}
          setActiveCollapse={setActiveCollapse}
          workType={jobSpan.type}
          spanTitle={jobSpan.title}
          status={jobSpan.status}
          getDayWorkRequests={getDayWorkRequests}
          onJobInvoicePressed={(invoiceId) => {
            toggleInvoiceModal();
            getInvoiceId(invoiceId)}}
        />
      )}
      {
        modal &&
        <EditAccountModal
          user={user}
          modal={modal}
          toggleModal={toggleModal}
          form={form}
          match={match}
          getUser={getUser}
          setErrorMessage={setErrorMessage}
          hasActiveJobs={hasActiveJobs}
          errorMessage={errorMessage}
        />
      }
      {
        modalDelete &&
        <DeleteUserModal
          userId={[+match.params.id]}
          history={history}
          modalDeleteVisibility={modalDelete}
          toggleModalDelete={toggleModalDelete}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      }
      <ShowPinModal
        modalVisibility={pinModalVisibility}
        toggleModal={togglePinModal}
        user={user}
      />

      {invoiceModalOpen && (
          <InvoiceModal
              invoiceModal={invoiceModalOpen}
              toggleInvoiceModal={toggleInvoiceModal}
              invoiceItem={invoiceItem}
              loadInvoiceModal={loadInvoiceModal}
          />
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps({ form }) {
  return {
    form,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPreview)
);
