import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Modal } from "reactstrap";
import Moment from "react-moment";
import Loading from "../../../shared/components/Loader";
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import { pageSize } from "../../../config/pagination";
import {getUninvoicedDayWork} from "../../../redux/services/day_work";
import { BuildIcon, CalendarIcon, CreateCalendarIcon, PrintIcon } from "../../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import { DAY_WORK_STATUS } from "../../../constants/enamerations";
import {calculateDayWorkHourCost} from "../../../config/func";
import PhotoViewer from "../../../shared/components/PhotoViewer";

import ReactToPrint from "react-to-print";
import DayWorkPrint from "../../Accounts/Print/DayWorkPrint";
import DayWorkForm from "../../Accounts/Forms/DayWorkForm";
import {formatPrice} from "../../../helpers/function";

const UninvoicedDayWorkTable = ({ match, getDayWorkRequests }) => {
    const [jobs, setJobs] = useState(null);
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentDayWork, setCurrentDayWork] = useState(null);
    const [moreImageID, setMoreImageID] = useState(null);
    const [photos, setPhotos] = useState(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const componentRef = React.createRef();

    const toggle = () => {
        setModalIsOpen(!modalIsOpen);
    };

    useEffect(() => {
        setLoad(true);
        getUninvoicedDayWork(+match.params.id, page).then((res) => {
            setJobs(res);
            setLoad(false);
        });
    }, [page]);

    const toggleMoreImage = (id) => setMoreImageID(id);

    const getPhotosByStep = (id, index) => {
        const photos = [];
        jobs.items.map(
            (item) =>
                item.id === id &&
                item.assets.map((item) =>
                    photos.push({ src: item.public_url, width: 4, height: 3 })
                )
        );
        setPhotos(photos);
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setViewerIsOpen(false);
        setCurrentImage(0);
    };

    const RenderComponent = React.forwardRef((props, ref) => (
        <div ref={ref}>
            <DayWorkPrint item={currentDayWork} />
        </div>
    ));

    return (
        <>
            {load ? (
                <Loading />
            ) : jobs && jobs.items && jobs.items.length !== 0 ? (
                <Table
                    responsive
                    hover
                    className="mt-1 table react-table table--bordered pl-0"
                >
                    <thead>
                    <tr>
                        <th>STATUS</th>
                        <th>DATE</th>
                        <th>DATE REQUESTED</th>
                        <th>PROJECT</th>
                        <th>BLOCK</th>
                        <th>LEVEL</th>
                        <th>SAGE REF</th>
                        <th>RATE</th>
                    </tr>
                    </thead>
                    <tbody>
                    {jobs.items.map((item, index) => (
                        <tr
                            key={index}
                            onClick={() => {
                                setCurrentDayWork(item);
                                setModalIsOpen(true);
                            }}
                        >
                            <td>
                                {item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
                                {item.status && DAY_WORK_STATUS[item.status].label}
                            </td>
                            <td>
                                <CalendarIcon /> &nbsp;{" "}
                                <Moment format="LL">{item && item.date_from}</Moment> -{" "}
                                <Moment format="LL">{item && item.date_to}</Moment>
                            </td>
                            <td>
                                <CreateCalendarIcon /> &nbsp;{" "}
                                <Moment format="LL">{item && item.created_at}</Moment> {" "}
                            </td>
                            <td>
                                <BuildIcon /> &nbsp; {item.project && item.project.name}
                            </td>
                            <td>{item.levels && item.levels.block.name}</td>
                            <td>{item.levels && item.levels.name}</td>
                            <td>{item && item.category}</td>
                            <td>{formatPrice(calculateDayWorkHourCost({
                                startDate: item.date_from,
                                endDate: item.date_to,
                                startTime: item.start_at,
                                endTime: item.end_at,
                                dayworkHour: item.hourly_rate,
                                userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                                budget: item.budgetZ
                            }).cost)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <div className="w-100 d-flex justify-content-center">
                    <p>Not found</p>
                </div>
            )}
            {jobs && Math.ceil(jobs.total / pageSize) >= 2 && (
                <div className="box-pagination">
                    <Pagination
                        count={jobs && jobs.total ? Math.ceil(jobs.total / pageSize) : 0}
                        page={page + 1}
                        onChange={(event, page) => setPage(page - 1)}
                    />
                </div>
            )}

            <PhotoViewer
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                photos={photos}
                viewerIsOpen={viewerIsOpen}
            />

            {
                <Modal
                    isOpen={modalIsOpen}
                    toggle={toggle}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--primary additional_work-modal`}
                >
                    <div className="modal__header">
                        <h3 className="page-title">DayWork request</h3>
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            type="button"
                            onClick={toggle}
                        />
                    </div>
                    <div className="modal__body text-left mt-5">
                        <DayWorkForm
                            getDayWorkRequests={getDayWorkRequests}
                            item={currentDayWork}
                            moreImageID={moreImageID}
                            toggleMoreImage={toggleMoreImage}
                            getPhotosByStep={getPhotosByStep}
                            setModal={setModalIsOpen}
                            setLoadTable={setModalIsOpen}
                            showButtons={false}
                        />
                        <div>
                            <ReactToPrint
                                trigger={() => (
                                    <a
                                        className="print-btn d-inline-block mt-2 float-right"
                                        role="button"
                                    >
                                        <PrintIcon /> <span>Print</span>
                                    </a>
                                )}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                </Modal>
            }

            <div style={{ display: "none" }}>
                <RenderComponent ref={componentRef} item={currentDayWork} />
            </div>
        </>
    );
};
const mapDispatchToProps = {
    pushCrumbs,
};

function mapStateToProps() {
    return {};
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UninvoicedDayWorkTable)
);
