import React, { useState } from "react";
import { Card, CardBody, Col, Button, Modal } from "reactstrap";
import {
  RequestsIndicator,
  CalendarIcon,
  ClockIcon,
  PoundIcon,
} from "../../../constants/icons";
import Moment from "react-moment";
import DayWorkForm from "../Forms/DayWorkForm";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import { DAY_WORK_STATUS } from "../../../constants/enamerations";
import {calculateDayWorkHourCost} from "../../../config/func";
import {formatPrice} from "../../../helpers/function";
const DayWorkRequests = ({ dayWork, getDayWorkRequests }) => {
  const [moreImageID, setMoreImageID] = useState(null);
  const [modal, setModal] = useState(false);
  const [photos, setPhotos] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentDayWork, setCurrentDayWork] = useState(null);

  const toggle = (item) => {
    setCurrentDayWork(item);
    setModal(!modal);
  };

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    dayWork.items.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };

  return (
    <>
      {dayWork &&
        dayWork.items &&
        dayWork.items.map(
          (item, index) =>
            [
              DAY_WORK_STATUS.PENDING.value,
              DAY_WORK_STATUS.APPROVED_BY_PM.value,
            ].includes(item.status) && (
              <Col key={index} md={4} xl={4}>
                <Card>
                  <CardBody>
                    <div className="wrapper_page-title d-flex align-items-center mb-3">
                      <h5 className="page-title m-0">DAYWORK REQUEST &nbsp;</h5>
                      <RequestsIndicator />
                    </div>

                    <div>
                      <p>{item && item.description}</p>
                    </div>

                    <div className="mt-2 d-flex justify-content-around">
                      {item && item.levels && (
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Level &nbsp;
                          </h3>
                          <p className="m-0">{item.levels.id}</p>
                        </div>
                      )}
                      {item && item.levels && item.levels.block && (
                        <div className="d-flex align-items-center">
                          <h3 className="page-subhead subhead m-0">
                            Block &nbsp;
                          </h3>
                          <p className="m-0">{item.levels.block.id}</p>
                        </div>
                      )}
                    </div>

                    <div className="hr_wrapper">
                      <hr />
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <p>
                          <CalendarIcon /> &nbsp;{" "}
                          <Moment format="LL">{item && item.date_from}</Moment>{" "}
                          - <Moment format="LL">{item && item.date_to}</Moment>
                        </p>
                        <p>
                          <ClockIcon /> &nbsp;{" "}
                          <Moment parse="HH:mm:ss" format="LT">
                            {item && item.start_at}
                          </Moment>{" "}
                          -{" "}
                          <Moment parse="HH:mm:ss" format="LT">
                            {item && item.end_at}
                          </Moment>
                        </p>
                        <p className="d-flex align-items-center">
                          <PoundIcon />
                          <span className="ml-2">{formatPrice(calculateDayWorkHourCost({
                            startDate: item.date_from,
                            endDate: item.date_to,
                            startTime: item.start_at,
                            endTime: item.end_at,
                            dayworkHour: item.hourly_rate,
                            userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                            budget: item.budget
                          }).cost)}</span>
                        </p>
                      </div>
                      <div className="d-flex align-items-end">
                        <Button
                          className="m-0"
                          color="primary"
                          onClick={() => toggle(item)}
                        >
                          Review
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
        )}

      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={viewerIsOpen}
      />

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary additional_work-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">DayWork request</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggle}
            />
          </div>
          <div className="modal__body text-left mt-5">
            <DayWorkForm
              getDayWorkRequests={getDayWorkRequests}
              item={currentDayWork}
              moreImageID={moreImageID}
              toggleMoreImage={toggleMoreImage}
              getPhotosByStep={getPhotosByStep}
              setModal={setModal}
              setLoadTable={setModal}
              showButtons={true}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DayWorkRequests;
