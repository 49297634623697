export const getAllTransformedQaSteps = (allQaSteps) => {
    let tempQaSteps = []
    allQaSteps.forEach((qaStep, index) => {
        tempQaSteps.push({...qaStep, originalIndex: index})
    })
    return tempQaSteps
}

export const checkedQaStepsAreValid = (qaSteps, checkedIndexes) => {
    let qaStepsAreValid = true
    checkedIndexes.forEach((checkedIndex) => {
        const qaStep = qaSteps.find((qaStep) => qaStep.originalIndex === checkedIndex)
        if(!!qaStep){
            qaStepsAreValid = qaStepsAreValid && !!qaStep.description
        }
    })
    return qaStepsAreValid
}


export const isQaStepChecked = (checkedIndexes, qaStepIndex) => {
   return checkedIndexes.includes(qaStepIndex)
}

export const getAllUncheckedSteps = (allQaSteps, checkedIndexes) => {
   let uncheckedSteps = []
    allQaSteps.forEach((qaStep,index) => {
        if(!checkedIndexes.includes(index)){
            uncheckedSteps.push(qaStep)
        }
    })
    return uncheckedSteps
}

export const getALlUpdatesSteps = (allQaSteps, checkedIndexes) => {
    let uncheckedSteps = []
    allQaSteps.forEach((qaStep,index) => {
        if(!checkedIndexes.includes(index)){
            uncheckedSteps.push(qaStep)
        }
    })
    return uncheckedSteps
}
