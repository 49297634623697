import React,{useEffect, useState} from 'react';
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Container, Button } from 'reactstrap';
import {SORT_BY} from "../../constants/enamerations";
import {generateInvoices} from '../../redux/api/invoice';
import {invoiceToCsv} from "../../redux/services/invoice";

const index = () => {
    const [status, setStatus] = useState('');
    const [res, setRes] = useState('');

    useEffect(()=>{
        const userStatus = window.localStorage.getItem('STATUS');
        const userRes = window.localStorage.getItem('RES');
        if(userStatus !== null) setStatus(JSON.parse(userStatus));
        if(userRes !== null) setRes(JSON.parse(userRes));
    },[])

    useEffect(()=>{
        window.localStorage.setItem('STATUS', JSON.stringify(status));
        window.localStorage.setItem('RES', JSON.stringify(res));
    }, [status])

    const handleClick = () =>{
        generateInvoices().then(response => {
            if(response && response.length){
                setRes(response);
                setStatus(`${response.length} invoices were created in projects:`)
            }
            else setStatus("There are no jobs completed since the last invoice generation.")
        })
    }

    const getAllUnpaidInvoices = () => {
        invoiceToCsv([]).then(() => {})
    };
    return (
        <Container>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Invoices</h5>
                    </Col>
                </Col>
            </Row>
                <Button
                    className="btn_with_img"
                    color="primary"
                    onClick={handleClick}>
                    Generate Invoices
                </Button>
            <div className={'mt-3'}>
                <Button
                    className="btn_with_img"
                    color="primary"
                    onClick={getAllUnpaidInvoices}>
                    Export all unpaid invoices
                </Button>
            </div>
                <h5>{status}
                {status !== "There are no jobs completed since the last invoice generation." && res && res.map((currentItem, currentIndex, array) => {
                if(array[currentIndex+1])
                    return <Link
                    to={{pathname: `/projects/preview-project/${currentItem.project_id}/invoices`,
                    state: {sortBy: SORT_BY.PROJECT.value}}}> {currentItem.project_name}, </Link>
                else return <Link
                    to={{pathname: `/projects/preview-project/${currentItem.project_id}/invoices`,
                    state: {sortBy: SORT_BY.PROJECT.value}}}> {currentItem.project_name}</Link>
                }

                )}
                </h5>

        </Container>
    )
}

export default withRouter(index);
