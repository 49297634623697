import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { CreateIcon, QuestionMarkIcon } from '../../../constants/icons';
import {getVariationOrdersByProjectId} from '../../../redux/services/variation_orders'
import Loading from '../../../shared/components/Loading';
import VoRow from '../VoRow';
import CreateVoModal from './CreateVoModal';
import {getProjectsById, projectEdit} from '../../../redux/services/projects'
import {withRouter, Link} from 'react-router-dom';
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import { connect } from 'react-redux';
import { getUnknownRequestsCounter } from '../../../redux/services/requests';
import Select from 'react-select'
import { getAllAdmins } from '../../../redux/services/users';
import { totalAdditionalWorksAmount, totalDayworksAmount } from '../../../helpers/variation_order';
import {COMMERCIAL, UNKNOWN_REQUESTS,} from "../../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";


const ContractVariationOrders = ({match}) => {
  const id = match.params.id;
  const [project, setProject] = useState(null);
  const [vos, setVos] = useState(null);
  const [load, setLoad] = useState(false);
  const [createVo, setCreateVo] = useState(false);
  const [unknownReq, setUnknownReq] = useState(0);
  const [admins, setAdmins] = useState(null);
  const tableHeads = [
    "ID","DESCRIPTION", "CLIENT REFERENCE", "TYPE", "ORIGINAL BUDGET", "REVISED BUDGET", "TOTAL COST", "DIFF(REV-TOT)", "DAY WORKS", "BUDGET VARIATIONS"
  ]


  const fetchVariationOrders = () => {
    getVariationOrdersByProjectId(id)
    .then(res => {
      return setVos(res)
    })
  }

  const fetchProjects = () => {
    getProjectsById(id).then( res => {
      return setProject(res.data)
    })
  }

  const toggleCreateVoModal = () => {
    setCreateVo(curr => !curr)
  }

  const fetchUnknownReqCounter = () => {
    getUnknownRequestsCounter(id)
    .then(res => setUnknownReq(res.data))
  }


  const fetchAdmins = () => {
    getAllAdmins()
    .then(res => {
      if(res){
        const parsedResult = res.map(item => {return {value:item.id, label: `${item.first_name} ${item.surname}`}})
        setAdmins(parsedResult)
      }
    })
  }

  const updateProjectQsUser = (projectId, newQsUser) => {
    projectEdit(projectId, {
      qsUser: newQsUser,
    })
  }



  useEffect(()=>{
    setLoad(true);
    Promise.all([
    fetchVariationOrders(),
    fetchProjects(),
    fetchUnknownReqCounter(),
    fetchAdmins()
    ])
    setLoad(false);
  },[])

  if(load || !project || !admins) return <Loading/>

  if(!vos) return <p> No variation order found!</p>

  return (
  <Container>
    <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
      <Link to={{pathname: COMMERCIAL}} >
        <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
          Commercial
        </h3>

      </Link>
      {project && project.id && <Link to={{pathname: ''}} >
        <h3 className={"page-subhead subhead bread_crumbs-active"}>
          {project.name ? project.name : 'Current Project'}
        </h3>
      </Link>}
    </Breadcrumbs>
  <Row>
    <Col>
    <h2>{project.name}</h2>
    </Col>
    <Row className={'pr-4'}>
      <Select
        className="mr-3"
        options={admins}
        defaultValue={admins && project && project.qsUser && admins.find(item => item.value === project.qsUser.id)}
        onChange={(selectedOption) => updateProjectQsUser(project.id,selectedOption.value)}
        styles={{
          input: (provided) => ({
            ...provided,
            overflow:'visible',
            width:'8rem'
          }),
        }}
      />
      <Link to={{pathname: UNKNOWN_REQUESTS.replace(':id', id)}}>
        <Button className="btn_with_img" color="primary">
          <QuestionMarkIcon color="#fff"/> Unassigned Requests ({unknownReq})
        </Button>
      </Link>
      <Button
        className="btn_with_img"
        color="primary"
        onClick={toggleCreateVoModal}
      >
      <CreateIcon /> Create variation order
      </Button>
    </Row>
  </Row>
  <Col>
  <Row>
  <Card className="mt-3">
    <CardBody>
    <p style={{fontWeight: 400}}>VARIATION ORDERS</p>
    <Table
      responsive
      hover
      className="mt-3 table react-table table--bordered pl-0"
    >
      <thead>
        <tr>
        {
          tableHeads.map((head, index) => {
            return <th key={head+index}>{head}</th>
          })
        }
        </tr>
      </thead>
      <tbody>
        {
          vos.items.length
            ? vos.items.sort((a,b) => b.id - a.id).map((item,index) => {
              const dayworksBudget = item.dayWorks.length ? totalDayworksAmount(item.dayWorks) : 0;
              const additionalBudget = item.additionalWorks.length ? totalAdditionalWorksAmount(item.additionalWorks) : 0;
              return (
              <VoRow
                key={JSON.stringify(item)}
                vo={item}
                totalCost={dayworksBudget+additionalBudget}
              />
            )
            })
            : <p> No variation orders found!</p>
        }
      </tbody>
    </Table>
    </CardBody>
  </Card>
  </Row>
  </Col>
  <CreateVoModal
    toggleModal={toggleCreateVoModal}
    modalVisibility={createVo}
    project={project}
  />
  </Container>
  )
}

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps(
) {
  return{}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractVariationOrders));
