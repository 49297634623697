import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, Button, Table } from "reactstrap";
import { CreateIcon } from "../../constants/icons";
import Loading from "../../shared/components/Loader";
import { Field, FieldArray, reduxForm, initialize } from "redux-form";
import TextFieldMaterial from "../../shared/components/material_field/TextFieldMaterial";
import renderCheckBoxField from "../../shared/components/form/CheckBox";
import { getVat, saveVat } from "../../redux/services/vat";
import store from "../../redux/store";

function validateFields(field) {
  if (!field.name) {
    alert("You must enter a name!");
    return false;
  }

  if (!field.rate) {
    alert("You must enter a value for rate!");
    return false;
  }

  if (field.rate < 0) {
    alert("Rate must be a positive number!");
    return false;
  }

  if (!field.sageCode) {
    alert("You must enter a sage code!");
    return false;
  }

  return true;
}

const renderVatValues = ({ fields }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <Table responsive hover className="table react-table table--bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>VAT Rate</th>
            <th>Sage code</th>
            <th>DRC</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((vat, index) => (
            <tr key={index}>
              <td>
                {isEditing && currentIndex === index ? (
                  <Field
                    name={`${vat}.name`}
                    component={TextFieldMaterial}
                    placeholder={"Enter VAT name"}
                    required={true}
                    type={"text"}
                  />
                ) : (
                  fields.get(index).name
                )}
              </td>

              <td>
                {isEditing && currentIndex === index ? (
                  <Field
                    name={`${vat}.rate`}
                    component={TextFieldMaterial}
                    placeholder={"Enter rate"}
                    type={"number"}
                    required={true}
                  />
                ) : (
                  fields.get(index).rate + " %"
                )}
              </td>

              <td>
                {isEditing && currentIndex === index ? (
                  <Field
                    name={`${vat}.sageCode`}
                    component={TextFieldMaterial}
                    placeholder={"Enter sage code"}
                    required={true}
                    type={"text"}
                  />
                ) : (
                  fields.get(index).sageCode
                )}
              </td>

              <td>
                <Field
                  name={`${vat}.drc`}
                  component={renderCheckBoxField}
                  disabled={!isEditing || currentIndex !== index}
                  type={"checkbox"}
                  checked={
                    isEditing && currentIndex === index
                      ? null
                      : fields.get(index).drc
                  }
                />
              </td>

              <td>
                {isEditing && currentIndex === index ? (
                  <Button
                    className={"editButton"}
                    onClick={async () => {
                      if (validateFields(fields.get(index))) {
                        setIsEditing(false);
                        await saveVAT();
                      }
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    className={"editButton"}
                    onClick={() => {
                      setCurrentIndex(index);
                      setIsEditing(true);
                    }}
                    disabled={isEditing}
                  >
                    Edit
                  </Button>
                )}
              </td>

              <td>
                <Button
                  className={"deleteButton"}
                  onClick={async () => {
                    fields.remove(index);
                    await saveVAT();
                  }}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Button
        className="btn_with_img mt-5"
        color="primary"
        onClick={() => {
          fields.push({ name: "", rate: 0, sageCode: "", drc: false });

          setIsEditing(true);
          setCurrentIndex(fields.length);
        }}
      >
        <CreateIcon /> Add category
      </Button>
    </>
  );
};

async function saveVAT() {
  const formValue = store.getState().form.vat_values.values;

  await saveVat(formValue);
}

async function getValues() {
  await getVat().then((response) => {
    store.dispatch(initialize("vat_values", response));
  });
}

const Settings = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit } = props;

  useEffect(() => {
    getValues().then(() => {
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <form onSubmit={handleSubmit}>
      <Container>
        <Card>
          <CardBody>
            <h3>VAT Settings</h3>

            <FieldArray
              name="vatValues"
              component={renderVatValues}
              rerenderOnEveryChange={false}
            />
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: "vat_values",
  enableReinitialize: true,
  // initialValues: {
  //   vatValues: [
  //     { name: "Name1", rate: "50", sageCode: "76171482", drc: true },
  //     { name: "Name2", rate: "20", sageCode: "31378572", drc: true },
  //     { name: "Name3", rate: "70", sageCode: "67727377", drc: false },
  //   ],
  // },
})(Settings);
