import React, { useState, useEffect } from "react";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Container, Row, Col, Card, CardBody, Button, Modal } from "reactstrap";
import { SettingsIcon } from "../../../constants/icons";
import ProjectAnalytics from "./Analytics";
import {
  getProjectsById,
  projectStart,
  projectDelete,
  projectEdit,
  projectStop,
  getUsersByProject,
} from "../../../redux/services/projects";
import Loading from "../../../shared/components/Loader";
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import { StartIcon, DeleteIcon } from "../../../constants/icons";
import {
  PROJECT_STATUS,
  PROJECT_ACTION,
} from "../../../constants/enamerations";
import ChangeModal from "../Modal/ChangeModal";
import ProjectEdit from "../Forms/ProjectEdit";
import { getAllContracts } from "../../../redux/services/contracts";
import {CONTRACTS, PREVIEW_CONTRACT, PREVIEW_PROJECT} from "../../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";

const index = ({ match, history, form }) => {
  const [project, setProject] = useState(null);
  const [contracts, setContracts] = useState();
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [changeModalBody, setChangeModalBody] = useState(null);
  const [modalLoad, setModalLoad] = useState(false);
  const [modalEditProject, setModalEditProject] = useState(false);
  const [projectUsers, setProjectUsers] = useState(null);
  const [modalUsers, setModalUsers] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    getProject();
    getAllContracts().then(res => {
      const helperContracts = res.items.map(contract => {return {value: contract.id, label:contract.name}})
      setContracts(helperContracts)
    });
  }, []);

  const getProject = () => {
    setLoad(true);
    getProjectsById(+match.params.id).then((res) => {
      setProject(res.data);
      setLoad(false);
    });
  };

  const getUserByProject = (userType) => {
    getUsersByProject(+match.params.id, userType).then((res) => {
      setProjectUsers(res);
      setModalUsers(true);
    });
  };
  const toggleModal = () => setModalUsers(!modalUsers);

  const changeModal = (action) => {
    setModal(!modal);
    setChangeModalBody(action ? action : null);
  };

  const toggleModalEditProject = () => {
    setModalEditProject(!modalEditProject);
  };

  const startProject = (id) => {
    setModalLoad(true);
    projectStart(id, PROJECT_STATUS.ACTIVE.value).then(() => {
      setModalLoad(false);
      setModal(!modal);
      history.push(`/projects`);
    });
  };

  const deleteProject = (id) => {
    setModalLoad(true);
    projectDelete(id).then(() => {
      setModalLoad(false);
      setModal(!modal);
      history.push(`/contracts`);
    });
  };

  const stopProject = (id) => {
    setModalLoad(true);
    projectStop(id, PROJECT_STATUS.COMPLETED.value).then(() => {
      setModalLoad(false);
      setModal(!modal);
      history.push(`/contracts`);
    });
  };

  const editProject = (data) => {
    let error = {};
    let isError = false;

    if (!data.name) {
      error.name = "Name field shouldn`t be empty";
      isError = true;
    }
    if (!data.budget) {
      error.budget = "Budget shouldn`t be empty";
      isError = true;
    }
    if (isError) {
      throw new SubmissionError(error);
    } else {
      if (!isError) {
        const req = { ...data };
        req.name = req.name.toString().trim()
        req.budget = +req.budget.replace("£ ", "");

        toggleModalEditProject();
        projectEdit(match.params.id, req).then(() => {
          getProject();
        });
      }
    }
  };

  const contract = project && project.projectGroup ? project.projectGroup : null

  const projectName = project && project.name ? project.name : null
  const projectId = project && project.id ? project.id : null

   if(load) return <Loading />

  return(
    <Container>
      <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
        <Link to={{pathname: CONTRACTS}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            Contracts
          </h3>

        </Link>
        {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            {!!contract.name ? contract.name : `Current Contract`}
          </h3>
        </Link>}
        {projectName && projectId && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',projectId)}} >
          <h3 className={"page-subhead subhead bread_crumbs-active"}>
            {projectName}
          </h3>
        </Link>}


      </Breadcrumbs>
      <Row className="pr-3 d-flex justify-content-between">
        <Col md={5} xl={5} className="d-flex align-items-center mb-4">
          <Col md={1.5} xl={1.5} className="p-0">
            <h5 className="page-title m-0">{project && project.name}</h5>
          </Col>
          <Col
            md={1.5}
            xl={1.5}
            className="p-0 ml-3 hover"
            onClick={() => toggleModalEditProject()}
          >
            <SettingsIcon />
          </Col>
          <Col
            md={1.5}
            xl={1.5}
            className="p-0 ml-3 hover"
            >
            <Link
                to={{pathname: `/projects/preview-projects/${+match.params.id}/structure`}}>
              <Button className="m-0" color="primary">
                Modify structure
              </Button>
            </Link>
          </Col>
        </Col>
        {match.params && match.params.status === PROJECT_STATUS.DRAFT.value && (
            <div className="d-flex">
              <Button
                className="btn_with_img"
                color="primary"
                onClick={() => changeModal(PROJECT_ACTION.START.value)}
              >
                <StartIcon /> Start project
              </Button>
              <Button
                className="btn_with_img"
                color="danger"
                onClick={() => changeModal(PROJECT_ACTION.DELETE.value)}
              >
                <DeleteIcon /> Delete project
              </Button>
            </div>
          )}
        {match.params && match.params.status === PROJECT_STATUS.ACTIVE.value && (
            <div className="d-flex">
              <Button
                className="btn_with_img"
                color="primary"
                onClick={() => changeModal(PROJECT_ACTION.STOP.value)}
              >
                <StartIcon /> Stop project
              </Button>
            </div>
          )}
      </Row>
      <Row className="pr-3 pl-3 d-flex">
        <Card>
          <CardBody className="d-flex">
            <Col md={8} xl={8} className="border-right pl-0">
              <div className="wrapper_page-title">
                <h5 className="page-title m-0">DESCRIPTION</h5>
              </div>
              <p>{project && project.description}</p>

              <div className="wrapper_page-title mt-2">
                <h5 className="page-title m-0">VAT</h5>
              </div>
              <div className="d-flex flex-row align-items-center">
                {project && (
                  <p className="m-0">
                    <span style={{ color: "#999999" }}>Name:</span>
                    {" " + project.vat.name}
                  </p>
                )}

                {project && (
                  <p className="m-0 ml-2">
                    <span style={{ color: "#999999" }}>VAT Rate:</span>
                    {" " + project.vat.rate}
                  </p>
                )}

                {project && (
                  <p className="m-0 ml-2">
                    <span style={{ color: "#999999" }}>Sage code:</span>
                    {" " + project.vat.sageCode}
                  </p>
                )}

                {project && (
                  <p className="m-0 ml-2">
                    <span style={{ color: "#999999" }}>DRC:</span>
                    {project.vat.drc ? " applied" : " not applied"}
                  </p>
                )}
              </div>
            </Col>
            <Col md={4} xl={4} className="d-flex pr-0">
              <Col>
                <Row className="d-flex flex-column">
                  <div className="wrapper_page-title">
                    <h5 className="page-title m-0">ADDRESS</h5>
                  </div>
                  <p className="m-0">
                    {project &&
                      project.street +
                        ` ` +
                        project.house_number +
                        `, ` +
                        project.city +
                        `, ` +
                        project.post_code}
                  </p>
                </Row>
                <div className="d-flex justify-content-around">
                  <Row className="d-flex flex-column mt-3">
                    <div className="wrapper_page-title">
                      <h5
                        className="page-title m-0 hover-p"
                        onClick={() => {
                          getUserByProject("qa");
                          setName("QUALITY ASSURANCE");
                        }}
                      >
                        QUALITY ASSURANCE
                      </h5>
                    </div>
                  </Row>
                  <Row className="d-flex flex-column mt-3">
                    <div className="wrapper_page-title">
                      <h5
                        className="page-title m-0 hover-p"
                        onClick={() => {
                          getUserByProject("pm");
                          setName("PROJECT MANAGER");
                        }}
                      >
                        PROJECT MANAGER
                      </h5>
                    </div>
                  </Row>
                </div>
              </Col>
            </Col>
          </CardBody>
        </Card>
      </Row>
      <Row className="mt-4">
        {project && (
          <ProjectAnalytics
              item={project}
          />
        )}
      </Row>

      {modalUsers && (
        <Modal
          isOpen={modalUsers}
          toggle={toggleModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">{name}</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModal}
            />
          </div>
          <div className="modal__body modal_body">
            <p className="form__form-group-label m-0 modal-p-users">
              <b>{name}</b>:{" "}
              {projectUsers && name === "QUALITY ASSURANCE"
                ? projectUsers.qa
                : projectUsers.pm}
            </p>
          </div>
        </Modal>
      )}

      {modal && (
        <Modal
          isOpen={modal}
          toggle={changeModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">
              {changeModalBody === PROJECT_ACTION.START.value &&
                `Start project`}
              {changeModalBody === PROJECT_ACTION.DELETE.value &&
                `Delete project`}
              {changeModalBody === PROJECT_ACTION.STOP.value && `Stop project`}
            </h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={changeModal}
            />
          </div>
          <div className="modal__body">
            {modalLoad ? (
              <div className="mt-5">
                <Loading />
              </div>
            ) : (
              <ChangeModal
                changeModalBody={changeModalBody}
                changeModal={changeModal}
                startProject={startProject}
                deleteProject={deleteProject}
                stopProject={stopProject}
                project={project}
              />
            )}
          </div>
        </Modal>
      )}

      {modalEditProject && (
        <Modal
          isOpen={modalEditProject}
          toggle={toggleModalEditProject}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">Edit project</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModalEditProject}
            />
          </div>
          <div className="modal__body">
            <ProjectEdit
              initialValues={{
                name: !!project.name ? project.name.trim(): '',
                budget: project.budget,
                street: project.street,
                house_number: project.house_number,
                project_group_id: project.project_group_id,
                city: project.city,
                post_code: project.post_code,
                description: project.description,
              }}
              contracts={contracts}
              f={form}
              toggle={toggleModalEditProject}
              editProject={editProject}
            />
          </div>
        </Modal>
      )}
    </Container>
  )
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps({ form }) {
  return { form };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
