import React from 'react'
import { deleteUsers } from "../../../redux/services/users";
import { USERS } from "../../../constants/routes";
import { Button, Card, CardBody, Col, Modal } from 'reactstrap';


function DeleteUserModal({
  userId,
  modalDeleteVisibility,
  toggleModalDelete,
  history
}) {


  const deleteUser = () => {
    deleteUsers(userId).then(() => {
      history.push(USERS);
      toggleModalDelete();
    });
  };

  return (
    <Modal
      isOpen={modalDeleteVisibility}
      toggle={toggleModalDelete}
      modalClassName={`ltr-support`}
      className={`modal-dialog--primary locum-preview-modal`}
    >
    <div className="modal__header">
      <h3 className="page-title">Delete user</h3>
      <button
        className="lnr lnr-cross modal__close-btn"
        type="button"
        onClick={toggleModalDelete}
      />
    </div>
    <div className="modal__body">
      <Col md={12} lg={12} className="p-0 mt-5">
      <Card className="p-0">
      <CardBody className="p-0 pt-4">
        <div className="wrapper_page-title mt-3">
          <p className="form__form-group-label m-0">
            Are you sure you want to delete this user?
          </p>
        </div>
        <div className="form__form-group m-0 form__form-group-field d-flex justify-content-end">
          <div className="d-flex">
            <Button
              className="mb-0"
              color="secondary"
              onClick={toggleModalDelete}
            >
              Cancel
            </Button>
            <Button
              className="mb-0"
              color="danger"
              onClick={deleteUser}
            >
              OK
            </Button>
          </div>
        </div>
      </CardBody>
      </Card>
      </Col>
    </div>
  </Modal>
  )
}

export default DeleteUserModal