import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, Table, Modal } from "reactstrap";
import { reduxForm, FieldArray } from "redux-form";
import AddTypeForm from "./components/AddTypeForm";
import EditTypeForm from "./components/EditTypeForm";
import UploadPlanForm from "../../Jobs/Forms/UploadPlanForm";
import { uploadFilePlan, uploadPhotoPlan } from "../../../redux/services/jobs";
import store from "../../../redux/store";

const renderColorCircle = ({ color }) => {
  return <div className="colorCircle" style={{ backgroundColor: color }}></div>;
};

const renderTypes = ({ fields, saveProject }) => {
  const [typePlan, setTypePlan] = useState(null);
  const [modal, setModal] = useState(false);
  const [uploadState, setUploadState] = useState(false);
  const [loadUploadFile, setLoadUploadFile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const addType = (data) => {
    fields.push({
      reference: data.reference,
      noOfBedrooms: data.noOfBedrooms,
      noOfBathrooms: data.noOfBathrooms,
      colorCode: data.colorCode,
    });
  };

  const editType = (data, index) => {
    const oldType = fields.get(index);

    fields.splice(index, 1, {
      reference: data.reference,
      noOfBedrooms: data.noOfBedrooms,
      noOfBathrooms: data.noOfBathrooms,
      colorCode: data.colorCode,
      ...(fields.get(index).hasOwnProperty("flatPlanFile") && {
        flatPlanFile: fields.get(index).flatPlanFile,
      }),
      ...(fields.get(index).hasOwnProperty("floorPlanFile") && {
        floorPlanFile: fields.get(index).floorPlanFile,
      }),
    });

    // Modify the level in the allocated jobs too
    if (store.getState().form.create_project_form_wizard.values.allocatedJobs) {
      const allocatedJobs = store.getState().form.create_project_form_wizard
        .values.allocatedJobs;

      allocatedJobs.forEach((element) => {
        if (element.unitType === oldType.reference) {
          element.unitType = data.reference;
        }
      });
    }

    if (store.getState().form.create_project_form_wizard.values.blocks) {
      const blocks = store.getState().form.create_project_form_wizard.values
        .blocks;

      blocks.forEach((block) => {
        if (block.levels) {
          const levels = block.levels;

          levels.forEach((level) => {
            if (level.flats) {
              const flats = level.flats;

              flats.forEach((flat) => {
                if (flat.type) {
                  if (flat.type.reference) {
                    if (flat.type.reference === oldType.reference) {
                      flat.type.reference = data.reference;
                    }
                  }
                }
              });
            }
          });
        }
      });
    }
  };

  const addFlatPlan = (fileName) => {
    const helperVar = fields.get(currentIndex);

    helperVar.flatPlanFile = fileName;

    fields.splice(currentIndex, 1, helperVar);
  };

  const addFloorPlan = (fileName) => {
    const helperVar = fields.get(currentIndex);

    helperVar.floorPlanFile = fileName;

    fields.splice(currentIndex, 1, helperVar);
  };

  const toggleModal = (type, index) => {
    setTypePlan(type);
    setUploadState(false);
    setModal(!modal);
    setCurrentIndex(index);
  };

  const getUploadFile = (file) => {
    setLoadUploadFile(true);

    const fileName = `${file.type.split("/")[0]}${Math.floor(
      Math.random() * 99999
    ) + 1}.${file.type.split("/")[1]}`;

    uploadFilePlan(typePlan, fileName).then((res) =>
      uploadPhotoPlan(res.data.signed_url, file).then(() => {
        typePlan === "FLAT" ? addFlatPlan(fileName) : addFloorPlan(fileName);

        setLoadUploadFile(false);
        setUploadState(true);
      })
    );
  };

  return (
    <form>
      <Container>
        <Row md={12} className="justify-content-between">
          <Col md={4}>
            <h2 className="text-nowrap">Create apartment/house types</h2>
          </Col>

          <div className="buttonContainer">
            <Col md={6}>
              <AddTypeForm onSubmit={addType} />
            </Col>

            <Col md={3}>
              <Button className="button" onClick={saveProject}>
                Save
              </Button>
            </Col>

            <Col md={3} className={"justify-content-end d-flex"}>
              <Button
                type={"submit"}
                className="text-nowrap button"
                disabled={fields.length === 0}
              >
                Next
              </Button>
            </Col>
          </div>
        </Row>

        <Container md={12} className="mt-3  innerContainerSecond">
          <Table responsive hover className="table react-table table--bordered">
            <thead>
              <tr>
                <th className="centeredText">TYPE</th>
                <th className="centeredText">NUMBER OF BEDROOMS</th>
                <th className="centeredText">NUMBER OF BATHROOMS</th>
                <th className="centeredText">COLOR CODE</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields && fields.length
                ? fields.getAll().map((type, index) => (
                    <tr key={index}>
                      <td className="centered">{type.reference}</td>
                      <td>
                        <div className="centered">{type.noOfBedrooms}</div>
                      </td>
                      <td>
                        <div className="centered">{type.noOfBathrooms}</div>
                      </td>
                      <td>
                        <div className="centered">
                          {renderColorCircle({ color: type.colorCode })}
                        </div>
                      </td>
                      <td>
                        {type.hasOwnProperty("flatPlanFile") ? (
                          <a
                            href={`https://job-assets.s3.eu-west-2.amazonaws.com/jobs/plan/FLAT/${type.flatPlanFile}`}
                          >
                            View: {type.flatPlanFile}
                          </a>
                        ) : (
                          <div className="centered">
                            <Button
                              className="editButton"
                              onClick={() => {
                                toggleModal("FLAT", index);
                              }}
                            >
                              ADD FLATPLAN
                            </Button>
                          </div>
                        )}
                      </td>
                      <td>
                        {type.hasOwnProperty("floorPlanFile") ? (
                          <a
                            href={`https://job-assets.s3.eu-west-2.amazonaws.com/jobs/plan/FLOOR/${type.floorPlanFile}`}
                          >
                            View: {type.floorPlanFile}
                          </a>
                        ) : (
                          <div className="centered">
                            <Button
                              className="editButton"
                              onClick={() => {
                                toggleModal("FLOOR", index);
                              }}
                            >
                              ADD FLOORPLAN
                            </Button>
                          </div>
                        )}
                      </td>
                      <td>
                        <div className="centered">
                          <EditTypeForm
                            reference={type.reference}
                            noOfBedrooms={type.noOfBedrooms}
                            noOfBathrooms={type.noOfBathrooms}
                            colorCode={type.colorCode}
                            onSubmit={(data) => editType(data, index)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="centered">
                          <Button
                            className="deleteButton"
                            onClick={() => {
                              fields.remove(index);
                            }}
                          >
                            DELETE TYPE
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Container>
      </Container>

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary locum-preview-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">
              {typePlan ? `Assign ${typePlan} plan` : `Job Create`}
            </h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggleModal}
            />
          </div>
          <div className="modal__body">
            <UploadPlanForm
              toggle={toggleModal}
              uploadState={uploadState}
              loadUploadFile={loadUploadFile}
              getUploadFile={getUploadFile}
              typePlan={typePlan}
            />
          </div>
        </Modal>
      )}
    </form>
  );
};

let CreateProjectStepThree = (props) => {
  const { handleSubmit, saveProject } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="types"
        component={renderTypes}
        rerenderOnEveryChange={true}
        props={{
          saveProject,
        }}
      />
    </form>
  );
};

CreateProjectStepThree = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepThree);

export default CreateProjectStepThree;
