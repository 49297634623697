import React, { useRef, useEffect, useState } from 'react';

const NamePicture = ({ first_name, surname }) => {
    const div = useRef(null);
    const [size, setSize] = useState(10);
    let name = '';

    first_name ? name += first_name[0] : name += '?'
    surname ? name += surname[0] : name += '?'

    useEffect(() => {
        div.current && div.current.offsetWidth && setSize(div.current.offsetWidth / 2.5)
    }, [div.current]);

    return (
        <div className='name-picture' ref={div}>
            <span style={{ fontSize: `${size}px` }}>{name}</span>
        </div>
    )
};

export default NamePicture;
