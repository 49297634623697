import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { CreateIcon } from '../../constants/icons';
import ContractsTable from './ContractsTable';
import CreateContractModal from './PreviewContract/Modals/CreateContractModal'
import Loading from '../../shared/components/Loading';
import { getAllContracts } from "../../redux/services/contracts";

const Contracts = ({match}) => {
  const [createContractModal, setCreateContractModal] = useState(false);
  const [contracts, setContracts] = useState(null);
  const [load, setLoad] = useState(false);

  const toggleCreateContractModal = () => {
    setCreateContractModal(curr => !curr)
  }

  const fetchContracts = () => {
    setLoad(true);
    getAllContracts()
    .then((res) => {
      setContracts(res);
      setLoad(false);
    });
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  if(load) return <Loading/>

  return (
    <Container>
    <Row>
      <Col md={12} xl={12} className="d-flex justify-content-between">
        <h1 className="page-title">Contracts</h1>
        <Button
          className="btn_with_img"
          color="primary"
          onClick={toggleCreateContractModal}
        >
          <CreateIcon /> Create contract
        </Button>
      </Col>

      <Col md={12} xl={12}>
        <Card>
          <CardBody>
            <ContractsTable
            match={match}
            contracts={contracts}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
    {
      createContractModal &&
      <CreateContractModal
        modalVisibility={createContractModal}
        toggleModal={toggleCreateContractModal}
        refetch={fetchContracts}
      />
    }
    </Container>
  )
}

export default Contracts
