import axios from "axios";
import { BASE_API } from "../../config/api";

export const getVatAsync = async () =>
  await axios
    .get(`${BASE_API}vat/getSavedVAT`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveVatAsync = async (formValue) =>
  await axios
    .post(`${BASE_API}vat/saveVAT/11`, {
      vatValues: formValue,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);
