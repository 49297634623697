import React from "react";
import { BuildIcon } from "../../../constants/icons";
import { DAYWORK_STATUS } from "../../../constants/enamerations";
import {calculateDayWorkHourCost} from "../../../config/func";
import Moment from "react-moment";
import {formatPrice} from "../../../helpers/function";

const DayWorkPrint = ({ item }) => {
  return (
    <>
      <div className="print_wrapper w-100">
        <div className="print_header">
          <div className="logo_header">
            <img
              className="pdfLogoimage"
              src={require("../../../shared/img/logo/pdfLogo.png")}
              alt={"logo"}
            />
          </div>
          <div className="contact_header">
            <p>020 3909 8880</p>
            <p>info@londonmep.co.uk</p>
          </div>
        </div>

        <div className="body_wrapper">
          <div className="d-flex project_build_wrapper justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <BuildIcon /> &nbsp; {item && item.project.name}
            </div>

            <div className="d-flex">
              <div className="ml-4">
                <span className="dim mr-1">Level</span>
                {item && item.levels && item.levels.name ? (
                  <span>{item.levels.name}</span>
                ) : (
                  <span>NA</span>
                )}
              </div>

              {/* <div className="ml-4">
                <span className="dim mr-1">Unit type</span>
                <span>C</span>
              </div> */}

              <div className="ml-4">
                <span className="dim mr-1">Block</span>
                {item && item.levels && item.levels.block.name ? (
                  <span>{item.levels.block.name}</span>
                ) : (
                  <span>NA</span>
                )}
              </div>
            </div>
          </div>

          <h5 className="text-uppercase my-5">Daywork request</h5>

          <div className="mb-4">
            <div
              className="d-flex justify-content-between daywork-print-wrapper"
              style={{
                borderLeft: `4px solid ${item &&
                  item.status &&
                  DAYWORK_STATUS[item.status].color}`,
              }}
            >
              {item && item.description ? (
                <div className="daywork-print-desc pr-2">
                  <div className="pl-2">
                    <p className="text-uppercase">Comment</p>
                    <p>{item && item.description}</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="pl-2 daywork-print-details">
                <div className="mb-3 d-flex flex-row flex-wrap">
                  {item && item.assets.length > 0
                    ? item.assets.map((img) => {
                        return (
                          <div key={img.id} className="mb-2">
                            <img
                              src={img.public_url}
                              alt=""
                              className="daywork-img-print"
                            />
                          </div>
                        );
                      })
                    : null}
                </div>

                <div className="daywork-print-info d-flex flex-column">
                  <div className="info-inner flex-row d-flex">
                    <span className="dim mr-2">Amount</span>
                    {item && <span>{formatPrice(calculateDayWorkHourCost({
                      startDate: item.date_from,
                      endDate: item.date_to,
                      startTime: item.start_at,
                      endTime: item.end_at,
                      dayworkHour: item.hourly_rate,
                      userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                      budget: item.budget,}).cost)}</span>}
                  </div>
                  <div className="info-inner flex-row d-flex">
                    <span className="dim mr-2">Date</span>
                    <span>
                      {" "}
                      <Moment format="LL">
                        {item && item.date_from}
                      </Moment> -{" "}
                      <Moment format="LL">{item && item.date_to}</Moment>
                    </span>
                  </div>
                  <div className="info-inner flex-row d-flex">
                    <span className="dim mr-2">Time</span>
                    <span>
                      <Moment parse="HH:mm:ss" format="LT">
                        {item && item.start_at}
                      </Moment>{" "}
                      -{" "}
                      <Moment parse="HH:mm:ss" format="LT">
                        {item && item.end_at}
                      </Moment>
                    </span>
                  </div>
                  <div className="info-inner flex-row d-flex">
                    <span className="dim mr-2">Name</span>
                    {item && item.creator ? (
                      <span>
                        {item.creator.first_name}&nbsp;{item.creator.surname}
                      </span>
                    ) : (
                      <span>NA</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="gallery-divider" />
        </div>
      </div>

      {item
        ? item.assets.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>
                    <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                {item.assets.map((img) => {
                  return (
                    <tr key={img.id}>
                      <td>
                        <div style={{ padding: `30px 30px` }}>
                          <img src={img.public_url} alt="" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
        : null}
    </>
  );
};

export default DayWorkPrint;
