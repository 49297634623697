import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { reduxForm, FieldArray, change } from "redux-form";
import { WORK_TYPE } from "../../../constants/enamerations";
import Pagination from "@material-ui/lab/Pagination";
import store from "../../../redux/store";
import AddCostTypeForm from "./components/AddCostTypeForm";
import EditBudgetForm from "./components/EditBudgetForm";
import { WORK_TYPE_OPTIONS } from "../../../constants/options";
import { FlatOptionsIcon } from "../../../constants/icons";
import EditFlatForm from "./components/EditFlatForm";

const pageSize = 10;

const renderJobs = ({ fields, costTypes, saveProject }) => {
  const [electricalPage, setElectricalPage] = useState(0);
  const [mechanicalPage, setMechanicalPage] = useState(0);
  const [ventilationPage, setVentilationPage] = useState(0);
  const [svprwgPage, setSvprwgPage] = useState(0);
  const [electricalLength, setElectricalLength] = useState(0);
  const [mechanicalLength, setMechanicalLength] = useState(0);
  const [ventilationLength, setVentilationLength] = useState(0);
  const [svprwgLength, setSvprwglength] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [currentCostType, setCurrentCostType] = useState("");
  const [editingCostTypeName, setEditingCostTypeName] = useState(null);

  useEffect(() => {
    const jobsWithoutCostType = fields.getAll().filter((job) => {
      return !job.hasOwnProperty("costTypes");
    });

    for (let i = 0; i < jobsWithoutCostType.length; i++) {
      const index = fields
        .getAll()
        .findIndex((job) => job.id === jobsWithoutCostType[i].id);

      var helperVar = fields.get(index);
      helperVar.costTypes = costTypes;
      fields.splice(index, 1, helperVar);
    }

    setElectricalLength(
      fields
        .getAll()
        .filter((job) => job.trade.value === WORK_TYPE_OPTIONS[1].value).length
    );

    setMechanicalLength(
      fields
        .getAll()
        .filter((job) => job.trade.value === WORK_TYPE_OPTIONS[2].value).length
    );

    setVentilationLength(
      fields
        .getAll()
        .filter((job) => job.trade.value === WORK_TYPE_OPTIONS[3].value).length
    );

    setSvprwglength(
      fields
        .getAll()
        .filter((job) => job.trade.value === WORK_TYPE_OPTIONS[4].value).length
    );
  }, []);

  function addCostType(data) {
    for (let i = 0; i < fields.length; i++) {
      const job = fields.get(i);

      if (job.costTypes.length) {
        const newCostTypes = [
          ...job.costTypes,
          { costTypeName: data.costTypeName, budget: 0 },
        ];

        job.costTypes = newCostTypes;
      } else {
        job.costTypes = [{ costTypeName: data.costTypeName, budget: 0 }];
      }
    }
  }

  function removeCostType(costTypeName) {
    for (let i = 0; i < fields.length; i++) {
      var helperArray = fields.get(i).costTypes;
      var helperVar = fields.get(i);

      helperArray.splice(
        helperArray.findIndex((value) => value.costTypeName === costTypeName),
        1
      );
      helperVar.costTypes = helperArray;

      fields.splice(i, 1, helperVar);
    }
  }

  function editCostType(data, costTypeName) {
    for (let i = 0; i < fields.length; i++) {
      var helperArray = fields.get(i).costTypes;
      var helperVar = fields.get(i);

      helperArray[
        helperArray.findIndex((value) => value.costTypeName === costTypeName)
      ].costTypeName = data.flatId;

      helperVar.costTypes = helperArray;
      fields.splice(i, 1, helperVar);
    }
  }

  function editBudget(data, id, costTypeIndex) {
    const index = fields.getAll().findIndex((job) => job.id === id);

    store.dispatch(
      change(
        "create_project_form_wizard",
        `jobs[${index}].costTypes[${costTypeIndex}].budget`,
        data.budget
      )
    );

    setCurrentId("");
    setCurrentCostType("");
  }

  return (
    <>
      <Row md={12} className="justify-content-between text-nowrap">
        <Col md={4}>
          <h2>Allocate budget</h2>
        </Col>

        <div className="buttonContainer">
          <Col md={6}>
            <Button className="button" onClick={saveProject}>
              Save
            </Button>
          </Col>

          <Col md={6} className={"justify-content-end d-flex"}>
            <Button type={"submit"} className="text-nowrap button">
              Next
            </Button>
          </Col>
        </div>
      </Row>

      {/* Electrical table */}
      {fields && fields.length > 0 && electricalLength ? (
        <Container className="allocateBudgetContainer ">
          <Row md={9}>
            <div className="jobTableContainer">
              <Table
                responsive
                hover
                className="table react-table table--bordered"
              >
                <thead>
                  <tr className={"d-flex"}>
                    <th className={"th60"}>TRADE</th>
                    <th className={"th220"}>NAME</th>
                    <th className={"th180"}>COST CODE</th>
                    {fields.get(0).costTypes &&
                      fields.get(0).costTypes.map((costType, stepIndex) => (
                        <th className={"th180"} key={stepIndex}>
                          <div className="costTypeContainer">
                            {editingCostTypeName === costType.costTypeName ? (
                              <EditFlatForm
                                flatId={costType.costTypeName}
                                onSubmit={(data) => {
                                  editCostType(data, costType.costTypeName);
                                  setIsEditing(false);
                                }}
                              />
                            ) : (
                              costType.costTypeName
                            )}

                            <UncontrolledDropdown
                              className={"costTypeDropDown"}
                            >
                              <DropdownToggle
                                className="flatDropdownToggle"
                                tag="span"
                              >
                                <FlatOptionsIcon />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setEditingCostTypeName(
                                      costType.costTypeName
                                    );
                                  }}
                                >
                                  <div>Rename</div>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    removeCostType(costType.costTypeName);
                                  }}
                                >
                                  <div>Delete</div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </th>
                      ))}
                    <th className={"th220 addCostTypeButton"}>
                      <AddCostTypeForm onSubmit={addCostType} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {fields
                    .getAll()
                    .filter(
                      (job) => job.trade.value === WORK_TYPE_OPTIONS[1].value
                    )
                    .slice(electricalPage * 10, electricalPage * 10 + pageSize)
                    .map((job, index) => {
                      return (
                        <tr
                          key={electricalPage * 10 + index}
                          className={
                            "d-flex justify-content-center align-items-center"
                          }
                        >
                          <td className={"td60"}>
                            <div>{WORK_TYPE[job.trade.value].bigIcon}</div>
                          </td>

                          <td className={"td220"}>
                            <div
                              className={
                                "operativeStepJobStepDescriptionContainer"
                              }
                            >
                              <span
                                className={
                                  job.description.length > 20
                                    ? "operativeStepJobDescriptionText"
                                    : null
                                }
                                data-text={job.description}
                              >
                                {job.description}
                              </span>
                            </div>
                          </td>

                          <td className={"td180"}>{job.sageRef.label}</td>

                          {job.costTypes &&
                            job.costTypes.map((costType, stepIndex) => (
                              <td className={"td180"} key={stepIndex}>
                                {isEditing &&
                                currentId === job.id &&
                                fields.get(0).costTypes[stepIndex]
                                  .costTypeName === currentCostType ? (
                                  <EditBudgetForm
                                    budget={costType.budget}
                                    onSubmit={(data) => {
                                      editBudget(data, job.id, stepIndex);
                                      setIsEditing(false);
                                    }}
                                  />
                                ) : (
                                  <span
                                    onClick={() => {
                                      setIsEditing(true);
                                      setCurrentId(job.id);
                                      setCurrentCostType(
                                        fields.get(0).costTypes[stepIndex]
                                          .costTypeName
                                      );
                                    }}
                                  >
                                    £{costType.budget}
                                  </span>
                                )}
                              </td>
                            ))}
                          <td className={"td220"}></td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {fields && Math.ceil(electricalLength / pageSize) >= 2 && (
                <div className="box-pagination">
                  <Pagination
                    count={
                      fields && electricalLength
                        ? Math.ceil(electricalLength / pageSize)
                        : 0
                    }
                    page={electricalPage + 1}
                    onChange={(event, page) => {
                      setElectricalPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Container>
      ) : null}

      {/*Mechanical table  */}
      {fields && fields.length > 0 && mechanicalLength ? (
        <Container className="allocateBudgetContainer mt-4">
          <Row md={9}>
            <div className="jobTableContainer">
              <Table
                responsive
                hover
                className="table react-table table--bordered"
              >
                <thead>
                  <tr className={"d-flex"}>
                    <th className={"th60"}>TRADE</th>
                    <th className={"th220"}>NAME</th>
                    <th className={"th180"}>COST CODE</th>
                    {fields.get(0).costTypes &&
                      fields.get(0).costTypes.map((costType, stepIndex) => (
                        <th className={"th180"} key={stepIndex}>
                          <div className="costTypeContainer">
                            {editingCostTypeName === costType.costTypeName ? (
                              <EditFlatForm
                                flatId={costType.costTypeName}
                                onSubmit={(data) => {
                                  editCostType(data, costType.costTypeName);
                                  setIsEditing(false);
                                }}
                              />
                            ) : (
                              costType.costTypeName
                            )}

                            <UncontrolledDropdown
                              className={"costTypeDropDown"}
                            >
                              <DropdownToggle
                                className="flatDropdownToggle"
                                tag="span"
                              >
                                <FlatOptionsIcon />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setEditingCostTypeName(
                                      costType.costTypeName
                                    );
                                  }}
                                >
                                  <div>Rename</div>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    removeCostType(costType.costTypeName);
                                  }}
                                >
                                  <div>Delete</div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </th>
                      ))}
                    <th className={"th220 addCostTypeButton"}>
                      <AddCostTypeForm onSubmit={addCostType} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {fields
                    .getAll()
                    .filter(
                      (job) => job.trade.value === WORK_TYPE_OPTIONS[2].value
                    )
                    .slice(mechanicalPage * 10, mechanicalPage * 10 + pageSize)
                    .map((job, index) => {
                      return (
                        <tr
                          key={mechanicalPage * 10 + index}
                          className={
                            "d-flex justify-content-center align-items-center"
                          }
                        >
                          <td className={"td60"}>
                            <div>{WORK_TYPE[job.trade.value].bigIcon}</div>
                          </td>

                          <td className={"td220"}>
                            <div
                              className={
                                "operativeStepJobStepDescriptionContainer"
                              }
                            >
                              <span
                                className={
                                  job.description.length > 20
                                    ? "operativeStepJobDescriptionText"
                                    : null
                                }
                                data-text={job.description}
                              >
                                {job.description}
                              </span>
                            </div>
                          </td>

                          <td className={"td180"}>{job.sageRef.label}</td>
                          {job.costTypes &&
                            job.costTypes.map((costType, stepIndex) => (
                              <td className={"td180"} key={stepIndex}>
                                {isEditing &&
                                currentId === job.id &&
                                fields.get(0).costTypes[stepIndex]
                                  .costTypeName === currentCostType ? (
                                  <EditBudgetForm
                                    budget={costType.budget}
                                    onSubmit={(data) => {
                                      editBudget(data, job.id, stepIndex);
                                      setIsEditing(false);
                                    }}
                                  />
                                ) : (
                                  <span
                                    onClick={() => {
                                      setIsEditing(true);
                                      setCurrentId(job.id);
                                      setCurrentCostType(
                                        fields.get(0).costTypes[stepIndex]
                                          .costTypeName
                                      );
                                    }}
                                  >
                                    £{costType.budget}
                                  </span>
                                )}
                              </td>
                            ))}
                          <td className={"td220"}></td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {fields && Math.ceil(mechanicalLength / pageSize) >= 2 && (
                <div className="box-pagination">
                  <Pagination
                    count={
                      fields && mechanicalLength
                        ? Math.ceil(mechanicalLength / pageSize)
                        : 0
                    }
                    page={mechanicalPage + 1}
                    onChange={(event, page) => {
                      setMechanicalPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Container>
      ) : null}

      {/* VENTILATION TABLE */}
      {fields && fields.length > 0 && ventilationLength ? (
        <Container className="allocateBudgetContainer mt-4">
          <Row md={9}>
            <div className="jobTableContainer">
              <Table
                responsive
                hover
                className="table react-table table--bordered"
              >
                <thead>
                  <tr className={"d-flex"}>
                    <th className={"th60"}>TRADE</th>
                    <th className={"th220"}>NAME</th>
                    <th className={"th180"}>COST CODE</th>
                    {fields.get(0).costTypes &&
                      fields.get(0).costTypes.map((costType, stepIndex) => (
                        <th className={"th180"} key={stepIndex}>
                          <div className="costTypeContainer">
                            {editingCostTypeName === costType.costTypeName ? (
                              <EditFlatForm
                                flatId={costType.costTypeName}
                                onSubmit={(data) => {
                                  editCostType(data, costType.costTypeName);
                                  setIsEditing(false);
                                }}
                              />
                            ) : (
                              costType.costTypeName
                            )}

                            <UncontrolledDropdown
                              className={"costTypeDropDown"}
                            >
                              <DropdownToggle
                                className="flatDropdownToggle"
                                tag="span"
                              >
                                <FlatOptionsIcon />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setEditingCostTypeName(
                                      costType.costTypeName
                                    );
                                  }}
                                >
                                  <div>Rename</div>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    removeCostType(costType.costTypeName);
                                  }}
                                >
                                  <div>Delete</div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </th>
                      ))}
                    <th className={"th220 addCostTypeButton"}>
                      <AddCostTypeForm onSubmit={addCostType} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {fields &&
                    fields.length > 0 &&
                    fields
                      .getAll()
                      .filter(
                        (job) => job.trade.value === WORK_TYPE_OPTIONS[3].value
                      )
                      .slice(
                        ventilationPage * 10,
                        ventilationPage * 10 + pageSize
                      )
                      .map((job, index) => {
                        return (
                          <tr
                            key={ventilationPage * 10 + index}
                            className={
                              "d-flex justify-content-center align-items-center"
                            }
                          >
                            <td className={"td60"}>
                              <div>{WORK_TYPE[job.trade.value].bigIcon}</div>
                            </td>

                            <td className={"td220"}>
                              <div
                                className={
                                  "operativeStepJobStepDescriptionContainer"
                                }
                              >
                                <span
                                  className={
                                    job.description.length > 20
                                      ? "operativeStepJobDescriptionText"
                                      : null
                                  }
                                  data-text={job.description}
                                >
                                  {job.description}
                                </span>
                              </div>
                            </td>

                            <td className={"td180"}>{job.sageRef.label}</td>
                            {job.costTypes &&
                              job.costTypes.map((costType, stepIndex) => (
                                <td className={"td180"} key={stepIndex}>
                                  {isEditing &&
                                  currentId === job.id &&
                                  fields.get(0).costTypes[stepIndex]
                                    .costTypeName === currentCostType ? (
                                    <EditBudgetForm
                                      budget={costType.budget}
                                      onSubmit={(data) => {
                                        editBudget(data, job.id, stepIndex);
                                        setIsEditing(false);
                                      }}
                                    />
                                  ) : (
                                    <span
                                      onClick={() => {
                                        setIsEditing(true);
                                        setCurrentId(job.id);
                                        setCurrentCostType(
                                          fields.get(0).costTypes[stepIndex]
                                            .costTypeName
                                        );
                                      }}
                                    >
                                      £{costType.budget}
                                    </span>
                                  )}
                                </td>
                              ))}
                            <td className={"td220"}></td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>

              {fields && Math.ceil(ventilationLength / pageSize) >= 2 && (
                <div className="box-pagination">
                  <Pagination
                    count={
                      fields && ventilationLength
                        ? Math.ceil(ventilationLength / pageSize)
                        : 0
                    }
                    page={ventilationPage + 1}
                    onChange={(event, page) => {
                      setVentilationPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Container>
      ) : null}

      {/* SVP_RWG TABLE */}
      {fields && fields.length > 0 && svprwgLength ? (
        <Container className="allocateBudgetContainer mt-4">
          <Row md={9}>
            <div className="jobTableContainer">
              <Table
                responsive
                hover
                className="table react-table table--bordered"
              >
                <thead>
                  <tr className={"d-flex"}>
                    <th className={"th60"}>TRADE</th>
                    <th className={"th220"}>NAME</th>
                    <th className={"th180"}>COST CODE</th>
                    {fields.get(0).costTypes &&
                      fields.get(0).costTypes.map((costType, stepIndex) => (
                        <th className={"th180"} key={stepIndex}>
                          <div className="costTypeContainer">
                            {editingCostTypeName === costType.costTypeName ? (
                              <EditFlatForm
                                flatId={costType.costTypeName}
                                onSubmit={(data) => {
                                  editCostType(data, costType.costTypeName);
                                  setIsEditing(false);
                                }}
                              />
                            ) : (
                              costType.costTypeName
                            )}

                            <UncontrolledDropdown
                              className={"costTypeDropDown"}
                            >
                              <DropdownToggle
                                className="flatDropdownToggle"
                                tag="span"
                              >
                                <FlatOptionsIcon />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setEditingCostTypeName(
                                      costType.costTypeName
                                    );
                                  }}
                                >
                                  <div>Rename</div>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    removeCostType(costType.costTypeName);
                                  }}
                                >
                                  <div>Delete</div>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </th>
                      ))}
                    <th className={"th220 addCostTypeButton"}>
                      <AddCostTypeForm onSubmit={addCostType} />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {fields &&
                    fields.length > 0 &&
                    fields
                      .getAll()
                      .filter(
                        (job) => job.trade.value === WORK_TYPE_OPTIONS[4].value
                      )
                      .slice(svprwgPage * 10, svprwgPage * 10 + pageSize)
                      .map((job, index) => {
                        return (
                          <tr
                            key={svprwgPage * 10 + index}
                            className={
                              "d-flex justify-content-center align-items-center"
                            }
                          >
                            <td className={"td60"}>
                              <div>{WORK_TYPE[job.trade.value].bigIcon}</div>
                            </td>

                            <td className={"td220"}>
                              <div
                                className={
                                  "operativeStepJobStepDescriptionContainer"
                                }
                              >
                                <span
                                  className={
                                    job.description.length > 20
                                      ? "operativeStepJobDescriptionText"
                                      : null
                                  }
                                  data-text={job.description}
                                >
                                  {job.description}
                                </span>
                              </div>
                            </td>

                            <td className={"td180"}>{job.sageRef.label}</td>
                            {job.costTypes &&
                              job.costTypes.map((costType, stepIndex) => (
                                <td className={"td180"} key={stepIndex}>
                                  {isEditing &&
                                  currentId === job.id &&
                                  fields.get(0).costTypes[stepIndex]
                                    .costTypeName === currentCostType ? (
                                    <EditBudgetForm
                                      budget={costType.budget}
                                      onSubmit={(data) => {
                                        editBudget(data, job.id, stepIndex);
                                        setIsEditing(false);
                                      }}
                                    />
                                  ) : (
                                    <span
                                      onClick={() => {
                                        setIsEditing(true);
                                        setCurrentId(job.id);
                                        setCurrentCostType(
                                          fields.get(0).costTypes[stepIndex]
                                            .costTypeName
                                        );
                                      }}
                                    >
                                      £{costType.budget}
                                    </span>
                                  )}
                                </td>
                              ))}
                            <td className={"td220"}></td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>

              {fields && Math.ceil(svprwgLength / pageSize) >= 2 && (
                <div className="box-pagination">
                  <Pagination
                    count={
                      fields && svprwgLength
                        ? Math.ceil(svprwgLength / pageSize)
                        : 0
                    }
                    page={svprwgLength + 1}
                    onChange={(event, page) => {
                      setSvprwgPage(page - 1);
                    }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Container>
      ) : null}
    </>
  );
};

const getCostTypes = () => {
  const selectedJobs = store.getState().form.create_project_form_wizard.values
    .jobs;

  const costTypes = [];

  for (let i = 0; i < selectedJobs.length; i++) {
    if (selectedJobs[i].hasOwnProperty("costTypes")) {
      selectedJobs[i].costTypes.forEach((data) => {
        costTypes.push({ costTypeName: data.costTypeName, budget: 0 });
      });

      break;
    }
  }

  return costTypes;
};

let CreateProjectStepEight = (props) => {
  const { handleSubmit, saveProject } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container className="m-0">
        <FieldArray
          name="jobs"
          component={renderJobs}
          rerenderOnEveryChange={true}
          props={{
            costTypes: getCostTypes(),
            saveProject,
          }}
        />
      </Container>
    </form>
  );
};

CreateProjectStepEight = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepEight);

export default CreateProjectStepEight;
