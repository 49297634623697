import React, { useEffect, useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import StepSeven from "./StepSeven";
import StepEight from "./StepEight";
import StepNine from "./StepNine";
import { initialize, reduxForm } from "redux-form";
import store from "../../../redux/store";
import Loading from "../../../shared/components/Loading";
import {
  getSavedProject,
  saveProjectState,
  saveCreatedProjectId,
} from "../../../redux/services/projects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateProjectBreadCrumbs from "./components/CreateProjectBreadCrumbs";
import { useLocation } from "react-router-dom";
import { createProjectFromForm } from "../../../redux/services/projects";

const CreateProject = ({ history }) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectId, setProjectId] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    if (location.state.projectId) {
      setProjectId(location.state.projectId);
      getValues(location.state.projectId);
    } else {
      setIsLoading(false);
    }
  }, []);

  async function getValues(id) {
    await getSavedProject(id).then((response) => {
      store.dispatch(initialize("create_project_form_wizard", response));

      if (store.getState().form.create_project_form_wizard.values.vat) {
        const selectValue = store.getState().form.create_project_form_wizard
          .values.vat;

        setDefaultSelectValue({
          label: selectValue.name,
          value: {
            rate: selectValue.rate,
            sageCode: selectValue.sageCode,
            drc: selectValue.drc,
          },
        });
      }

      setIsLoading(false);
    });
  }

  async function saveProject(id) {
    const formValue = store.getState().form.create_project_form_wizard.values;
    if(formValue.hasOwnProperty("projectName")){
      setProjectName(formValue.projectName.toString().trim())
    }

    // Saving only necessary data
    const filteredFormValue = {
      ...(formValue.hasOwnProperty("additional_information") && {
        additional_information: formValue.additional_information,
      }),
      ...(formValue.hasOwnProperty("address_city") && {
        address_city: formValue.address_city,
      }),
      ...(formValue.hasOwnProperty("address_number") && {
        address_number: formValue.address_number,
      }),
      ...(formValue.hasOwnProperty("address_post_code") && {
        address_post_code: formValue.address_post_code,
      }),
      ...(formValue.hasOwnProperty("address_street") && {
        address_street: formValue.address_street,
      }),
      ...(formValue.hasOwnProperty("allocatedJobs") && {
        allocatedJobs: formValue.allocatedJobs,
      }),
      ...(formValue.hasOwnProperty("apartments") && {
        apartments: formValue.apartments,
      }),
      ...(formValue.hasOwnProperty("blocks") && {
        blocks: formValue.blocks,
      }),
      ...(formValue.hasOwnProperty("budget") && {
        budget: formValue.budget,
      }),
      ...(formValue.hasOwnProperty("client") && {
        client: formValue.client,
      }),
      ...(formValue.hasOwnProperty("jobs") && {
        jobs: formValue.jobs,
      }),
      ...(formValue.hasOwnProperty("projectName") && {
        projectName: formValue.projectName.toString().trim(),
      }),
      ...(formValue.hasOwnProperty("project_group_id") && {
        project_group_id: formValue.project_group_id,
      }),
      ...(formValue.hasOwnProperty("projectReference") && {
        projectReference: formValue.projectReference,
      }),
      ...(formValue.hasOwnProperty("types") && {
        types: formValue.types,
      }),
      ...(formValue.hasOwnProperty("vat") && {
        vat: formValue.vat,
      }),
      ...(formValue.hasOwnProperty("deadline") && {
        deadline: formValue.deadline,
      }),
      ...(formValue.hasOwnProperty("pm_id") && {
        pm_id: formValue.pm_id,
      }),
      ...(formValue.hasOwnProperty("qa_id") && {
        qa_id: formValue.qa_id,
      }),
    };

    await saveProjectState(filteredFormValue, id).then((response) => {
      setProjectId(response.id);
      toast("Project saved!");
    });
  }

  async function createProject(data) {
    data.createdProjectId = location.state.createdProjectId;
    setIsLoading(true);

    await createProjectFromForm(data)
      .then(async (response) => {
        if (projectId !== -1) {
          await saveCreatedProjectId(projectId, response.data.id);
        }

        history.replace(`/contracts/preview-contracts/${data.project_group_id}`);
      })
      .catch(() => {
        alert("A problem occured while creating the project.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      {!isLoading ? (
        <CreateProjectBreadCrumbs
          breadCrumbs={breadCrumbs}
          currentIndex={page}
          setPage={setPage}
          setBreadCrumbs={setBreadCrumbs}
          projectName={projectName}
        />
      ) : null}

      {isLoading ? (
        <Loading />
      ) : (
        [
          page === 1 && (
            <StepOne
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Create project" },
                ]);
              }}
              key={1}
              saveProject={() => saveProject(projectId)}
              defaultSelectValue={defaultSelectValue}
            />
          ),
          page === 2 && (
            <StepTwo
              key={2}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Create blocks" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 3 && (
            <StepThree
              key={3}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Create types" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),
          page === 4 && (
            <StepFour
              key={4}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Confirm levels" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 5 && (
            <StepFive
              key={5}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Add flats" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 6 && (
            <StepSix
              key={6}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Define types" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 7 && (
            <StepSeven
              key={7}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Job types" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 8 && (
            <StepEight
              key={8}
              onSubmit={() => {
                setPage(page + 1);
                setBreadCrumbs((breadCrumbs) => [
                  ...breadCrumbs,
                  { name: "Allocate budget" },
                ]);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),

          page === 9 && (
            <StepNine
              key={9}
              onSubmit={(data) => {
                createProject(data);
              }}
              saveProject={() => saveProject(projectId)}
            />
          ),
        ]
      )}
      <ToastContainer
        position={"top-center"}
        autoClose={2000}
        pauseOnHover={false}
        hideProgressBar={true}
      />
    </div>
  );
};

export default reduxForm({
  form: "create_project_form_wizard",
  enableReinitialize: true,
})(CreateProject);
