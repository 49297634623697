import {
  getAllContractsAsync,
  createContractAsync,
  getContractsWithProjectsStatusesAsync,
  getContractDayWorkRequestsCounterAsync,
  getContractAdditionalWorkRequestsCounterAsync,
  getContractQaRequestsCounterAsync,
  getContractJobRequestsCounterAsync,
  getContractBudgetAnalyticsAsync,
  getContractActiveJobsAsync,
  getContractJobCompletionByTypesAsync,
  getContractJobUnderVerificationAsync,
  getContractInvoicesAsync,
  editContractAsync,
  getContractByIdAsync,
} from "../api/contracts";

export const getAllContracts = async () => await getAllContractsAsync();

export const getContractById = async (contractId) => await getContractByIdAsync (contractId);

export const getContractsWithProjectsStatuses = async (contractId) => await getContractsWithProjectsStatusesAsync(contractId);

export const createContract = async (projectGroupName) => await createContractAsync(projectGroupName);

export const editContract = async (contractId, newContractName) => await editContractAsync(contractId, newContractName);

export const getContractDayWorkRequestsCounter = async (groupId) => await getContractDayWorkRequestsCounterAsync(groupId);

export const getContractAdditionalWorkRequestsCounter = async (groupId) => await getContractAdditionalWorkRequestsCounterAsync(groupId);

export const getContractQaRequestsCounter = async (groupId) => await getContractQaRequestsCounterAsync(groupId);

export const getContractJobRequestsCounter = async (groupId) => await getContractJobRequestsCounterAsync(groupId);

export const getContractBudgetAnalytics = async (groupId) => await getContractBudgetAnalyticsAsync(groupId);

export const getContractActiveJobs = async (groupId) => await getContractActiveJobsAsync(groupId);

export const getContractJobCompletionByTypes = async (groupId) => await getContractJobCompletionByTypesAsync(groupId);

export const getContractJobUnderVerification = async (groupId) => await getContractJobUnderVerificationAsync(groupId);

export const getContractInvoices = async (groupId) => await getContractInvoicesAsync(groupId);
