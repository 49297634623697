import React, { PureComponent } from "react";
import Select from "react-select";

class SelectFieldBlue extends PureComponent {
  static defaultProps = {
    placeholder: "",
    options: [],
  };

  handleChange = (selectedOption) => {
    const { onChange } = this.props;
    onChange(selectedOption);
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    defaultValue && this.handleChange(defaultValue);
  }

  render() {
    const { value, name, placeholder, options, isSearchable } = this.props;

    return (
      <Select
        name={name}
        value={value}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        isSearchable={isSearchable ? isSearchable : false}
        menuPosition={"fixed"}
        styles={{
          container: (base) => ({
            ...base,
            backgroundColor: "#007DBC",
            color: "white",
          }),
          placeholder: (base) => ({
            ...base,
            color: "white",
          }),
          input: (base) => ({
            ...base,
            color: "white",
          }),
          menuList: (base) => ({
            ...base,
            background: "#007DBC !important",
          }),
          option: (base) => ({
            ...base,
            backgroundColor: "#007DBC !important",
            color: "white !important",
          }),
          singleValue: (base) => ({
            ...base,
            color: "white",
          }),
        }}
      />
    );
  }
}

const renderSelectField = (props) => {
  const {
    input,
    meta,
    options,
    placeholder,
    className,
    defaultValue,
    isSearchable,
  } = props;
  return (
    <div className={`form__form-group-input-wrap ${className}`}>
      <SelectFieldBlue
        {...input}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
      />
      {meta.touched && meta.error && (
        <span className="form__form-group-error">{meta.error}</span>
      )}
    </div>
  );
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: "",
  className: "",
};

export default renderSelectField;
