import {
  getUsersAsync,
  getUserByIdAsync,
  createUserAsync,
  editUserAsync,
  deleteUsersAsync,
  getProjectManagersAync,
  getAllAdminsAsync,
  getReducedUsersAsync, exportAllUsersToCsvAsync,
} from "../api/users";

export const getUsers = async (page, data) => await getUsersAsync(page, data);

export const getUserById = async (id) => await getUserByIdAsync(id);

export const createUser = async (userInfo) => await createUserAsync(userInfo);

export const editUser = async (id, data) => await editUserAsync(id, data);

export const deleteUsers = async (data) => await deleteUsersAsync(data);

export const getProjectManagers = async () => await getProjectManagersAync();

export const getAllAdmins = async () => await getAllAdminsAsync();

export const getReducedUsers = async (text) => await getReducedUsersAsync(text);

export const usersToCsv = async () => await exportAllUsersToCsvAsync()
