import { combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { authReducer, sidebarReducer, breadCrumbsReducer, photoLoadReducer } from './reducers/index';

const reducer = combineReducers({
    form: reduxFormReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    bread_crumbs: breadCrumbsReducer,
    photo_load: photoLoadReducer

});
/* eslint-disable no-underscore-dangle */
const store = createStore(
    reducer, /* preloadedState, */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
/* eslint-enable */

export default store;
