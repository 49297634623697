import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Layout from "../containers/Layout";
import Users from "../containers/Accounts";
import UserPreview from "../containers/Accounts/UserPreview";
import Invoices from "../containers/Invoices";
import Uninvoiced from "../containers/Uninvoiced";
import Projects from "../containers/Projects";
import PreviewProject from "../containers/Projects/ProjectPreview";
import Jobs from "../containers/Jobs";
import PreviewJob from "../containers/Jobs/JobPreview";
import Requests from "../containers/Requests";
import DayWork from "../containers/DayWork/index";
import {
  USERS,
  PREVIEW_USER,
  INVOICES,
  UNINVOICED,
  INVOICES_BY_PROJECT,
  PROJECTS,
  PREVIEW_PROJECT,
  JOBS,
  PREVIEW_JOB,
  REQUESTS,
  DAY_WORK,
  CREATE_PROJECT,
  VAT_TEMPLATES,
  GENERATE_INVOICES,
  JOB_TEMPLATES,
  QA_STEPS,
  OPERATIVE_STEPS,
  CREATED_PROJECTS,
  CONTRACTS,
  PREVIEW_CONTRACT,
  COMMERCIAL,
  CONTRACT_VARIATION_ORDERS,
  UNKNOWN_REQUESTS,
  PREVIEW_VO, PROJECT_STRUCTURE, APPROVAL_DAYWORKS, APPROVAL_JOBS, APPROVAL_UPLIFTS,
} from "../constants/routes";
import VATTemplates from "../containers/VATTemplates";
import GenerateInvoices from "../containers/GenerateInvoices";
import JobTemplates from "../containers/JobTemplates";
import QASteps from "../containers/QASteps";
import OperativeSteps from "../containers/OperativeSteps";
import CreateProject from "../containers/Projects/CreateProject";
import CreatedProjects from "../containers/Projects/CreatedProjects";
import Contracts from "../containers/Contracts";
import PreviewContract from '../containers/Contracts/PreviewContract'
import Commercial from '../containers/Commercial'
import ContractVariationOrders from "../containers/Commercial/ContractVariationOrders";
import UnknownRequests from "../containers/Commercial/UnknownRequests";
import PreviewVariationOrder from "../containers/Commercial/PreviewVariationOrder";
import Structure from "../containers/Projects/ProjectPreview/Structure";
import DayWorks from "../containers/Approvals/Dayworks/Dayworks";
import JobsApproval from "../containers/Approvals/Jobs/Jobs";
import UpliftsApproval from "../containers/Approvals/Uplifts/Uplifts";

const AuthRouters = ({ sidebar }) => (
  <div style={{ background: `#f2f4f7` }}>
    <Layout />
    <div
      className={
        sidebar.collapse ? "container__wrap" : "container__wrap--collapse"
      }
      style={{marginTop: 100}}
    >
      <Switch>
        {/* Home */}
        <Route exact path="/">
          <Redirect to={CONTRACTS} />
        </Route>

        {/* Users */}
        <Route exact path={USERS} component={Users} />
        <Route exact path={PREVIEW_USER} component={UserPreview} />

        {/* Ivoices */}
        <Route exact path={INVOICES} render={() => <Invoices type={'USER'}/>}/>
        <Route exact path={UNINVOICED} component={Uninvoiced} />
        <Route exact path={INVOICES_BY_PROJECT} render={() => <Invoices type={'PROJECT'}/>}  />

        {/* Contracts */}
        <Route exact path={CONTRACTS} component={Contracts} />
        <Route exact path={PREVIEW_CONTRACT} component={PreviewContract} />

        {/* Commercial */}
        <Route exact path={COMMERCIAL} component={Commercial} />
        <Route exact path={CONTRACT_VARIATION_ORDERS} component={ContractVariationOrders} />
        <Route exact path={UNKNOWN_REQUESTS} component={UnknownRequests} />
        <Route exact path={PREVIEW_VO} component={PreviewVariationOrder} />

        {/* Projects */}
        <Route exact path={PROJECTS} component={Projects} />
        <Route exact path={PREVIEW_PROJECT} render={() => <PreviewProject/>}  />
        <Route exact path={CREATE_PROJECT} component={CreateProject} />
        <Route exact path={CREATED_PROJECTS} component={CreatedProjects} />
        <Route exact path={PROJECT_STRUCTURE} component={Structure} />

        {/* Jobs */}
        <Route exact path={JOBS} render={() => <Jobs/>} />
        <Route exact path={PREVIEW_JOB} component={PreviewJob} />

        {/* Requests */}
        <Route exact path={REQUESTS} component={Requests} />

        {/*Day Work*/}
        <Route exact path={DAY_WORK} component={DayWork} />

        {/*Templates*/}
        <Route exact path={VAT_TEMPLATES} component={VATTemplates} />
        <Route exact path={JOB_TEMPLATES} component={JobTemplates} />
        <Route exact path={QA_STEPS} component={QASteps} />
        <Route exact path={OPERATIVE_STEPS} component={OperativeSteps} />
        <Route exact path={GENERATE_INVOICES} component={GenerateInvoices} />

        {/*Approvals*/}
        <Route exact path={APPROVAL_DAYWORKS} component={DayWorks} />
        <Route exact path={APPROVAL_UPLIFTS} component={UpliftsApproval} />
        <Route exact path={APPROVAL_JOBS} component={JobsApproval} />

      </Switch>
    </div>
  </div>
);

const mapStateToProps = ({ sidebar, bread_crumbs }) => {
  return { sidebar, bread_crumbs };
};

export default withRouter(connect(mapStateToProps)(AuthRouters));
