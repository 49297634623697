import React, {useEffect, useState} from "react"
import {change, Field, reduxForm} from 'redux-form';
import { Col } from 'reactstrap';
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate"
import {REQUEST_TYPE_OPTIONS, WORK_TYPE_OPTIONS} from "../../../constants/options";
import {PROJECT_STRUCTURE} from "../../../constants/enamerations";

const RequestsFilterForm = ({setFilter, filter,dispatch, data, project}) => {
    const [blockID, setBlockID] = useState(null);
    const [levelID, setLevelID] = useState(null);

    const [blockOptions, setBlockOptions] = useState([])
    const [levelOptions, setLevelOptions] = useState([])
    const [plotOptions, setPlotOptions] = useState([])

    useEffect(() => {
        dispatch(change('filter_form_requests', 'request_type', data))
    }, [])




    const getBlocks = () => {
        const tempBlockOptions = [{ value: null, label: 'All' }]
        project.data.blocks.forEach(item => tempBlockOptions.push({ value: item.id, label: item.name }))
        setBlockOptions(tempBlockOptions)
    }

    useEffect(() => {
        project && project.data && getBlocks()
    }, [project])


    const getLevels = (id) => {
        const tempLevelOptions = [{ value: null, label: 'All' }]
        project.data.blocks.forEach(item => item.id === id && item.levels.map(item => tempLevelOptions.push({ value: item.id, label: item.name })))
        setLevelOptions(tempLevelOptions);
    }

    const chooseBlock = (id) => {
        setBlockID(id)
        getLevels(id)
        id ?
            setFilter({ ...filter, structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.BLOCK } }):
            setFilter({ ...filter, structure: { target_ids: [project.data.id], target_type: PROJECT_STRUCTURE.PROJECT } })
        dispatch(change('filter_form_jobs', 'level', ''))
        dispatch(change('filter_form_jobs', 'plot', ''))
    }



    const chooseLevel = (id) => {
        setLevelID(id)
        getPlots(id)
        id ?
            setFilter({ ...filter, structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.LEVEL } }):
            setFilter({ ...filter, structure: { target_ids: [blockID], target_type: PROJECT_STRUCTURE.BLOCK }});
        dispatch(change('filter_form_jobs', 'plot', ''))
    }

    const getPlots = (id) => {
        const tempPlotOptions = [{ value: null, label: 'All' }]
        project.data.blocks.forEach(block => block.id === blockID && block.levels.map(level => level.id === id && level.plots.map(item => tempPlotOptions.push({ value: item.id, label: item.name }))))
        setPlotOptions(tempPlotOptions)
    }

    const choosePlot = (id) => {
        id ?
            setFilter({ ...filter, structure: { target_ids: [id], target_type: PROJECT_STRUCTURE.PLOT } }) :
            setFilter({ ...filter, structure: { target_ids: [levelID], target_type: PROJECT_STRUCTURE.LEVEL } })
    }

    return (
        <form>
            <Col md={12} xl={12} className="d-flex justify-content-between p-0">
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="request_type"
                                value={{ label: "Select Dept", value: 0 }}
                                component={SelectFieldNoValidate}
                                placeholder="Request type"
                                options={REQUEST_TYPE_OPTIONS}
                                onChange={data => setFilter({ ...filter, data: data })}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="job_type"
                                component={SelectFieldNoValidate}
                                placeholder="Trade"
                                options={WORK_TYPE_OPTIONS}
                                onChange={data => setFilter({ ...filter, job_type: data })}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="block"
                                component={SelectFieldNoValidate}
                                placeholder="Block"
                                options={blockOptions && blockOptions.length !== 0 ? blockOptions : []}
                                onChange={data => chooseBlock(data)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="level"
                                component={SelectFieldNoValidate}
                                placeholder="Level"
                                options={levelOptions && levelOptions.length !== 0 ? levelOptions : []}
                                // disabled={f && f.filter_form_jobs && f.filter_form_jobs.values && f.filter_form_jobs.values.block ? false : true}
                                onChange={data => chooseLevel(data)}
                            />
                        </div>
                    </div>
                </div>
                <div className="form__form-group w-50 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="plot"
                                component={SelectFieldNoValidate}
                                placeholder="Plot"
                                options={plotOptions && plotOptions.length !== 0 ? plotOptions : []}
                                // disabled={f && f.filter_form_jobs && f.filter_form_jobs.values && f.filter_form_jobs.values.level ? false : true}
                                onChange={data => choosePlot(data)}
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </form>
    )
}

export default reduxForm({
    form: 'filter_form_requests',
})(RequestsFilterForm);
