import axios from "axios";
import moment from "moment";
import { BASE_API } from "../../config/api";
import {defaultPage, pageSize} from "../../config/pagination";

export const getJobsByUserAsync = async (id, filter, pagination) =>
  await axios
    .post(`${BASE_API}admin/user/${id}/jobs`, {
      filters: filter,
      pagination,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getActiveJobsByUserAsync = async (id) =>
  await axios
    .post(`${BASE_API}admin/user/${id}/active-jobs`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getJobsByProjectAsync = async (pagination, filters) =>
  await axios
    .post(`${BASE_API}job/jobs-pageable`, {
      pagination: pagination,
      filters: filters,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getJobsByIdAsync = async (id, data) =>
  await axios
    .post(`${BASE_API}job/${id}`, {
      includeStepImages: data,
      includeQuestionImages: data,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const uploadFilePlanAsync = async (type, file) =>
  await axios
    .post(`${BASE_API}job/plan/${type}/put-signed-url`, {
      source: file,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const uploadPhotoPlanAsync = async (url, file) => {
  const token = axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Authorization"];
  await axios
    .put(`${url}`, file)
    .then((response) => {
      axios.defaults.headers.common["Authorization"] = token;
      return response && response.data;
    })
    .catch((error) => {
      axios.defaults.headers.common["Authorization"] = token;
      return error && error.message;
    });
};

export const applyPhotoToJobsAsync = async (type, file, ids) =>
  await axios
    .post(`${BASE_API}job/plan/${type}/link-to-jobs`, {
      job_ids: ids,
      source: file,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getPhotoPlanAsync = async (id, type) =>
  await axios
    .get(`${BASE_API}job/${id}/plan/${type}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getRequestsAsync = async (id, includeAssets) =>
  await axios
    .post(`${BASE_API}job/${id}/additional-work-request`, {
      includeAssets: includeAssets,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const updateRequestStatusAsync = async (id, status, amount, comment) =>
  await axios
    .patch(`${BASE_API}admin/additional-work-request/${id}/update/${status}`, {
      ...(amount ? { budget: amount } : { budget: 0}),
      ...(comment && { deny_comment: comment }),
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const deleteJobAsync = async (jobId) =>
  await axios
    .post(`${BASE_API}admin/job/delete/${jobId}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const deleteManyJobsAsync = async (jobIds) =>
await axios
  .post(`${BASE_API}admin/job/delete`,{
    jobIds,
  })
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const resetJobAsync = async (jobId, conditions) =>
await axios
  .post(`${BASE_API}job/${jobId}/resetJob`, {
    ...conditions
  })
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const addJobToProjectAsync = async (data) =>
  await axios
    .post(`${BASE_API}job/addToProject`, {
      data,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);


export const getUninvoicedJobsAsync = async (id, page) =>
    await axios.post(`${BASE_API}job/uninvoiced`, { userId: id, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const exportJobCsvAsync = async(data) => 
await axios
  .post(`${BASE_API}job/export`, {
    ...data,
  })
  .then((response) => {
    const csv = response && response.data;
    const pom = document.createElement("a");
    const csvContent = csv;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute(
      "download",
      `pw_jobs_export-${moment().format("DD-MM-YYYY")}.csv`
    );
    pom.click();
  })
  .catch((error) => error.message);

export const exportBudgetVariationCsvAsync = async(data) => 
  await axios
    .post(`${BASE_API}job/additional-work-requests/export`, {
      ...data,
    })
    .then((response) => {
      const csv = response && response.data;
      const pom = document.createElement("a");
      const csvContent = csv;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      pom.href = url;
      pom.setAttribute(
        "download",
        `pw_budget_variation_export-${moment().format("DD-MM-YYYY")}.csv`
      );
      pom.click();
    })
    .catch((error) => error.message);
