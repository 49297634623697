import axios from "axios";
import {BASE_API} from "../../config/api";
import {defaultPage, pageSize} from "../../config/pagination";
import moment from "moment";

export const getUsersAsync = async (page, data) =>
  await axios
    .post(`${BASE_API}admin/users-pageable`, {
      filters: data,
      pagination: {
        page: page ? page : defaultPage,
        pageSize,
      },
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getUserByIdAsync = async (id) =>
  await axios
    .get(`${BASE_API}admin/user/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const createUserAsync = async (userInfo) =>
  await axios
    .post(`${BASE_API}admin/create`, userInfo)
    .then((response) => response && response);
// .catch(error => error.message);

export const editUserAsync = async (id, data) =>
  await axios
    .patch(`${BASE_API}user/${id}`, data)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const deleteUsersAsync = async (data) =>
  await axios
    .patch(`${BASE_API}admin/user/delete`, {
      user_ids: data,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getProjectManagersAync = async () =>
  await axios
    .get(`${BASE_API}user/projectManagers`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getAllAdminsAsync = async () =>
await axios
  .get(`${BASE_API}admin/users/admins`)
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const getReducedUsersAsync = async (text) =>
await axios
  .post(`${BASE_API}user/users-reduced-pageable`, {
    filters: {
      text: text,
    },
    pagination: {
      page: 0,
      pageSize:10000
    },
  })
  .then((response) => response && response.data)
  .catch((error) => error.message);

export const exportAllUsersToCsvAsync = async() =>
    await axios
        .get(`${BASE_API}admin/users/export`)
        .then((response) => {
            const csv = response && response.data;
            const pom = document.createElement("a");
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            pom.href = URL.createObjectURL(blob);
            pom.setAttribute(
                "download",
                `admin_users_export-${moment().format("DD-MM-YYYY")}.csv`
            );
            pom.click();
        })
        .catch((error) => error.message);

