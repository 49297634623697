const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case 'theme-dark': {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default: return ligthTheme;
  }
}


export const getJobQAStepsQuestionsImages = (job) => {
  const groupedImages = [];

  // Group images by 4
  if (job && job.questions) {
    const questionsWithAssets = job.questions.filter(
        (question) => question.assets && question.assets.length > 0
    );

    const images = questionsWithAssets.flatMap((question) =>
        question.assets.map((asset) => asset.public_url)
    );

    while (images.length > 0) {
      groupedImages.push(images.splice(0, 4));
    }
  }
  return groupedImages
}

export const getJobOperativeStepsQuestionsImages = (job) => {
  const groupedImages = [];

  // Group images by 4
  if (job && job.steps) {
    const stepsWithAssets = job.steps.filter(
        (step) => step.assets && step.assets.length > 0
    );

    const images = stepsWithAssets.flatMap((question) =>
        question.assets.map((asset) => asset.public_url)
    );

    while (images.length > 0) {
      groupedImages.push(images.splice(0, 4));
    }
  }
  return groupedImages
}

export default getTooltipStyles;
