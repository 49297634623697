import React from "react";

export const USER_ROLE = {
  USER: {
    label: "User",
    value: "USER",
  },
  PROJECT_MANAGER: {
    label: "PM/QA",
    value: "PROJECT_MANAGER",
  },
  ADMIN: {
    label: "Admin",
    value: "ADMIN",
  },
};

export const WORK_TYPE = {
  ELECTRICAL: {
    label: "Electrical",
    color: "#3DCDD7",
    icon: (
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M10.01 4L10 0H8V4H4V0H2V4H1.99C1 3.99 0 4.99 0 5.99V11.48L3.5 15V18H8.5V15L12 11.49V5.99C12 4.99 11 3.99 10.01 4Z"
          fill="#3DCDD7"
        />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#3DCDD7" />{" "}
        <path
          d="M26.01 17L26 13H24V17H20V13H18V17H17.99C17 16.99 16 17.99 16 18.99V24.48L19.5 28V31H24.5V28L28 24.49V18.99C28 17.99 27 16.99 26.01 17Z"
          fill="white"
        />{" "}
      </svg>
    ),
  },
  MECHANICAL: {
    label: "Mechanical",
    color: "#FFB84F",
    icon: (
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M22.6999 18L13.5999 8.90001C14.4999 6.60001 13.9999 3.90001 12.0999 2.00001C10.0999 1.20401e-05 7.09994 -0.399988 4.69994 0.700012L8.99994 5.00001L5.99994 8.00001L1.59994 3.70001C0.399939 6.10001 0.899939 9.10001 2.89994 11.1C4.79994 13 7.49994 13.5 9.79994 12.6L18.8999 21.7C19.2999 22.1 19.8999 22.1 20.2999 21.7L22.5999 19.4C23.0999 19 23.0999 18.3 22.6999 18Z"
          fill="#FFB84F"
        />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#FFB84F" />{" "}
        <path
          d="M30.025 27.25L23.2 20.425C23.875 18.7 23.5 16.675 22.075 15.25C20.575 13.75 18.325 13.45 16.525 14.275L19.75 17.5L17.5 19.75L14.2 16.525C13.3 18.325 13.675 20.575 15.175 22.075C16.6 23.5 18.625 23.875 20.35 23.2L27.175 30.025C27.475 30.325 27.925 30.325 28.225 30.025L29.95 28.3C30.325 28 30.325 27.475 30.025 27.25Z"
          fill="white"
        />{" "}
      </svg>
    ),
  },
  VENTILATION: {
    label: "Ventilation",
    color: "#A275EB",
    icon: (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M10.8116 14.8822C12.4643 14.8822 13.8041 13.5424 13.8041 11.8897C13.8041 10.237 12.4643 8.89722 10.8116 8.89722C9.15888 8.89722 7.81909 10.237 7.81909 11.8897C7.81909 13.5424 9.15888 14.8822 10.8116 14.8822Z"
          fill="#A275EB"
        />{" "}
        <path
          d="M14.1781 1.78998C11.1327 -2.1014 5.94873 1.02315 5.94873 4.78249C5.94873 6.67487 6.50982 8.09762 7.25365 9.15435C7.77853 8.47069 8.48933 7.95278 9.30094 7.66264C10.1126 7.37249 10.9906 7.32241 11.8299 7.51839C13.5609 5.51677 16.4038 4.63454 14.1781 1.78998Z"
          fill="#A275EB"
        />{" "}
        <path
          d="M6.51568 13.1931C3.91762 12.6952 1.73197 10.6742 0.381037 14.0239C-1.46628 18.6062 3.83158 21.5343 7.08724 19.6547C8.72602 18.7085 9.67745 17.5107 10.221 16.3386C9.3667 16.2249 8.56317 15.8679 7.90619 15.3102C7.24921 14.7525 6.76651 14.0176 6.51568 13.1931Z"
          fill="#A275EB"
        />{" "}
        <path
          d="M19.3981 11.2319C17.7599 10.286 16.2474 10.0608 14.961 10.1761C15.2897 10.9726 15.3821 11.847 15.2273 12.6947C15.0725 13.5424 14.6769 14.3276 14.0879 14.9566C14.9565 17.4559 14.2979 20.3607 17.8749 19.855C22.7677 19.1633 22.6538 13.1115 19.3981 11.2319Z"
          fill="#A275EB"
        />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#A275EB" />{" "}
        <path
          d="M21.8217 25.8306C23.1098 25.8306 24.154 24.7864 24.154 23.4983C24.154 22.2103 23.1098 21.1661 21.8217 21.1661C20.5337 21.1661 19.4895 22.2103 19.4895 23.4983C19.4895 24.7864 20.5337 25.8306 21.8217 25.8306Z"
          fill="white"
        />{" "}
        <path
          d="M24.4455 15.627C22.072 12.5942 18.0319 15.0294 18.0319 17.9593C18.0319 19.4341 18.4692 20.543 19.0489 21.3665C19.4579 20.8337 20.0119 20.4301 20.6444 20.2039C21.277 19.9778 21.9613 19.9388 22.6154 20.0915C23.9645 18.5315 26.1801 17.844 24.4455 15.627Z"
          fill="white"
        />{" "}
        <path
          d="M18.4737 24.5142C16.4489 24.1262 14.7455 22.551 13.6926 25.1617C12.2529 28.7329 16.3818 31.015 18.9191 29.5501C20.1963 28.8126 20.9378 27.8792 21.3614 26.9657C20.6957 26.8771 20.0694 26.5989 19.5574 26.1642C19.0454 25.7295 18.6692 25.1567 18.4737 24.5142Z"
          fill="white"
        />{" "}
        <path
          d="M28.5137 22.9857C27.2369 22.2485 26.0581 22.073 25.0556 22.1628C25.3117 22.7836 25.3838 23.4651 25.2631 24.1257C25.1425 24.7864 24.8342 25.3984 24.3751 25.8886C25.0521 27.8364 24.5388 30.1003 27.3266 29.7062C31.1398 29.1671 31.051 24.4506 28.5137 22.9857Z"
          fill="white"
        />{" "}
      </svg>
    ),
  },
  SVP_RWG: {
    label: "SVP & RWG",
    color: "#EB7575",
    icon: (
      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M4 2V13.5H14V23"
          stroke="#EB7575"
          strokeWidth="5"
          strokeLinejoin="round"
        />{" "}
        <rect width="8" height="3" fill="#EB7575" />{" "}
        <rect x="10" y="20" width="8" height="3" fill="#EB7575" />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#EB7575" />{" "}
        <path
          d="M17.9565 15.4783V23.9783H25.3478V31"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />{" "}
        <rect x="15" y="14" width="5.91304" height="2.21739" fill="white" />{" "}
        <rect
          x="22.3913"
          y="28.7826"
          width="5.91304"
          height="2.21739"
          fill="white"
        />{" "}
      </svg>
    ),
  },
  SVP: {
    label: "SVP",
    color: "#EB7575",
    icon: (
      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M4 2V13.5H14V23"
          stroke="#EB7575"
          strokeWidth="5"
          strokeLinejoin="round"
        />{" "}
        <rect width="8" height="3" fill="#EB7575" />{" "}
        <rect x="10" y="20" width="8" height="3" fill="#EB7575" />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#EB7575" />{" "}
        <path
          d="M17.9565 15.4783V23.9783H25.3478V31"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />{" "}
        <rect x="15" y="14" width="5.91304" height="2.21739" fill="white" />{" "}
        <rect
          x="22.3913"
          y="28.7826"
          width="5.91304"
          height="2.21739"
          fill="white"
        />{" "}
      </svg>
    ),
  },
  RWG: {
    label: "RWG",
    color: "#EB7575",
    icon: (
      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M4 2V13.5H14V23"
          stroke="#EB7575"
          strokeWidth="5"
          strokeLinejoin="round"
        />{" "}
        <rect width="8" height="3" fill="#EB7575" />{" "}
        <rect x="10" y="20" width="8" height="3" fill="#EB7575" />{" "}
      </svg>
    ),
    bigIcon: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <rect width="44" height="44" rx="4" fill="#EB7575" />{" "}
        <path
          d="M17.9565 15.4783V23.9783H25.3478V31"
          stroke="white"
          strokeWidth="4"
          strokeLinejoin="round"
        />{" "}
        <rect x="15" y="14" width="5.91304" height="2.21739" fill="white" />{" "}
        <rect
          x="22.3913"
          y="28.7826"
          width="5.91304"
          height="2.21739"
          fill="white"
        />{" "}
      </svg>
    ),
  },
};

export const EMPLOYMENT_TYPE = {
  ASSIGNED: {
    label: "Assigned",
    icon: (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M13.8333 2.50001H10.35C10 1.53334 9.08333 0.833344 8 0.833344C6.91667 0.833344 6 1.53334 5.65 2.50001H2.16667C1.25 2.50001 0.5 3.25001 0.5 4.16668V15.8333C0.5 16.75 1.25 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16668C15.5 3.25001 14.75 2.50001 13.8333 2.50001ZM8 2.50001C8.45833 2.50001 8.83333 2.87501 8.83333 3.33334C8.83333 3.79168 8.45833 4.16668 8 4.16668C7.54167 4.16668 7.16667 3.79168 7.16667 3.33334C7.16667 2.87501 7.54167 2.50001 8 2.50001ZM6.33333 14.1667L3 10.8333L4.175 9.65834L6.33333 11.8083L11.825 6.31668L13 7.50001L6.33333 14.1667Z"
          fill="#007DBC"
        />{" "}
      </svg>
    ),
  },
  IDLE: {
    label: "Idle",
    icon: (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8333 2.50001H10.35C10 1.53334 9.08333 0.833344 8 0.833344C6.91667 0.833344 6 1.53334 5.65 2.50001H2.16667C1.25 2.50001 0.5 3.25001 0.5 4.16668V15.8333C0.5 16.75 1.25 17.5 2.16667 17.5H13.8333C14.75 17.5 15.5 16.75 15.5 15.8333V4.16668C15.5 3.25001 14.75 2.50001 13.8333 2.50001ZM8 2.50001C8.45833 2.50001 8.83333 2.87501 8.83333 3.33334C8.83333 3.79168 8.45833 4.16668 8 4.16668C7.54167 4.16668 7.16667 3.79168 7.16667 3.33334C7.16667 2.87501 7.54167 2.50001 8 2.50001Z"
          fill="#95A4B1"
        />
      </svg>
    ),
  },
};

export const PROJECT_STRUCTURE = {
  PROJECT: "PROJECT",
  BLOCK: "BLOCK",
  LEVEL: "LEVEL",
  PLOT: "PLOT",
};

export const JOB_STATUS = {
  DRAFT: {
    value: "DRAFT",
    label: "Draft",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M15.6665 4.00033H12.3332V2.33366C12.3332 1.40866 11.5915 0.666992 10.6665 0.666992H7.33317C6.40817 0.666992 5.6665 1.40866 5.6665 2.33366V4.00033H2.33317C1.40817 4.00033 0.674837 4.74199 0.674837 5.66699L0.666504 14.8337C0.666504 15.7587 1.40817 16.5003 2.33317 16.5003H15.6665C16.5915 16.5003 17.3332 15.7587 17.3332 14.8337V5.66699C17.3332 4.74199 16.5915 4.00033 15.6665 4.00033ZM10.6665 4.00033H7.33317V2.33366H10.6665V4.00033Z"
          fill="#95A4B1"
        />{" "}
      </svg>
    ),
  },
  ACTIVE: {
    value: "ACTIVE",
    label: "Active",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 4.00033H12.3332V2.33366C12.3332 1.40866 11.5915 0.666992 10.6665 0.666992H7.33317C6.40817 0.666992 5.6665 1.40866 5.6665 2.33366V4.00033H2.33317C1.40817 4.00033 0.674837 4.74199 0.674837 5.66699L0.666504 14.8337C0.666504 15.7587 1.40817 16.5003 2.33317 16.5003H15.6665C16.5915 16.5003 17.3332 15.7587 17.3332 14.8337V5.66699C17.3332 4.74199 16.5915 4.00033 15.6665 4.00033ZM10.6665 4.00033H7.33317V2.33366H10.6665V4.00033Z"
          fill="#007DBC"
        />
      </svg>
    ),
  },
  ASSIGNED_TO_USER: {
    value: "ASSIGNED_TO_USER",
    label: "Assigned to user",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6667 3.99999H12.3334V2.33332C12.3334 1.41666 11.5834 0.666656 10.6667 0.666656H7.33335C6.41669 0.666656 5.66669 1.41666 5.66669 2.33332V3.99999H2.33335C1.41669 3.99999 0.666687 4.74999 0.666687 5.66666V14.8333C0.666687 15.75 1.41669 16.5 2.33335 16.5H15.6667C16.5834 16.5 17.3334 15.75 17.3334 14.8333V5.66666C17.3334 4.74999 16.5834 3.99999 15.6667 3.99999ZM5.16669 11.0833C4.66669 11.0833 4.33335 10.75 4.33335 10.25C4.33335 9.74999 4.66669 9.41666 5.16669 9.41666C5.66669 9.41666 6.00002 9.74999 6.00002 10.25C6.00002 10.75 5.66669 11.0833 5.16669 11.0833ZM9.00002 11.0833C8.50002 11.0833 8.16669 10.75 8.16669 10.25C8.16669 9.74999 8.50002 9.41666 9.00002 9.41666C9.50002 9.41666 9.83335 9.74999 9.83335 10.25C9.83335 10.75 9.50002 11.0833 9.00002 11.0833ZM10.6667 3.99999H7.33335V2.33332H10.6667V3.99999ZM12.8334 11.0833C12.3334 11.0833 12 10.75 12 10.25C12 9.74999 12.3334 9.41666 12.8334 9.41666C13.3334 9.41666 13.6667 9.74999 13.6667 10.25C13.6667 10.75 13.25 11.0833 12.8334 11.0833Z"
          fill="#0099E7"
        />
      </svg>
    ),
  },
  ASSIGNED_TO_PM: {
    value: "ASSIGNED_TO_PM",
    label: "Assigned to PM",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 3.99984H12.3332V2.33317C12.3332 1.4165 11.5832 0.666504 10.6665 0.666504H7.33317C6.4165 0.666504 5.6665 1.4165 5.6665 2.33317V3.99984H2.33317C1.4165 3.99984 0.666504 4.74984 0.666504 5.6665V14.8332C0.666504 15.7498 1.4165 16.4998 2.33317 16.4998H15.6665C16.5832 16.4998 17.3332 15.7498 17.3332 14.8332V5.6665C17.3332 4.74984 16.5832 3.99984 15.6665 3.99984ZM5.1665 11.0832C4.6665 11.0832 4.33317 10.7498 4.33317 10.2498C4.33317 9.74984 4.6665 9.4165 5.1665 9.4165C5.6665 9.4165 5.99984 9.74984 5.99984 10.2498C5.99984 10.7498 5.6665 11.0832 5.1665 11.0832ZM8.99984 11.0832C8.49984 11.0832 8.1665 10.7498 8.1665 10.2498C8.1665 9.74984 8.49984 9.4165 8.99984 9.4165C9.49984 9.4165 9.83317 9.74984 9.83317 10.2498C9.83317 10.7498 9.49984 11.0832 8.99984 11.0832ZM10.6665 3.99984H7.33317V2.33317H10.6665V3.99984ZM12.8332 11.0832C12.3332 11.0832 11.9998 10.7498 11.9998 10.2498C11.9998 9.74984 12.3332 9.4165 12.8332 9.4165C13.3332 9.4165 13.6665 9.74984 13.6665 10.2498C13.6665 10.7498 13.2498 11.0832 12.8332 11.0832Z"
          fill="#FEAE36"
        />
      </svg>
    ),
  },
  COMPLETED: {
    value: "COMPLETED",
    label: "Completed",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 3.99984H12.3332V2.33317C12.3332 1.4165 11.5832 0.666504 10.6665 0.666504H7.33317C6.4165 0.666504 5.6665 1.4165 5.6665 2.33317V3.99984H2.33317C1.4165 3.99984 0.666504 4.74984 0.666504 5.6665V14.8332C0.666504 15.7498 1.4165 16.4998 2.33317 16.4998H15.6665C16.5832 16.4998 17.3332 15.7498 17.3332 14.8332V5.6665C17.3332 4.74984 16.5832 3.99984 15.6665 3.99984ZM7.33317 2.33317H10.6665V3.99984H7.33317V2.33317ZM7.74984 13.5832L4.83317 10.7498L5.83317 9.74984L7.6665 11.5832L12.4165 6.83317L13.4165 7.83317L7.74984 13.5832Z"
          fill="#0FC758"
        />
      </svg>
    ),
  },
  DECLINED: {
    value: "DECLINED",
    label: "Declined",
    icon: (
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0768 0.0636248C8.8905 0.0384977 7.71091 0.238935 6.60725 0.653169C5.50359 1.0674 4.49811 1.68708 3.64984 2.47583C2.80157 3.26457 2.12762 4.20648 1.66754 5.24625C1.20747 6.28602 0.970554 7.4027 0.970703 8.53071C0.970852 9.65873 1.20806 10.7753 1.66841 11.815C2.12876 12.8547 2.80296 13.7964 3.65144 14.585C4.49991 15.3735 5.50556 15.9929 6.60933 16.4069C7.7131 16.8209 8.89275 17.021 10.079 16.9956C12.4063 16.9458 14.6206 16.0318 16.2479 14.4493C17.8753 12.8668 18.7863 10.7416 18.786 8.52859C18.7857 6.31555 17.8741 4.19056 16.2464 2.60847C14.6186 1.02638 12.4041 0.112921 10.0768 0.0636248ZM3.40777 8.52962C3.41014 6.84896 4.11356 5.23779 5.36376 4.04947C6.61396 2.86116 8.30887 2.19272 10.0768 2.19075C11.5901 2.19075 12.9827 2.67737 14.1025 3.48806L4.77354 12.3578C3.88843 11.259 3.40847 9.91364 3.40777 8.52962ZM10.0768 14.8685C8.69516 14.8691 7.34801 14.4585 6.22424 13.6944L15.5108 4.86612C16.3137 5.93516 16.7451 7.21604 16.7447 8.52962C16.7423 10.2101 16.039 11.8211 14.7891 13.0094C13.5391 14.1977 11.8445 14.8663 10.0768 14.8685Z"
          fill="#FF4444"
        />
      </svg>
    ),
  },
};

export const STEP_STATUS = {
  DRAFT: {
    color: `#95A4B1`,
  },
  IN_PROGRESS: {
    color: `#007DBC`,
  },
  COMPLETED: {
    color: `#3BD122`,
  },
  UNDER_VERIFICATION: {
    color: `#FEAE36`,
  },
  REJECTED: {
    color: `#FF0000`,
  },
  YES: {
    label: `Yes`,
    color: `#0FC758`,
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <circle cx="10" cy="10" r="9" stroke="#95A4B1" strokeWidth="2" />{" "}
        <circle cx="10" cy="10" r="6" fill="#0FC758" />{" "}
      </svg>
    ),
  },
  NO: {
    label: `No`,
    color: `#FF4444`,
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" stroke="#95A4B1" strokeWidth="2" />
        <circle cx="10" cy="10" r="6" fill="#FF4444" />
      </svg>
    ),
  },
  UNDEFINED: {
    label: `UNDEFINED`,
    color: `#95A4B1`,
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      ></svg>
    ),
  },
  ASSIGNED_TO_USER: {
    color: "#0099E7",
  },
  PENDING: {
    color: "#0099E7",
  },
};

export const JOB_PLAN = {
  FLOOR: {
    value: `FLOOR`,
  },
  FLAT: {
    value: `FLAT`,
  },
};

export const PROJECT_STATUS = {
  DRAFT: {
    value: `DRAFT`,
  },
  ACTIVE: {
    value: `ACTIVE`,
  },
  COMPLETED: {
    value: `COMPLETED`,
  },
};

export const INVOICES_STATUS = {
  PAID: {
    label: `Paid`,
    value: "PAID",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M9.00033 0.666992C4.40033 0.666992 0.666992 4.40033 0.666992 9.00033C0.666992 13.6003 4.40033 17.3337 9.00033 17.3337C13.6003 17.3337 17.3337 13.6003 17.3337 9.00033C17.3337 4.40033 13.6003 0.666992 9.00033 0.666992ZM7.33366 13.167L3.16699 9.00033L4.34199 7.82533L7.33366 10.8087L13.6587 4.48366L14.8337 5.66699L7.33366 13.167Z"
          fill="#0FC758"
        />{" "}
      </svg>
    ),
  },
  UNPAID: {
    label: `Unpaid`,
    value: "UNPAID",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00033 0.666992C4.39199 0.666992 0.666992 4.39199 0.666992 9.00033C0.666992 13.6087 4.39199 17.3337 9.00033 17.3337C13.6087 17.3337 17.3337 13.6087 17.3337 9.00033C17.3337 4.39199 13.6087 0.666992 9.00033 0.666992ZM13.167 11.992L11.992 13.167L9.00033 10.1753L6.00866 13.167L4.83366 11.992L7.82533 9.00033L4.83366 6.00866L6.00866 4.83366L9.00033 7.82533L11.992 4.83366L13.167 6.00866L10.1753 9.00033L13.167 11.992Z"
          fill="#FF4444"
        />
      </svg>
    ),
  },
};

export const PROJECT_ACTION = {
  START: {
    value: `START`,
  },
  STOP: {
    value: `STOP`,
  },
  DELETE: {
    value: `DELETE`,
  },
};

export const SORT_BY = {
  PROJECT: {
    value: `PROJECT`,
  },
  USER: {
    value: `USER`,
  },
};

export const REQUEST_TYPE = {
  ADDITIONAL_WORK_REQUEST: {
    value: `Additional_work_request`,
  },
  JOB_REQUEST: {
    value: `Job_request`,
  },
  QA_REQUEST: {
    value: `QA_request`,
  },
};

export const ADDITIONAL_WORK_REQUEST_STATUS = {
  PENDING: {
    value: `PENDING`,
    label: "Pending",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M15.6667 3.99999H12.3334V2.33332C12.3334 1.41666 11.5834 0.666656 10.6667 0.666656H7.33335C6.41669 0.666656 5.66669 1.41666 5.66669 2.33332V3.99999H2.33335C1.41669 3.99999 0.666687 4.74999 0.666687 5.66666V14.8333C0.666687 15.75 1.41669 16.5 2.33335 16.5H15.6667C16.5834 16.5 17.3334 15.75 17.3334 14.8333V5.66666C17.3334 4.74999 16.5834 3.99999 15.6667 3.99999ZM5.16669 11.0833C4.66669 11.0833 4.33335 10.75 4.33335 10.25C4.33335 9.74999 4.66669 9.41666 5.16669 9.41666C5.66669 9.41666 6.00002 9.74999 6.00002 10.25C6.00002 10.75 5.66669 11.0833 5.16669 11.0833ZM9.00002 11.0833C8.50002 11.0833 8.16669 10.75 8.16669 10.25C8.16669 9.74999 8.50002 9.41666 9.00002 9.41666C9.50002 9.41666 9.83335 9.74999 9.83335 10.25C9.83335 10.75 9.50002 11.0833 9.00002 11.0833ZM10.6667 3.99999H7.33335V2.33332H10.6667V3.99999ZM12.8334 11.0833C12.3334 11.0833 12 10.75 12 10.25C12 9.74999 12.3334 9.41666 12.8334 9.41666C13.3334 9.41666 13.6667 9.74999 13.6667 10.25C13.6667 10.75 13.25 11.0833 12.8334 11.0833Z"
          fill="#0099E7"
        />{" "}
      </svg>
    ),
  },
  COMPLETED: {
    value: `COMPLETED`,
    label: "Approved",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 3.99984H12.3332V2.33317C12.3332 1.4165 11.5832 0.666504 10.6665 0.666504H7.33317C6.4165 0.666504 5.6665 1.4165 5.6665 2.33317V3.99984H2.33317C1.4165 3.99984 0.666504 4.74984 0.666504 5.6665V14.8332C0.666504 15.7498 1.4165 16.4998 2.33317 16.4998H15.6665C16.5832 16.4998 17.3332 15.7498 17.3332 14.8332V5.6665C17.3332 4.74984 16.5832 3.99984 15.6665 3.99984ZM7.33317 2.33317H10.6665V3.99984H7.33317V2.33317ZM7.74984 13.5832L4.83317 10.7498L5.83317 9.74984L7.6665 11.5832L12.4165 6.83317L13.4165 7.83317L7.74984 13.5832Z"
          fill="#0FC758"
        />
      </svg>
    ),
  },
  REJECTED: {
    value: `REJECTED`,
    label: "Rejected",
    icon: (
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0768 0.0636248C8.8905 0.0384977 7.71091 0.238935 6.60725 0.653169C5.50359 1.0674 4.49811 1.68708 3.64984 2.47583C2.80157 3.26457 2.12762 4.20648 1.66754 5.24625C1.20747 6.28602 0.970554 7.4027 0.970703 8.53071C0.970852 9.65873 1.20806 10.7753 1.66841 11.815C2.12876 12.8547 2.80296 13.7964 3.65144 14.585C4.49991 15.3735 5.50556 15.9929 6.60933 16.4069C7.7131 16.8209 8.89275 17.021 10.079 16.9956C12.4063 16.9458 14.6206 16.0318 16.2479 14.4493C17.8753 12.8668 18.7863 10.7416 18.786 8.52859C18.7857 6.31555 17.8741 4.19056 16.2464 2.60847C14.6186 1.02638 12.4041 0.112921 10.0768 0.0636248ZM3.40777 8.52962C3.41014 6.84896 4.11356 5.23779 5.36376 4.04947C6.61396 2.86116 8.30887 2.19272 10.0768 2.19075C11.5901 2.19075 12.9827 2.67737 14.1025 3.48806L4.77354 12.3578C3.88843 11.259 3.40847 9.91364 3.40777 8.52962ZM10.0768 14.8685C8.69516 14.8691 7.34801 14.4585 6.22424 13.6944L15.5108 4.86612C16.3137 5.93516 16.7451 7.21604 16.7447 8.52962C16.7423 10.2101 16.039 11.8211 14.7891 13.0094C13.5391 14.1977 11.8445 14.8663 10.0768 14.8685Z"
          fill="#FF4444"
        />
      </svg>
    ),
  },
  APPROVED_TO_PM: {
    value: `APPROVED_TO_PM`,
    label: "Approved to PM",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M15.6667 3.99999H12.3334V2.33332C12.3334 1.41666 11.5834 0.666656 10.6667 0.666656H7.33335C6.41669 0.666656 5.66669 1.41666 5.66669 2.33332V3.99999H2.33335C1.41669 3.99999 0.666687 4.74999 0.666687 5.66666V14.8333C0.666687 15.75 1.41669 16.5 2.33335 16.5H15.6667C16.5834 16.5 17.3334 15.75 17.3334 14.8333V5.66666C17.3334 4.74999 16.5834 3.99999 15.6667 3.99999ZM5.16669 11.0833C4.66669 11.0833 4.33335 10.75 4.33335 10.25C4.33335 9.74999 4.66669 9.41666 5.16669 9.41666C5.66669 9.41666 6.00002 9.74999 6.00002 10.25C6.00002 10.75 5.66669 11.0833 5.16669 11.0833ZM9.00002 11.0833C8.50002 11.0833 8.16669 10.75 8.16669 10.25C8.16669 9.74999 8.50002 9.41666 9.00002 9.41666C9.50002 9.41666 9.83335 9.74999 9.83335 10.25C9.83335 10.75 9.50002 11.0833 9.00002 11.0833ZM10.6667 3.99999H7.33335V2.33332H10.6667V3.99999ZM12.8334 11.0833C12.3334 11.0833 12 10.75 12 10.25C12 9.74999 12.3334 9.41666 12.8334 9.41666C13.3334 9.41666 13.6667 9.74999 13.6667 10.25C13.6667 10.75 13.25 11.0833 12.8334 11.0833Z"
          fill="#0099E7"
        />{" "}
      </svg>
    ),
  },
};

export const DAY_WORK_STATUS = {
  PENDING: {
    value: "PENDING",
    label: "Pending",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M15.6667 3.99999H12.3334V2.33332C12.3334 1.41666 11.5834 0.666656 10.6667 0.666656H7.33335C6.41669 0.666656 5.66669 1.41666 5.66669 2.33332V3.99999H2.33335C1.41669 3.99999 0.666687 4.74999 0.666687 5.66666V14.8333C0.666687 15.75 1.41669 16.5 2.33335 16.5H15.6667C16.5834 16.5 17.3334 15.75 17.3334 14.8333V5.66666C17.3334 4.74999 16.5834 3.99999 15.6667 3.99999ZM5.16669 11.0833C4.66669 11.0833 4.33335 10.75 4.33335 10.25C4.33335 9.74999 4.66669 9.41666 5.16669 9.41666C5.66669 9.41666 6.00002 9.74999 6.00002 10.25C6.00002 10.75 5.66669 11.0833 5.16669 11.0833ZM9.00002 11.0833C8.50002 11.0833 8.16669 10.75 8.16669 10.25C8.16669 9.74999 8.50002 9.41666 9.00002 9.41666C9.50002 9.41666 9.83335 9.74999 9.83335 10.25C9.83335 10.75 9.50002 11.0833 9.00002 11.0833ZM10.6667 3.99999H7.33335V2.33332H10.6667V3.99999ZM12.8334 11.0833C12.3334 11.0833 12 10.75 12 10.25C12 9.74999 12.3334 9.41666 12.8334 9.41666C13.3334 9.41666 13.6667 9.74999 13.6667 10.25C13.6667 10.75 13.25 11.0833 12.8334 11.0833Z"
          fill="#0099E7"
        />{" "}
      </svg>
    ),
  },
  APPROVED_BY_PM: {
    value: "APPROVED_BY_PM",
    label: "Approved by PM",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 3.99984H12.3332V2.33317C12.3332 1.4165 11.5832 0.666504 10.6665 0.666504H7.33317C6.4165 0.666504 5.6665 1.4165 5.6665 2.33317V3.99984H2.33317C1.4165 3.99984 0.666504 4.74984 0.666504 5.6665V14.8332C0.666504 15.7498 1.4165 16.4998 2.33317 16.4998H15.6665C16.5832 16.4998 17.3332 15.7498 17.3332 14.8332V5.6665C17.3332 4.74984 16.5832 3.99984 15.6665 3.99984ZM5.1665 11.0832C4.6665 11.0832 4.33317 10.7498 4.33317 10.2498C4.33317 9.74984 4.6665 9.4165 5.1665 9.4165C5.6665 9.4165 5.99984 9.74984 5.99984 10.2498C5.99984 10.7498 5.6665 11.0832 5.1665 11.0832ZM8.99984 11.0832C8.49984 11.0832 8.1665 10.7498 8.1665 10.2498C8.1665 9.74984 8.49984 9.4165 8.99984 9.4165C9.49984 9.4165 9.83317 9.74984 9.83317 10.2498C9.83317 10.7498 9.49984 11.0832 8.99984 11.0832ZM10.6665 3.99984H7.33317V2.33317H10.6665V3.99984ZM12.8332 11.0832C12.3332 11.0832 11.9998 10.7498 11.9998 10.2498C11.9998 9.74984 12.3332 9.4165 12.8332 9.4165C13.3332 9.4165 13.6665 9.74984 13.6665 10.2498C13.6665 10.7498 13.2498 11.0832 12.8332 11.0832Z"
          fill="#FEAE36"
        />
      </svg>
    ),
  },
  APPROVED: {
    value: "APPROVED",
    label: "Approved",
    icon: (
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6665 3.99984H12.3332V2.33317C12.3332 1.4165 11.5832 0.666504 10.6665 0.666504H7.33317C6.4165 0.666504 5.6665 1.4165 5.6665 2.33317V3.99984H2.33317C1.4165 3.99984 0.666504 4.74984 0.666504 5.6665V14.8332C0.666504 15.7498 1.4165 16.4998 2.33317 16.4998H15.6665C16.5832 16.4998 17.3332 15.7498 17.3332 14.8332V5.6665C17.3332 4.74984 16.5832 3.99984 15.6665 3.99984ZM7.33317 2.33317H10.6665V3.99984H7.33317V2.33317ZM7.74984 13.5832L4.83317 10.7498L5.83317 9.74984L7.6665 11.5832L12.4165 6.83317L13.4165 7.83317L7.74984 13.5832Z"
          fill="#0FC758"
        />
      </svg>
    ),
  },
  REJECTED: {
    value: "REJECTED",
    label: "Rejected",
    icon: (
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0768 0.0636248C8.8905 0.0384977 7.71091 0.238935 6.60725 0.653169C5.50359 1.0674 4.49811 1.68708 3.64984 2.47583C2.80157 3.26457 2.12762 4.20648 1.66754 5.24625C1.20747 6.28602 0.970554 7.4027 0.970703 8.53071C0.970852 9.65873 1.20806 10.7753 1.66841 11.815C2.12876 12.8547 2.80296 13.7964 3.65144 14.585C4.49991 15.3735 5.50556 15.9929 6.60933 16.4069C7.7131 16.8209 8.89275 17.021 10.079 16.9956C12.4063 16.9458 14.6206 16.0318 16.2479 14.4493C17.8753 12.8668 18.7863 10.7416 18.786 8.52859C18.7857 6.31555 17.8741 4.19056 16.2464 2.60847C14.6186 1.02638 12.4041 0.112921 10.0768 0.0636248ZM3.40777 8.52962C3.41014 6.84896 4.11356 5.23779 5.36376 4.04947C6.61396 2.86116 8.30887 2.19272 10.0768 2.19075C11.5901 2.19075 12.9827 2.67737 14.1025 3.48806L4.77354 12.3578C3.88843 11.259 3.40847 9.91364 3.40777 8.52962ZM10.0768 14.8685C8.69516 14.8691 7.34801 14.4585 6.22424 13.6944L15.5108 4.86612C16.3137 5.93516 16.7451 7.21604 16.7447 8.52962C16.7423 10.2101 16.039 11.8211 14.7891 13.0094C13.5391 14.1977 11.8445 14.8663 10.0768 14.8685Z"
          fill="#FF4444"
        />
      </svg>
    ),
  },
};

export const CIS_STATUS = {
  GROSS_STATUS: {
    value: 0,
    label: "0%",
  },
  NET_STANDARD: {
    value: 0.2,
    label: "20%",
  },
  NET_HIGHER: {
    value: 0.3,
    label: "30%",
  },
};

export const DAYWORK_STATUS = {
  COMPLETED: {
    color: "#3BD122",
  },
  PENDING: {
    color: "#007DBC",
  },
  APPROVED_BY_PM: {
    color: "#FEAE36",
  },
  APPROVED: {
    color: "#0FC758",
  },
  REJECTED: {
    color: "#FF0000",
  },
};

export const CURRENT_PREVIEW_USER_EXPAND = {
  ACTIVE: {
    value: [
      `${JOB_STATUS.ASSIGNED_TO_USER.value}`,
      `${JOB_STATUS.ASSIGNED_TO_PM.value}`,
      `${JOB_STATUS.DECLINED.value}`,
    ],
  },
  JOB_HISTORY: {
    value: [`${JOB_STATUS.COMPLETED.value}`],
  },
  DAY_WORK: {
    value: [`DAY_WORK`],
  },
};
