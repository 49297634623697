import React, { useEffect, useState } from 'react'
import CircularProgressBar from '../../../shared/components/progress/CircularProgressBar'
import { getContractJobCompletionByTypes } from '../../../redux/services/contracts'
import { WORK_TYPE } from '../../../constants/enamerations'

const WorkTypesPerContract = ({match, totalJobs}) => {
  const [jobCompletionByType, setJobCompletionByType] = useState();

  const workTypes = [
    'ELECTRICAL',
    'MECHANICAL',
    'VENTILATION',
    'SVP_RWG',
    'SVP',
    'RWG'
  ];

  useEffect(()=>{
    getContractJobCompletionByTypes(match.params.id)
    .then((res)=>{
      setJobCompletionByType(res)
    })
  },[])

  if(!jobCompletionByType) return <p>No job type found</p>

  // testing deployment

  return (
    <div className="dashboard_progress_bar_wrapper mt-4">
      {workTypes.map((type, index) => ( 
          <div key={index} className="progress_bar_group">
              <CircularProgressBar color={WORK_TYPE[type].color} value={
              !!jobCompletionByType[type]  
              ? (jobCompletionByType[type].total * 100 / totalJobs).toFixed(2) 
              : 0
              } />
              <div className="wrapper_page-title mt-3">
                  <p className="form__form-group-label m-0">{WORK_TYPE[type].label}</p>
              </div>
          </div>
      ))}
    </div>
  )
}

export default WorkTypesPerContract