import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import IvoicesTable from "./Tables/IvoicesTable"
import {getUserById} from "../../redux/services/users";
import Loading from "../../shared/components/Loading";
import {CONTRACTS, PREVIEW_CONTRACT, PREVIEW_PROJECT, PREVIEW_USER, USERS} from "../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";
import {getProjectsById} from "../../redux/services/projects";

const index = ({ match, type }) => {
    const [loading, setLoad] = useState(false);
    const [user, setUserInfo] = useState(null);
    const [project, setProject] = useState(null);


    const getUser = () => {
        setLoad(true);
        getUserById(match.params.id).then((res) => {
            setUserInfo(res);
            setLoad(false);
        });
    };


    const getProject = () => {
        setLoad(true);
        getProjectsById(+match.params.id).then((res) => {
            setProject(res.data);
            setLoad(false);
        });
    };

    useEffect(() => {
        if(type === 'USER'){
            getUser();
        }
        if(type === 'PROJECT'){
            getProject();
        }
    }, []);

    const userData = user && user.first_name ? `${user.first_name} ${user.surname}` : 'Current User'
    const contract = project && project.projectGroup ? project.projectGroup : null
    const projectId = project && project.id ? project.id : null
    const projectName = project && project.name ? project.name : null

    return ( loading ?  <Loading/> : <Container>
            {type === 'USER' ?  <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
                <Link to={{pathname: USERS}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        Accounts
                    </h3>

                </Link>
                <Link to={{pathname: PREVIEW_USER.replace(':id', match.params.id)}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {userData}
                    </h3>

                </Link>
                <Link to={{pathname: ''}} >
                    <h3 className={"page-subhead subhead bread_crumbs-active"}>
                        Invoices
                    </h3>
                </Link>
            </Breadcrumbs>:  <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
                <Link to={{pathname: CONTRACTS}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        Contracts
                    </h3>
                </Link>
                {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!contract.name ? contract.name : `Current Contract`}
                    </h3>
                </Link>}
                {!!projectId && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',projectId).replace(':status', 'ACTIVE')}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!projectName ? projectName : 'Current Project'}
                    </h3>
                </Link>}

                <Link to={{pathname: ''}} >
                    <h3 className={"page-subhead subhead bread_crumbs-active"}>
                        Invoices
                    </h3>
                </Link>
            </Breadcrumbs>
            }

        <Row className="pr-3 d-flex justify-content-between">
            <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                <Col md={1.5} xl={1.5} className="p-0">
                    <h5 className="page-title m-0">Invoices</h5>
                </Col>
            </Col>
        </Row>
        <Card>
            <CardBody>
                <IvoicesTable type={type} />
            </CardBody>
        </Card>
    </Container>

    )
}

export default withRouter(index);
