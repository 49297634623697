import axios from "axios";
import { BASE_API } from "../../config/api";
import moment from "moment";

export const getProjectsAsync = async (status, pagination) =>
  await axios
    .post(`${BASE_API}admin/projects-pageable`, {
      filter: status,
      pagination: pagination,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getProjectsByIdAsync = async (id) =>
  await axios
    .post(`${BASE_API}project/${id}`, { includeJobs: false })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const uploadFileProjectAsync = async (source) =>
  await axios
    .post(`${BASE_API}project/signed/url`, { source: source })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const uploadCSVProjectAsync = async (url, file) => {
  const token = axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Authorization"];

  await axios
    .put(`${url}`, file)
    .then((response) => {
      axios.defaults.headers.common["Authorization"] = token;
      return response && response.data;
    })
    .catch((error) => {
      axios.defaults.headers.common["Authorization"] = token;
      return error && error.message;
    });
};

export const getProjectFilesAsync = async () =>
  await axios
    .get(`${BASE_API}admin/projects-template`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const projectCreateAsync = async (data) => {
  await axios
    .post(`${BASE_API}project`, data)
    .then((response) => response && response.data)
    .catch((error) => error.message);
};

export const projectStartAsync = async (id, status) =>
  await axios
    .patch(`${BASE_API}project/${id}/change/status`, {
      statusProject: status,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const projectDeleteAsync = async (id) =>
  await axios
    .delete(`${BASE_API}project/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const projectStopAsync = async (id, status) =>
  await axios
    .patch(`${BASE_API}project/${id}/change/status`, {
      statusProject: status,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getProjectsByUserAsync = async () =>
  await axios
    .get(`${BASE_API}project/projects`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const projectEditAsync = async (id, data) =>
  await axios
    .patch(`${BASE_API}admin/project/${id}`, data)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getWorkDeploymentAnalitycsAsync = async (id) =>
  await axios
    .get(`${BASE_API}stat/project/${id}/active-jobs-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getQAStatusAnalyticsAsync = async (id) =>
  await axios
    .get(`${BASE_API}stat/project/${id}/under-verification-jobs-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getWorkTypeAnalyticsAsync = async (id) =>
  await axios
    .get(`${BASE_API}stat/project/${id}/job-types-group`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getBudgetAnalyticsAsync = async (id) =>
  await axios
    .get(`${BASE_API}stat/project/${id}/budget`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getInvoiceAnalyticsAsync = async (id) =>
  await axios
    .get(`${BASE_API}stat/project/${id}/invoice`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getProjectsByFilterAsync = async () =>
  await axios
    .get(`${BASE_API}project/projects/filter`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getUniqueValuesByFilterAsync = async (id, filedName) =>
  await axios
    .post(`${BASE_API}project/${id}/job/uniqueFields`, {
      key: filedName,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getUsersByProjectAsync = async (id, userType) =>
  await axios
    .post(`${BASE_API}project/${id}/users`, {
      userType,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getSavedProjectAsync = async (id) =>
  await axios
    .get(`${BASE_API}project/getSavedProject/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveProjectStateAsync = async (formValue, id) =>
  await axios
    .post(`${BASE_API}project/saveProject/${id}`, {
      formValues: formValue,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const deleteSavedProjectAsync = async (id) =>
  await axios
    .delete(`${BASE_API}project/deleteSavedProject/${id}`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getAllSavedProjectsAsync = async () =>
  await axios
    .get(`${BASE_API}project/getAllSavedProjects`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const createProjectFromFormAsync = async (data) =>
  await axios
    .post(`${BASE_API}project/createProjectFromForm/11`, {
      data,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveCreatedProjectIdAsync = async (
  savedProjectId,
  createdProjectId
) =>
  await axios
    .post(`${BASE_API}project/saveCreatedProjectId/${savedProjectId}`, {
      createdProjectId,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const updateBlockAsync = async (data) =>
    await axios
        .patch(`${BASE_API}admin/editBlock/${data.blockId}`, data)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const addBlockAsync = async (data) =>
    await axios
        .post(`${BASE_API}admin/addBlock/${data.projectId}`, data)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const editLevelAsync = async (data) =>
    await axios
        .patch(`${BASE_API}admin/editLevel/${data.id}`, data)
        .then((response) => response && response.data)
        .catch((error) => error.message);


export const exportAllProjectJobsToCsvAsync = async(projectId, includeSteps, ) => {
    const includeStepsParam = includeSteps ? 'includeSteps=true' : ''
    const params = `${includeStepsParam}`
    const exportURL = `${BASE_API}project/${projectId}/export${!!params ? `?${params}` : ''}`
    return await axios
        .post(exportURL)
        .then((response) => {
            const csv = response && response.data;
            const pom = document.createElement("a");
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            pom.href = URL.createObjectURL(blob);
            pom.setAttribute(
                "download",
                `project-${projectId}-jobs-${moment().format("DD-MM-YYYY")}.csv`
            );
            pom.click();
        })
        .catch((error) => error.message);
}
