import React from "react";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";

const HourlyFieldMaterial = ({
  input: { value, onChange },
  label,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <>
      <InputMask
        mask="£\ 9999999999999999"
        maskChar=""
        alwaysShowMask={false}
        value={value}
        onChange={onChange}
      >
        {(inputProps) => (
          <TextField {...inputProps} placeholder={placeholder} label={label} />
        )}
      </InputMask>
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </>
  );
};

export default HourlyFieldMaterial;
