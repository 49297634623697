import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import LinearProgressBar from '../../../shared/components/progress/LinearProgressBar'
import MultiLinearProgressBar from '../../../shared/components/progress/MultiLinearProgressBar'

const AnalyticsCard = ({
  title,
  leftText,
  rightText,
  progressTitle,
  leftCounter,
  rightCounter,
  completedCounter,
  totalCounter,
  completedColor,
  totalColor,
}) => {
  return (
  <Card style={{flexBasis:'40%'}}>
  <CardBody>
  <div className="wrapper_page-title">
      <h5 className="page-title m-0">{title}</h5>
  </div>
  <Row className="mt-3 d-flex justify-content-between">
      <Col md={6} xl={6} className="border-right">
          <div className="lg_wrapper_page-title">
              <p className="page-title m-0 text-blue">{title === 'Budget' && '£'}{leftCounter}</p>
          </div>
          <div className="wrapper_page-title">
              <span className="form__form-group-label m-0">{leftText}</span>
          </div>
      </Col>
      <Col md={5} xl={5}>
          <div className="lg_wrapper_page-title">
              <p className="page-title m-0 text-gray">{title === 'Budget' && '£'}{rightCounter}</p>
          </div>
          <div className="wrapper_page-title">
              <span className="form__form-group-label m-0">{rightText}</span>
          </div>
      </Col>
  </Row>
  <div className="wrapper_page-title mt-5">
      <p className="form__form-group-label m-0">{progressTitle}</p>
  </div>
  <Row className="mt-1 mb-2">
      <Col md={12} xl={12}>
        {
          title === 'Budget'
          ? <LinearProgressBar
            color='purple'
            value={
                totalCounter === 0 
                ? 100
                : (100 - (totalCounter * 100/completedCounter)).toFixed(2)
            }
            />
          : <MultiLinearProgressBar
              one={completedColor} 
              two={totalColor} 
              oneValue={completedCounter} 
              twoValue={totalCounter}
            />
        }
      </Col>
  </Row>
  </CardBody>
  </Card>
  )
}

export default AnalyticsCard