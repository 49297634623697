import React, { useEffect } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import store from "../../../../redux/store";
import MiniTextField from "./MiniTextField";
import { submit } from "redux-form";

const required = (value) => (value ? undefined : "You must enter a value");

let EditLevelForm = ({ handleSubmit, level }) => {
  function initializeEditLevelForm() {
    store.dispatch(
      initialize("edit_level_form", {
        level: level,
      })
    );
  }

  useEffect(() => {
    initializeEditLevelForm();
  }, []);

  return (
    <div>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
          event.stopPropagation();
        }}
      >
        <Field
          name={`level`}
          component={MiniTextField}
          type={"text"}
          validate={required}
          onBlur={() => {
            store.dispatch(submit("edit_level_form"));
          }}
        />

        <button type="submit" hidden></button>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "edit_level_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditLevelForm);
