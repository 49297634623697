import {
  CHANGE_SIDEBAR_VISIBILITY,
} from '../actions/sidebar';

const initialState = {
  collapse: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SIDEBAR_VISIBILITY:
      return { ...state, collapse: !state.collapse };
    default:
      return state;
  }
}
