import React from "react"
import { Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import SelectFieldNoValidate from '../../../shared/components/SelectFieldNoValidate';
import { WORK_TYPE_OPTIONS } from "../../../constants/options"

const ActiveJobsFilterForm = ({ filter, setFilter }) => {
    return (
        <form>
            <Col md={12} xl={12} className="d-flex justify-content-between p-0">
                <div className="form__form-group w-25 mr-3">
                    <div className="form__form-group-field">
                        <div className="create_user_select_wrapper w-100">
                            <Field
                                name="job_type"
                                component={SelectFieldNoValidate}
                                placeholder="Trade"
                                options={WORK_TYPE_OPTIONS}
                                onChange={data => setFilter({ ...filter, job_type: data ? [data] : [] })}
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </form>
    )
}

export default reduxForm({
    form: 'filter_form_jobs',
})(ActiveJobsFilterForm);