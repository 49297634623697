import React, { useEffect } from "react";
import { Col, Row, Container, Button, Table } from "reactstrap";
import { reduxForm, FieldArray } from "redux-form";
import AddBlockForm from "./components/AddBlockForm";
import EditBlockForm from "./components/EditBlockForm";
import store from "../../../redux/store";

const renderLetter = ({ letter }) => {
  return <div className="letterContainer">{letter}</div>;
};

const renderBlocks = ({ fields, saveProject }) => {
  const addBlock = (data) => {
    fields.push({
      id: data.id,
      noOfLevels: data.noOfLevels,
      quantity: data.quantity,
      levels: Array.from({ length: data.noOfLevels }, (_, i) => ({
        level: i,
        flats: [],
      })),
    });
  };

  const editBlock = (data, index) => {
    const oldId = fields.get(index).id;
    const oldBlock = fields.get(index);

    // If only the ID changes the levels and properties will not be deleted
    if (
      oldBlock.noOfLevels === data.noOfLevels &&
      oldBlock.quantity === data.quantity
    ) {
      oldBlock.id = data.id;

      fields.splice(index, 1, oldBlock);
    } else {
      fields.splice(index, 1, {
        id: data.id,
        noOfLevels: data.noOfLevels,
        quantity: data.quantity,
        levels: Array.from({ length: data.noOfLevels }, (_, i) => ({
          level: i,
          flats: [],
        })),
      });
    }

    // Modify the block id in the allocated jobs too
    if (store.getState().form.create_project_form_wizard.values.allocatedJobs) {
      const allocatedJobs = store.getState().form.create_project_form_wizard
        .values.allocatedJobs;

      allocatedJobs.forEach((element) => {
        if (element.block === oldId) {
          element.block = oldBlock.id;
        }
      });
    }
  };

  return (
    <form>
      <Container>
        <Row md={12} className="justify-content-between">
          <Col md={4}>
            <h2>Create blocks</h2>
          </Col>

          <div className="buttonContainer">
            <Col md={6}>
              <AddBlockForm onSubmit={addBlock} />
            </Col>

            <Col md={3}>
              <Button className="button" onClick={saveProject}>
                Save
              </Button>
            </Col>

            <Col md={3} className={"justify-content-end d-flex"}>
              <Button
                type={"submit"}
                className="text-nowrap button"
                disabled={fields.length === 0}
              >
                Next
              </Button>
            </Col>
          </div>
        </Row>

        <Container md={12} className="mt-3  innerContainerSecond">
          <Table responsive hover className="table react-table table--bordered">
            <thead>
              <tr>
                <th className="centeredText">BLOCK</th>
                <th className="centeredText">NUMBER OF LEVELS</th>
                <th className="centeredText">QUANTITY OF PROPERTIES</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields && fields.length
                ? fields.getAll().map((block, index) => (
                    <tr key={index}>
                      <td>
                        <div className="centered">
                          {renderLetter({ letter: block.id })}
                        </div>
                      </td>
                      <td>
                        <div className="centered">{block.noOfLevels}</div>
                      </td>
                      <td>
                        <div className="centered">{block.quantity}</div>
                      </td>
                      <td>
                        <div className="centered">
                          <EditBlockForm
                            id={block.id}
                            noOfLevels={block.noOfLevels}
                            quantity={block.quantity}
                            onSubmit={(data) => editBlock(data, index)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="centered">
                          <Button
                            className="deleteButton"
                            onClick={() => {
                              fields.remove(index);
                            }}
                          >
                            DELETE BLOCK
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Container>
      </Container>
    </form>
  );
};

let CreateProjectStepTwo = (props) => {
  const { handleSubmit, saveProject } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="blocks"
        component={renderBlocks}
        rerenderOnEveryChange={true}
        props={{
          saveProject,
        }}
      />
    </form>
  );
};

CreateProjectStepTwo = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepTwo);

export default CreateProjectStepTwo;
