import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { CURRENT_PREVIEW_USER_EXPAND as CURRENT_EXPAND } from "../../../constants/enamerations";
import { CollapseIcon, ExpandIcon } from '../../../constants/icons';
import DayWorkTable from '../Tables/DayWorkTable';
import JobsTable from '../Tables/JobsTable';

function WorkCategorySpan({
  workType,
  spanTitle,
  status,
  getDayWorkRequests,
  setActiveCollapse,
  activeCollapse,
                              onJobInvoicePressed,
}) {

  const handleCollapse = () => {
   setActiveCollapse(
    activeCollapse === CURRENT_EXPAND[`${workType}`].value
        ? null
        : CURRENT_EXPAND[`${workType}`].value
    )
  }

  return (
    <Row className="mt-4">
      <Col md={12} xl={12}>
      <Card className="h-auto">
      <CardBody className="p-4 pr-2">
        <div
          className="wrapper_page-title d-flex justify-content-between align-items-center hover"
          onClick={handleCollapse}
        >
          <p className="page-title m-0">{spanTitle.toUpperCase()}</p>
          {
            activeCollapse === CURRENT_EXPAND[`${workType}`].value
            ? <CollapseIcon />
            : <ExpandIcon />
          }
        </div>
        {
          activeCollapse === CURRENT_EXPAND[`${workType}`].value
          ? (workType === 'DAY_WORK')
              ? <DayWorkTable getDayWorkRequests={getDayWorkRequests} />
              : <JobsTable  status={status} onJobInvoicePressed={onJobInvoicePressed} />
          : null
        }
      </CardBody>
      </Card>
      </Col>
    </Row>
  )
}

export default WorkCategorySpan
