import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody, Button } from "reactstrap";
import { Field } from 'redux-form';
import TextFieldMaterial from "../../../shared/components/material_field/TextFieldMaterial"
import HourlyFieldMaterial from "../../../shared/components/material_field/HourlyFieldMaterial"
import DateFieldMaterial from "../../../shared/components/material_field/DateFieldMaterial"
import SelectFieldNoValidate from "../../../shared/components/SelectFieldNoValidate"
import { getProjectFiles } from "../../../redux/services/projects"

const CreateProjectStepTwoForm = ({ toggle }) => {
    const [options, setOptions] = useState([])
    
    useEffect(() => {
        getProjectFiles()
            .then(res => setOptions(res.items))
    }, []);

    return (
        <Col md={12} lg={12} className="p-0 mt-5">
            <Card className="p-0">
                <CardBody className="p-0 pt-4">
                    <div className="form__form-group mb-4">
                        <div className="form__form-group-field d-flex justify-content-between">
                            <div className="create_project_field_wrapper">
                                <Field
                                    name="description"
                                    component={TextFieldMaterial}
                                    placeholder="Description of project"
                                    label="Description"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form__form-group mb-4">
                        <div className="form__form-group-field d-flex justify-content-between">
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="budget"
                                    component={HourlyFieldMaterial}
                                    placeholder="Budget of project"
                                    label="Budget"
                                />
                            </div>
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="deadline"
                                    component={DateFieldMaterial}
                                    placeholder="Deadline"
                                    label="Deadline"
                                />
                            </div>
                        </div>
                    </div>
                    <h4 className="page-title">LOCATION</h4>
                    <div className="form__form-group">
                        <div className="form__form-group-field d-flex justify-content-between">
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="street"
                                    component={TextFieldMaterial}
                                    placeholder="Street of you address"
                                    label="Street"
                                />
                            </div>
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="house_number"
                                    component={TextFieldMaterial}
                                    placeholder="Number of your home"
                                    label="Number"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form__form-group mb-4">
                        <div className="form__form-group-field d-flex justify-content-between">
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="city"
                                    component={TextFieldMaterial}
                                    placeholder="Your city"
                                    label="City"
                                />
                            </div>
                            <div className="create_user_field_wrapper">
                                <Field
                                    name="post_code"
                                    component={TextFieldMaterial}
                                    placeholder="Post code of you address"
                                    label="Post code"
                                />
                            </div>
                        </div>
                    </div>
                    <h4 className="page-title mb-2">SELECT FILE</h4>
                    <div className="form__form-group mb-4">
                        <div className="create_user_select_wrapper">
                            <Field
                                name="source"
                                component={SelectFieldNoValidate}
                                placeholder="Select/Type project file name"
                                options={options}
                            />
                        </div>
                    </div>
                    <div className="form__form-group m-0">
                        <div className="form__form-group-field d-flex justify-content-end">
                            <div className="d-flex">
                                <Button className="mb-0" type="button" onClick={() => toggle()}>Cancel</Button>
                                <Button className="mb-0" color="primary" type="submit">Finish</Button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default CreateProjectStepTwoForm;