import React, {useCallback, useEffect, useState} from "react";
import { reduxForm, FieldArray, Field, initialize } from "redux-form";
import { Container, Card, CardBody, Button, Table } from "reactstrap";
import {CameraIcon, CommentIcon, CreateIcon} from "../../constants/icons";
import renderCheckBoxField from "../../shared/components/form/CheckBox";
import Pagination from "@material-ui/lab/Pagination";
import store from "../../redux/store";
import {
  getQATemplates,
  saveQATemplates,
} from "../../redux/services/templates";
import Loading from "../../shared/components/Loader";
import SearchIcon from "mdi-react/SearchIcon";
import {
    checkedQaStepsAreValid,
    getAllTransformedQaSteps,
    getAllUncheckedSteps,
    isQaStepChecked
} from "../../helpers/qa-steps";
import {TextField} from "@material-ui/core";

const PAGE_SIZE = 10;


const renderQASteps = ({ fields }) => {

    const [qaSteps, setQaSteps] = useState(fields && fields.getAll() ? getAllTransformedQaSteps(fields.getAll()) : [])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCounts, setPageCounts] = useState(0);
    const [searchFilter, setSearchFilter] = useState("");
    const [checkedQaStepIndexes,setCheckedQaStepIndexes] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isNewTemplate, setIsNewTemplate] = useState(false);


    //Changing Filters
    useEffect(() => {
        if(!isNewTemplate){
            setCurrentPage(0)
            setCheckedQaStepIndexes([])
        }
        const searchedQaSteps = !!searchFilter ? getAllTransformedQaSteps(fields.getAll()).filter((qaStep) => {
            return qaStep.description
                .toLowerCase()
                .includes(searchFilter.toLowerCase())
        }) : getAllTransformedQaSteps(fields.getAll())
        setQaSteps(searchedQaSteps)
    }, [fields, searchFilter,getAllTransformedQaSteps ])


    useEffect(() => {
        setPageCounts(Math.ceil(qaSteps.length / PAGE_SIZE))
    },[qaSteps])

    const saveQaSteps = async () => {
        const allQaSteps = fields && fields.getAll() ? fields.getAll() : []
        fields.removeAll()
        allQaSteps.forEach((qaStep, index) => {
            if(checkedQaStepIndexes.includes(index)){
                const foundQaStep = qaSteps.find((qaStep) => qaStep.originalIndex === index)
                if(foundQaStep){
                    const composedQaStep = {
                        description: foundQaStep.description,
                        steps: foundQaStep.steps.filter((step) => step.stepDescription !== "")
                    }
                    fields.push(composedQaStep)
                }
            }else {
                fields.push(qaStep)
            }
        })
        setIsNewTemplate(false);
        setSearchFilter("");
        setCheckedQaStepIndexes([])
        await saveValues();
    }

    const deleteQaSteps = async () => {
        const allUnchecked = getAllUncheckedSteps(fields.getAll(), checkedQaStepIndexes)
        fields.removeAll()
        allUnchecked.forEach((notChecked) => fields.push(notChecked))
        setCheckedQaStepIndexes([])
        await saveValues();
    }

    const addQaStep = () => {
        setIsNewTemplate(true);
        setSearchFilter("");
        const tempQaStep = {description: '', steps: []}
        fields.push(tempQaStep);
        setCheckedQaStepIndexes([fields.getAll().length])
        const newJobPageCount = Math.ceil((fields.getAll().length + 1) / PAGE_SIZE)
        setCurrentPage(newJobPageCount -1 )
        setIsEditing(true);
    }

    const onQaStepCheckboxPressed = useCallback((qaStepIndex,event) => {
        if(typeof qaStepIndex == "number" && event && event && event.target && event.target.checked){
            const newArray = [...checkedQaStepIndexes, qaStepIndex];
            setCheckedQaStepIndexes(newArray);
        } else {
            const newArray = checkedQaStepIndexes.filter((item) => item !== qaStepIndex);
            setCheckedQaStepIndexes(newArray);
        }
    },[checkedQaStepIndexes])

    const _onChangeDescription = useCallback((stepIndex, description) => {
        let qaStep = qaSteps[stepIndex]
        if(!!qaStep){
            qaStep.description = description
            let tempQaSteps = qaSteps
            tempQaSteps[stepIndex] = qaStep
            setQaSteps([...tempQaSteps])
        }

    },[qaSteps, setQaSteps])

    const _onTogglePhotoRequired = useCallback((qaStepIndex, stepIndex,) => {
        let qaStep = qaSteps[qaStepIndex]
        if(!!qaStep){
            let step = qaStep.steps[stepIndex]
            if(!!step){
                step.isPhotoRequired = !step.isPhotoRequired
                let tempStepDescriptions = qaStep.steps
                tempStepDescriptions[stepIndex] = step
                qaStep.steps = [...tempStepDescriptions]
            }
            let tempQaSteps = qaSteps
            tempQaSteps[qaStepIndex] = qaStep
            setQaSteps([...tempQaSteps])
        }
    },[qaSteps,setQaSteps])

    const _onToggleCommentRequired = useCallback((qaStepIndex, stepIndex,) => {
        let qaStep = qaSteps[qaStepIndex]
        if(!!qaStep){
            let step = qaStep.steps[stepIndex]
            if(!!step){
                step.isCommentRequired = !step.isCommentRequired
                let tempStepDescriptions = qaStep.steps
                tempStepDescriptions[stepIndex] = step
                qaStep.steps = [...tempStepDescriptions]
            }
            let tempQaSteps = qaSteps
            tempQaSteps[qaStepIndex] = qaStep
            setQaSteps([...tempQaSteps])
        }
    },[qaSteps,setQaSteps])

    const _onChangeStepDescription = useCallback((qaStepIndex, stepIndex, description) => {
        let qaStep = qaSteps[qaStepIndex]
        if(!!qaStep){
            let step = qaStep.steps[stepIndex]
            if(!!step){
                step.stepDescription = description
                let tempStepDescriptions = qaStep.steps
                tempStepDescriptions[stepIndex] = step
                qaStep.steps = [...tempStepDescriptions]
            }
            let tempQaSteps = qaSteps
            tempQaSteps[qaStepIndex] = qaStep
            setQaSteps([...tempQaSteps])
        }

    },[qaSteps,setQaSteps])

    const _onRemoveStepButtonPressed = useCallback((qaStepIndex, stepIndex) => {
        let tempQaSteps = [...qaSteps]; // Create a copy of qaSteps array

        if (qaStepIndex < tempQaSteps.length) {
            let qaStep = tempQaSteps[qaStepIndex];

            if (stepIndex < qaStep.steps.length) {
                qaStep.steps.splice(stepIndex, 1); // Remove the element at stepIndex
            }

            setQaSteps(tempQaSteps); // Update the state with the modified array
        }
    },[qaSteps, setQaSteps])

    const _onAddNewStepButtonPressed = useCallback((qaStepIndex) => {
        let qaStep = qaSteps[qaStepIndex]
        if(!!qaStep){
            const tempStep = {stepDescription: '',isPhotoRequired: true, isCommentRequired: true}
            qaStep.steps.push(tempStep)
            let tempQaSteps = qaSteps
            tempQaSteps[qaStepIndex] = qaStep
            setQaSteps([...tempQaSteps])
        }
    },[qaSteps, setQaSteps])

    const _renderSearchSection = useCallback(() => {
        return  <div className="r-flex flex-row justify-content-between w-100">
            <h3>QA verification steps templates</h3>
            <div className="mt-3">
                <div className="form__form-group-field">
                    <div className="search_input">
                        <SearchIcon />
                        <input
                            type="text"
                            name="search"
                            placeholder="Search..."
                            className="search_input"
                            value={searchFilter}
                            onChange={(data) => {
                                setSearchFilter(data.target.value);
                                setCheckedQaStepIndexes([])
                                setIsEditing(false)
                            }}
                            onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    },[searchFilter, setSearchFilter, setCheckedQaStepIndexes,setIsEditing])

    const _renderActionButtons = useCallback(() => {
        if(checkedQaStepIndexes.length > 0){
            return   <div className={"actionButtonContainer mt-2 mb-2"} >
                <div className={"operativeStepActionButton"}>
                    <Button
                        className={"editButton"}
                        onClick={() => {
                            if(isEditing){
                                saveQaSteps();
                            }
                            setIsEditing(!isEditing)
                        }}
                        disabled={isEditing ? !checkedQaStepsAreValid(qaSteps, checkedQaStepIndexes) : false}
                    >
                        {isEditing ? 'Save' : 'Edit'}
                    </Button>
                </div>
                <div className={"operativeStepActionButton"}>
                    <Button
                        className={"deleteButton"}
                        onClick={() => deleteQaSteps()}
                        disabled={isEditing}>
                        Delete
                    </Button>
                </div>
            </div>
        } else {
            return null
        }
    },[isEditing, checkedQaStepIndexes,saveQaSteps,deleteQaSteps,checkedQaStepsAreValid,qaSteps,setIsEditing ])

    const _renderTableHeader = useCallback(() => {
        return <thead>
        <tr className={"d-flex"}>
            <th className={"th30"}></th>
            <th className={"th200"}>Description</th>
            <th className={"th220"}>Steps</th>
        </tr>
        </thead>
    },[])

    const _renderTable = useCallback(() => {
        return <div className={'table--bordered'}>
            {_renderActionButtons()}
            <Table responsive hover className="table react-table table--bordered">
                {_renderTableHeader()}
                <tbody>
                {qaSteps.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE).map((qaStep, index) => {
                    return (
                        <tr key={`qaStep-${qaStep.originalIndex}-${index}`}
                            className={"d-flex justify-content-start align-items-center"}
                        >
                            <td className={"operativeStepsCheckBox"}>
                                <div>
                                    <Field
                                        name={`Step${currentPage * 10 + qaStep.originalIndex}`}
                                        component={renderCheckBoxField}
                                        onChange={(event) => onQaStepCheckboxPressed(qaStep.originalIndex, event)}
                                        checked={isQaStepChecked(checkedQaStepIndexes, qaStep.originalIndex)}
                                        disabled={isEditing}
                                    />
                                </div>
                            </td>
                            <td className={"td200"}>
                                {isEditing && isQaStepChecked(checkedQaStepIndexes,  qaStep.originalIndex) ? (
                                    <TextField
                                        placeholder={"Enter step description"}
                                        value={qaStep.description}
                                        type={'text'}
                                        required={true}
                                        error={!qaStep.description}
                                        helperText={!qaStep.description ? "You must enter a value!" : undefined}
                                        onChange={(event) => _onChangeDescription(currentPage * 10 + index,event.target.value)}/>
                                ) : (
                                    <div className={"operativeStepJobStepDescriptionContainer"} style={{maxWidth: 200}}>
                                        <span className={qaStep.description.length > 20 ? "operativeStepJobDescriptionText" : null}
                                              data-text={qaStep.description}>
                                            {qaStep.description}
                                        </span>
                                    </div>
                                )}
                            </td>
                            {qaStep.steps.map((step, stepsIndex) => {
                                return <td className={"td250"} key={`${qaStep.originalIndex}-step${stepsIndex}`}>
                                    <div className={"d-flex flex-row align-items-center"}>
                                        <div>
                                            <button  className="commentButton"
                                                     type={"button"}
                                                     onClick={() => _onToggleCommentRequired(currentPage * 10 + index, stepsIndex)}
                                                     disabled={!(isEditing && isQaStepChecked(checkedQaStepIndexes,  qaStep.originalIndex))}
                                            >
                                                <CommentIcon color={
                                                    !isEditing
                                                        ? step.isCommentRequired
                                                            ? '#70bbfd' : '#dddddd'
                                                        : !checkedQaStepIndexes.includes(qaStep.originalIndex)
                                                            ? step.isCommentRequired ? '#70bbfd' : '#dddddd'
                                                            : step.isCommentRequired ? '#007dbc' : undefined } />
                                            </button>

                                        </div>
                                        <div>
                                            <button  className="cameraButton"
                                                     type={"button"}
                                                     onClick={() => _onTogglePhotoRequired(currentPage * 10 + index, stepsIndex)}
                                                     disabled={!(isEditing && isQaStepChecked(checkedQaStepIndexes,  qaStep.originalIndex))}
                                            >
                                                <CameraIcon color={
                                                    !isEditing
                                                        ? step.isPhotoRequired
                                                            ? '#70bbfd' : '#dddddd'
                                                        : !checkedQaStepIndexes.includes(qaStep.originalIndex)
                                                            ? step.isPhotoRequired ? '#70bbfd' : '#dddddd'
                                                            : step.isPhotoRequired ? '#007dbc' : undefined } />
                                            </button>

                                        </div>
                                        {isEditing && isQaStepChecked(checkedQaStepIndexes,  qaStep.originalIndex) ?
                                            <div className={"d-flex"}>
                                                <TextField
                                                    placeholder={"Enter step name"}
                                                    value={step.stepDescription}
                                                    type={'text'}
                                                    required={true}
                                                    onChange={(event) => _onChangeStepDescription(currentPage * 10 + index,stepsIndex,event.target.value)}/>
                                                {qaStep.steps.length > 0 && <div className={"ml-2"}>
                                                    <button className="removeOperativeStepButton"
                                                            type={"button"}
                                                            onClick={() => _onRemoveStepButtonPressed(currentPage * 10 + index, stepsIndex)}>
                                                        -
                                                    </button>
                                                </div>}
                                            </div>

                                            :
                                            <div className={"operativeStepJobDescriptionContainer"} style={{width: '100%'}}>
                                                <span className={step.stepDescription.length > 20 ? "operativeStepJobDescriptionText" : null}
                                                      data-text={step.stepDescription}>
                                                    {step.stepDescription}
                                                </span>
                                        </div> }
                                    </div>
                                </td>
                            })}
                            {isEditing && isQaStepChecked(checkedQaStepIndexes,  qaStep.originalIndex) && <td className={"td220"}>
                                <button className="addOperativeStepButton"
                                        type={"button"}
                                        onClick={() => _onAddNewStepButtonPressed(currentPage * 10 + index)}>
                                    +
                                </button>
                            </td>}

                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    },[_renderActionButtons,_renderTableHeader, qaSteps, currentPage,onQaStepCheckboxPressed,isQaStepChecked,isEditing,checkedQaStepIndexes, _onChangeStepDescription, _onChangeDescription, _onAddNewStepButtonPressed, _onRemoveStepButtonPressed  ])
    const _renderQaStepsPagination = useCallback(() => {
        if(qaSteps && pageCounts >= 2){
            return <div>
                <Pagination
                    count={pageCounts}
                    page={currentPage + 1}
                    onChange={(event, page) => {
                        setCurrentPage(page - 1)
                        setCheckedQaStepIndexes([]);
                        setIsEditing(false)
                    }}
                />
            </div>
        } else {
            return null
        }
    },[qaSteps,pageCounts,currentPage ])

    const _renderAddQaStep = useCallback(() => {
        return <Button
            className="btn_with_img mt-5"
            color="primary"
            onClick={addQaStep}
            disabled={isEditing}
        >
            <CreateIcon /> Create QA step
        </Button>
    },[addQaStep, isEditing])

  return (
    <>
        {_renderSearchSection()}
        {_renderTable()}
        {_renderQaStepsPagination()}
        {_renderAddQaStep()}
    </>
  );
};

async function saveValues() {
  const formValue = store.getState().form.qa_steps.values;

  await saveQATemplates({ qaSteps: formValue.qaSteps });
}

async function getValues() {
  await getQATemplates().then((response) => {
    store.dispatch(initialize("qa_steps", response));
  });
}

const QASteps = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getValues()
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <form>
      <Container>
        <Card>
          <CardBody style={{ minWidth: "min-content" }}>
            <FieldArray
              name="qaSteps"
              component={renderQASteps}
              rerenderOnEveryChange={false}
            />
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: "qa_steps",
  enableReinitialize: true,
})(QASteps);
