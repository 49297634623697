import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'reactstrap'
import { updateRequestStatus } from '../../../redux/services/jobs';
import { ADDITIONAL_WORK_REQUEST_STATUS } from '../../../constants/enamerations';
import { reduxForm, Field, change } from 'redux-form';
import HourlyFieldMaterial from '../../../shared/components/material_field/HourlyFieldMaterial';
import DayWorkRejectedForm from '../../Accounts/Forms/DayWorkRejectedForm';
import { InfoIcon, PoundIcon, UserIcon } from '../../../constants/icons';
import Tooltip from '../../../shared/components/Tooltip';

const AdditionalWorkModal = ({
  isOpen,
  toggleModal,
  item,
  dispatch,
  handleSubmit,
  voLabel,
}) => {
  const [switchModal, setSwitchModal] = useState(1);

  useEffect(() => {
      item && item.budget && 
      dispatch(change('additional_work_form', 'amount', `£ ${item.budget}`))
  }, [item]);

  const updateRequest = (status, amount, comment) => {
    let mutatedAmount = (status === ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value) ? 0 : amount && +amount.split("£ ")[1]
    updateRequestStatus(item.id, status, mutatedAmount , comment)
    .then(res => {
      toggleModal()
      window.location.reload();
    })
  }
  
  const Approve = (data) => updateRequest(ADDITIONAL_WORK_REQUEST_STATUS.COMPLETED.value, data.amount, '');
  const Rejected = (data) => updateRequest(ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value, 0, data.deny_comment);
  
  const showButtons = (item && item.status !== ADDITIONAL_WORK_REQUEST_STATUS.COMPLETED.value &&
  item.status !== ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value);

  if(!item || !isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog--primary additional_work-modal`}
    >
      <div className="modal__header text-left d-flex flex-column justify-content-center">
        <h4 className="m-0">Budget variation request</h4>
        <p className='m-0 text-white'>Variation Order: {voLabel || '-'}</p>
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
      </div>
      <div className="modal__body text-left mt-5">
      {( switchModal === 1 
          ? <form onSubmit={handleSubmit(data => Approve(data))}>
                <Row className="m-0 mt-5 d-flex justify-content-around">
                  {item && item.jobs.level && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">Level &nbsp;</h3>
                      <p className="m-0">{item.jobs.level.name}</p>
                    </div>
                  )}
                  {item && item.jobs.level && item.jobs.level.block && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">Block &nbsp;</h3>
                      <p className="m-0">{item.jobs.level.block.name}</p>
                    </div>
                  )}
                  {item && item.jobs.unit_type  && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">Unit Type &nbsp;</h3>
                      <p className="m-0">{item.jobs.unit_type}</p>
                    </div>
                  )}
                  {item && item.jobs.plot && item.jobs.plot.name && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">Unit Type &nbsp;</h3>
                      <p className="m-0">{item.jobs.plot.name}</p>
                    </div>
                  )}
                </Row>
                <hr></hr>
                <Row className='d-flex pl-3 mb-3'>
                  <p className="d-flex align-items-center">
                    <UserIcon />
                    <span className="ml-2">
                      {item && item.jobs && item.jobs.employee 
                      && (`${item.jobs.employee.first_name} ${item.jobs.employee.surname}`)}
                    </span>
                  </p>
                </Row>
                <Row className='d-flex pl-3 mb-3'>
                  <p className="d-flex align-items-center">
                    <PoundIcon/>
                    <span className="ml-2">{item && item.budget}</span>
                  </p>
                </Row>
                <Row className={'pl-3'}>
                    <div className="img_question_wrapper-collapse">
                        {item && item.assets && item.assets.map((item, index) => (
                            <img key={index} src={item.public_url} alt={`#${index}`} />
                        ))}
                    </div>
                </Row>
                <div style={{ marginRight: '0px' }} className="description_question_wrapper mb-3">
                    <p className="form__form-group-label m-0">{item && item.description}</p>
                </div>

                {
                showButtons &&
                <>
                <div className="form__form-group mb-4">
                    <div className="form__form-group-field d-flex justify-content-between align-items-center">
                        <div className="create_project_field_wrapper">
                            <Field
                                name="amount"
                                component={HourlyFieldMaterial}
                                placeholder="Amount"
                                label="Amount"
                            />
                        </div>
                        <div>
                        <Tooltip
                          text="The amount that you are approving for this variation order."
                        >
                          <InfoIcon/>
                        </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="d-flex d-flex justify-content-end">
                    <Button className="m-0 mr-3" color="secondary" onClick={() => setSwitchModal(2)}>Deny</Button>
                    <Button className="m-0" color="primary" type="submit">Approve</Button>
                </div>
                </>
                }
            </form>
            : <DayWorkRejectedForm Rejected={Rejected} setModal={toggleModal} />
        )}
      </div>
  </Modal>
  )
}

export default reduxForm({
  form: 'commercial_additional_work_form'
})(AdditionalWorkModal);