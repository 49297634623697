import moment from "moment";

export const WORK_TYPE_OPTIONS = [
  { value: "", label: "All" },
  { value: "ELECTRICAL", label: "Electrical" },
  { value: "MECHANICAL", label: "Mechanical" },
  { value: "VENTILATION", label: "Ventilation" },
  { value: "SVP_RWG", label: "SVP & RWG" },
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: "", label: "All" },
  { value: "ASSIGNED", label: "Assigned" },
  { value: "IDLE", label: "Idle" },
];

export const USER_ROLE_OPTIONS = [
  { value: "", label: "All" },
  { value: "USER", label: "User" },
  { value: "PROJECT_MANAGER", label: "PM/QA" },
  { value: "ADMIN", label: "Admin" },
];

export const JOB_STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "DRAFT", label: "Draft" },
  { value: "ACTIVE", label: "Active" },
  { value: "ASSIGNED_TO_USER", label: "Assigned to user" },
  { value: "ASSIGNED_TO_PM", label: "Assigned to pm" },
  { value: "COMPLETED", label: "Completed" },
  { value: "DECLINED", label: "Declined" },
];

export const BUSINESS_TYPE_OPTIONS = [
  { value: "SOLE_TRADER", label: "Sole Trader" },
  { value: "LIMITED_COMPANY", label: "Sub Contractor" },
];

export const CIS_STATUS_OPTIONS = [
  { value: "GROSS_STATUS", label: "Gross Status" },
  { value: "NET_STANDARD", label: "Net Standard" },
  { value: "NET_HIGHER", label: "Net higher" },
];

export const INVOICE_STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "PAID", label: "Paid" },
  { value: "UNPAID", label: "Unpaid" },
];

export const TIME_PERIODS_OPTIONS = [
  {
    label: "All time",
    value: {
      from: null,
      to: null,
    },
  },
  {
    label: "Last 2 weeks",
    value: {
      from: moment()
        .subtract(14, "days")
        .unix(),
      to: moment().unix(),
    },
  },
  {
    label: "Last month",
    value: {
      from: moment()
        .subtract(1, "months")
        .unix(),
      to: moment().unix(),
    },
  },
  {
    label: "Last 2 months",
    value: {
      from: moment()
        .subtract(2, "months")
        .unix(),
      to: moment().unix(),
    },
  },
  {
    label: "Last 3 months",
    value: {
      from: moment()
        .subtract(3, "months")
        .unix(),
      to: moment().unix(),
    },
  },
];

export const PAGE_SIZE_OPTIONS = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

export const REQUEST_TYPE_OPTIONS = [
  { value: "Additional_work_request", label: "Additional Work Request" },
  { value: "Job_request", label: "Job Request" },
  { value: "QA_request", label: "QA Request" },
];

export const DAY_WORK_STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "APPROVED", label: "Approved" },
  { value: "APPROVED_BY_PM", label: "Approved by PM" },
  { value: "PENDING", label: "Pending" },
  { value: "REJECTED", label: "Rejected" },
];

export const JOB_AREA_OPTIONS = [
  { value: "INTERNAL", label: "Internal" },
  { value: "COMMUNAL", label: "Communal" },
  { value: "EXTERNAL", label: "External" },
];

export const SAGE_REF_OPTIONS = [
  { value: "MANA", label: "MANA" },
  { value: "PCE1", label: "PCE1" },
  { value: "PCE2", label: "PCE2" },
  { value: "PCM1", label: "PCM1" },
  { value: "PCM2", label: "PCM2" },
  { value: "PDE1", label: "PDE1" },
  { value: "PDE2", label: "PDE2" },
  { value: "PDEF", label: "PDEF" },
  { value: "PDM1", label: "PDM1" },
  { value: "PDMC", label: "PDMC" },
  { value: "LAST", label: "LAST" },
  { value: "LAGG", label: "LAGG" },
  { value: "LMVO", label: "LMVO" },
  { value: "MVHRL", label: "MVHRL" },
  { value: "MVHRV", label: "MVHRV" },
  { value: "PDMF", label: "PDMF" },
  { value: "PDMK", label: "PDMK" },
  { value: "PDMM", label: "PDMM" },
  { value: "SAN1", label: "SAN1" },
  { value: "SAN2", label: "SAN2" },
  { value: "SANF", label: "SANF" },
  { value: "SVPI", label: "SVPI" },
  { value: "SVPT", label: "SVPT" },
  { value: "RWPI", label: "RWPI" },
  { value: "RWPT", label: "RWPT" },
  { value: "BCWL", label: "BCWL" },
];
