import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import Loading from "../../shared/components/Loader";
import {getUninvoicedJobs} from "../../redux/services/jobs";
import UninvoicedJobsTable from "./Table/UninvoicedJobs";
import {pushCrumbs} from "../../redux/actions/bread_crumbs";
import {connect} from "react-redux";
import UninvoicedDayWorkTable from "./Table/UninvoicedDayWork";
import UnvoicedAdditionalWorkRequestsTable from "./Table/UninvoicedAdditionalRequests";
import {getUninvoicedAdditionalWorkRequests} from "../../redux/services/requests";
import {getUserById} from "../../redux/services/users";
import {PREVIEW_USER, USERS} from "../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";

const index = ({ match, history, pushCrumbs }) => {
    const [jobs, setJobs] = useState(null);
    const [page, setPage] = useState(0);
    const [load, setLoad] = useState(false);
    const [requests, setRequests] = useState(null)
    const [loadingUser, setLoadingUser] = useState(false);
    const [user, setUserInfo] = useState(null);

    const getJobs = () => {
        setLoad(true);
        getUninvoicedJobs(
            match.params.id,
            page && page
        ).then((res) => {
            setJobs(res);
            setLoad(false);
        });
    };

    const getRequests = () => {
        getUninvoicedAdditionalWorkRequests(match.params.id, page)
            .then(res => {
                setRequests(res);
                setLoad(false)
            })

    };

    const getUser = () => {
        setLoadingUser(true);
        getUserById(match.params.id).then((res) => {
            setUserInfo(res);
            setLoadingUser(false);
        });
    };


    const previewJob = (job) => {
        history.push(
            `/projects/preview-projects/${job.project.id}/jobs/preview-jobs/${job.id}`
        );
        pushCrumbs({
            name: `Job #${job.id}`,
            path: `/projects/preview-projects/${job.project.id}/jobs/preview-jobs/${job.id}`,
            id: 4,
        });
    };

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        getJobs();
        getRequests();
    }, [page]);

    const userData = user && user.data && user.data.first_name ? `${user.data.first_name} ${user.data.surname}` : 'Current User'

    return ( loadingUser ?  <Loading/> :
            <Container>
                <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
                    <Link to={{pathname: USERS}} >
                        <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                            Accounts
                        </h3>

                    </Link>
                    <Link to={{pathname: PREVIEW_USER.replace(':id', match.params.id)}} >
                        <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                            {userData}
                        </h3>

                    </Link>
                    <Link to={{pathname: ''}} >
                        <h3 className={"page-subhead subhead bread_crumbs-active"}>
                            Uninvoiced
                        </h3>
                    </Link>
                </Breadcrumbs>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Uninvoiced jobs</h5>
                    </Col>
                </Col>
            </Row>
            <Card style={{ marginBottom: '30px' }}>
                <CardBody>
                    {load ? (
                        <Loading />
                    ) : jobs && jobs.items && jobs.items.length > 0 ? (
                        <UninvoicedJobsTable
                            jobs={jobs}
                            previewJob={previewJob}
                            setPage={setPage}
                            page={page}
                            filter={{}}
                        />
                    ) : (
                        <div className="w-100 d-flex justify-content-center">
                            <p>Not found</p>
                        </div>
                    )}
                </CardBody>
            </Card>

            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Uninvoiced day works</h5>
                    </Col>
                </Col>
            </Row>
            <Card style={{ marginBottom: '30px' }}>
                <CardBody>
                    {load ? (
                        <Loading />
                    ) : jobs && jobs.items && jobs.items.length > 0 ? (
                        <UninvoicedDayWorkTable />
                    ) : (
                        <div className="w-100 d-flex justify-content-center">
                            <p>Not found</p>
                        </div>
                    )}
                </CardBody>
            </Card>

            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Uninvoiced requests</h5>
                    </Col>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {load
                        ? <Loading/>
                        : requests && requests.items && requests.items.length !== 0
                            ? <UnvoicedAdditionalWorkRequestsTable requests={requests} page={page} setPage={setPage}
                                             previewJob={previewJob}/>

                            : <div className="d-flex justify-content-center"><p>Not Found</p></div>
                    }
                </CardBody>
            </Card>
        </Container>
    )
}

const mapDispatchToProps = {
    pushCrumbs,
};

function mapStateToProps() {
    return {};
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(index)
);
