import React, { useEffect, useState, useRef } from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import Loading from "../../../shared/components/Loader";
import { getProjects } from "../../../redux/services/projects";
import { pageSize } from "../../../config/pagination";
import Pagination from "@material-ui/lab/Pagination";

import ProjectRow from "./ProjectRow";

const ProjectTable = ({ href, status }) => {
  const [project, setProject] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(0);

  const isProjectGroupVisible = useRef({});

  useEffect(() => {
    getAllProjects();
  }, [page]);

  const getAllProjects = () => {
    setLoad(true);
    getProjects({ status }, { page, pageSize }).then((res) => {
      setProject(res);
      setLoad(false);
    });
  };

  const expandProject = (projectId) => {
    return isProjectGroupVisible.current[projectId] = true;
  }

  return (
    <>
      {load ? (
        <Loading />
      ) : project && project.items && project.items.length > 0 ? (
        <Table
          responsive
          hover
          className="mt-3 table react-table table--bordered pl-0"
        >
          <thead>
            <tr>
              <th>NAME</th>
              <th>BUDGET</th>
              <th>PAID</th>
              <th>DATE</th>
              <th>TERM</th>
            </tr>
          </thead>
          <tbody>
            {
              project &&
              project.items &&
              project.items.map((item, index) => {
              return(
              <ProjectRow
              item={item}
              index={index}
              href={href}
              status={status}
              expandProject={expandProject}
              isProjectGroupVisibleRef={isProjectGroupVisible}
              />
              )
              }
              )}
          </tbody>
        </Table>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <p>Not found</p>
        </div>
      )}
      {project && Math.ceil(project.total / pageSize) > 1 && (
        <div className="box-pagination">
          <Pagination
            count={
              project && project.total ? Math.ceil(project.total / pageSize) : 0
            }
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(ProjectTable);
