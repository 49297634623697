import React from 'react'
import { Card, CardBody, Col, Button } from 'reactstrap';
import DropZone from "../../../shared/components/form/DropZone"
import Loading from "../../../shared/components/Loader"

const UploadPlan = ({ getUploadFile, typePlan, loadUploadFile, uploadState, toggle }) => {
    return (
        <Col md={12} lg={12} className="p-0 mt-5">
            <Card className="p-0">
                <CardBody className="p-0 pt-4">
                    <div className="wrapper_page-title mb-3">
                        <h5 className="page-title m-0">{typePlan} PLAN</h5>
                    </div>
                    {loadUploadFile
                        ? <Loading />
                        : (uploadState ?
                            <>
                                <div>
                                    <div className="wrapper_page-title mt-3">
                                        <p className="form__form-group-label m-0">The {typePlan} plan has been uploaded succesfully.</p>
                                    </div>
                                </div>
                                <div className="form__form-group m-0">
                                    <div className="form__form-group-field d-flex justify-content-end">
                                        <div className="d-flex">
                                            <Button className="mb-0" color="primary" onClick={() => toggle()}>OK</Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <DropZone getUploadFile={getUploadFile} accept="image/*" />
                        )
                    }
                </CardBody>
            </Card>
        </Col>
    )
}

export default UploadPlan;