import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { Button, Row } from 'reactstrap';
import { getVariationOrdersByProjectId } from '../../../redux/services/variation_orders'
import Loading from '../../../shared/components/Loading'
import AdditionalWorks from './AdditionalWorks';
import Dayworks from './Dayworks';
import { labelVariationOrder } from '../../../helpers/variation_order';
import { addVoToAdditionalWork } from '../../../redux/services/variation_orders';
import { addVoToDayWorks } from '../../../redux/services/day_work';
import {Link} from "react-router-dom";
import {COMMERCIAL, CONTRACT_VARIATION_ORDERS} from "../../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";
import {getProjectAdditionalWorkRequestPageable} from "../../../redux/services/requests";

const PAGE_SIZE = 20
const UnknownRequests = ({ match }) => {
  const projectId = match.params.id;
  const [projectName, setProjectName] = useState('');
  const [contractName, setContractName] = useState('');
  const [load, setLoad] = useState(false);

  const [variationOrder, setVariationOrder] = useState();

  const [addedDayworksToVo, setAddedDayworksToVo] = useState({});
  const [addedAdditionalWorkToVo, setAddedAdditionalWorkToVo] = useState({});
  const [variationOrders, setVariationOrders] = useState([
    {
      value: null,
      label: "Select variation order"
    }
  ])

  const [additionalWorks, setAdditionalWorks] = useState(null);
  const [additionalWorksLoading, setAdditionalWorksLoading] = useState(false);
  const [additionalWorksPage, setAdditionalWorksPage] = useState(0);

  useEffect(() => {
    getAdditionalWorks();
  }, [additionalWorksPage]);

  const getAdditionalWorks = (pageNumber) => {
    setAdditionalWorksLoading(true);
    getProjectAdditionalWorkRequestPageable(projectId, {
      page:pageNumber ? pageNumber: additionalWorksPage,
      pageSize:PAGE_SIZE,
    }).then((res) => {
      setAdditionalWorks(res);
      setAdditionalWorksLoading(false);
    })
  };

  const addDayWorkToVo = (dayworkId) => {
    if (addedDayworksToVo[dayworkId]) {
      return setAddedDayworksToVo(currentDayworks => {
        return { ...currentDayworks, [dayworkId]: null }
      })
    }

    setAddedDayworksToVo(currentDayworks => {
      return { ...currentDayworks, [dayworkId]: variationOrder }
    })
  }

  const addAdditionalWorkToVo = (additionalWorkId) => {
    if (addedDayworksToVo[additionalWorkId]) {
      return setAddedAdditionalWorkToVo(currentDayworks => {
        return { ...currentDayworks, [additionalWorkId]: null }
      })
    }

    setAddedAdditionalWorkToVo(currentDayworks => {
      return { ...currentDayworks, [additionalWorkId]: variationOrder }
    })
  }

  const fetchVos = () => {
    const newVariationOrders = [{
      value: null,
      label: "Select variation order"
    }];
    getVariationOrdersByProjectId(projectId)
      .then(res => {
        if (res && res.items.length) {
          setProjectName(res.items[0].project.name);
          setContractName(res.items[0].project.projectGroup.name);
          res.items.forEach(item => {
            if (item && item.project && item.project.name && item.project.projectGroup && item.project.projectGroup.name) {
              const voLabel = labelVariationOrder(item.id, item.project.name, item.project.projectGroup.name);
              newVariationOrders.push({
                value: item.id,
                label: voLabel
              })
            }
          })
          setVariationOrders(newVariationOrders);
        }
      })
  }

  const processDayworksAndAdditionalWork = (dayworks, additionalWorkRequest) => {
    function parseObject(obj) {
      let result = {};

      for (let key in obj) {
        if (obj[key]) {
          if (result[obj[key]]) {
            result[obj[key]].push(parseInt(key));
          } else {
            result[obj[key]] = [parseInt(key)];
          }
        }
      }

      return result;
    }

    const dayworksToVo = parseObject(dayworks);
    const additionalWorkToVo = parseObject(additionalWorkRequest);
    return { dayworksToVo, additionalWorkToVo }
  }

  const saveChanges = (e) => {
    const { dayworksToVo, additionalWorkToVo } = processDayworksAndAdditionalWork(addedDayworksToVo, addedAdditionalWorkToVo)
    e.preventDefault();
    Promise.all([
      addVoToAdditionalWork(additionalWorkToVo),
      addVoToDayWorks(dayworksToVo)
    ]).then(() => {
      setAddedDayworksToVo({})
      setAddedAdditionalWorkToVo({})
      getAdditionalWorks(0)
      setAdditionalWorksPage(0)
    })
  }

  useEffect(() => {
    setLoad(true)
    Promise.all([
      fetchVos()
    ]).then(() => setLoad(false))
  }, [])

  if (load) return <Loading />

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
        <Link to={{pathname: COMMERCIAL}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            Commercial
          </h3>

        </Link>
        <Link to={{pathname: CONTRACT_VARIATION_ORDERS.replace(':id', projectId)}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            {!!projectName ? projectName : 'Current Project'}
          </h3>
        </Link>
        <Link to={{pathname: ''}} >
          <h3 className={"page-subhead subhead bread_crumbs-active"}>
           Unknown Requests
          </h3>
        </Link>
      </Breadcrumbs>
      <div>
        <h1 className="page-title">Unassigned requests</h1>
        <form>
          <Row className='d-flex justify-content-between' >
            <Select
                placeholder={"Variation order"}
                onChange={(e) => setVariationOrder(e.value)}
                options={variationOrders.sort((a, b) => a.value - b.value)}
                classNamePrefix="react-select"
                className="react-select bg-white"
                defaultValue={variationOrders[0]}
            />
            <Button
                type='disabled'
                color='primary'
                disabled={ Object.keys(addedDayworksToVo).length === 0 && Object.keys(addedAdditionalWorkToVo).length === 0}
                style={!variationOrder ?
                    { backgroundColor: '#FFF',
                      border: "1px solid rgba(0,0,0,0.3)",
                      color: "rgba(0,0,0,0.5)"
                    } : undefined}
                onClick={(e) => saveChanges(e)}
            >
              Save changes
            </Button>
          </Row>
        </form>
        <Row>
          <AdditionalWorks
              projectId={projectId}
              projectName={projectName}
              contractName={contractName}
              addAdditionalWorkToVo={addAdditionalWorkToVo}
              additionalWorksAddedToVo={addedAdditionalWorkToVo}
              variationOrder={variationOrder}
              additionalWorksLoading={additionalWorksLoading}
              additionalWorksPage={additionalWorksPage}
              additionalWorks={additionalWorks}
              pageSize={PAGE_SIZE}
              setAdditionalWorksPage={setAdditionalWorksPage}
          />
          <Dayworks
              projectId={projectId}
              projectName={projectName}
              contractName={contractName}
              addDayWorkToVo={addDayWorkToVo}
              dayworksAddedToVo={addedDayworksToVo}
              variationOrder={variationOrder}
          />
        </Row>
      </div>

    </>
  )
}

export default UnknownRequests
