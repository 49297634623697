import React, { useState } from "react"
import { Card, CardBody, Col, Button, Modal } from 'reactstrap';
import { RequestsIndicator } from "../../../constants/icons"
import { updateRequestStatus } from "../../../redux/services/jobs"
import { MoneyIcon } from "../../../constants/icons"
import AdditionalWorkForm from "../Forms/AdditionalWorkFilterForm"
import ImagesCard from "./ImagesCard"
import PhotoViewer from "../../../shared/components/PhotoViewer"
import { ADDITIONAL_WORK_REQUEST_STATUS } from "../../../constants/enamerations"

const RequestsSteps = ({ data, getRequestsByJob }) => {
    const [moreImageID, setMoreImageID] = useState(null);
    const [photos, setPhotos] = useState(null)
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [modal, setModal] = useState(false)
    const [assets, setAssets] = useState(null)
    const [currentRequest, setCurrentRequest] = useState(null)
    const [load, setLoad] = useState(false);

    const toggleModal = (item) => {
        setAssets(item && item.assets)
        setCurrentRequest(item && item)
        setModal(!modal)
    }

    const toggleMoreImage = (id) => setMoreImageID(id)

    const getPhotosByStep = (id, index) => {
        const photos = [];
        data.map(item => item.id === id && item.assets.map(item => photos.push({ src: item.public_url, width: 4, height: 3 })))
        setPhotos(photos);
        setCurrentImage(index);
        setViewerIsOpen(true);
    }

    const closeLightbox = () => {
        setViewerIsOpen(false);
        setCurrentImage(0);
    };

    const updateRequest = (status, amount, comment) => {
        let mutatedAmount = (status === 'REJECTED') ? 0 : amount && +amount.split("£ ")[1]
        setLoad(true)
        updateRequestStatus(currentRequest.id, status, mutatedAmount , comment)
            .then(res => {
                setLoad(false)
                toggleModal()
                getRequestsByJob()
            })
    }

    return (
        <>
            {data && data.map((item, index) => (
                item.status === ADDITIONAL_WORK_REQUEST_STATUS.APPROVED_TO_PM.value || item.status === ADDITIONAL_WORK_REQUEST_STATUS.PENDING.value
                    ? <Col key={index} md={3} xl={3}>
                        <Card>
                            <CardBody>
                                <div className="wrapper_page-title d-flex align-items-center mb-3">
                                    <h5 className="page-title m-0">BUDGET VARIATION REQUEST &nbsp;</h5>
                                    <RequestsIndicator />
                                </div>

                                <div className="ml-3">
                                    <ImagesCard
                                        item={item}
                                        moreImageID={moreImageID}
                                        toggleMoreImage={toggleMoreImage}
                                        getPhotosByStep={getPhotosByStep}
                                    />
                                </div>

                                <div className="d-flex d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <MoneyIcon />
                                        <p className="m-0">&nbsp; ₤{item.budget}</p>
                                    </div>
                                    <Button className="m-0 mt-3" color="primary" onClick={() => toggleModal(item)}>Review</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    : null
            ))}

            <PhotoViewer
                closeLightbox={closeLightbox}
                currentImage={currentImage}
                photos={photos}
                viewerIsOpen={viewerIsOpen}
            />

            {modal && <Modal
                isOpen={modal}
                toggle={toggleModal}
                modalClassName={`ltr-support`}
                className={`modal-dialog--primary additional_work-modal`}
            >
                <div className="modal__header">
                    <h3 className="page-title">Additional budget request</h3>
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal} />
                </div>
                <div className="modal__body text-left mt-5">
                    <AdditionalWorkForm updateRequest={updateRequest} currentRequest={currentRequest} assets={assets} setModal={setModal} load={load} />
                </div>
            </Modal>}
        </>
    )
}

export default RequestsSteps