import React from "react";
import Moment from "react-moment";
import { BuildIcon } from "../../../constants/icons";
import { STEP_STATUS } from "../../../constants/enamerations";

const BudgetCard = ({ requests }) => {
  return (
    requests &&
    requests.map((item, index) =>
      item.status === "COMPLETED" ? (
        item.assets.length === 0 ? (
          <>
            <tr key={index} className="w-100">
              <td className="w-100">
                <div
                  style={{
                    borderLeft: `4px solid ${item.status &&
                      STEP_STATUS[item.status].color}`,
                  }}
                  className="question_card_wrapper"
                >
                  <div className="w-100">
                    <div className="question_job_wrapper">
                      <h5 className="m-0 mb-3 mt-1">REASON</h5>
                      <div className="w-75">
                        <p>{item.description ? item.description : ""}</p>
                      </div>
                      {item.status !== STEP_STATUS.UNDEFINED.label && (
                        <div className="mt-3 d-flex align-items-center">
                          {STEP_STATUS[item.status].icon} &nbsp;{" "}
                          {STEP_STATUS[item.status].label}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {index !== requests.length - 1 && (
              <tr key={index + `hr`}>
                <td>
                  <hr className="m-0 ml-4 mr-4" />
                </td>
              </tr>
            )}
          </>
        ) : (
          <>
            <tr key={index}>
              <td>
                <div
                  style={{
                    borderLeft: `4px solid ${item.status &&
                      STEP_STATUS[item.status].color}`,
                  }}
                  className="question_card_wrapper"
                >
                  <div className="w-50">
                    <div className="question_job_wrapper">
                      <h5 className="m-0 mb-3 mt-1">REASON</h5>
                      <div>
                        <p>{item.description ? item.description : ""}</p>
                      </div>
                      <div className="mt-3 d-flex align-items-center">
                        {item.status && STEP_STATUS[item.status].icon} &nbsp;{" "}
                        {item.status && STEP_STATUS[item.status].label}
                      </div>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="img_wrapper">
                      {item.assets.length > 4
                        ? item.assets.map(
                            (img, index) =>
                              index < 5 && (
                                <img
                                  key={index}
                                  style={{
                                    marginRight:
                                      item.assets.length - 1 !== index
                                        ? `50px`
                                        : `0px`,
                                  }}
                                  src={img.public_url}
                                  alt={`#${index}`}
                                  className="operatives-sm-img"
                                />
                              )
                          )
                        : item.assets.map((img, index) => (
                            <img
                              key={index}
                              style={{
                                marginRight:
                                  item.assets.length - 1 !== index
                                    ? `50px`
                                    : `0px`,
                              }}
                              src={img.public_url}
                              alt={`#${index}`}
                              className="operatives-sm-img"
                            />
                          ))}
                    </div>
                    <div className="mb-2 mt-4 budget-print-details">
                      <span className="dim">Amount</span>
                      <span className="budget-value">₤{item.budget}</span>
                    </div>
                    <div className="budget-print-details">
                      <span className="dim">Date</span>
                      <span className="budget-value">
                        <Moment format="YYYY-MM-DD">
                          {item && item.created_at}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {index !== requests.length - 1 && (
              <tr key={index + `hr`}>
                <td>
                  <hr className="m-0 ml-4 mr-4" />
                </td>
              </tr>
            )}
          </>
        )
      ) : (
        ""
      )
    )
  );
};

const BudgetVariationsPrint = ({ requests, job }) => {
  return (
    <>
      <div className="print_wrapper w-100">
        <div className="print_header">
          <div className="logo_header">
            <img
              className="pdfLogoimage"
              src={require("../../../shared/img/logo/pdfLogo.png")}
              alt={"logo"}
            />
          </div>
          <div className="contact_header">
            <p>020 3909 8880</p>
            <p>info@londonmep.co.uk</p>
          </div>
        </div>

        <div className="body_wrapper">
          <div className="project_build_wrapper">
            <div className="project_info">
              <div className="d-flex align-items-center">
                <BuildIcon /> &nbsp; {job && job.project.name}
              </div>
              <h1>{job && job.description}</h1>
            </div>
            <div className="build_info">
              <div className="pr-5 w-50 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <p className="dim">Level</p>
                  <span>{job && job.level.name}</span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <p className="dim">Block</p>
                  <span>{job && job.block.name}</span>
                </div>
              </div>
              <div className="pl-5 w-50 d-flex flex-column justify-content-between">
                <div className="mb-2 d-flex justify-content-between">
                  <p className="dim">Unit type</p>
                  <span>{job && job.unit_type}</span>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                  <p className="dim">Unit ID</p>
                  <span>{job && job.plot.name}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="inspection_wrapper">
            <h3>JOB INFORMATION</h3>

            <div className="inspection_information">
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <p className="dim">Conducted on</p>
                  <span>
                    {job &&
                      job.completed_at &&
                      new Date(job.completed_at).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </span>
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <p className="dim">Operative name</p>
                  <span>
                    {job &&
                      job.employee &&
                      job.employee.first_name + " " + job.employee.surname}
                  </span>
                </div>
              </div>
              <div className="inspection_item">
                <div className="mb-3 w-100 d-flex justify-content-between">
                  <p className="dim">Main Contractor/Client</p>
                  <span>{job && job.project && job.project.client}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="text-uppercase mb-0 mt-3" style={{ padding: `0 30px` }}>
        Budget variation
      </h3>
      <table className="w-100">
        <thead>
          <tr>
            <th>
              <br />
            </th>
          </tr>
        </thead>
        <tbody className="w-100">
          <BudgetCard requests={requests} />
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>
              <br />
            </th>
          </tr>
        </thead>
        <tbody>
          {requests &&
            requests.map((item, index) =>
              item.status === "COMPLETED"
                ? item.assets.length !== 0 &&
                  item.assets.map((img, index) => (
                    <tr key={index}>
                      <td>
                        <div style={{ padding: `30px 30px` }}>
                          <img src={img.public_url} alt={`#${index}`} />
                        </div>
                      </td>
                    </tr>
                  ))
                : ""
            )}
        </tbody>
      </table>
    </>
  );
};

export default BudgetVariationsPrint;
