import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Table } from "reactstrap";
import Moment from "react-moment";
import Loading from "../../../shared/components/Loader";
import { pushCrumbs } from "../../../redux/actions/bread_crumbs";
import { pageSize } from "../../../config/pagination";
import { BuildIcon, CalendarIcon } from "../../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import { DAY_WORK_STATUS } from "../../../constants/enamerations";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import DayWorkForm from "../../Accounts/Forms/DayWorkForm";
import {calculateDayWorkHourCost} from "../../../config/func";

const DayWorkTableByProject = ({ dayWork, load, page,getDayWorkRequest, setLoad,setPage }) => {

  const [moreImageID, setMoreImageID] = useState(null);
  const [modal, setModal] = useState(false);
  const [photos, setPhotos] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentDayWork, setCurrentDayWork] = useState(null);

  const toggle = (item) => {
    setCurrentDayWork(item);
    setModal(!modal);
  };

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];
    dayWork.items.map(
      (item) =>
        item.id === id &&
        item.assets.map((item) =>
          photos.push({ src: item.public_url, width: 4, height: 3 })
        )
    );
    setPhotos(photos);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };



  return (
    <>
      {load ? (
        <Loading />
      ) : dayWork && dayWork.items && dayWork.items.length !== 0 ? (
        <Table
          responsive
          hover
          className="mt-1 table react-table table--bordered pl-0"
        >
          <thead>
            <tr>
              <th>STATUS</th>
              <th>OPERATIVE</th>
              <th>DATE</th>
              <th>DATE REQUESTED</th>
              <th>PROJECT</th>
              <th>BLOCK</th>
              <th>LEVEL</th>
              <th>SAGE REF</th>
              <th>RATE</th>
            </tr>
          </thead>
          <tbody>
            {dayWork.items.map((item, index) => (
              <tr key={index} onClick={() => toggle(item)}>
                <td>
                  {item.status && DAY_WORK_STATUS[item.status].icon} &nbsp;{" "}
                  {item.status && DAY_WORK_STATUS[item.status].label}
                </td>
                <td>
                  {item.creator && item.creator.first_name + ' ' + item.creator.surname}
                </td>
                <td>
                  <CalendarIcon /> &nbsp;{" "}
                  <Moment format="LL">{item.date_from}</Moment>
                </td>
                <td>
                <CalendarIcon /> &nbsp;{" "}
                <Moment format="LL">{item.created_at}</Moment>
                </td>
                <td>
                  <BuildIcon /> &nbsp; {item.project && item.project.name}
                </td>
                <td>{item.levels && item.levels.block.name}</td>
                <td>{item.levels && item.levels.name}</td>
                <td>{item.category}</td>
                <td>{calculateDayWorkHourCost({startDate: item.date_from,
                  endDate: item.date_to,
                  startTime: item.start_at,
                  endTime: item.end_at,
                  dayworkHour: item.hourly_rate,
                  userHourRate:  item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
                  budget: item.budget}).cost}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <p>Not found</p>
        </div>
      )}
      {dayWork && Math.ceil(dayWork.total / pageSize) >= 2 && (
        <div className="box-pagination">
          <Pagination
            count={
              dayWork && dayWork.total ? Math.ceil(dayWork.total / pageSize) : 0
            }
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={viewerIsOpen}
      />

      {modal && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalClassName={`ltr-support`}
          className={`modal-dialog--primary additional_work-modal`}
        >
          <div className="modal__header">
            <h3 className="page-title">DayWork request</h3>
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={toggle}
            />
          </div>
          <div className="modal__body text-left mt-5">
            <DayWorkForm
              getDayWorkRequests={getDayWorkRequest}
              item={currentDayWork}
              moreImageID={moreImageID}
              toggleMoreImage={toggleMoreImage}
              getPhotosByStep={getPhotosByStep}
              setModal={setModal}
              setLoadTable={setLoad}
              showButtons={true}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

const mapDispatchToProps = {
  pushCrumbs,
};

function mapStateToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DayWorkTableByProject)
);
