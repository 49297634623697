import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "reactstrap";
import { reduxForm, FieldArray } from "redux-form";
import { EditIcon } from "../../../constants/icons";
import EditLevelForm from "./components/EditLevelForm";
import store from "../../../redux/store";

const renderBlockByLetter = ({ blockId }) => {
  return (
    <div className="blockLetterContainer">
      <span className="blockLetter">{blockId}&nbsp;&nbsp;</span>
      {"Block " + blockId}
    </div>
  );
};

const renderBlockLevels = ({ fields }) => {
  return (
    <Row md={12}>
      <Col md={12}>
        <Row md={12}>
          {fields.map((block, index) => (
            <FieldArray
              name={`${block}.levels`}
              component={renderLevels}
              rerenderOnEveryChange={true}
              props={{ blockId: fields.get(index).id }}
              key={index}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const renderLevels = ({ fields, blockId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const editLevel = (data, index) => {
    const level = fields.get(index);
    const oldLevel = level.level;
    level.level = data.level;

    fields.splice(index, 1, level);

    // Modify the level in the allocated jobs too
    if (store.getState().form.create_project_form_wizard.values.allocatedJobs) {
      const allocatedJobs = store.getState().form.create_project_form_wizard
        .values.allocatedJobs;

      allocatedJobs.forEach((element) => {
        if (
          element.level.toString() === oldLevel.toString() &&
          element.block === blockId
        ) {
          element.level = data.level;
        }
      });
    }
  };

  return (
    <Col md={4} className={"mt-5"}>
      {renderBlockByLetter({ blockId })}
      {fields.map((level, index) => (
        <div className="levelsContainer">
          <div className="levelContainer">
            <div className="levelLetterContainer">
              {fields.get(index).level}
            </div>

            <div>
              Level{" "}
              {isEditing && currentIndex === index
                ? null
                : fields.get(index).level}
            </div>

            {isEditing && currentIndex === index ? (
              <EditLevelForm
                level={fields.get(index).level}
                onSubmit={(data) => {
                  editLevel(data, index);
                  setIsEditing(false);
                }}
              />
            ) : null}

            <div
              className="levelEditIcon"
              onClick={() => {
                setCurrentIndex(index);
                setIsEditing(true);
              }}
            >
              <EditIcon />
            </div>
          </div>
        </div>
      ))}
    </Col>
  );
};

let CreateProjectStepFour2 = (props) => {
  const { handleSubmit } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row md={12} className="justify-content-between">
          <Col md={4}>
            <h2>Confirm levels</h2>
          </Col>

          <div className="buttonContainer">
            <Col md={12} className={"justify-content-end d-flex"}>
              <Button type={"submit"} className="text-nowrap button">
                Confirm
              </Button>
            </Col>
          </div>
        </Row>

        <FieldArray
          name="blocks"
          component={renderBlockLevels}
          rerenderOnEveryChange={false}
        />
      </Container>
    </form>
  );
};

CreateProjectStepFour2 = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepFour2);

export default CreateProjectStepFour2;
