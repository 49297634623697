import React from 'react'
import Moment from 'react-moment'
import { FolderIcon } from '../../../constants/icons'
import {PREVIEW_CONTRACT} from "../../../constants/routes";

export const ContractRow = ({contract, index}) => {


  const totalProjects = (contract) => {
   return contract.projects.length
  }

  const totalBudget = (contract) => {
    return contract.projects.reduce((acc, project) => acc + parseInt(project.budget), 0)
  }

  const lastDeadlineOfGroup = (contract) => {
    let lastDeadline = new Date(0);

    contract.projects.forEach(project => {
      if(new Date(project.deadline) > lastDeadline) lastDeadline = new Date(project.deadline);
    });

    return lastDeadline;
  }

  const contractUrl = PREVIEW_CONTRACT.replace(':id',contract.id)
  return (
    <tr key={index}>
      <td className="td-project">
          <a href={contractUrl} className={'table-link'} style={{color: '#333e48'}}>
              <div className="d-flex">
                  <div><FolderIcon/> &nbsp; {contract.name}</div>
              </div>
          </a>
      </td>
      <td>
          <a href={contractUrl} className={'table-link'} style={{color: '#333e48'}}>
              {totalProjects(contract)} projects
          </a>
      </td>
      <td>
          <a href={contractUrl} className={'table-link'} style={{color: '#333e48'}}>
              £ {totalBudget(contract)}
          </a>
      </td>
      <td>
          <a href={contractUrl} className={'table-link'} style={{color: '#333e48'}}>
              <Moment format="DD-MM-YYYY HH:mm">
                  {lastDeadlineOfGroup(contract)}
              </Moment>
          </a>
      </td>
    </tr>
  )
}

export default ContractRow
