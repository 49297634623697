import { PUSH_CRUMBS, PUSH_NEW_CRUMBS, BACK_TO_CRUMBS, POP_CRUMBS } from "../actions"

export const pushCrumbs = (crumbs) => ({
    type: PUSH_CRUMBS,
    payload: crumbs,
})

export const pushNewCrumbs = (crumbs) => ({
    type: PUSH_NEW_CRUMBS,
    payload: crumbs,
})

export const backToCrumbs = (crumbs) => ({
    type: BACK_TO_CRUMBS,
    payload: crumbs,
})

export const popCrumbs = (crumbs) => ({
    type: POP_CRUMBS,
    payload: crumbs,
})