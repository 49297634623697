import React from "react"
import { Button } from "reactstrap";
import { WORK_TYPE } from "../../../constants/enamerations"
import NamePicture from "../../../shared/components/NamePicture"

const AccountInfo = ({ data, togglePinModal }) => {
    const classPrefix = "prev_account--big"
    const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

    return (
    data 
    ?   
        <>
        <div className="d-flex align-items-center w-75" >
        {data.avatar_url
            ? <img className="topbar__avatar-img"
                src={data.avatar_url ? `${data.avatar_url}?${new Date().getTime()}` : Ava}
                alt="avatar" />
            : <div className={`${classPrefix} p-0`}>
                <NamePicture classPrefix={classPrefix} first_name={data.first_name} surname={data.surname} />
              </div>}
        <div className="m-0 pl-4 d-flex flex-column justify-content-between">
            <div className="mb-3">
                <div className="lg_wrapper_page-title">
                <p className="page-title m-0">{data && data.first_name + ' ' + data.surname}</p>
                </div>
                <span className="m-0">{data && data.operative_type ? WORK_TYPE[data.operative_type].label : ''}</span>
            </div>
            <div className="d-flex flex-column">
                <span className="m-0">{data && data.email}</span>
                <span className="m-0">{data && data.phone}</span>
            </div>
        </div>
        </div>
        {
            data && !!data.pin &&
            <div className="mr-3 d-flex align-items-end justify-content-end w-25">
            <Button onClick={togglePinModal}>
              SHOW PIN
            </Button>
            </div>
        }
        </>
    : <div className="h-100 w-100 d-flex justify-content-center">Not found</div>
    )
}

export default AccountInfo