import { JOB_STATUS } from "../../../constants/enamerations";
export const typesOfJobsCounters = ["active", "completed", "rejected"]

export const typesOfJobsSpan = [
  {
  type:"ACTIVE",
  title:"Active Jobs",
  status:[
    `${JOB_STATUS.ASSIGNED_TO_USER.value}`,
    `${JOB_STATUS.ASSIGNED_TO_PM.value}`,
    `${JOB_STATUS.DECLINED.value}`,
  ]
  },
  {
    type:"JOB_HISTORY",
    title:"Job History",
    status:[`${JOB_STATUS.COMPLETED.value}`]
  },
  {
    type:"DAY_WORK",
    title:"Daywork History",
    status:''
  }
]