import {
    getProjectsAsync,
    getProjectsByIdAsync,
    uploadFileProjectAsync,
    uploadCSVProjectAsync,
    getProjectFilesAsync,
    projectCreateAsync,
    projectStartAsync,
    projectDeleteAsync,
    projectStopAsync,
    getProjectsByUserAsync,
    projectEditAsync,
    getWorkDeploymentAnalitycsAsync,
    getQAStatusAnalyticsAsync,
    getWorkTypeAnalyticsAsync,
    getBudgetAnalyticsAsync,
    getInvoiceAnalyticsAsync,
    getProjectsByFilterAsync,
    getUniqueValuesByFilterAsync,
    getUsersByProjectAsync,
    getSavedProjectAsync,
    saveProjectStateAsync,
    getAllSavedProjectsAsync,
    deleteSavedProjectAsync,
    createProjectFromFormAsync,
    saveCreatedProjectIdAsync, updateBlockAsync, addBlockAsync, editLevelAsync, exportAllProjectJobsToCsvAsync,
} from "../api/projects";

export const getProjects = async (status, pagination) =>
  await getProjectsAsync(status, pagination);
export const getProjectsById = async (id) =>
  await getProjectsByIdAsync(id);
export const uploadFileProject = async (source) =>
  await uploadFileProjectAsync(source);
export const uploadCSVProject = async (url, file) =>
  await uploadCSVProjectAsync(url, file);
export const getProjectFiles = async () => await getProjectFilesAsync();
export const projectCreate = async (data) => await projectCreateAsync(data);
export const projectStart = async (id, status) =>
  await projectStartAsync(id, status);
export const projectDelete = async (id) => await projectDeleteAsync(id);
export const projectStop = async (id, status) =>
  await projectStopAsync(id, status);
export const getProjectsByUser = async () => await getProjectsByUserAsync();
export const projectEdit = async (id, data) => await projectEditAsync(id, data);
export const getWorkDeploymentAnalitycs = async (id) =>
  await getWorkDeploymentAnalitycsAsync(id);
export const getQAStatusAnalytics = async (id) =>
  await getQAStatusAnalyticsAsync(id);
export const getWorkTypeAnalytics = async (id) =>
  await getWorkTypeAnalyticsAsync(id);
export const getBudgetAnalytics = async (id) =>
  await getBudgetAnalyticsAsync(id);
export const getInvoiceAnalytics = async (id) =>
  await getInvoiceAnalyticsAsync(id);
export const getProjectsByFilter = async () => await getProjectsByFilterAsync();
export const getUniqueValuesByFilter = async (id, filedName) =>
  await getUniqueValuesByFilterAsync(id, filedName);
export const getUsersByProject = async (id, userType) => await getUsersByProjectAsync(id, userType);

export const getSavedProject = async (id) => await getSavedProjectAsync(id);

export const saveProjectState = async (formValue, id) =>
  await saveProjectStateAsync(formValue, id);

export const deleteSavedProject = async (id) =>
  await deleteSavedProjectAsync(id);

export const getAllSavedProjects = async () => await getAllSavedProjectsAsync();

export const createProjectFromForm = async (data) =>
  await createProjectFromFormAsync(data);

export const saveCreatedProjectId = async (savedProjectId, createdProjectId) =>
  await saveCreatedProjectIdAsync(savedProjectId, createdProjectId);

export const updateBlock = async (data) => await updateBlockAsync(data);
export const addBlock = async (data) => await addBlockAsync(data);
export const editLevel = async (data) => await editLevelAsync(data);
export const exportAllProjectJobs = async (data,includeSteps) => await exportAllProjectJobsToCsvAsync(data, includeSteps);
