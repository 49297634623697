import React, { useState } from 'react'
import { Row } from 'reactstrap'
import { debounce } from 'lodash';
import { updateVariationOrder } from '../../redux/services/variation_orders';

const EditableBudgetElement = ({
  title,
  budget,
  icon,
  voId,
}) => {
  const [revisedBudget, setRevisedBudget] = useState(budget);

  const editRevisedBudget = debounce(() => {
    if(revisedBudget === budget) return;
    updateVariationOrder(voId, {
      revised_amount: revisedBudget,
    }).then(() => window.location.reload())
  }, 500)

  const handleInputChange = (e) => {
    setRevisedBudget(e.target.value)
  }

  const inputContainer = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    border: '1px solid rgba(0,0,0,0.2)',
    color: '#343a40',
    borderRadius:'5px',
  }

  const inputStyle = {
    width:'90%',
    border:0,
    outline:'none',
    fontWeight:'bold',
    color: '#343a40',
    '& :focus':{
      border:0,
    }
  }

  return (
    <Row className={'flex flex-row align-items-center justify-items-between m-2'}>
      <div 
      className={'bg-light-gray border-radius-full budget-element'}
      >
        {icon}
      </div>
      <div 
      className={'pl-3 d-flex flex-column justify-items-center flex-basis-70'}>
        <div style={inputContainer}>
          <div >
            <h5 className={budget < 0 ? 'text-red' : null}><b>₤</b></h5> 
          </div>
          <input
            type="number"
            style={inputStyle}
            value={revisedBudget}
            onChange={(e) => handleInputChange(e)}
            onBlur={() => editRevisedBudget()}
          />
        </div>
        <div>
          <h5 className={budget < 0 ? 'text-red' : null}>{title}</h5>
        </div>
      </div>
    </Row>
  )
}

export default EditableBudgetElement