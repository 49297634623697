import {Field, formValueSelector, reduxForm} from "redux-form";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";
import React from "react";
import {connect} from "react-redux";
import {addBlock} from "../../../../redux/services/projects";

const minValueLevels = (value) => {
    return value && value <= 0 ? `Value must be greater than 0` : undefined;
}
const requiredFieldEditBlock = (value) => (value ? undefined : "You must enter a value");

let AddBlockToProjectForm = ({modalAddBlock, toggleAddModal, id, noOfLevels, projectId}) => {

    const handleSubmit = (event) => {
        console.log({
            blockName: id,
            levels: noOfLevels,
            projectId
        });
        addBlock({
            blockName: id,
            levels: noOfLevels,
            projectId
        });
    }

    return (<div className="centered">
        <Modal
            isOpen={modalAddBlock}
            toggle={toggleAddModal}
            modalClassName={`ltr-support`}
            className={`modal-dialog--primary locum-preview-modal createBlockModal`}
        >
            <ModalHeader className="modal-header">
                <div>
                    <h3>Add block</h3>
                </div>
                <button
                    className="lnr lnr-cross modal__close-btn"
                    type="button"
                    onClick={() => {
                        toggleAddModal();
                    }}
                />
            </ModalHeader>
            <ModalBody className="createBlockModalBody">
                <form
                    id={"add_block_project_form"}
                    onSubmit={(event) => {
                        handleSubmit(event);
                        event.preventDefault();

                        if (
                            id.length &&
                            noOfLevels > 0
                        ) {
                            toggleAddModal();
                        }
                    }}
                >

                    <Row md={12} className="mt-2">
                        <Field
                            name="id"
                            component={TextFieldMaterial}
                            placeholder="Enter Block Letter/Number/ID"
                            type={"text"}
                            validate={[requiredFieldEditBlock]}
                        />
                    </Row>

                    <Row md={12} className="mt-4">
                        <Field
                            name="noOfLevels"
                            component={TextFieldMaterial}
                            placeholder="Enter Number of Levels"
                            type={"number"}
                            validate={[requiredFieldEditBlock, minValueLevels]}
                        />
                    </Row>

                    <Row md={12} className="mt-4">
                        <Field name="projectId"
                               component={"input"}
                               type={"hidden"}/>
                    </Row>

                    <Row md={12} className="mt-4 justify-content-end">
                        <Row md={12} className="justify-content-end">
                            <Col md={5}>
                                <Button
                                    className="cancelButton"
                                    onClick={() => {
                                        toggleAddModal();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>

                            <Col md={5}>
                                <Button
                                    className="button"
                                    type={"submit"}
                                    form={"add_block_project_form"}
                                >
                                    Confirm
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    </div>)
}

AddBlockToProjectForm =  reduxForm({
    form: "add_block_project_form",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    touchOnChange: true,
})(AddBlockToProjectForm);

const selector = formValueSelector('add_block_project_form');
AddBlockToProjectForm = connect(state => {
    return selector(state, 'id', 'noOfLevels', 'projectId');
})(AddBlockToProjectForm)

export default  AddBlockToProjectForm;