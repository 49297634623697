import React, {useCallback, useEffect, useState} from "react";
import {reduxForm, FieldArray, initialize, Field} from "redux-form";
import {Container, Card, CardBody, Button, Table} from "reactstrap";
import store from "../../redux/store";
import {
  getOperativeTemplates,
  saveOperativeTemplates,
} from "../../redux/services/templates";
import Loading from "../../shared/components/Loader";
import SearchIcon from "mdi-react/SearchIcon";
import {
  allOperativeStepsAreValid,
  getAllTransformedOperativeSteps, getAllUncheckedOperativeSteps,
  isOperativeStepChecked
} from "../../helpers/operative-steps";
import {CameraIcon, CommentIcon, CreateIcon} from "../../constants/icons";
import Pagination from "@material-ui/lab/Pagination";
import renderCheckBoxField from "../../shared/components/form/CheckBox";
import {TextField} from "@material-ui/core";

const PAGE_SIZE = 10;


const renderOperativeSteps = ({ fields }) => {
  const [operativeSteps, setOperativeSteps] = useState(fields && fields.getAll() ? getAllTransformedOperativeSteps(fields.getAll()) : [])
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCounts, setPageCounts] = useState(0);
  const [searchFilter, setSearchFilter] = useState("");
  const [checkedOperativeStepIndexes,setCheckedOperativeStepIndexes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  //Changing Filters
  useEffect(() => {
    if(!isNewTemplate){
      setCurrentPage(0)
      setCheckedOperativeStepIndexes([])
    }
    const searchedOperativeSteps = !!searchFilter ? getAllTransformedOperativeSteps(fields.getAll()).filter((operativeStep) => {
      return operativeStep.description
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
    }) : getAllTransformedOperativeSteps(fields.getAll())
    setOperativeSteps(searchedOperativeSteps)
  }, [fields, searchFilter, getAllTransformedOperativeSteps ])


  useEffect(() => {
    setPageCounts(Math.ceil(operativeSteps.length / PAGE_SIZE))
  },[operativeSteps])


  const saveOperativeSteps = async () => {
    const allOperativeSteps = fields && fields.getAll() ? fields.getAll() : []
    fields.removeAll()
    allOperativeSteps.forEach((operativeStep, index) => {
      if(checkedOperativeStepIndexes.includes(index)){
        const foundOperativeStep = operativeSteps.find((opStep) => opStep.originalIndex === index)
        if(foundOperativeStep){
          const composedOperativeStep = {
            description: foundOperativeStep.description,
            steps: foundOperativeStep.steps.filter((step) => step.stepDescription !== "")
          }
          fields.push(composedOperativeStep)
        }
      }else {
        fields.push(operativeStep)
      }
    })
    setIsNewTemplate(false);
    setSearchFilter("");
    setCheckedOperativeStepIndexes([])
    await saveValues();
  }

  const deleteOperativeSteps = async () => {
    const allUnchecked = getAllUncheckedOperativeSteps(fields.getAll(), checkedOperativeStepIndexes)
    fields.removeAll()
    allUnchecked.forEach((notChecked) => fields.push(notChecked))
    setCheckedOperativeStepIndexes([])
    await saveValues();
  }

  const addOperativeStep = () => {
    setIsNewTemplate(true);
    setSearchFilter("");
    const tempOperativeStep = {description: '', steps: []}
    fields.push(tempOperativeStep);
    setCheckedOperativeStepIndexes([fields.getAll().length])
    const newJobPageCount = Math.ceil((fields.getAll().length + 1) / PAGE_SIZE)
    setCurrentPage(newJobPageCount -1 )
    setIsEditing(true);
  }

  const onOperativeStepCheckboxPressed = useCallback((operativeStepIndex,event) => {
    if(typeof operativeStepIndex == "number" && event && event && event.target && event.target.checked){
      const newArray = [...checkedOperativeStepIndexes, operativeStepIndex];
      setCheckedOperativeStepIndexes(newArray);
    } else {
      const newArray = checkedOperativeStepIndexes.filter((item) => item !== operativeStepIndex);
      setCheckedOperativeStepIndexes(newArray);
    }
  },[checkedOperativeStepIndexes])

  const _onChangeDescription = useCallback((operativeIndex, description) => {
    let operativeStep = operativeSteps[operativeIndex]
    if(!!operativeStep){
      operativeStep.description = description
      let tempOperativeSteps = operativeSteps
      tempOperativeSteps[operativeIndex] = operativeStep
      setOperativeSteps([...tempOperativeSteps])
    }

  },[operativeSteps, setOperativeSteps])

  const _onTogglePhotoRequired = useCallback((operativeStepIndex, stepIndex,) => {
    let operativeStep = operativeSteps[operativeStepIndex]
    if(!!operativeStep){
      let step = operativeStep.steps[stepIndex]
      if(!!step){
        if(!!step.isCommentRequired){
          step.isCommentRequired = false
        }
        step.isPhotoRequired = !step.isPhotoRequired
        let tempStepDescriptions = operativeStep.steps
        tempStepDescriptions[stepIndex] = step
        operativeStep.steps = [...tempStepDescriptions]
      }
      let tempOperativeSteps = operativeSteps
      tempOperativeSteps[operativeStepIndex] = operativeStep
      setOperativeSteps([...tempOperativeSteps])
    }
  },[operativeSteps,setOperativeSteps])

  const _onToggleCommentRequired = useCallback((operativeStepIndex, stepIndex,) => {
    let operativeStep = operativeSteps[operativeStepIndex]
    if(!!operativeStep){
      let step = operativeStep.steps[stepIndex]
      if(!!step){
        if(!!step.isPhotoRequired){
          step.isPhotoRequired = false
        }
        step.isCommentRequired = !step.isCommentRequired
        let tempStepDescriptions = operativeStep.steps
        tempStepDescriptions[stepIndex] = step
        operativeStep.steps = [...tempStepDescriptions]
      }
      let tempOperativeSteps = operativeSteps
      tempOperativeSteps[operativeStepIndex] = operativeStep
      setOperativeSteps([...tempOperativeSteps])
    }
  },[operativeSteps,setOperativeSteps])

  const _onChangeStepDescription = useCallback((operativeStepIndex, stepIndex, description) => {
    let operativeStep = operativeSteps[operativeStepIndex]
    if(!!operativeStep){
      let step = operativeStep.steps[stepIndex]
      if(!!step){
        step.stepDescription = description
        let tempStepDescriptions = operativeStep.steps
        tempStepDescriptions[stepIndex] = step
        operativeStep.steps = [...tempStepDescriptions]
      }
      let tempOperativeSteps = operativeSteps
      tempOperativeSteps[operativeStepIndex] = operativeStep
      setOperativeSteps([...tempOperativeSteps])
    }

  },[operativeSteps,setOperativeSteps])

  const _onRemoveStepButtonPressed = useCallback((operativeStepIndex, stepIndex) => {
    // Create a copy of Operative Steps array
    let tempOperativeSteps = [...operativeSteps];

    if (operativeStepIndex < tempOperativeSteps.length) {
      let operativeStep = tempOperativeSteps[operativeStepIndex];

      if (stepIndex < operativeStep.steps.length) {
        // Remove the element at stepIndex
        operativeStep.steps.splice(stepIndex, 1);
      }
      // Update the state with the modified array
      setOperativeSteps(tempOperativeSteps);
    }
  },[operativeSteps, setOperativeSteps])

  const _onAddNewStepButtonPressed = useCallback((operativeStepIndex) => {
    let operativeStep = operativeSteps[operativeStepIndex]
    if(!!operativeStep){
      const tempStep = {stepDescription: '', isPhotoRequired: false, isCommentRequired: false}
      operativeStep.steps.push(tempStep)
      let tempOperativeSteps = operativeSteps
      tempOperativeSteps[operativeStepIndex] = operativeStep
      setOperativeSteps([...tempOperativeSteps])
    }

  },[operativeSteps, setOperativeSteps])


  const _renderSearchSection = useCallback(() => {
    return  <div className="r-flex flex-row justify-content-between w-100">
      <h3 className={"mb-4"}>Operative steps template</h3>
      <div className="mt-3">
        <div className="form__form-group-field">
          <div className="search_input">
            <SearchIcon />
            <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search_input"
                value={searchFilter}
                onChange={(data) => {
                  setSearchFilter(data.target.value);
                  setCheckedOperativeStepIndexes([])
                  setIsEditing(false)
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
            />
          </div>
        </div>
      </div>
    </div>
  },[searchFilter, setSearchFilter, setCheckedOperativeStepIndexes,setIsEditing])

  const _renderActionButtons = useCallback(() => {
    if(checkedOperativeStepIndexes.length > 0){
      return   <div className={"actionButtonContainer mt-2 mb-2"} >
        <div className={"operativeStepActionButton"}>
          <Button
              className={"editButton"}
              onClick={() => {
                if(isEditing){
                  saveOperativeSteps();
                }
                setIsEditing(!isEditing)
              }}
              disabled={isEditing ? !allOperativeStepsAreValid(operativeSteps, checkedOperativeStepIndexes) : false}
          >
            {isEditing ? 'Save' : 'Edit'}
          </Button>
        </div>
        <div className={"operativeStepActionButton"}>
          <Button
              className={"deleteButton"}
              onClick={() => deleteOperativeSteps()}
              disabled={isEditing}>
            Delete
          </Button>
        </div>
      </div>
    } else {
      return null
    }
  },[isEditing, checkedOperativeStepIndexes,saveOperativeSteps,deleteOperativeSteps,allOperativeStepsAreValid,operativeSteps,setIsEditing ])

  const _renderOperativeStepsPagination = useCallback(() => {
    if(operativeSteps && pageCounts >= 2){
      return <div>
        <Pagination
            count={pageCounts}
            page={currentPage + 1}
            onChange={(event, page) => {
              setCurrentPage(page - 1)
              setCheckedOperativeStepIndexes([]);
              setIsEditing(false)
            }}
        />
      </div>
    } else {
      return null
    }
  },[operativeSteps,pageCounts,currentPage ])

  const _renderTableHeader = useCallback(() => {
    return <thead>
    <tr className={"d-flex"}>
      <th className={"th30"}></th>
      <th className={"th200"}>Description</th>
      <th className={"th250"}>Steps</th>
    </tr>
    </thead>
  },[])

  const _renderTable = useCallback(() => {
    return <div className={'table--bordered'}>
      {_renderActionButtons()}
      <Table responsive hover className="table react-table table--bordered">
        {_renderTableHeader()}
        <tbody>
        {operativeSteps.slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE).map((operativeStep, index) => {
          return (
              <tr key={`operativeStep-${operativeStep.originalIndex}-${index}`}
                  className={"d-flex justify-content-start align-items-center"}
              >
                <td className={"operativeStepsCheckBox"}>
                  <div>
                    <Field
                        name={`Step${currentPage * 10 + operativeStep.originalIndex}`}
                        component={renderCheckBoxField}
                        onChange={(event) => onOperativeStepCheckboxPressed(operativeStep.originalIndex, event)}
                        checked={isOperativeStepChecked(checkedOperativeStepIndexes, operativeStep.originalIndex)}
                        disabled={isEditing}
                    />
                  </div>
                </td>
                <td className={"td200"}>
                  {isEditing && isOperativeStepChecked(checkedOperativeStepIndexes,  operativeStep.originalIndex) ? (
                      <TextField
                          placeholder={"Enter step description"}
                          value={operativeStep.description}
                          type={'text'}
                          required={true}
                          error={!operativeStep.description}
                          helperText={!operativeStep.description ? "You must enter a value!" : undefined}
                          onChange={(event) => _onChangeDescription(currentPage * 10 + index,event.target.value)}/>
                  ) : (
                      <div className={"operativeStepJobStepDescriptionContainer"} style={{maxWidth: 200}}>
                                        <span className={operativeStep.description.length > 20 ? "operativeStepJobDescriptionText" : null}
                                              data-text={operativeStep.description}>
                                            {operativeStep.description}
                                        </span>
                      </div>
                  )}
                </td>
                {operativeStep.steps.map((step, stepsIndex) => {
                  return <td className={"td250"} key={`${operativeStep.originalIndex}-step${stepsIndex}`}>
                    <div className={"d-flex flex-row align-items-center"}>
                      <div>
                        <button  className="commentButton"
                                 type={"button"}
                                 onClick={() => _onToggleCommentRequired(currentPage * 10 + index, stepsIndex)}
                                 disabled={!(isEditing && isOperativeStepChecked(checkedOperativeStepIndexes,  operativeStep.originalIndex))}
                        >
                          <CommentIcon color={
                            !isEditing
                              ? step.isCommentRequired
                                    ? '#70bbfd' : '#dddddd'
                                : !checkedOperativeStepIndexes.includes(operativeStep.originalIndex)
                                    ? step.isCommentRequired ? '#70bbfd' : '#dddddd'
                                    : step.isCommentRequired ? '#007dbc' : undefined } />
                        </button>

                      </div>
                      <div>
                        <button  className="cameraButton"
                                 type={"button"}
                                 onClick={() => _onTogglePhotoRequired(currentPage * 10 + index, stepsIndex)}
                                 disabled={!(isEditing && isOperativeStepChecked(checkedOperativeStepIndexes,  operativeStep.originalIndex))}
                        >
                          <CameraIcon color={
                            !isEditing
                                ? step.isPhotoRequired
                                    ? '#70bbfd' : '#dddddd'
                                : !checkedOperativeStepIndexes.includes(operativeStep.originalIndex)
                                    ? step.isPhotoRequired ? '#70bbfd' : '#dddddd'
                                    : step.isPhotoRequired ? '#007dbc' : undefined } />
                        </button>

                      </div>
                      {isEditing && isOperativeStepChecked(checkedOperativeStepIndexes,  operativeStep.originalIndex) ?
                          <div className={"d-flex"}>
                            <TextField
                                placeholder={"Enter step name"}
                                value={step.stepDescription}
                                type={'text'}
                                required={true}
                                onChange={(event) => _onChangeStepDescription(currentPage * 10 + index,stepsIndex,event.target.value)}/>
                            {operativeStep.steps.length > 0 && <div className={"ml-2"}>
                              <button className="removeOperativeStepButton"
                                      type={"button"}
                                      onClick={() => _onRemoveStepButtonPressed(currentPage * 10 + index, stepsIndex)}>
                                -
                              </button>
                            </div>}
                          </div>

                          :
                          <div className={"operativeStepJobDescriptionContainer"} style={{width: '100%'}}>
                                                <span className={step.stepDescription.length > 20 ? "operativeStepJobDescriptionText" : null}
                                                      data-text={step.stepDescription}>
                                                    {step.stepDescription}
                                                </span>
                          </div> }
                    </div>
                  </td>
                })}
                {isEditing && isOperativeStepChecked(checkedOperativeStepIndexes,  operativeStep.originalIndex) && <td className={"td220"}>
                  <button className="addOperativeStepButton"
                          type={"button"}
                          onClick={() => _onAddNewStepButtonPressed(currentPage * 10 + index)}>
                    +
                  </button>
                </td>}

              </tr>
          )
        })}
        </tbody>

      </Table>
    </div>
  },[_renderActionButtons,_renderTableHeader, operativeSteps, currentPage,onOperativeStepCheckboxPressed,isOperativeStepChecked,isEditing,_onChangeStepDescription, _onChangeDescription, _onAddNewStepButtonPressed, _onRemoveStepButtonPressed  ])

  const _renderAddOperativeStep = useCallback(() => {
    return <Button
        className="btn_with_img mt-5"
        color="primary"
        onClick={addOperativeStep}
        disabled={isEditing}
    >
      <CreateIcon /> Create Operative step
    </Button>
  },[addOperativeStep, isEditing])

  return (
      <>
        {_renderSearchSection()}
        {_renderTable()}
        {_renderOperativeStepsPagination()}
        {_renderAddOperativeStep()}
      </>
  );
};

async function saveValues() {
  const formValue = store.getState().form.operative_steps.values;

  await saveOperativeTemplates({ operativeSteps: formValue.operativeSteps });
}

async function getValues() {
  await getOperativeTemplates().then((response) => {
    store.dispatch(initialize("operative_steps", response));
  });
}

const OperativeSteps = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getValues()
      .catch((e) => {
        alert(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <form>
      <Container>
        <Card>
          <CardBody style={{ minWidth: "min-content" }}>
            <FieldArray
              name="operativeSteps"
              component={renderOperativeSteps}
              rerenderOnEveryChange={false}
            />
          </CardBody>
        </Card>
      </Container>
    </form>
  );
};

export default reduxForm({
  form: "operative_steps",
  enableReinitialize: true,
  // initialValues: {
  //   operativeSteps: [
  //     { description: "asdds", steps: [] },
  //     { description: "asdds", steps: [] },
  //   ],
  // },
})(OperativeSteps);
