import React from "react";
import { Table } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { pageSize } from "../../../config/pagination";
import { WORK_TYPE } from "../../../constants/enamerations";
import {PREVIEW_JOB} from "../../../constants/routes";

const RequestsTable = ({ requests, page, setPage }) => {
  return (
    <>
      <Table responsive hover className="table react-table table--bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>TRADE</th>
            <th>NAME</th>
            <th>OPERATIVE</th>
            <th>PROJECT MANAGER</th>
            <th>BLOCK</th>
            <th>LEVEL</th>
            <th>UNIT TYPE</th>
            <th>UNIT ID</th>
          </tr>
        </thead>
        <tbody>
          {requests &&
            requests.items &&
            requests.items.map((item, index) => {
              const fullUrl = PREVIEW_JOB.replace(':project_id', item.project.id).replace(':id', item.id)
              return <tr key={index}>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.id}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && WORK_TYPE[item.job_type].bigIcon}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.description}
                  </a>
                 </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.employee && item.employee.first_name + " " + item.employee.surname}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.pm && item.pm.first_name + " " + item.pm.surname}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.block.name}
                  </a>
                 </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.level.name}
                  </a>
                 </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.unit_type}
                  </a>
                 </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item && item.plot.name}
                  </a>
                </td>
              </tr>
            }

            )}
        </tbody>
      </Table>
      {requests && Math.ceil(requests.total / pageSize) >= 2 && (
        <div className="box-pagination">
          <Pagination
            count={
              requests && requests.total
                ? Math.ceil(requests.total / pageSize)
                : 0
            }
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
    </>
  );
};

export default RequestsTable;
