import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import {pushCrumbs} from "../../../../redux/actions/bread_crumbs";
import {getProjectsById} from "../../../../redux/services/projects";
import {initialize} from "redux-form";
import store from "../../../../redux/store";
import EditExistingBlockForm from "./EditExistingBlockForm";
import {CreateIcon, EditIcon} from "../../../../constants/icons";
import AddBlockToProjectForm from "./AddBlockToProjectForm";
import EditLevelProjectForm from "./EditLevelProjectForm";
import Loading from "../../../../shared/components/Loader";

const index = ({ match, history, location, pushCrumbs, form }) => {
    const [project, setProject] = useState(null);
    const [load, setLoad] = useState(false);
    const [modalEditBlock, setModalEditBlock] = useState(false);
    const [modalAddBlock, setModalAddBlock] = useState(false);
    const [modalEditLevel, setModalEditLevel] = useState(false);

    const toggleEditModal = () => setModalEditBlock(!modalEditBlock);
    const toggleAddModal = () => setModalAddBlock(!modalAddBlock);
    const toggleEditLevelModal = () => setModalEditLevel(!modalEditLevel);

    useEffect(() => {
        getProject();
    }, [modalAddBlock, modalEditBlock, modalEditLevel]);

    function initializeEditBlockForm(id, noOfLevels, blockId) {
        store.dispatch(
            initialize("edit_existing_block_form", {
                id,
                noOfLevels,
                initialBlocks: noOfLevels,
                blockId: blockId,
            }, false)
        );
    }

    function initializeAddBlockForm(id, noOfLevels, projectId) {
        store.dispatch(
            initialize("add_block_project_form", {
                id,
                noOfLevels,
                projectId,
            }, false)
        );
    }

    function initializeEditLevelForm(id, name) {
        store.dispatch(
            initialize("edit_level_project_form", {
                id,
                name,
            }, false)
        );
    }

    const getProject = () => {
        setLoad(true);
        getProjectsById(+match.params.id).then((res) => {
            setProject(res.data);
            setLoad(false);
        });
    };

    return (
        <>
            <Container>
                <Row className="pr-3 d-flex">
                    <Col md={12} xl={12} className="d-flex mb-4 align-items-center justify-content-between">
                        <Col md={2} xl={2} className="p-0">
                            <h5 className="page-title m-0">{project && project.name} structure</h5>
                        </Col>
                        <Col
                            md={1.5}
                            xl={1.5}
                            className="p-0 ml-3 hover justify-content-end"
                        >
                            <Button className="m-0 btn_with_img" color="primary" onClick={() => {
                                initializeAddBlockForm('', '', match.params.id);
                                setModalAddBlock(true);
                            }}>
                                <CreateIcon /> Add block
                            </Button>
                        </Col>
                    </Col>
                </Row>
                {load ? (
                    <Loading />
                ) : (
                    <Card>
                        <CardBody>
                            <Table responsive
                                   className="mt-3 table react-table table--bordered pl-0">
                                <thead>
                                <tr>
                                    <th>BLOCK</th>
                                    <th>LEVELS</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {(project && project.blocks && project.blocks.map((block, index) => (
                                        <tr key={block.id}>
                                            <td style={{ verticalAlign: 'top' }}>
                                                {block.name}
                                                <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => {
                                                    initializeEditBlockForm(block.name, block.levels.length, block.id)
                                                    setModalEditBlock(true);
                                                }} >
                                                    <EditIcon/>
                                                </span>
                                            </td>
                                            <td>
                                                <Table>
                                                    <tbody>
                                                        {block.levels.sort((b1, b2) => b1.id - b2.id).map((level, index) => (
                                                        <tr key={level.id}>
                                                            {(index === 0 ?
                                                                <td style={{ paddingTop: 0 }}>
                                                                    {level.name}
                                                                    <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => {
                                                                        initializeEditLevelForm(level.id, level.name)
                                                                        setModalEditLevel(true);
                                                                    }} >
                                                                        <EditIcon/>
                                                                    </span>
                                                                </td>:
                                                                <td>
                                                                    {level.name}
                                                                    <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => {
                                                                        initializeEditLevelForm(level.id, level.name)
                                                                        setModalEditLevel(true);
                                                                    }} >
                                                                        <EditIcon/>
                                                                    </span>
                                                                </td>)}
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                )}
            </Container>

            {modalEditBlock &&
                <EditExistingBlockForm
                    modalEditBlock={modalEditBlock}
                    toggleEditModal={toggleEditModal}
                />
            }

            {modalAddBlock &&
                <AddBlockToProjectForm
                    modalAddBlock={modalAddBlock}
                    toggleAddModal={toggleAddModal}
                />
            }

            {modalEditLevel &&
                <EditLevelProjectForm
                    modalEditLevel={modalEditLevel}
                    toggleEditModal={toggleEditLevelModal}
                />
            }
        </>
    )
}

const mapDispatchToProps = {
    pushCrumbs,
};

function mapStateToProps({ form }) {
    return { form };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));