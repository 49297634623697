import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { SettingsIcon } from '../../../constants/icons'
import ContractAnalytics from './ContractAnalytics'
import EditContractModal from './Modals/EditContractModal'
import ProjectTypes from './ProjectsTable/ProjectTypes.jsx'
import { getContractById } from '../../../redux/services/contracts'
import Loading from '../../../shared/components/Loading'
import {Breadcrumbs} from "@material-ui/core";
import {Link} from "react-router-dom";
import {CONTRACTS} from "../../../constants/routes";

export const PreviewContract = ({match}) => {
  // const {contract} = location.state
  const [contract, setContract] = useState();
  const contractId = match.params.id;
  const [editContractModal, setEditContractModal] = useState(false);
  const [load, setLoad] = useState(false);

  const toggleEditContractModal = () => {
    setEditContractModal(curr => !curr)
  }

  const fetchContract = () => {
    setLoad(true)
    getContractById(contractId)
    .then(res => setContract(res.items[0]))
    setLoad(false);
  }

  useEffect(()=>{
    fetchContract();
  },[])

  const contactName = contract && contract.name ?contract.name : 'Current Contract'

  if(load || !contract) return <Loading/>

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
        <Link to={{pathname: CONTRACTS}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            Contracts
          </h3>

        </Link>
        <Link to={{pathname: ''}} >
          <h3 className={"page-subhead subhead bread_crumbs-active"}>
            {contactName}
          </h3>
        </Link>
      </Breadcrumbs>
    <Row className="pr-3 d-flex justify-content-between">
        <Col md={5} xl={5} className="d-flex align-items-center mb-4">
          <Col md={1.5} xl={1.5} className="p-0">
            <h5 className="page-title m-0">{contract.name}</h5>
          </Col>
          <Col
            md={1.5}
            xl={1.5}
            className="p-0 ml-3 hover"
            onClick={() => toggleEditContractModal()}
          >
            <SettingsIcon />
          </Col>
        </Col>
    </Row>
    <ContractAnalytics match={match} contract={contract}/>
    <ProjectTypes match={match} contract={contract}/>
    {
      editContractModal &&
      <EditContractModal
        modalVisibility={editContractModal}
        toggleModal={toggleEditContractModal}
        contract={contract}
      />
    }
    </Container>
  )
}

export default PreviewContract
