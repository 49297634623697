import React, { useState } from 'react'
import Moment from 'react-moment';
import { Button, Modal } from 'reactstrap';
import {calculateDayWorkHourCost} from '../../../config/func';
import { DAY_WORK_STATUS } from '../../../constants/enamerations';
import { CalendarIcon, ClockIcon, CreateCalendarIcon, PoundIcon, UserIcon } from '../../../constants/icons';
import { updateDayWorkById } from '../../../redux/services/day_work';
import DayWorkRejectedForm from '../../Accounts/Forms/DayWorkRejectedForm';
import ImagesCard from '../../Jobs/JobPreview/ImagesCard';
import Loading from "../../../shared/components/Loader";

const DayworkModal = ({
  modal,
  setModal,
  item,
  load,
  setLoad,
  refetch,
}) => {
  const [moreImageID, setMoreImageID] = useState(null);
  const [switchModal, setSwitchModal] = useState(1);
  const showButtons = item && item.status === DAY_WORK_STATUS.PENDING.value;

  const toggleMoreImage = (id) => setMoreImageID(id);

  const getPhotosByStep = (id, index) => {
    const photos = [];

    item.assets.map((item) =>
      photos.push({ src: item.public_url, width: 4, height: 3 })
    )
  };


  const Approved = () => {
    setLoad(true);
    updateDayWorkById(item.id, DAY_WORK_STATUS.APPROVED.value).then(() => {
      setLoad(false);
      setModal(false);
      refetch();
    });
  };

  const Rejected = (data) => {
    setLoad(true);
    updateDayWorkById(
      item.id,
      DAY_WORK_STATUS.REJECTED.value,
      data.deny_comment
    ).then(() => {
      setLoad(false);
      setModal(false);
      refetch();
    });
  };

  if(!modal || !item) return null;

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog--primary additional_work-modal`}
    >
      <div className="modal__header">
        <h3 className="page-title">DayWork request</h3>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={setModal}
        />
      </div>
      <div className="modal__body text-left mt-5">

  {load ? (
    <Loading />
  ) : switchModal === 1 ? (
    <div>
      <div className="mt-4 d-flex justify-content-around">
        {item && item.levels && (
          <div className="d-flex align-items-center">
            <h3 className="page-subhead subhead m-0">Level &nbsp;</h3>
            <p className="m-0">{item.levels.name}</p>
          </div>
        )}
        {item && item.levels && item.levels.block && (
          <div className="d-flex align-items-center">
            <h3 className="page-subhead subhead m-0">Block &nbsp;</h3>
            <p className="m-0">{item.levels.block.name}</p>
          </div>
        )}
      </div>

      <div className="hr_wrapper">
        <hr />
      </div>

      <div>
        <p>
          <CalendarIcon /> &nbsp;{" "}
          <Moment format="LL">{item && item.date_from}</Moment> -{" "}
          <Moment format="LL">{item && item.date_to}</Moment>
        </p>
        <p>
          <ClockIcon /> &nbsp;{" "}
          <Moment parse="HH:mm:ss" format="LT">
            {item && item.start_at}
          </Moment>{" "}
          -{" "}
          <Moment parse="HH:mm:ss" format="LT">
            {item && item.end_at}
          </Moment>
        </p>
        <p className="d-flex align-items-center">
          <PoundIcon />
          <span className="ml-2">{calculateDayWorkHourCost({
            startDate: item.date_from,
            endDate: item.date_to,
            startTime: item.start_at,
            endTime: item.end_at,
            dayworkHour: item.hourly_rate,
            userHourRate: item.creator && item.creator.hourly_rate ? item.creator.hourly_rate : undefined,
            budget: item.budget
          }).cost}</span>
        </p>
        {item && item.creator && (
          <p className="d-flex align-items-center">
            <UserIcon />
            <span className="ml-2">
              {item.creator.first_name}&nbsp;{item.creator.surname}
            </span>
          </p>
        )}
        <p>
          <CreateCalendarIcon /> &nbsp;{" "}
          <Moment format="LL">{item && item.created_at}</Moment>
        </p>
      </div>
      <div className="mt-3 ml-3">
        <ImagesCard
          item={item}
          moreImageID={moreImageID}
          toggleMoreImage={toggleMoreImage}
          getPhotosByStep={getPhotosByStep}
        />
      </div>

      {showButtons ? (
        <>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              className="m-0 mr-3"
              color="secondary"
              onClick={() => setSwitchModal(2)}
            >
              Deny
            </Button>
            <Button className="m-0" color="primary" onClick={() => Approved()}>
              Approve
            </Button>
          </div>
        </>
      ) : null}
    </div>
  ) : (
    <DayWorkRejectedForm Rejected={Rejected} setModal={setModal} />
  )}
      </div>
    </Modal>
  )
}

export default DayworkModal
