import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import { SORT_BY } from "../../../constants/enamerations";
import {INVOICES, UNINVOICED} from "../../../constants/routes";

function RecentInvoicesTab({
  userId,
  generateInvoicesById,
  setGeneratedInvoicesLength,
}) {

  const handleGenerateInvoices = () => {
    generateInvoicesById(userId)
    .then(response => setGeneratedInvoicesLength(response.length))
  }

  return (
    <Row className="mb-4">
    <Col md={12} xl={12} className="d-flex align-items-center">
      <Col md={2} xl={2} className="p-0">
        <div className="wrapper_page-title">
        <h5 className="page-title m-0">RECENT INVOICES &nbsp;</h5>
        </div>
      </Col>
      <Col md={10} xl={10} className="wrapper_page-title p-0 d-flex justify-content-between">
        <Col md={1.5} xl={1.5} className="d-flex align-items-center">
          <Link to={{pathname: INVOICES.replace(':id', userId)}}>
              <h5 className="page-title bread_crumbs-active m-0">
                VIEW ALL
              </h5>
          </Link>
        </Col>
        <Col md={7} xl={7} className="d-flex align-items-center">
          <Link
          to={{pathname: UNINVOICED.replace(':id', userId),
          state: { sortBy: SORT_BY.USER.value }}}>
              <h5 className="page-title bread_crumbs-active m-0">
                UNINVOICED
              </h5>
          </Link>
        </Col>
        <Col md={1.5} xl={1.5} className="p-0 ml-5 align-items-center">
          <Button color="primary"
          onClick={handleGenerateInvoices}
          >
          Generate Invoice
          </Button>
        </Col>
      </Col>
    </Col>
    </Row>
  )
}

export default RecentInvoicesTab
