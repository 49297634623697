import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse, } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../../redux/services/auth";
import NamePicture from "../../../shared/components/NamePicture";


const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
    }

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    render() {
        const { collapse } = this.state;
        const { auth, history } = this.props;
        const { user } = auth;
        return (
            <div className="topbar__profile">
                <button className="topbar__avatar" type="button" onClick={this.toggle}>
                    {user.avatar_url
                        ? <img className="topbar__avatar-img"
                            src={user.avatar_url ? `${user.avatar_url}?${new Date().getTime()}` : Ava}
                            alt="avatar" />
                        : <div className="topbar__avatar-img">
                            <NamePicture first_name={user.first_name} surname={user.surname} />
                        </div>}
                    <p className="topbar__avatar-name">{`${user.first_name} ${user.surname}`}</p>
                    <DownIcon className="topbar__icon" />
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
                <Collapse isOpen={collapse} className="topbar__menu-wrap">
                    <div className="topbar__menu">
                        <TopbarMenuLink
                            title="Log Out"
                            icon="exit"
                            path="/"
                            onClick={() => {
                                this.toggle();
                                logoutUser(history);
                            }}
                        />
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default withRouter(connect(({ auth }) => ({
    auth,
}))(TopbarProfile));
