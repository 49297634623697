import React, {useEffect, useState} from "react"
import { reduxForm, Field, change } from 'redux-form';
import { Row, Button } from 'reactstrap';
import HourlyFieldMaterial from "../../../shared/components/material_field/HourlyFieldMaterial"
import { ADDITIONAL_WORK_REQUEST_STATUS } from "../../../constants/enamerations"
import DayWorkRejectedForm from "../../Accounts/Forms/DayWorkRejectedForm";
import Loading from "../../../shared/components/Loader"

const AdditionalWorkForm = ({ currentRequest, assets, dispatch, updateRequest, handleSubmit, setModal, load }) => {
    const [switchModal, setSwitchModal] = useState(1);

    useEffect(() => {
        currentRequest && currentRequest.budget && dispatch(change('additional_work_form', 'amount', `£ ${currentRequest.budget}`))
    }, [currentRequest]);

    const Approve = (data) => updateRequest(ADDITIONAL_WORK_REQUEST_STATUS.COMPLETED.value, data.amount, '');
    const Rejected = (data) => updateRequest(ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value, 0, data.deny_comment);

    const showButtons = (currentRequest && currentRequest.status !== ADDITIONAL_WORK_REQUEST_STATUS.COMPLETED.value &&
        currentRequest.status !== ADDITIONAL_WORK_REQUEST_STATUS.REJECTED.value);

    return (
        load ?
            <Loading/> :
            (switchModal === 1 ?
                <form onSubmit={handleSubmit(data => Approve(data))}>
                    <Row className="m-0 mt-5">
                        <div className="img_question_wrapper-collapse">
                            {assets && assets.map((item, index) => (
                                <img key={index} src={item.public_url} alt={`#${index}`} />
                            ))}
                        </div>
                    </Row>
                    <div style={{ marginRight: '0px' }} className="description_question_wrapper mb-3">
                        <p className="form__form-group-label m-0">{currentRequest && currentRequest.description}</p>
                    </div>

                    {
                    showButtons &&
                    <>
                    <div className="form__form-group mb-4">
                        <div className="form__form-group-field d-flex justify-content-between">
                            <div className="create_project_field_wrapper">
                                <Field
                                    name="amount"
                                    component={HourlyFieldMaterial}
                                    placeholder="Amount"
                                    label="Amount"
                                />
                            </div>
                        </div>
                    </div>

                    
                    <div className="d-flex d-flex justify-content-end">
                        <Button className="m-0 mr-3" color="secondary" onClick={() => setSwitchModal(2)}>Deny</Button>
                        <Button className="m-0" color="primary" type="submit">Approve</Button>
                    </div>
                    </>
                    }
                    
                </form>
                : <DayWorkRejectedForm Rejected={Rejected} setModal={setModal} />
            )
    )
}

export default reduxForm({
    form: 'additional_work_form'
})(AdditionalWorkForm);