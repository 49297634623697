import React, { useEffect } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import store from "../../../../redux/store";
import MiniNumberField from "./MiniNumberField";
import { submit } from "redux-form";

const requiredNumber = (value) => {
  if (value) {
    if (value < 0) {
      return "Value must be a positive number";
    }
    return undefined;
  } else {
    return "You must enter a value";
  }
};

let EditBudgetForm = ({ handleSubmit, budget }) => {
  function initializeEditBudgetForm() {
    store.dispatch(
      initialize("edit_budget_form", {
        budget,
      })
    );
  }

  useEffect(() => {
    initializeEditBudgetForm();
  }, []);

  return (
    <div>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
          event.stopPropagation();
        }}
      >
        <Field
          name={`budget`}
          component={MiniNumberField}
          type={"number"}
          validate={requiredNumber}
          onBlur={() => {
            store.dispatch(submit("edit_budget_form"));
          }}
        />

        <button type="submit" hidden></button>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "edit_budget_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(EditBudgetForm);
