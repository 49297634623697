import {
  getJobsByUserAsync,
  getJobsByProjectAsync,
  getJobsByIdAsync,
  uploadFilePlanAsync,
  uploadPhotoPlanAsync,
  getPhotoPlanAsync,
  applyPhotoToJobsAsync,
  getRequestsAsync,
  updateRequestStatusAsync,
  deleteJobAsync,
  getActiveJobsByUserAsync,
  addJobToProjectAsync,
  resetJobAsync,
  getUninvoicedJobsAsync,
  exportJobCsvAsync,
  exportBudgetVariationCsvAsync,
  deleteManyJobsAsync,
} from "../api/jobs";

export const getJobsByUser = async (id, filter, pagination) =>
  await getJobsByUserAsync(id, filter, pagination);
export const getJobsByProject = async (pagination, filters) =>
  await getJobsByProjectAsync(pagination, filters);
export const getJobsById = async (id, data) => await getJobsByIdAsync(id, data);
export const uploadFilePlan = async (type, file) =>
  await uploadFilePlanAsync(type, file);
export const uploadPhotoPlan = async (url, file) =>
  await uploadPhotoPlanAsync(url, file);
export const applyPhotoToJobs = async (type, file, ids) =>
  await applyPhotoToJobsAsync(type, file, ids);
export const getPhotoPlan = async (id, type) =>
  await getPhotoPlanAsync(id, type);
export const getRequests = async (id, includeAssets) =>
  await getRequestsAsync(id, includeAssets);
export const updateRequestStatus = async (id, status, amount, comment) =>
  await updateRequestStatusAsync(id, status, amount, comment);
export const deleteJob = async (jobId) => await deleteJobAsync(jobId);
export const deleteManyJobs = async(jobIds) => await deleteManyJobsAsync(jobIds);
export const resetJob = async(jobId, conditions) => await resetJobAsync(jobId, conditions)
export const getActiveJobsByUser = async (id) =>
  await getActiveJobsByUserAsync(id);
export const addJobToProject = async (data) => await addJobToProjectAsync(data);
export const getUninvoicedJobs = async (id, page) => await getUninvoicedJobsAsync(id, page);
export const exportJobCsv = async(data) => await exportJobCsvAsync(data)
export const exportBudgetVariationCsv = async(data) => await exportBudgetVariationCsvAsync(data)
