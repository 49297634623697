import axios from "axios";
import { BASE_API } from "../../config/api";

export const getOperativeTemplatesAsync = async () =>
  await axios
    .get(`${BASE_API}template/getOperativeTemplate`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveOperativeTemplatesAsync = async (formValue) =>
  await axios
    .post(`${BASE_API}template/saveOperativeTemplate/11`, {
      operativeTemplate: formValue,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getQATemplatesAsync = async () =>
  await axios
    .get(`${BASE_API}template/getQATemplate`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveQATemplatesAsync = async (formValue) =>
  await axios
    .post(`${BASE_API}template/saveQATemplate/11`, {
      qaTemplate: formValue,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const getJobTemplatesAsync = async () =>
  await axios
    .get(`${BASE_API}template/getJobTemplate`)
    .then((response) => response && response.data)
    .catch((error) => error.message);

export const saveJobTemplatesAsync = async (formValue) =>
  await axios
    .post(`${BASE_API}template/saveJobTemplate/11`, {
      jobTemplate: formValue,
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);
