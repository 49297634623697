import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import Loading from "../../../shared/components/Loader";
import { getJobsByUser } from "../../../redux/services/jobs";
import { BuildIcon } from "../../../constants/icons";
import { WORK_TYPE, JOB_STATUS } from "../../../constants/enamerations";
import { pageSize } from "../../../config/pagination";
import HistoryJobsFilterForm from "../Forms/HistoryJobsFilterForm";
import ActiveJobsFilterForm from "../Forms/ActiveJobsFilterForm";
import { getProjectsByUser } from "../../../redux/services/projects";
import Pagination from "@material-ui/lab/Pagination";
import {PREVIEW_JOB} from "../../../constants/routes";


const JobsTable = ({ match, status, form,onJobInvoicePressed }) => {
  const [projects, setProjects] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    job_status: status && status,
    job_type: [],
  });

  useEffect(() => {
    status[0] === JOB_STATUS.COMPLETED.value &&
      getProjectsByUser().then((res) => {
        setProjects(res);
      });
  }, []);

  useEffect(() => {
    setLoad(true);
    getJobsByUser(+match.params.id, filter, { page, pageSize }).then((res) => {
      setJobs(res);
      setLoad(false);
    });
  }, [page, filter]);

  return (
    <>
      <div className="mt-3">
        {status[0] !== JOB_STATUS.COMPLETED.value ? (
          <ActiveJobsFilterForm filter={filter} setFilter={setFilter} />
        ) : (
          <HistoryJobsFilterForm
            filter={filter}
            setFilter={setFilter}
            projects={projects}
            jobs={jobs}
            f={form}
            status={status}
          />
        )}
      </div>
      {load ? (
        <Loading />
      ) : jobs && jobs.items && jobs.items.length !== 0 ? (
        <Table
          responsive
          hover
          className="mt-1 table react-table table--bordered pl-0"
        >
          <thead>
            <tr>
              <th>TRADE</th>
              <th>NAME</th>
              <th>STATUS</th>
              <th>PROJECT</th>
              <th>BLOCK</th>
              <th>LEVEL</th>
              <th>PLOT</th>
              <th>RATE</th>
              <th>INVOICE ID</th>
            </tr>
          </thead>
          <tbody>
            {jobs.items.map((item, index) => {
              const fullUrl = PREVIEW_JOB.replace(':project_id', item.project_id).replace(':id', item.id)
             return (<tr key={index}>
                <td><a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.job_type && WORK_TYPE[item.job_type].bigIcon}</a></td>
                <td><a  href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.description && item.description}</a></td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    {item.status && JOB_STATUS[item.status].icon} &nbsp;{" "}
                    {item.status && JOB_STATUS[item.status].label}
                  </a>
                </td>
                <td>
                  <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>
                    <BuildIcon /> &nbsp;{" "}
                    {item.project && item.project.name && item.project.name}
                  </a>
                </td>
                <td> <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.block && item.block.name && item.block.name}</a></td>
                <td> <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.level && item.level.name && item.level.name}</a></td>
                <td> <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>{item.plot && item.plot.name && item.plot.name}</a></td>
                <td> <a href={fullUrl} className={'table-link'} style={{color: 'black'}}>₤{item.rate && item.rate}</a></td>
                <td>{!!item.InvoiceItem && !!item.InvoiceItem.invoice_id ?  <div onClick={() => onJobInvoicePressed(item.InvoiceItem.invoice_id)} style={{color: '#70bbfd'}}>
                  {`#${item.InvoiceItem.invoice_id}`}
                </div> : '' }</td>
              </tr>)
            }
            )}
          </tbody>
        </Table>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <p>Not found</p>
        </div>
      )}
      {jobs && Math.ceil(jobs.total / pageSize) >= 2 && (
        <div className="box-pagination">
          <Pagination
            count={jobs && jobs.total ? Math.ceil(jobs.total / pageSize) : 0}
            page={page + 1}
            onChange={(event, page) => setPage(page - 1)}
          />
        </div>
      )}
    </>
  );
};

function mapStateToProps({ form }) {
  return { form };
}

export default withRouter(
  connect(mapStateToProps)(JobsTable)
);
