import React from 'react'
import { Modal } from 'reactstrap';
import { editUser } from "../../../redux/services/users";
import { useValidateEditUser } from '../../../utils/hooks/PreviewUserHooks';
import UserEdit from "../Forms/UserEditForm";
import { SubmissionError } from "redux-form";

function EditAccountModal({
  user,
  modal,
  toggleModal,
  form,
  match,
  getUser,
  setErrorMessage,
  hasActiveJobs,
  errorMessage
}) {


  const goEditUser = (data) => {
    let {error, isError} = useValidateEditUser(user, data, hasActiveJobs);
    data.phone = data.phone.replace(/\s/g, "");

    if (isError) throw new SubmissionError(error)
    else {
        if (!data.is_vat_registered) data.vat_number = "-";

        delete data.is_vat_registered;

        editUser(+match.params.id, data)
        .then(res => {
          if(!res) return setErrorMessage(res)
          getUser();
          toggleModal();
        });
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      modalClassName={`ltr-support`}
      className={`modal-dialog--primary locum-preview-modal`}
    >
      <div className="modal__header">
        <h3 className="page-title">Edit account</h3>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleModal}
        />
      </div>
      <div className="modal__body">
        <UserEdit
          initialValues={{
            sage_reference: user.data.sage_reference,
            first_name: user.data.first_name,
            surname: user.data.surname,
            street: user.data.street,
            house_number: user.data.house_number,
            city: user.data.city,
            post_code: user.data.post_code,
            phone: user.data.phone,
            email: user.data.email,
            company_name: user.data.company_name,
            sub_contractor_type: user.data.sub_contractor_type,
            show_prices: user.data.show_prices,
            cis_status: user.data.cis_status,
            hourly_rate: user.data.hourly_rate,
            utr: user.data.utr,
            is_vat_registered: user.data.vat_number === "-" ? false : true,
            vat_number:
              user.data.vat_number === "-" ? "" : user.data.vat_number,
            role: user.data.role,
          }}
          f={form}
          user={user}
          toggleModal={toggleModal}
          goEditUser={goEditUser}
          errorMessage={errorMessage}
        />
      </div>
    </Modal>
  )
}

export default EditAccountModal
