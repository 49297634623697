import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { BuildIcon } from '../../../constants/icons'
import ExpandProjectButton from './ExpandProjectButton'
import ProjectGroupExpanding from './ProjectGroupExpanding'
import {formatPrice} from "../../../helpers/function";

function ProjectRow({
  index,
  item,
  href,
  status,
  expandProject,
  isGroupProjectVisibileRef
  })
  {
  const [isGroupExpanded, setIsGroupExpanded ] = useState(false);
  const projectHasGroup = false;

  const fullUrl = href.toString().replace(':id', item.id).replace(':status', status)

  useEffect(()=>{
    setIsGroupExpanded(isGroupProjectVisibileRef)
  },[isGroupProjectVisibileRef])

  return (
    <>
    <tr key={index}>
      <td className="td-project">
        <a href={fullUrl} className={'table-link'} style={{color: '#333e48'}}>
          <div className="d-flex">
            <div>
              <BuildIcon /> &nbsp; {item.name}
            </div>
            {projectHasGroup && <ExpandProjectButton expandProject={expandProject}/>}
          </div>
        </a>
      </td>
      <td>
        <a href={fullUrl} className={'table-link'} style={{color: '#333e48'}}>
          ₤{item.budget && item.budget}
        </a>
      </td>
      <td>
        <a href={fullUrl} className={'table-link'} style={{color: '#333e48'}} >
          {formatPrice(item.spent_budget ? item.spent_budget : 0)}
        </a>
      </td>
      <td>
        <a href={fullUrl} className={'table-link'} style={{color: '#333e48'}}>
          <Moment format="DD-MM-YYYY">
            {item.created_at && item.created_at}
          </Moment>
        </a>
      </td>
      <td>
        <a href={fullUrl} className={'table-link'} style={{color: '#333e48'}}>
          <Moment format="DD-MM-YYYY">
            {item.deadline && item.deadline}
          </Moment>
        </a>
      </td>
    </tr>
    {isGroupExpanded && <ProjectGroupExpanding/>}
    </>
  )
  }

export default ProjectRow
