import React from "react";
import { Input } from "@material-ui/core";

const ColorFieldMaterial = ({
  input: { value, onChange },
  label,
  placeholder,
  type,
  meta: { touched, error },
}) => {
  const handleChange = (value) => {
    onChange(value);
  };

  const getValue = (value) => {
    return value;
  };

  return (
    <>
      <Input
        value={getValue(value)}
        onChange={handleChange}
        type={type ? type : "text"}
        style={{
          width: "100%",
        }}
        inputProps={{
          style: { width: "100%", height: "25px", padding: 0 },
        }}
      />
      {touched && error && (
        <span className="form__form-group-error">{error}</span>
      )}
    </>
  );
};

export default ColorFieldMaterial;
