import {
    LOGIN_USER,
    LOGOUT_USER,
    AUTH_LOADING,
    AUTH_ERROR,
    SWITCH_ROLE,
    UPDATE_USER,
    GET_USER_DOCUMENTS,
} from "../actions";

export const authLoadingAction = boolean => ({
    type: AUTH_LOADING,
    payload: boolean,
});

export const loginUserAction = user => ({
    type: LOGIN_USER,
    payload: user,
});


export const logoutUserAction = () => ({
    type: LOGOUT_USER,
});

export const authErrorAction = error => ({
    type: AUTH_ERROR,
    payload: error,
});

export const switchRoleAction = role => ({
    type: SWITCH_ROLE,
    payload: role,
});

export const updateUserAction = user => ({
    type: UPDATE_USER,
    payload: user,
});

export const getUserDocumentsAction = documents => ({
    type: GET_USER_DOCUMENTS,
    payload: documents
});
