import React, { useState } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import ModalStepTwo from "../Forms/CreateProjectStepTwoForm";
import ModalStepOne from "../Forms/ProjectCreateStepOneForm";
import {
  projectCreate,
  uploadFileProject,
  uploadCSVProject,
} from "../../../redux/services/projects";
import {
  patternDate,
} from "../../../config/validationRules";

const index = ({ handleSubmit, toggle }) => {
  const [step, setStep] = useState(1);
  const [uploadStatus, setStatusUpload] = useState(null);
  const [loadUploadFile, setLoadUploadFile] = useState(false);

  const nextStep = () => setStep(2);

  const getUploadFile = (file) => {
    setLoadUploadFile(true);
    uploadFileProject(file.name).then((res) =>
      uploadCSVProject(res.data.signed_url, file).then(() => {
        setStatusUpload(file);
        setLoadUploadFile(false);
      })
    );
  };

  const createProject = (data) => {
    let error = {};
    let isError = false;

    if (!data.budget) {
      error.budget = "Budget field shouldn`t be empty";
      isError = true;
    }
    if (!data.deadline) {
      error.deadline = "Deadline field shouldn`t be empty";
      isError = true;
    }

    if (data.deadline && !patternDate.test(data.deadline)) {
      error.deadline = "Should be DD-MM-YYYY";
      isError = true;
    }
    if (!data.source) {
      error.source = "Select project file";
      isError = true;
    }

    if (isError) {
      throw new SubmissionError(error);
    } else if (!isError) {
      const req = { ...data };
      req.budget = req.budget.replace(" ", "");
      req.budget = req.budget.replace("£", "");

      projectCreate({ ...req });
      toggle();
    }
  };

  return (
    <form onSubmit={handleSubmit(createProject)}>
      {step === 1 && (
        <ModalStepOne
          nextStep={nextStep}
          toggle={toggle}
          getUploadFile={getUploadFile}
          uploadStatus={uploadStatus}
          loadUploadFile={loadUploadFile}
        />
      )}
      {step === 2 && <ModalStepTwo toggle={toggle} />}
    </form>
  );
};
export default reduxForm({
  form: "project_create_form",
})(index);
