export const maxUploadFileSize = 4000000;
export const patternDate = /^\s*(3[01]|[12][0-9]|0?[1-9])-(1[012]|0?[1-9])-((?:19|20)\d{2})\s*$/;
export const unixTime = 2678400; //One month = 2678400s
export const patternPhoneEdit = /^\d{3}\d{4}\d{4}$/;
export const patternPhoneCreate = /^\d{3}\d{4}\d{4}$/;
export const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const billText = {
  row1: "London MEP,",
  row2: "VAT No. 268 2760 74",
};
export const timeFormat = "HH:mm";
