import React from "react"
import { Progress } from 'reactstrap';

const MultiLinearProgressBar = ({ one, two, oneValue, twoValue }) => {
    return (
        <Progress multi>
            {!isNaN((oneValue * 100) / (oneValue + twoValue)) && <Progress bar color={one} value={(oneValue * 100) / (oneValue + twoValue)}>{oneValue}</Progress>}
            {!isNaN((oneValue * 100) / (oneValue + twoValue)) && <Progress bar color={two} value={(twoValue * 100) / (oneValue + twoValue)}>{twoValue}</Progress>}
        </Progress>
    )
}

export default MultiLinearProgressBar