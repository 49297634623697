import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import { reduxForm, FieldArray } from "redux-form";
import { FlatOptionsIcon } from "../../../constants/icons";
import EditFlatForm from "./components/EditFlatForm";
import store from "../../../redux/store";

function sumOfFlats(block) {
  var sum = 0;

  for (let i = 0; i < block.levels.length; i++) {
    sum += block.levels[i].flats.length;
  }

  return sum;
}

const renderBlocks = ({ fields, saveProject }) => {
  const [everyFlatIsAssigned, setEveryFlatIsAssigned] = useState(false);
  const [noOfAllFlats, setNoOfAllFlats] = useState(0);

  const checkNoOfFlats = () => {
    const quantity = fields
      .getAll()
      .map((block) => parseInt(block.quantity))
      .reduce((prev, next) => prev + next);

    const noOfFlats = fields
      .getAll()
      .map((block) =>
        block.levels
          .map((level) => parseInt(level.flats.length))
          .reduce((prev, next) => prev + next)
      )
      .reduce((prev, next) => prev + next);

    setNoOfAllFlats(noOfFlats);

    if (quantity === noOfFlats) {
      setEveryFlatIsAssigned(true);
    } else {
      setEveryFlatIsAssigned(false);
    }
  };

  useEffect(() => {
    checkNoOfFlats();
  }, []);

  useEffect(() => {
    checkNoOfFlats();
  }, [fields]);

  return (
    <>
      <Row md={12} className="justify-content-between">
        <Col md={4}>
          <h2>Add flats</h2>
        </Col>

        <div className="buttonContainer">
          <Col md={6}>
            <Button className="button" onClick={saveProject}>
              Save
            </Button>
          </Col>

          <Col md={6} className={"justify-content-end d-flex"}>
            <Button
              type={"submit"}
              className="text-nowrap button"
              disabled={!everyFlatIsAssigned}
            >
              Next
            </Button>
          </Col>
        </div>
      </Row>

      <Row md={12} className={"mt-3"}>
        <Col md={12}>
          {fields.map((block, index) => (
            <div key={index}>
              <div className="blockContainer">
                <div className="levelLetterContainer">
                  {fields.get(index).id}
                </div>

                <div>Block </div>

                <div className="flatLimit">
                  ({sumOfFlats(fields.get(index))}/{fields.get(index).quantity})
                </div>
              </div>
              <FieldArray
                name={`${block}.levels`}
                component={renderLevels}
                // rerenderOnEveryChange={true}
                key={index}
                props={{
                  flatsCanBeAdded:
                    sumOfFlats(fields.get(index)) !==
                    parseInt(fields.get(index).quantity),
                  noOfAllFlats,
                }}
              />
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

const renderLevels = ({ fields, flatsCanBeAdded, noOfAllFlats }) => {
  return (
    <Col className="align-items-center">
      {fields.map((level, index) => (
        <Row md={12} className="flex-nowrap" key={index}>
          <div className="levelsContainer">
            <div className="levelContainer flatLevel">
              <div className="levelLetterContainer">
                {fields.get(index).level}
              </div>

              <div>Level {fields.get(index).level}</div>
            </div>
          </div>
          <FieldArray
            name={`${level}.flats`}
            component={renderFlats}
            type={"text"}
            // rerenderOnEveryChange={true}
            props={{ flatsCanBeAdded, noOfAllFlats }}
          />
        </Row>
      ))}
    </Col>
  );
};

const renderFlats = ({ fields, flatsCanBeAdded, noOfAllFlats }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const editFlat = (data, index) => {
    const flat = fields.get(index);
    const oldFlatId = flat.flatId;
    flat.flatId = data.flatId;

    fields.splice(index, 1, flat);

    // Modify the unit id in the allocated jobs too
    if (store.getState().form.create_project_form_wizard.values.allocatedJobs) {
      const allocatedJobs = store.getState().form.create_project_form_wizard
        .values.allocatedJobs;

      allocatedJobs.forEach((element) => {
        if (element.unitId === oldFlatId) {
          element.unitId = data.flatId;
        }
      });
    }
  };

  return (
    <Row className=" flatRowContainer flex-nowrap">
      {fields.map((flat, index) => (
        <div className="flatContainer" key={index}>
          {isEditing && currentIndex === index ? (
            <EditFlatForm
              flatId={fields.get(index).flatId}
              onSubmit={(data) => {
                editFlat(data, index);
                setIsEditing(false);
              }}
            />
          ) : (
            fields.get(index).flatId
          )}
          <UncontrolledDropdown className="flatOptionsIcon">
            <DropdownToggle className="flatDropdownToggle" tag="span">
              <FlatOptionsIcon />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setIsEditing(true);
                  setCurrentIndex(index);
                }}
              >
                <div>Rename</div>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <div>Delete</div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      ))}
      <Col md={1} className="addFlatButtonContainer">
        <Button
          onClick={() => {
            fields.push({
              flatId: `${noOfAllFlats + 1}`,
            });
          }}
          className={"addFlatButton"}
          disabled={!flatsCanBeAdded}
        >
          +
        </Button>
      </Col>
    </Row>
  );
};

let CreateProjectStepFive = (props) => {
  const { handleSubmit, saveProject } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Container className="m-0">
        <FieldArray
          name="blocks"
          component={renderBlocks}
          rerenderOnEveryChange={true}
          props={{
            saveProject,
          }}
        />
      </Container>
    </form>
  );
};

CreateProjectStepFive = reduxForm({
  form: "create_project_form_wizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CreateProjectStepFive);

export default CreateProjectStepFive;
