import {
  patternPhoneEdit,
  patternEmail,
} from "../../config/validationRules.js";

export function useValidateEditUser(user, data, hasActiveJobs){
  let error = {};
  let isError = false;

  if (!data.first_name) {
    error.first_name = "First name field shouldn`t be empty";
    isError = true;
  }

  if (!data.surname) {
    error.surname = "Surname field shouldn`t be empty";
    isError = true;
  }

  if (!data.phone || !data.phone.trim()) {
    error.phone = "Phone field shouldn’t be empty";
    isError = true;
   } else if (!patternPhoneEdit.test(data.phone.trim().replace(/\s+/g,''))) {
    error.phone = "Phone number should be a format xxx xxxx xxxx";
    isError = true;
  }

  if (!data.email) {
    error.email = "Email field shouldn’t be empty";
    isError = true;
  } else if (!patternEmail.test(data.email)) {
    error.email = "Invalid email address";
    isError = true;
  }

  if (!data.sub_contractor_type) {
    error.sub_contractor_type =
      "Sub-Contractor Type field shouldn`t be empty";
    isError = true;
  }

  if (!data.hourly_rate) {
    error.hourly_rate = "Hourly rate field shouldn`t be empty";
    isError = true;
  }

  if (!data.vat_number && data.is_vat_registered) {
    error.vat_number = "VAT number field shouldn’t be empty";
    isError = true;
  }

  if (!data.utr || !data.utr.trim()) {
    error.utr = "Utr rate field shouldn`t be empty";
    isError = true;
  }

  if (hasActiveJobs && user.data.role !== data.role) {
    error.role =
      "The role cannot be changed while user still has jobs to be completed";
    isError = true;
  }

  return {error, isError}
}
