import React, { useEffect, useState, useRef } from "react";
import {Link, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import {
  DeleteIcon,
  BuildIcon,
  MoneyIcon,
  PrintIcon,
  InactivePrintIcon,
  EditIcon,
  ResetIcon,
} from "../../../constants/icons";
import RequestsSteps from "./RequestsSteps";
import StepForPrint from "./StepsForPrint";
import OperativeForPrint from "./OperativeForPrint";
import { getJobsById, getRequests } from "../../../redux/services/jobs";
import {
  JOB_STATUS,
  WORK_TYPE,
  JOB_PLAN,
} from "../../../constants/enamerations";
import Loading from "../../../shared/components/Loader";
import AccountInfo from "../../Accounts/UserPreview/AccountInfo";
import ReactToPrint from "react-to-print";
import { getPhotoPlan } from "../../../redux/services/jobs";
import StepCard from "./StepCard";
import PhotoViewer from "../../../shared/components/PhotoViewer";
import { setTimeOutID, setStatus } from "../../../redux/actions/photo_load";
import { createStepOrQuestion, deleteStepOrQuestion, editStepOrQuestion, editJobRateOrDescription } from "../../../redux/api/edit_field";

import BudgetVariations from "./BudgetVariations";
import BudgetVariationsPrint from "./BudgetVariationsPrint";
import DeleteJobModal from "./DeleteJobModal";
import ResetJobModal from "./ResetJobModal";
import JobHistoryTable from "../../Projects/Tables/JobHistory/JobHistoryTable";
import {getInvoiceById} from "../../../redux/services/invoice";
import InvoiceModal from "../../Invoices/invoiceModal";
import {getProjectsById} from "../../../redux/services/projects";
import {CONTRACTS, JOBS, PREVIEW_CONTRACT, PREVIEW_PROJECT} from "../../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";

const PreviewJob = ({
  match,
  history,
  setStatus,
  setTimeOutID,
  photo_load,
}) => {
  const [job, setJob] = useState(null);
  const [load, setLoad] = useState(false);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [steps, setSteps] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [requests, setRequsts] = useState(null);
  const [deleteJobModal, setDeleteJobModal] = useState(false);
  const [resetJobModal, setResetJobModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const componentRef = React.createRef();
  const budgetComponentRef = React.createRef();
  const componentOperativeRef = React.createRef();

  const [editableFieldStyling, setEditableFieldStyling] = useState({})
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [loadInvoiceModal, setLoadInvoiceModal] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [project, setProject] = useState(null);

  let jobDescription = useRef("");
  let jobRate = useRef("");

  const toggleInvoiceModal = () => {
    setInvoiceModalOpen(!invoiceModalOpen);
  };

  const getInvoiceId = (id) => {
    setLoadInvoiceModal(true);
    getInvoiceById(id).then((res) => {
      setInvoiceItem(res);
      setLoadInvoiceModal(false);
    });
  };

  const getProject = () => {
    getProjectsById(+match.params.project_id).then((res) => {
      setProject(res.data);
    });
  };

  useEffect(() => {
    getProject()
  }, []);


  const [pageEditable, setPageEditable] = useState(false);


  // this is an object, the frontend state should be updated once this object updates
  // addToEditedField function updates this object
  // when this object changes, the local Storage is reset and copies
  // the new object in order to make changes persist through state changes
  const startingFields = {
    STEP:{
      added:[],
      deleted:[],
      edited:[],
    },
    QUESTION:{
      added:[],
      deleted:[],
      edited:[],
    },
    DESCRIPTION:{
      edited:"",
    },
    RATE:{
      edited:"",
    }
  }
  const [fields, setFields] = useState (startingFields)

  const fetch = () => {
    clearTimeout(null);
    setStatus(false);
    getJob();
    getRequestsByJob();
  }

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    localStorage.removeItem('fields');
    localStorage.setItem('fields', JSON.stringify(fields))
  },[fields])

  useEffect(()=>{
    if(pageEditable) {
      setEditableFieldStyling({
        border:"2px dashed black",
        borderRadius:"0.5rem",
        padding:"1%"
      })

      setFields(startingFields)
    }
    else {
      setEditableFieldStyling({})
    }
  },[pageEditable])

  const toggle = () => setViewerIsOpen(!viewerIsOpen);

  const toggleJobDeletionModal = () => setDeleteJobModal(currentValue => !currentValue);

  const toggleJobResetModal = () => setResetJobModal(currentValue => !currentValue);

  const getJob = () => {
    setLoad(true);
    getJobsById(match.params.id, true).then((res) => {
      setJob(res.data);
      setSteps(res.data.steps.sort((a, b) => a.order - b.order));
      setQuestions(res.data.questions);
      setLoad(false);
    });
  };

  const getRequestsByJob = () => {
    getRequests(match.params.id, true).then((res) => setRequsts(res.items));
  };

  const viewPhotoPlan = (type) => {
    getPhotoPlan(match.params.id, type).then((res) => {
      if (res.data && res.data.public_url) {
        setPhotos([{ src: res.data.public_url, width: 4, height: 3 }]);
        toggle();
      }
    });
  };

  const closeLightbox = () => {
    setViewerIsOpen(false);
    setCurrentImage(0);
  };


  const photoLoader = () => {
    clearTimeout(photo_load.timeID);
    let timerId = setTimeout(() => setStatus(true), 10000);
    setTimeOutID(timerId);
  };

  // sets the pageEditable variable on true, which allows the user to edit different fields
  // the fields have contentEditable attribute which takes the value of pageEditable
  // when pageEditable is true, the content is editable.
  const makePageEditable = () => {
    setPageEditable(true);
  }

  // this function adds each modified field in it's category.
  // for example, if you edit a STEP, it will push that element into STEP -> edited;
  // those edited fields are iterated and the changes are sent to the database when
  // 'Save changes' button is pressed.
  const addToEditedField = (item, typeOfField, action) => {
  // pushing changes to fields object, that should update the state of the page
    if(action==='deleted' && !item.id){  // if an item has no id that means it just has been added. if the delete button is pressed, we delete the item from "added" field
      fields[typeOfField].added.map(element=>{
        if (typeOfField === "STEP"){
          if(element.item.description === item.item.description){
            // delete step from fields added array
            const filteredAddedArray = fields[typeOfField].added.filter(filteredItem => JSON.stringify(filteredItem) !== JSON.stringify(element));
            setFields( currentFields => {
              return {
                ...currentFields,
                [typeOfField]:{
                  ...currentFields[typeOfField],
                  added: filteredAddedArray
                }
              }
            })
          }
        }
        if (typeOfField === "QUESTION"){
          if(element.item === item.item){
            // delete question from fields added array
            const filteredAddedArray = fields[typeOfField].added.filter(filteredItem => JSON.stringify(filteredItem) !== JSON.stringify(element));
            setFields( currentFields => {
              return {
                ...currentFields,
                [typeOfField]:{
                  ...currentFields[typeOfField],
                  added: filteredAddedArray
                }
              }
            })
          }
        }
        return true
      })
      return false;
    }
    setFields( currentFields => {
    return {
    ...currentFields,
      [typeOfField]: {
        ...currentFields[typeOfField],
        [action]:[
          ...currentFields[typeOfField][action],
          {item, typeOfField}
        ]
      },
    }
   })

   localStorage.removeItem('fields')
   localStorage.setItem('fields', JSON.stringify(fields))

  }

  // checkForChanges function is checking if the job rate or job description were modified
  // and if they were edited, it pushes the change to the fields object and further to localstorage
  const checkForChanges = (fieldValue, typeOfField) => {
    if(job.description || job.rate){
      if((typeOfField === "DESCRIPTION" && fieldValue !== job.description)
      || (typeOfField === "RATE" && parseFloat(fieldValue) !== parseFloat(job.rate))){
        setFields( currentFields => {
          return {
          ...currentFields,
            [typeOfField]: {
              edited:fieldValue
            },
          }
         })
      }

    }
  }

  // this function iterates through localStorage object named 'fields' in order to query all the changes
  const saveChanges = () => {
    //sending queries for edited steps and questions
    const fieldsFromStorage = JSON.parse(localStorage.getItem('fields'));

    if(fieldsFromStorage.STEP.edited.length || fieldsFromStorage.QUESTION.edited.length){
      for(let i=0; i<fieldsFromStorage.STEP.edited.length;i++){
        editStepOrQuestion(fieldsFromStorage.STEP.edited[i])
      }
      for(let i=0; i<fieldsFromStorage.QUESTION.edited.length;i++){
        editStepOrQuestion(fieldsFromStorage.QUESTION.edited[i])
      }
    }

    // sending queries for added steps and questions
    if(fieldsFromStorage.STEP.added.length || fieldsFromStorage.QUESTION.added.length){
    for(let i=0; i<fieldsFromStorage.STEP.added.length;i++){
      createStepOrQuestion(fieldsFromStorage.STEP.added[i], job.id, job.steps.length + i + 1)
    }
    for(let i=0; i<fieldsFromStorage.QUESTION.added.length;i++){
      createStepOrQuestion(fieldsFromStorage.QUESTION.added[i], job.id, job.questions.length + i + 1)
    }
    }

    //sending queries for deleted steps and questions
    if(fieldsFromStorage.STEP.deleted.length || fieldsFromStorage.QUESTION.deleted.length){
      for(let i=0; i<fieldsFromStorage.STEP.deleted.length;i++){
        deleteStepOrQuestion(fieldsFromStorage.STEP.deleted[i], "STEP")
      }
      for(let i=0; i<fieldsFromStorage.QUESTION.deleted.length;i++){
        deleteStepOrQuestion(fieldsFromStorage.QUESTION.deleted[i], "QUESTION")
      }
    }

    // if the description is changed, the change will be pushed in
    // fieldsFromStorage.DESCRIPTION.edited.length, so if this length is > 0
    // then we should query the changes to the database
    if(fieldsFromStorage.DESCRIPTION.edited.length > 0) editJobRateOrDescription(fieldsFromStorage.DESCRIPTION.edited, job.id, "DESCRIPTION")
    // same with the rate
    if(fieldsFromStorage.RATE.edited.length > 0) {
      // TODO: make formatting
      fieldsFromStorage.RATE.edited = parseInt('' + parseFloat(fieldsFromStorage.RATE.edited) * 100) / 100;
      console.log(fieldsFromStorage.RATE.edited);
      editJobRateOrDescription(fieldsFromStorage.RATE.edited, job.id, "RATE")
    }

    // exit out of edit mode
    // by setting pageEditable on false
    setPageEditable(false);

    // clear local storage object that contains the edited fields
    // when user Save the changes, we don't need the changes stored anymore
    localStorage.removeItem('fields');
    setTimeout(()=>{
      fetch();
    }, 500)
  }

  const contract = project && project.projectGroup ? project.projectGroup : null
  const projectId = project && project.id ? project.id : null
  const projectName = project && project.name ? project.name : null
  const jobId = job && job.id ? `Job #${job.id}`: ''

  return load ? (
    <Loading />
  ) : (
    <Container onLoad={() => photoLoader()}>
      <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
        <Link to={{pathname: CONTRACTS}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            Contracts
          </h3>
        </Link>
        {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            {!!contract.name ? contract.name : `Current Contract`}
          </h3>
        </Link>}
        {!!projectId && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',projectId).replace(':status', 'ALL')}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            {!!projectName ? projectName : 'Current Project'}
          </h3>
        </Link>}
        {!!projectId && <Link to={{pathname: JOBS.replace(':id',projectId).replace(':status', 'ALL')}} >
          <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
            JOBS
          </h3>
        </Link>}
        {!!jobId &&  <Link to={{pathname: ''}} >
          <h3 className={"page-subhead subhead bread_crumbs-active"}>
            {jobId}
          </h3>
        </Link>}

      </Breadcrumbs>
      {/* Page title */}
      <Row className="pr-3 d-flex justify-content-between">
        <Col md={5} xl={5} className="d-flex align-items-center mb-4">
          <Col md={1.5} xl={1.5} className="p-0">
            <h5 className="page-title m-0">Job {job && job.id}</h5>
          </Col>
          <Col md={1.5} xl={1.5} className="p-0 ml-3 d-flex align-items-center">
            {job && JOB_STATUS[job.status].icon} &nbsp;{" "}
            {job && JOB_STATUS[job.status].label}
          </Col>
        </Col>
        {job && job.status !== JOB_STATUS.COMPLETED.value && (
          <Col md={4} xl={4} className="pr-0 d-flex justify-content-end">
          <Button
            className="btn_with_img"
            color="primary"
            onClick={() => pageEditable ? saveChanges() : makePageEditable()}
            style={{
              paddingLeft:10,
              paddingRight:10,
            }}
          >
              <EditIcon
                color = "white"
              />
              {
                pageEditable
                ? "Save Changes"
                 : "Edit Job"
              }
            </Button>
            {
              !!job && (!!job.employee_id || !!job.pm_id || !!job.qa) &&
              <Button
              className="btn_with_img"
              color="primary"
              onClick={toggleJobResetModal}
              style={{
                paddingLeft:10,
                paddingRight:10,
              }}
            >
              <ResetIcon /> Reset Job
            </Button>
            }
            <Button
              className="btn_with_img"
              color="danger"
              onClick={() => toggleJobDeletionModal()}
              style={{
                paddingLeft:10,
                paddingRight:10,
              }}
            >
              <DeleteIcon /> Delete job
            </Button>
          </Col>
        )}
      </Row>

      {/* Job info */}
      <Row className="mb-4">
        <Col md={6} xl={6}>
          <Card>
            <CardBody className="h-auto">
              <Row>
                <Col
                  md={12}
                  xl={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  {job && job.job_type && WORK_TYPE[job.job_type].bigIcon}
                  <div className="w-50 ml-2 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <BuildIcon /> &nbsp; {job && job.project.name}
                    </div>
                    <span className="d-flex align-items-center">
                      <MoneyIcon /> &nbsp;
                      ₤ <p className="mb-2 p-1"
                      contentEditable={pageEditable}
                      ref={jobRate}
                      style={editableFieldStyling}
                      onKeyDown={(event) => {
                        //Current Value
                        const currentValue = jobRate.current.innerText

                        // Get the pressed key
                        const keyPressed = event.key;

                        // Prevent the default behavior if the pressed key is not a number, a dot, or a backspace
                        if (isNaN(keyPressed) && keyPressed !== '.' && keyPressed !== 'Backspace') {
                          event.preventDefault();
                          event.stopPropagation();
                        }

                        // Allow only one dot in the input value
                        if (keyPressed === '.' && currentValue.includes('.')) {
                          event.preventDefault();
                          event.stopPropagation();
                        }

                        // Allow only non-negative numbers
                        if (keyPressed === '-'  || keyPressed === 'e') {
                          event.preventDefault();
                          event.stopPropagation();
                        }

                        // Allow only up to 2 decimal places
                        if (currentValue.includes('.') && keyPressed !== 'Backspace') {
                          const decimalPlaces = currentValue.split('.')[1].length;
                          if (decimalPlaces >= 2) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }
                      }
                      }
                      onBlur={ () => { checkForChanges(jobRate.current.innerText, "RATE") }}
                      >{job && job.rate}</p>
                    </span>
                  </div>
                  <div className="w-50 d-flex justify-content-end">
                    <div className="plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={() => viewPhotoPlan(JOB_PLAN.FLAT.value)}
                      >
                        FLAT PLAN
                      </span>
                    </div>
                    <div className="ml-3 plan_wrapper">
                      <span
                        className="bread_crumbs-active"
                        onClick={() => viewPhotoPlan(JOB_PLAN.FLOOR.value)}
                      >
                        FLOOR PLAN
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12} xl={12} className="d-flex align-items-center">
                  <span contentEditable={pageEditable}
                  ref={jobDescription}
                  style={editableFieldStyling}
                  onBlur={ () => { checkForChanges(jobDescription.current.innerText, "DESCRIPTION") }}
                  >{job && job.description}</span>
                </Col>
              </Row>
              <div className="hr_wrapper">
                <hr />
              </div>
              <Row>
                <Col
                  md={12}
                  xl={12}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <h3 className="page-subhead subhead m-0">Level &nbsp;</h3>
                    <p className="m-0 p-1" >{job && job.level.name}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="page-subhead subhead m-0">Block &nbsp;</h3>
                    <p className="m-0 p-1" >{job && job.block.name}</p>
                  </div>
                  {job && job.unit_type && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">
                        Unit type &nbsp;
                      </h3>
                      <p className="m-0">{job.unit_type}</p>
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <h3 className="page-subhead subhead m-0">Unit ID &nbsp;</h3>
                    <p className="m-0 p-1" >{job && job.plot.name}</p>
                  </div>
                  {job && job.sage_reference && (
                    <div className="d-flex align-items-center">
                      <h3 className="page-subhead subhead m-0">
                        Sage reference &nbsp;
                      </h3>
                      <p className="m-0">{job.sage_reference}</p>
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        {/* Account info */}
        <Col md={6} xl={6} className="p-0 d-flex justify-content-between">
          <Col md={6} xl={6} className="pl-0">
            <CardBody className="d-flex flex-column">
              <div className="wrapper_page-title mb-2">
                <p className="page-title m-0">OPERATIVE</p>
              </div>
              <Row className="h-100 pl-3 d-flex justify-content-center">
                <div className="workers_account_wrapper d-flex w-100">
                  <AccountInfo
                    classPrefix="prev_account--small"
                    data={job && job.employee}
                  />
                </div>
              </Row>
            </CardBody>
          </Col>
          <Col md={6} xl={6} className="pl-0">
            <CardBody className="d-flex flex-column">
              <div className="wrapper_page-title mb-2">
                <p className="page-title m-0">QUALITY ASSURANCE</p>
              </div>
              <Row className="h-100 pl-3 d-flex justify-content-center">
                <div className="workers_account_wrapper d-flex w-100">
                  <AccountInfo
                    classPrefix="prev_account--small"
                    data={job && job.qa}
                  />
                </div>
              </Row>
            </CardBody>
          </Col>
        </Col>
      </Row>

      {/* REQUESTS ADDITIONAL WORK */}
      <Row style={{ overflowX: "auto", flexWrap: "nowrap" }} className="mb-4">
        <RequestsSteps getRequestsByJob={getRequestsByJob} data={requests} />
      </Row>

      <Row className="mb-5">
        {/* OPERATIVE STEPS */}
        <Col md={6} xl={6}>
          <Card className="h-auto">
            <CardBody className="pb-0">
              <Row className="mb-3">
                <Col md={12} xl={12}>
                  <div className="wrapper_page-title d-flex justify-content-between">
                    <h5 className="page-title m-0">OPERATIVE</h5>
                    {job &&
                      job.steps.length > 0 &&
                      (photo_load.printReady ? (
                        <div className="hover">
                          <ReactToPrint
                            trigger={() => (
                              <a>
                                <PrintIcon />
                              </a>
                            )}
                            content={() => componentOperativeRef.current}
                          />
                        </div>
                      ) : (
                        <div>
                          Loading photos... &nbsp; <InactivePrintIcon />
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
              {job && job.steps.length > 0 ? (
                <StepCard data={steps}
                typeCard="STEP"
                isPageEditable={pageEditable}
                addToEditedField={addToEditedField}
                EditedFields={fields}
                />
              ) : (
                <div className="mb-4 d-flex justify-content-center align-items-center">
                  <p>Not Found</p>
                </div>
              )}
              <div style={{ display: "none" }}>
                <RenderOperativeComponent
                  ref={componentOperativeRef}
                  job={job}
                />
              </div>
            </CardBody>
          </Card>

          <Card className="mt-3 h-auto mb-3">
            <CardBody className="pb-0">
              <Row className="mb-3">
                <Col md={12} xl={12}>
                  <div className="wrapper_page-title d-flex justify-content-between">
                    <h5 className="page-title m-0 text-uppercase">
                      Budget variations
                    </h5>
                    {requests &&
                      requests.length > 0 &&
                      (photo_load.printReady ? (
                        <div className="hover">
                          <ReactToPrint
                            trigger={() => (
                              <a>
                                <PrintIcon />
                              </a>
                            )}
                            content={() => budgetComponentRef.current}
                          />
                        </div>
                      ) : (
                        <div>
                          Loading photos... &nbsp; <InactivePrintIcon />
                        </div>
                      ))}
                    <div style={{ display: "none" }}>
                      <RenderBudgetPrintComponent
                        ref={budgetComponentRef}
                        requests={requests}
                        job={job}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {requests && requests.length > 0 ? (
                <BudgetVariations data={requests} typeCard="Reason" />
              ) : (
                <div className="mb-4 d-flex justify-content-center align-items-center">
                  <p>Not Found</p>
                </div>
              )}
            </CardBody>
          </Card>

        </Col>

        {/* QA STEPS */}
        <Col md={6} xl={6} className="pl-0">
          <Card className="h-auto">
            <CardBody className="pb-0">
              <Row className="mb-3">
                <Col md={12} xl={12}>
                  <div className="wrapper_page-title d-flex justify-content-between">
                    <h5 className="page-title m-0">QUALITY ASSURANCE</h5>
                    {job &&
                      job.questions.length > 0 &&
                      (photo_load.printReady
                      ? <div className="hover">
                          <ReactToPrint
                            trigger={() =>
                              <a><PrintIcon/></a>
                            }
                            content={() => componentRef.current}
                          />
                        </div>
                       :
                        <div>
                          Loading photos... &nbsp; <InactivePrintIcon />
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              {job && job.questions.length > 0
              ? <StepCard data={questions}
                typeCard="QUESTION"
                isPageEditable={pageEditable}
                addToEditedField={addToEditedField}
                EditedFields={fields}
                />
              : <div className="mb-4 d-flex justify-content-center align-items-center">
                  <p>Not Found</p>
                </div>
              }
              <div style={{ display: "none" }}>
                <RenderComponent ref={componentRef} job={job} />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-3">
          <Card>
            <CardBody>
              <Row className="pl-4 pr-4">
              <div
                className="w-100 wrapper_page-title d-flex justify-content-between align-items-center hover"
              >
                <p className="page-title m-0">JOB HISTORY LOG</p>
              </div>
              </Row>
              <Row className="pl-3 pr-3">
                <JobHistoryTable jobId={match.params.id} onInvoicePressed={(invoiceId) => {
                  toggleInvoiceModal();
                  getInvoiceId(invoiceId)}}/>
              </Row>
            </CardBody>
          </Card>
          </Col>
      </Row>

      <PhotoViewer
        closeLightbox={closeLightbox}
        currentImage={currentImage}
        photos={photos}
        viewerIsOpen={viewerIsOpen}
      />
      {deleteJobModal && (
        <DeleteJobModal
          isModalOpen={deleteJobModal}
          toggleModal={toggleJobDeletionModal}
          match={match}
          job={job}
          history={history}
        />
      )}
      {
        resetJobModal &&
        <ResetJobModal
          isModalOpen={resetJobModal}
          toggleModal={toggleJobResetModal}
          job={job}
          history={history}
        />
      }
      {invoiceModalOpen && (
          <InvoiceModal
              invoiceModal={invoiceModalOpen}
              toggleInvoiceModal={toggleInvoiceModal}
              invoiceItem={invoiceItem}
              loadInvoiceModal={loadInvoiceModal}
          />
      )}
    </Container>
  );
};

const RenderComponent = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <StepForPrint job={props.job} />
  </div>
));

const RenderBudgetPrintComponent = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <BudgetVariationsPrint requests={props.requests} job={props.job} />
  </div>
));

const RenderOperativeComponent = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <OperativeForPrint job={props.job} />
  </div>
));

const mapDispatchToProps = {
  setStatus,
  setTimeOutID,
};

function mapStateToProps({ photo_load }) {
  return { photo_load };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewJob)
);
