import React from 'react'
import { Row } from 'reactstrap'
import { InfoIcon } from '../../constants/icons'
import Tooltip from './Tooltip'
import {formatPrice} from "../../helpers/function";

const BudgetElement = ({
  title,
  budget,
  icon,
  tooltipInfo
}) => {
  return (
    <Row className={'flex flex-row align-items-center m-2'}>
      <div
      className={'bg-light-gray border-radius-full budget-element'}
      >
        {icon}
      </div>
      <div className={'pl-3 d-flex flex-column justify-items-center'}>
        <div>
          <h5 className={budget < 0 ? 'text-red' : null}><b>{formatPrice(budget)}</b></h5>
        </div>
        <div className={'d-flex flex-row align-items-center'}>
          <h5 className={budget < 0 ? 'text-red' : null}>{title}</h5>
          {
          title === 'Difference' ?
          <Tooltip text={tooltipInfo} className={'ml-2'}>
            <InfoIcon
             size={16}
            />
          </Tooltip>
          : null
          }
        </div>
      </div>
    </Row>
  )
}

export default BudgetElement
