import axios from "axios";
import { BASE_API } from "../../config/api";

export const editStepOrQuestion = async (element) => {
    const body = {
        typeOfField: element.typeOfField,
        newText: element.item.newDescription
    }
    await axios
    .patch(`${BASE_API}admin/step-or-question/${element.item.id}/update`, body)
    .then((response) => response && response.data)
    .catch((error) => error.message);
}
  

export const createStepOrQuestion = async (element, jobId, stepOrQuestionNumber) => {
  if(element.typeOfField === "STEP"){
    await axios
    .post(`${BASE_API}admin/step-or-question/add`, {
      typeOfField: element.typeOfField,
      jobId: jobId,
      operativeComment: "",
      stepName: `Step ${stepOrQuestionNumber}`,
      text: element.item.description,
      order: stepOrQuestionNumber,
      isPhotoMandatory: element.item.photoMandatory
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);
  } 
  if(element.typeOfField === "QUESTION") {
    await axios
    .post(`${BASE_API}admin/step-or-question/add`, {
      typeOfField: element.typeOfField,
      jobId: jobId,
      pmComment: "",
      status: "UNDEFINED",
      type: "OPTIONAL",
      name: `Q${stepOrQuestionNumber}`,
      text: element.item
    })
    .then((response) => response && response.data)
    .catch((error) => error.message);
  }
}

export const deleteStepOrQuestion = async(element, typeOfField) => {
  await axios
  .post(`${BASE_API}admin/step-or-question/delete`, {
    typeOfField: typeOfField,
    fieldId:element.item.id
  })
  .then((response) => response && response.data)
  .catch((error) => error.message);
}

export const editJobRateOrDescription = async(newValue, jobId, typeOfField) => {
  await axios
  .patch(`${BASE_API}admin/job/edit`, {
    typeOfField: typeOfField,
    jobId: jobId,
    value: newValue
  })
  .then((response) => response && response.data)
  .catch((error) => error.message);
}
  
