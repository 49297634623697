import React from "react";
import { Card, CardBody, Row } from "reactstrap";

const ImagesCard = ({
  item,
  moreImageID,
  toggleMoreImage,
  getPhotosByStep,
  cardClass,
}) => {
  return (
    <Row>
      {moreImageID !== item.id && (
        <div className={`img_question_wrapper ${cardClass}`}>
          {item.assets && item.assets.length > 4 ? (
            <>
              {item.assets.map(
                (item, index) =>
                  index < 4 && (
                    <img
                      key={index}
                      src={item.public_url}
                      alt={`#${index}`}
                      onClick={() =>
                        getPhotosByStep(
                          (item.step_id && item.step_id) ||
                            (item.question_id && item.question_id) ||
                            (item.additional_work_id &&
                              item.additional_work_id) ||
                            (item.day_work_id && item.day_work_id),
                          index
                        )
                      }
                    />
                  )
              )}
              <div
                className="get_more_img"
                onClick={() => toggleMoreImage(item.id)}
              >
                <img src={item.assets[4].public_url} alt="more img" />
                <p>+{item.assets.length - 4}</p>
              </div>
            </>
          ) : (
            <>
              {item.assets.map((item, index) => (
                <img
                  key={index}
                  src={item.public_url}
                  alt={`#${index}`}
                  onClick={() =>
                    getPhotosByStep(
                      (item.step_id && item.step_id) ||
                        (item.question_id && item.question_id) ||
                        (item.additional_work_id && item.additional_work_id) ||
                        (item.day_work_id && item.day_work_id),
                      index
                    )
                  }
                />
              ))}
            </>
          )}
        </div>
      )}
      {moreImageID === item.id && (
        <div className="img_question_wrapper-collapse">
          {item.assets.map((item, index) => (
            <img
              key={index}
              src={item.public_url}
              alt={`#${index}`}
              onClick={() =>
                getPhotosByStep(
                  (item.step_id && item.step_id) ||
                    (item.question_id && item.question_id) ||
                    (item.additional_work_id && item.additional_work_id) ||
                    (item.day_work_id && item.day_work_id),
                  index
                )
              }
            />
          ))}
        </div>
      )}
      {!cardClass && (
        <Card>
          <CardBody className="p-0">
            <div className="description_question_wrapper">
              <p>
                {(item.operative_comment && item.operative_comment) ||
                  (item.pm_comment && item.pm_comment) ||
                  (item.description && item.description)}
              </p>
            </div>
          </CardBody>
        </Card>
      )}
    </Row>
  );
};

export default ImagesCard;
