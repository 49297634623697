import React from 'react';
import Dropzone from 'react-dropzone';
import { Col, Card } from 'reactstrap';

const DropZoneField = ({ getUploadFile, customHeight, name, accept }) => {
    const onDrop = (file) => {
        getUploadFile(file[0])
    }

    return (
        <Col md={12} lg={12} className="mb-4 p-0">
            <Card className="card--not-full-height">
                <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}`}>
                    <Dropzone
                        accept={accept ? accept : ""}
                        name={name}
                        multiple={false}
                        onDrop={(fileToUpload) => onDrop(fileToUpload)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone__input">
                                <div className="dropzone__drop-here">
                                    <div>
                                        <div className="wrapper_page-title">
                                            <p className="form__form-group-label m-0">Select file</p>
                                        </div>
                                    </div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16V10H5L12 3L19 10H15V16H9ZM5 20V18H19V20H5Z" fill="#95A4B1" />
                                    </svg>
                                </div>
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                </div>
            </Card>
        </Col>
    );
}


const renderDropZoneField = (props) => {
    const { input, customHeight, getUploadFile, accept } = props;
    return (
        <DropZoneField
            {...input}
            customHeight={customHeight}
            getUploadFile={getUploadFile}
            accept={accept}
        />
    );
};

export default renderDropZoneField;