import React, { useState } from "react";
import { Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { CreateIcon } from "../../../../constants/icons";
import TextFieldMaterial from "../../../../shared/components/material_field/TextFieldMaterial";

const minValue = (min) => (value) =>
  value && value <= min ? `Value must be greater than ${min}` : undefined;

const minValueDefault = minValue(0);

const requiredField = (value) => (value ? undefined : "You must enter a value");
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength5 = maxLength(5);

var values = ["", 0, 0];

let AddBlockForm = ({ handleSubmit, reset }) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const toggle = () => setModalIsOpened(!modalIsOpened);

  return (
    <div>
      <Button className="button" onClick={toggle}>
        <div className="addButton">
          <div className="mr-2">
            <CreateIcon />
          </div>
          Add new block
        </div>
      </Button>

      <Modal
        isOpen={modalIsOpened}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--primary locum-preview-modal createBlockModal`}
      >
        <ModalHeader className="modal-header">
          <div>
            <h3>Add new block</h3>
          </div>
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => {
              toggle();
              reset();
            }}
          />
        </ModalHeader>

        <ModalBody className="createBlockModalBody">
          <form
            id={"add_block_form"}
            onSubmit={(event) => {
              handleSubmit(event);
              event.stopPropagation();

              if (
                values[0].length <= 5 &&
                parseInt(values[1]) > 0 &&
                values[1].length <= 5 &&
                parseInt(values[2]) > 0
              ) {
                values = ["", 0, 0];
                toggle();
                reset();
              }
            }}
          >
            <Row md={12} className="mt-2">
              <Field
                name="id"
                component={TextFieldMaterial}
                placeholder="Enter Block Letter/Number/ID/test"
                type="text"
                validate={[requiredField, maxLength5]}
                onChange={(value) => {
                  values[0] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="noOfLevels"
                component={TextFieldMaterial}
                placeholder="Enter Number of Levels"
                type={"number"}
                validate={[requiredField, maxLength5, minValueDefault]}
                onChange={(value) => {
                  values[1] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4">
              <Field
                name="quantity"
                component={TextFieldMaterial}
                placeholder="Enter quantity of properties/plots/units"
                type={"number"}
                validate={[minValueDefault, requiredField]}
                onChange={(value) => {
                  values[2] = value.currentTarget.value;
                }}
              />
            </Row>

            <Row md={12} className="mt-4 justify-content-end">
              <Row md={12} className="justify-content-end">
                <Col md={5}>
                  <Button
                    className="cancelButton"
                    onClick={() => {
                      toggle();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={5}>
                  <Button
                    className="button"
                    type={"submit"}
                    form={"add_block_form"}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: "add_block_form",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(AddBlockForm);
