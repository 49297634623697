import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import DayWorkTable from './Table/DayWorkTable';
import {CONTRACTS, PREVIEW_CONTRACT, PREVIEW_PROJECT} from "../../constants/routes";
import {Breadcrumbs} from "@material-ui/core";
import {getDayWorkRequests} from "../../redux/services/requests";

const DayWork = ({match}) => {
    const projectId = match.params.id
    const [dayWork, setDayWork] = useState(null);
    const [load, setLoad] = useState(false);
    const [page, setPage] = useState(0);

    useEffect(() => {
        setLoad(true);
        getDayWorkRequest();
    }, [page]);

    const getDayWorkRequest = () => {
        getDayWorkRequests(+projectId, page).then((res) => {
            setDayWork(res);
            setLoad(false);
        });
    };

    const project = dayWork && dayWork.project ? dayWork.project : null
    const contract = project && project.projectGroup ? project.projectGroup : null

    return (
        <Container>
            <Breadcrumbs aria-label="breadcrumb" separator={<h3 className={"page-subhead subhead bread_crumbs-not_active"}>/</h3>}>
                <Link to={{pathname: CONTRACTS}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        Contracts
                    </h3>
                </Link>
                {contract && <Link to={{pathname: PREVIEW_CONTRACT.replace(':id',contract.id)}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!contract.name ? contract.name : `Current Contract`}
                    </h3>
                </Link>}
                {project && project.id && <Link to={{pathname: PREVIEW_PROJECT.replace(':id',project.id).replace(':status', 'ACTIVE')}} >
                    <h3 className={"page-subhead subhead bread_crumbs-not_active"}>
                        {!!project.name ? project.name : 'Current Project'}
                    </h3>
                </Link> }

                <Link to={{pathname: ''}} >
                    <h3 className={"page-subhead subhead bread_crumbs-active"}>
                        Daywork Requests
                    </h3>
                </Link>
            </Breadcrumbs>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Day Work</h5>
                    </Col>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <DayWorkTable dayWork={dayWork} load={load} page={page} getDayWorkRequest={getDayWorkRequest} setLoad={setLoad} setPage={setPage} />
                </CardBody>
            </Card>
        </Container>
    )
}

export default withRouter(DayWork);
