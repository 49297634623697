import React, { useState } from 'react';
import LogInForm from './LogInForm';
import { loginUser } from '../../../redux/services/auth.js'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as LogoIcon } from '../../../shared/img/logo/logo.svg';
import { ReactComponent as FooterIcon } from '../../../shared/img/footer_logo.svg';

const LoginCard = ({ history, auth }) => {
    const [disSubmit, setDisSubmit] = useState(false)

    const login = (user, history) => {
        setDisSubmit(true)
        loginUser(user, history)
            .then(res => res && setDisSubmit(false))

    }

    return (
        <div className="account__wrapper">
            <div className="account__card">
                <div className="account__head">
                    <LogoIcon />
                </div>
                <LogInForm onSubmit={user => login(user, history)} errorMessage={auth.error} disSubmit={disSubmit} />
            </div>
            <div className="w-100 d-flex justify-content-center align-items-end">
                <FooterIcon />
            </div>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default withRouter(connect(
    mapStateToProps
)(LoginCard));
