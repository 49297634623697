import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import {
  JobTemplatesIcon,
  TemplatesIcon,
  QAStepsIcon,
  OperativeStepsIcon,
  VATIcon,
  InvoicesIcon,
} from "../../../constants/icons";
import {APPROVAL_DAYWORKS, APPROVAL_JOBS, APPROVAL_UPLIFTS} from "../../../constants/routes";

const SidebarLinkTemplates = ({ title, icon, onClick, sidebar }) => {
  const [collapseIsOpened, setCollapseIsOpened] = useState(false);
  const [approvalsIsOpened, setApprovalsIsOpened] = useState(false);

  return (
    <div>
      <div
        onClick={() => {
          setCollapseIsOpened(!collapseIsOpened);
        }}
      >
        <li className={sidebar ? "sidebar__link--collapse" : "sidebar__link"}>
          <TemplatesIcon />
          <p className="sidebar__link-title">{title}</p>

          {!sidebar.collapse ? (
            <span
              className={`lnr lnr-chevron-down`}
              style={{
                position: "absolute",
                right: 20,
                transform: collapseIsOpened
                  ? "rotate(-180deg)"
                  : "rotate(0deg)",
                transition: "ease-in-out 0.4s ",
              }}
            />
          ) : null}
        </li>
      </div>
      <Collapse isOpen={collapseIsOpened} timeout={400}>
        <NavLink
          to={"/jobTemplates"}
          onClick={onClick}
          activeClassName="sidebar__link-active"
        >
          <li
            className={
              sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"
            }
          >
            <JobTemplatesIcon />
            <p className="sidebar__link-title">Job templates</p>
          </li>
        </NavLink>

        <NavLink
          to={"/operativeSteps"}
          onClick={onclick}
          activeClassName="sidebar__link-active"
        >
          <li
            className={
              sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"
            }
          >
            <OperativeStepsIcon />
            <p className="sidebar__link-title">Operative steps</p>
          </li>
        </NavLink>

        <NavLink
          to={"/qaSteps"}
          onClick={onclick}
          activeClassName="sidebar__link-active"
        >
          <li
            className={
              sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"
            }
          >
            <QAStepsIcon />
            <p className="sidebar__link-title">QA steps</p>
          </li>
        </NavLink>

        <NavLink
          to={"/vatTemplates"}
          onClick={onClick}
          activeClassName="sidebar__link-active"
        >
          <li
            className={
              sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"
            }
          >
            <VATIcon />
            <p className="sidebar__link-title">VAT templates</p>
          </li>
        </NavLink>

        <NavLink
          to={"/generateInvoices"}
          onClick={onClick}
          activeClassName="sidebar__link-active"
        >
          <li
            className={
              sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"
            }
          >
            <InvoicesIcon />
            <p className="sidebar__link-title">Generate Invoices</p>
          </li>
        </NavLink>
        <div>
          <div onClick={() => {
            setApprovalsIsOpened(!approvalsIsOpened);
          }}>
            <li className={sidebar ? "sidebar__link--collapse" : "sidebar__link"}>
              <TemplatesIcon />
              <p className="sidebar__link-title">Approvals</p>
              {!sidebar.collapse ? (
                  <span
                      className={`lnr lnr-chevron-down`}
                      style={{
                        position: "absolute",
                        right: 20,
                        transform: approvalsIsOpened
                            ? "rotate(-180deg)"
                            : "rotate(0deg)",
                        transition: "ease-in-out 0.4s ",
                      }}
                  />
              ) : null}
            </li>
          </div>
          <Collapse isOpen={approvalsIsOpened} timeout={400}>
            <NavLink to={APPROVAL_DAYWORKS}
                     onClick={onclick}
                     activeClassName="sidebar__link-active">
              <li className={sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"}>
                <p className="sidebar__link-title">Dayworks</p>
              </li>
            </NavLink>
            <NavLink to={APPROVAL_UPLIFTS}
                     onClick={onclick}
                     activeClassName="sidebar__link-active">
              <li className={sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"}>
                <p className="sidebar__link-title">Uplifts</p>
              </li>
            </NavLink>
            <NavLink to={APPROVAL_JOBS}
                     onClick={onclick}
                     activeClassName="sidebar__link-active">
              <li className={sidebar ? "sidebar__link--collapse ml-2" : "sidebar__link"}>
                <p className="sidebar__link-title">Jobs</p>
              </li>
            </NavLink>
          </Collapse>
        </div>

      </Collapse>
    </div>
  );
};

SidebarLinkTemplates.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarLinkTemplates.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => {},
};

export default SidebarLinkTemplates;
