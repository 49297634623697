import React, { useState, useEffect } from "react"
import { Row, Col } from 'reactstrap';
import MultiLinearProgressBar from "../../../../shared/components/progress/MultiLinearProgressBar"
import Loading from "../../../../shared/components/Loader"
import { getWorkDeploymentAnalitycs } from "../../../../redux/services/projects"

const WorkDeployment = ({ id }) => {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        setLoad(true)
        getWorkDeploymentAnalitycs(id)
            .then(res => {
                setLoad(false)
                setData(res.data)
            })
    }, [])

    return (
        load
            ? <Loading />
            : <>
                <div className="wrapper_page-title">
                    <h5 className="page-title m-0">WORK DEPLOYMENT</h5>
                </div>
                <Row className="mt-3 d-flex justify-content-between">
                    <Col md={6} xl={6} className="border-right">
                        <div className="lg_wrapper_page-title">
                            <p className="page-title m-0 text-blue">{data && data.counter_of_active_users}</p>
                        </div>
                        <div className="wrapper_page-title">
                            <span className="form__form-group-label m-0">ASSIGNED OPERATIVES</span>
                        </div>
                    </Col>
                    <Col md={5} xl={5}>
                        <div className="lg_wrapper_page-title">
                            <p className="page-title m-0 text-gray">{data && data.counter_of_inactive_users}</p>
                        </div>
                        <div className="wrapper_page-title">
                            <span className="form__form-group-label m-0">IDLE OPERATIVES</span>
                        </div>
                    </Col>
                </Row>
                <div className="wrapper_page-title mt-3">
                    <p className="form__form-group-label m-0">Total operatives</p>
                </div>
                <Row className="mt-1 mb-2">
                    <Col md={12} xl={12}>
                        <MultiLinearProgressBar one='blue' two='gray' oneValue={data && +data.counter_of_active_users} twoValue={data && +data.counter_of_inactive_users} />
                    </Col>
                </Row>
            </>
    )
}

export default WorkDeployment