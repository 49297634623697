import React from 'react'

const EvidencePrint = ({variationOrder}) => {
  return (
    <div>
      {
        variationOrder.dayWorks && 
        variationOrder.dayWorks.length &&
        variationOrder.dayWorks.map( daywork => 
          daywork.assets && daywork.assets.length &&
          daywork.assets.map( asset => 
            <div key={asset.public_url} className='vh-100 vw-100 relative'> 
              <img
                src={asset.public_url}
                object-fit='cover'
                fill="true"
                alt='Evidence for a daywork request'
              />
            </div>
          )
        )
      }
      {
        variationOrder.additionalWorks && 
        variationOrder.additionalWorks.length &&
        variationOrder.additionalWorks.map( additionalWork => 
          additionalWork.assets && additionalWork.assets.length &&
          additionalWork.assets.map( asset => 
          asset &&
            <div className='vh-100 vw-100 relative'> 
              <img
                src={asset.public_url}
                object-fit='cover'
                fill="true"
                alt='Evidence for a budget variation request'
              />
            </div>
          )
        )
      }
    </div>
  )
}

export default EvidencePrint