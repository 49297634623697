import React from 'react'
import Moment from 'react-moment'
import { ActiveIcon } from '../../../../constants/icons'

export default function JobLogRow({ index, item, onInvoicePressed }){
  const creatorsNames = {
    ADMIN: !!item.admin && `${item.admin.first_name} ${item.admin.surname}`,
    PM: !!item.pm && `${item.pm.first_name} ${item.pm.surname}`,
    OPERATIVE: !!item.operative && `${item.operative.first_name} ${item.operative.surname}`,
    ADMIN_PM: !!item.admin && `${item.admin.first_name || item.pm.first_name} ${item.admin.surname || item.pm.surname}`,
    QA: !!item.qa && `${item.qa.first_name} ${item.qa.surname}`,
    PM_SYSTEM: !!item.pm ? `${item.pm.first_name} ${item.pm.surname}` : 'System',
    SYSTEM: 'System',
  }

  const quantityString = item.action === 'JOB_REQUESTED' ? `(Quantity: ${JSON.stringify(item.job_application.request_qty)})` : null;

  return (
    <tr key={index} >
      <td className="td-project">
      <div className="d-flex">
        <div>
        <ActiveIcon /> &nbsp; {item.action_description} {quantityString}  &nbsp;
        </div>
          {!!item && !!item.action && item.action === 'INVOICE' && !!item.invoice && !!item.invoice.id &&
              <div onClick={() => onInvoicePressed(item.invoice.id)} style={{color: '#70bbfd'}}>
                  {`#${item.invoice.id}`}
              </div>
          }
      </div>
      </td>
      <td>
        {!!item && !!item.creator && creatorsNames[item.creator]}
      </td>
      <td>
        <Moment format="DD-MM-YYYY HH:mm">
          {item.created_at && item.created_at}
        </Moment>
      </td>
    </tr>
  )
}
