import axios from "axios";
import moment from "moment";
import { BASE_API } from "../../config/api";
import {
    defaultPage, pageSize,
    pageSize as defaultPageSize,
} from "../../config/pagination";

export const getDayWorkByIdAsync = async (id, filter, page, pageSize) =>
    await axios
        .post(`${BASE_API}day-work/${id}`, {
            filter: filter,
            pagination: {
                page: page ? page : defaultPage,
                pageSize: pageSize ? pageSize : defaultPageSize,
            },
        })
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const updateDayWorkByIdAsync = async (id, status, comment) =>
    await axios
        .patch(`${BASE_API}day-work/${id}`, {
            status: status,
            deny_comment: comment,
        })
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const addNoteToDayWorkAsync = async (id, note) => {
    return await axios
        .patch(`${BASE_API}day-work/${id}/note`, {
            note: note
        })
        .then((response) => response)
        .catch((error) => error.message);
}

export const toggleDayworkHold = async (id, status, comment) =>
    await axios
        .get(`${BASE_API}day-work/${id}/toggle-hold`)
        .then((response) => response && response.data)
        .catch((error) => error.message);


export const getDayWorkLogsByIdAsync = async (dayworkId) =>
    await axios
        .get(`${BASE_API}day-work-log/logs/${dayworkId}`)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const unassignDayworkVOAsync = async (id) =>
    await axios
        .patch(`${BASE_API}day-work/${id}/remove-variation-order`)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const addVoToDayWorksAsync = async (data) =>
    await axios
        .patch(`${BASE_API}day-work/`, data)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const addVoCategoryToDayWorksAsync = async (id, data) =>
    await axios
        .patch(`${BASE_API}day-work/${id}/add-variation-order-category/`, data)
        .then((response) => response && response.data)
        .catch((error) => error.message);

export const getUninvoicedDayWorkAsync = async (id, page) =>
    await axios.post(`${BASE_API}day-work/uninvoiced`, { userId: id, pagination: { page: page ? page : defaultPage, pageSize: pageSize } })
        .then(response => response && response.data)
        .catch(error => error.message);

export const exportDayworkToCsvAsync = async(data) =>
    await axios
        .post(`${BASE_API}day-work/export`, {
            ...data,
        })
        .then((response) => {
            const csv = response && response.data;
            const pom = document.createElement("a");
            const csvContent = csv;
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute(
                "download",
                `pw_daywork_export-${moment().format("DD-MM-YYYY")}.csv`
            );
            pom.click();
        })
        .catch((error) => error.message);
